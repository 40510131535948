<form action="http://cmu.on.worldcat.org/search" target="_blank" method="get" ngNoForm>
  <div class="w3-row">
    <h3>Find <span>Books</span></h3>
  </div>
  <div class="w3-row">

    <div class="w3-row w3-right-align">
      <a class="w3-hover w3-rigth w3-tiny w3-text-purple" title="Advanced Search" href="http://cmu.on.worldcat.org/advancedsearch" target="_blank">Advanced Search</a>
    </div>
  </div>
  <div class="w3-row">
    <div class="w3-col w3-theme-d1" style="width:90%">
      <input type="hidden" name="sortKey" value="LIBRARY_PLUS_RELEVANCE">
      <input placeholder="Find books...." id="query2" name="queryString"  class="w3-input w3-border">
      <input type="hidden" name="format" value="Book">
      <input type="hidden" name="subformat" value="Book::book_printbook">
      <input type="hidden" name="subformat" value="Book::book_digital">
      <input type="hidden" name="subformat" value="Book::book_thsis">
      <input type="hidden" name="subformat" value="Book::book_mic">
      <input type="hidden" name="subformat" value="Book::book_largeprint">
      <input type="hidden" name="subformat" value="Book::book_braille">
      <input type="hidden" name="subformat" value="Book::book_continuing">
      <input type="hidden" name="database" value="all">
      <input type="hidden" name="author" value="all">
      <input type="hidden" name="year" value="all">
      <input type="hidden" name="yearFrom" value="">
      <input type="hidden" name="yearTo" value="">
      <input type="hidden" name="lang" value="en">

    </div>
    <div class="w3-col"  style="width:10%;">
      <button class="w3-botton w3-white w3-left w3-xlarge w3-theme-d1"><span class="fa fa-search w3-xlarge"></span></button>
      <input type="hidden" name="lang" value="en">
    </div>
  </div>
  <div class="w3-row">

    <label class="w3-tiny">Limit results to:</label>
    <select class="w3-select w3-theme-l5" name="scope">
      <option value="">Libraries Worldwide</option>
      <option value="sz:36683">Thai Libraries Consortium</option>
      <option value="wz:59814">Chiang Mai University Library</option>
    </select>
  </div>
  <div class="w3-row w3-margin">
    <a href="http://library.cmu.ac.th/cmul/sites/default/files/web/CMU-WorldCatWorldshare2016.pdf" target="_blank" class="left"><i class="fa fa-file-pdf-o" aria-hidden="true"></i><!-- {{text_wordcat}}--></a>
  </div>

</form>
