import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-switchlang',
  templateUrl: './switchlang.component.html',
  styleUrls: ['./switchlang.component.scss']
})
export class SwitchlangComponent implements OnInit {
  public iconth = false;
  public iconen = false;
  constructor() { }
  ngOnInit(): void {
    if (localStorage.getItem('lang') === 'En'){
      localStorage.setItem('lang', 'En');
    } else {
      localStorage.setItem('lang', 'Th');
    }
    if (localStorage.getItem('lang') === 'En' ) {
      this.iconen = false;
      this.iconth = true;
    }else{
      this.iconen = true;
      this.iconth = false;
    }
    // console.log(localStorage.getItem('lang'));
  }
  OnClickTh(): any{
    localStorage.setItem('lang', 'Th');
    window.location.reload();
  }
  OnClickUs(): any{
    localStorage.setItem('lang', 'En');
    window.location.reload();
  }
}
