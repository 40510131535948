<div class="row">
  <div class="container">
    <div class="col-12">
      <i class="fas fa-step-backward fa-2x text-warning" (click)="ClickBack()">Back</i>
    </div>
  </div>
  <div class="container">
    <ng-container *ngIf="ContentTh;else ContentEn">
      <div class="card">
        <div class="container-fliud">
          <div class="wrapper row">
            <div class="preview col-md-6 text-center">
              <div class="preview-pic">
                <div class=""><img src="{{pathimage}}{{DataItem.ImageSSTh}}"/>
                </div>
              </div>
            </div>

            <div class="details col-md-6">
              <ng-container *ngIf="DataItem.TitleTh; else NoTitleTh">
                <h3 class="product-title">{{DataItem.TitleTh}}</h3>
              </ng-container>
              <ng-template #NoTitleTh>
                <h3 class="product-title">{{DataItem.TitleEn}}</h3>
              </ng-template>
              <ng-container *ngIf="DataItem.ContentTh; else NoDetailTh">
                รายละเอียด :<p [innerHTML]="DataItem.ContentTh"></p>
              </ng-container>
              <ng-template #NoDetailTh>
                Detail : <p [innerHTML]="DataItem.ContentEn"></p>
              </ng-template>

            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #ContentEn>
      <div class="card">
        <div class="container-fliud">
          <div class="wrapper row">
            <div class="preview col-md-6 text-center">
              <div class="preview-pic">
                <div id="{{DataItem.ImageSSTh}}"><img src="{{pathimage}}{{DataItem.ImageSSTh}}"/>
                </div>
              </div>
            </div>

            <div class="details col-md-6">
              <ng-container *ngIf="DataItem.TitleEn; else Notitleen">
                <h3 class="product-title">{{DataItem.TitleEn}}</h3>
              </ng-container>
              <ng-template #Notitleen>
                <h3 class="product-title">{{DataItem.TitleTh}}</h3>
              </ng-template>
              <ng-container *ngIf="DataItem.ContentEn; else NoDetailEn">
                Detail : <p [innerHTML]="DataItem.ContentEn"></p>
              </ng-container>
              <ng-template #NoDetailEn>
                รายละเอียด :<p [innerHTML]="DataItem.ContentTh"></p>
              </ng-template>

            </div>
          </div>
        </div>
      </div>

    </ng-template>








  </div>
  <div class="container mt-2">

  </div>
</div>
