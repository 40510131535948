import { Component, OnInit } from '@angular/core';
import {ApiwebService} from '../../../apiweb.service';
declare var jQuery: any;
@Component({
  selector: 'app-homenavigationtop',
  templateUrl: './homenavigationtop.component.html',
  styleUrls: ['./homenavigationtop.component.scss']
})
export class HomenavigationtopComponent implements OnInit {

  public DataMenuNavigation;
  constructor(public apiweb: ApiwebService) { }

  ngOnInit(): void {
    this.CallDataManu(this.apiweb.SiteFaculty);
  }
  CallDataManu(Factulty): void {
    this.apiweb.CallMenu(Factulty).subscribe((dataresponse: any) => {
      const data = dataresponse.data;
      this.DataMenuNavigation = data;
      this.ChangeMenuof();
    });

  }
  CallDataManu2(Factulty): void {
    this.apiweb.CallMenu(Factulty).subscribe((data: any[]) => {
      this.DataMenuNavigation = data;
      this.ChangeMenuof();
    });

  }

  ChangeMenu(): any {
    // console.log('test call changemenu ');
    // tslint:disable-next-line:only-arrow-functions
    (function($) {
      // tslint:disable-next-line:only-arrow-functions
      $(document).ready(function() {

        $('.navbar .dropdown-item').on('click', function(e) {
          const $el = $(this).children('.dropdown-toggle');
          const $parent = $el.offsetParent('.dropdown-menu');
          $(this).parent('li').toggleClass('open');

          if (!$parent.parent().hasClass('navbar-nav')) {
            if ($parent.hasClass('show')) {
              $parent.removeClass('show');
              $el.next().removeClass('show');
              $el.next().css({top: -999, left: -999});
            } else {
              $parent.parent().find('.show').removeClass('show');
              $parent.addClass('show');
              $el.next().addClass('show');
              $el.next().css({top: $el[0].offsetTop, left: $parent.outerWidth() - 4});
            }
            e.preventDefault();
            e.stopPropagation();
          }
        });

        $('.navbar .dropdown').on('hidden.bs.dropdown', function() {
          $(this).find('li.dropdown').removeClass('show open');
          $(this).find('ul.dropdown-menu').removeClass('show open');
        });

      });
    })(jQuery);


  }
  ChangeMenuCss(){
    // console.log('test call changemenu ');
    // tslint:disable-next-line:only-arrow-functions
    (function($) {
      $.fn.menumaker = function(options) {
        // tslint:disable-next-line:prefer-const one-variable-per-declaration
        let cssmenu = $(this), settings = $.extend({
          format: 'dropdown',
          sticky: false
        }, options);
        return this.each(function(multiTg, resizeFix) {
          $(this).find('.button').on('click', function(){
            $(this).toggleClass('menu-opened');
            const mainmenu = $(this).next('ul');
            if (mainmenu.hasClass('open')) {
              mainmenu.slideToggle().removeClass('open');
            }
            else {
              mainmenu.slideToggle().addClass('open');
              if (settings.format === 'dropdown') {
                mainmenu.find('ul').show();
              }
            }
          });
          cssmenu.find('li ul').parent().addClass('has-sub');
          // tslint:disable-next-line:only-arrow-functions
          multiTg = function() {
            cssmenu.find('.has-sub').prepend('<span class="submenu-button"></span>');
            cssmenu.find('.submenu-button').on('click', function() {
              $(this).toggleClass('submenu-opened');
              if ($(this).siblings('ul').hasClass('open')) {
                $(this).siblings('ul').removeClass('open').slideToggle();
              }
              else {
                $(this).siblings('ul').addClass('open').slideToggle();
              }
            });
          };
          if (settings.format === 'multitoggle') { multiTg(); }
          else { cssmenu.addClass('dropdown'); }
          if (settings.sticky === true) { cssmenu.css('position', 'fixed'); }
          // tslint:disable-next-line:only-arrow-functions
          resizeFix = function() {
            const mediasize = 700;
            if ($( window ).width() > mediasize) {
              cssmenu.find('ul').show();
            }
            if ($(window).width() <= mediasize) {
              cssmenu.find('ul').hide().removeClass('open');
            }
          };
          resizeFix();
          return $(window).on('resize', resizeFix);
        });
      };
    })(jQuery);

    // tslint:disable-next-line:only-arrow-functions
    (function($){
      // tslint:disable-next-line:only-arrow-functions
      $(document).ready(function(){
        $('#cssmenu').menumaker({
          format: 'multitoggle'
        });
      });
    })(jQuery);


  }
  ChangeMenuof(): void {
    // console.log('test call changemenu ');
    // tslint:disable-next-line:only-arrow-functions typedef
    (function($) {
      // tslint:disable-next-line:only-arrow-functions
      $(document).ready(function() {
        $('.dropdown-menu a.dropdown-toggle').on('click', function(e) {
          if (!$(this).next().hasClass('show')) {
            $(this).parents('.dropdown-menu').first().find('.show').removeClass('show');
          }
          // tslint:disable-next-line:prefer-const
          let $subMenu = $(this).next('.dropdown-menu');
          $subMenu.toggleClass('show');


          // tslint:disable-next-line:only-arrow-functions no-shadowed-variable
          $(this).parents('li.nav-item.dropdown.show').on('hidden.bs.dropdown', function(e) {
            $('.dropdown-submenu .show').removeClass('show');
          });


          return false;
        });

      });
    })(jQuery);


  }
}
