import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blocksearchtools',
  templateUrl: './blocksearchtools.component.html',
  styleUrls: ['./blocksearchtools.component.scss']
})
export class BlocksearchtoolsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
