<div class="row">
  <div class="col-12">

  </div>
</div>

<div class="row border border-success p-2 mr-1">
  <div class="container text-center">
    <span class="btn btn-success"> Save Configs</span>
  </div>
</div>
