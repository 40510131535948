<div class="row">
  <ng-container *ngFor="let data of DataAll">
    <div class="col-2 p-1">
      <div class="card">
        <ng-container *ngIf="data.Links; else nolinks">
          <a [href]="data.Links" [title]="data.TitleTh"> <img [src]="addressimage + data.ImageCover" alt="{{data.TitleTh}}" style="width: 100%;"></a>
        </ng-container>
        <ng-template #nolinks>
          <a href="newbooksdetail/{{data.Id}}" [title]="data.TitleTh"> <img [src]="addressimage + data.ImageCover" alt="{{data.TitleTh}}" style="width: 100%;"></a>
        </ng-template>
        <div class="card-footer text-right">
          <span class="mr-1">{{data.BookPublishDate}}</span>
        </div>
      </div>
    </div>
  </ng-container>

</div>
<div class="row">
  <div class="col-12 text-right">
    <a href="newbooks" class="action-button shadow animate red">More..</a>
  </div>
</div>
