import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiwebService} from '../../apiweb.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';

  public scope = this.apiweb.CMUOauthClientScope;
  public clientSecret = this.apiweb.CMUOauthClientSecret;
  public clientID = this.apiweb.CMUOauthClientID;
  public callback = this.apiweb.CMUOauthClientCallback;
  public homepage = this.apiweb.CMUOauthClientHomepage;

  constructor(private formBuilder: FormBuilder, private route: ActivatedRoute, private router: Router, private apiweb: ApiwebService) {
    // redirect to home if already logged in    private alertService: AlertService
    // if (this.authenticationService.currentUserValue) {
    if (localStorage.getItem('currentUser')) {
      this.router.navigate(['dashboard']);
    } else {
      this.router.navigate(['login']);
    }


  }

  ngOnInit() {
    console.log(localStorage.getItem('currentUser'));
    if (localStorage.getItem('currentUser')) {
      this.router.navigate(['dashboardstore']);
    } else {
      this.router.navigate(['login']);
    }
    /*
    this.loginForm = this.formBuilder.group({
      Username: ['', Validators.required],
      Password: ['', Validators.required]
    });

    // get return url from route parameters or default to '/'
    this.returnUrl = this.route.snapshot.queryParams.returnUrl || 'dashboardstore';
  }
  get f() { return this.loginForm.controls; }

  onSubmit() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.loginForm.invalid) {
      return;
    }
    this.loading = true;

    // console.log(this.loginForm.value);
    this.apiweb.login(this.loginForm.value).subscribe((data: any[]) => {
          // console.log(data[0]);
          if (data[0].Email !== null) {
            localStorage.setItem('currentUser', JSON.stringify(data[0]));
            // this.router.navigate([this.returnUrl]);
            // this.router.navigate(['dashboardstore']);
            window.location.reload();
          }

        },
        error => {
          this.error = error;
          this.loading = false;
        });*/

  }

}
