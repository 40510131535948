<div class="row mt-2">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <a href="/termpaper"> << Back </a>
      </div>
    </div>
    <div class="card mt-2 mb-2">
      <div class="card-header">
        <h2>ชื่อเรื่อง: {{DataTermpaper.TitleTh}}</h2>
      </div>
      <div class="card-body">
        <span class=""><strong>รหัส:</strong></span> {{DataTermpaper.Codes}}<br>
        <span class=""><strong>เลขเรียกหนังสือ:</strong></span> {{DataTermpaper.Calls}}<br>
        <span class=""><strong>ปี:</strong></span> {{DataTermpaper.Years}}<br>
        <!--
        <span class=""><strong>TitleTh:</strong></span> {{DataTermpaper.TitleTh}}<br>
        <span class=""><strong>TitleEn:</strong></span> {{DataTermpaper.TitleEn}}<br>
        -->
        <span class=""><strong>ผู้แต่ง:</strong></span> {{DataTermpaper.Author}}<br>
        <span class=""><strong>Imprints:</strong></span> {{DataTermpaper.Imprints}}<br>
        <span class=""><strong>Note:</strong></span> {{DataTermpaper.Note}}<br>
        <span class=""><strong>Links:</strong></span> {{DataTermpaper.Links}}<br>
        <span class=""><strong>FileUpload:</strong></span> {{DataTermpaper.FileUpload}}<br>
        <span class=""><strong>วันที่เพิ่มข้อมูล:</strong></span> {{DataTermpaper.DateAdd}}<br>
      </div>
    </div>

  </div>
</div>

<!--
<div class="row">
  <div class="col-4">

  </div>
  <div class="col-8">

  </div>
</div>-->
