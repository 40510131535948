<div class="row ml-1 border border-info text-center">
  <div class="col-12">
    <a href="dashboard/circulation/circulationout" alt="ยืม">
      <!-- <i class="fas fa-shopping-basket fa-4x text-success"></i> -->
      <svg id="Flat" height="100" viewBox="0 0 512 512" width="100" xmlns="http://www.w3.org/2000/svg"><path d="m184 280a32 32 0 0 0 -32-32h-24l-8-16h-32l8.706 26.119a32 32 0 0 0 30.358 21.881h8.936l48 128h48z" fill="#f7cca9"/><path d="m448 200v-80a96 96 0 0 0 -96-96 96 96 0 0 0 -96 96v80a32 32 0 0 0 -32 32v16a32 32 0 0 0 32 32h192a32 32 0 0 0 32-32v-16a32 32 0 0 0 -32-32z" fill="#ad6643"/><path d="m440 264h-163.62a32 32 0 0 0 -29 18.468l-47.38 93.532 40 32 40-48v128h192v-192a32 32 0 0 0 -32-32z" fill="#f7b030"/><path d="m280 368a8 8 0 0 1 -8-8v-32a8 8 0 0 1 16 0v32a8 8 0 0 1 -8 8z" fill="#eba72e"/><path d="m408 488v-160a8 8 0 0 1 16 0v160z" fill="#eba72e"/><path d="m424 392h48v96h-48z" fill="#f7cca9"/><g fill="#edc4a2"><path d="m288 184a32 32 0 0 1 -32-32 32 32 0 0 1 32-32h8v64z"/><path d="m416 120a32 32 0 0 1 32 32 32 32 0 0 1 -32 32h-8v-64z"/><path d="m328 216h48a0 0 0 0 1 0 0v48a16 16 0 0 1 -16 16h-16a16 16 0 0 1 -16-16v-48a0 0 0 0 1 0 0z"/></g><path d="m384 88-96 32v48a64 64 0 0 0 64 64 64 64 0 0 0 64-64v-48z" fill="#f7cca9"/><circle cx="320" cy="152" fill="#4f5659" r="8"/><circle cx="384" cy="152" fill="#4f5659" r="8"/><path d="m232 328h-144l-16 160h176z" fill="#c7312e"/><path d="m192 328h-144l-16 160h176z" fill="#eb423f"/><path d="m97.7 260.73a23.171 23.171 0 0 0 -2.67 2.3 23.851 23.851 0 0 0 -7.03 16.97v64a8 8 0 0 1 -16 0v-64a39.766 39.766 0 0 1 11.71-28.29 40.876 40.876 0 0 1 7.01-5.59 7.476 7.476 0 0 1 1.72-.81l4.27 12.81q.45 1.335.99 2.61z" fill="#4f5659"/><path d="m160 352a8 8 0 0 1 -8-8v-64a23.992 23.992 0 0 0 -24-24 8 8 0 0 1 0-16 39.991 39.991 0 0 1 40 40v64a8 8 0 0 1 -8 8z" fill="#4f5659"/></svg>
      <br>
      <strong>ยืมวัสดุ-ครุภัณฑ์</strong>
    </a>
    <hr>
  </div>
  <div class="col-12 mt-1">
    <a href="dashboard/circulation/circulationin" alt="คืน">
      <!-- <i class="fas fa-people-carry fa-4x text-info"></i> -->
      <svg id="Flat" height="100" viewBox="0 0 512 512" width="100" xmlns="http://www.w3.org/2000/svg"><path d="m272 152h192v128h-192z" fill="#ad6643"/><path d="m24 456h464v32h-464z" fill="#ad6643"/><path d="m64 280h80v48h-80z" fill="#3397e8"/><path d="m96 328h16v32h-16z" fill="#6a7073"/><path d="m64 360h144v48h-144z" fill="#e9eef2"/><path d="m24 408h224v48h-224z" fill="#6a7073"/><path d="m280 264h176a32 32 0 0 1 32 32v64a0 0 0 0 1 0 0h-240a0 0 0 0 1 0 0v-64a32 32 0 0 1 32-32z" fill="#e9eef2"/><path d="m368 24a96 96 0 0 1 96 96v32a0 0 0 0 1 0 0h-192a0 0 0 0 1 0 0v-32a96 96 0 0 1 96-96z" fill="#ad6643"/><g fill="#edc4a2"><path d="m304 184a32 32 0 0 1 -32-32 32 32 0 0 1 32-32h8v64z"/><path d="m432 120a32 32 0 0 1 32 32 32 32 0 0 1 -32 32h-8v-64z"/><path d="m344 216h48a0 0 0 0 1 0 0v48a16 16 0 0 1 -16 16h-16a16 16 0 0 1 -16-16v-48a0 0 0 0 1 0 0z"/></g><path d="m400 88-96 32v48a64 64 0 0 0 64 64 64 64 0 0 0 64-64v-48z" fill="#f7cca9"/><circle cx="336" cy="152" fill="#4f5659" r="8"/><circle cx="400" cy="152" fill="#4f5659" r="8"/><path d="m248 360h48v96h-48z" fill="#f7cca9"/><path d="m440 360h48v96h-48z" fill="#f7cca9"/><path d="m312 264-16 80v16h32v-16l16-80z" fill="#c7312e"/><path d="m424 264h-32l16 80v16h32v-16z" fill="#c7312e"/><path d="m296 344h144v112h-144z" fill="#eb423f"/></svg>
      <br>
      <strong>รับคืนวัสดุ-ครุภัณฑ์</strong>
       </a>
    <hr>
  </div>
  <div class="col-12 mt-1">
    <a href="dashboard/circulation/circulationmanage" alt="บริหารจัดการข้อมูล วัสดุ">
      <!-- <i class="fa fa-cog fa-4x text-primarys" aria-hidden="true"></i>-->
      <svg width="100px" height="100px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 512.003 512.003" style="enable-background:new 0 0 512.003 512.003;" xml:space="preserve">
<path style="fill:#E8E8E8;" d="M479.743,210.113h-34.157c-5.731,0-10.749-3.762-12.45-9.235
	c-3.369-10.833-7.706-21.239-12.907-31.119c-2.673-5.079-1.793-11.295,2.266-15.353l24.165-24.164
	c9.668-9.668,9.668-25.344,0-35.012l-29.886-29.886c-9.668-9.668-25.344-9.668-35.012,0l-24.165,24.165
	c-4.058,4.058-10.274,4.939-15.353,2.265c-9.88-5.201-20.286-9.538-31.119-12.907c-5.473-1.702-9.235-6.719-9.235-12.45V32.26
	c0-13.673-11.084-24.757-24.757-24.757h-42.266c-13.673,0-24.757,11.084-24.757,24.757v34.156c0,5.731-3.762,10.748-9.235,12.45
	c-10.833,3.369-21.239,7.706-31.119,12.907c-5.079,2.673-11.295,1.793-15.353-2.265l-24.165-24.165
	c-9.668-9.668-25.344-9.668-35.012,0L65.341,95.23c-9.668,9.668-9.668,25.344,0,35.012l24.165,24.165
	c4.058,4.058,4.939,10.274,2.266,15.353c-5.201,9.88-9.538,20.286-12.907,31.119c-1.702,5.473-6.719,9.235-12.45,9.235H32.257
	c-13.673,0-24.757,11.084-24.757,24.757v42.266c0,13.673,11.084,24.757,24.757,24.757h34.156c5.731,0,10.748,3.762,12.45,9.235
	c3.369,10.833,7.706,21.238,12.907,31.119c2.673,5.079,1.793,11.294-2.266,15.353l-24.165,24.165
	c-9.668,9.668-9.668,25.344,0,35.012l29.886,29.886c9.668,9.668,25.344,9.668,35.012,0l24.165-24.165
	c4.058-4.058,10.274-4.939,15.353-2.266c9.88,5.201,20.286,9.538,31.119,12.907c5.473,1.702,9.235,6.719,9.235,12.451v34.156
	c0,13.673,11.084,24.757,24.757,24.757h42.266c13.673,0,24.757-11.084,24.757-24.757V445.59c0-5.732,3.762-10.748,9.235-12.451
	c10.833-3.369,21.238-7.706,31.119-12.907c5.079-2.673,11.294-1.793,15.353,2.266l24.165,24.165c9.668,9.668,25.344,9.668,35.012,0
	l29.886-29.886c9.668-9.668,9.668-25.344,0-35.012l-24.165-24.164c-4.058-4.058-4.939-10.274-2.266-15.353
	c5.201-9.88,9.538-20.286,12.907-31.119c1.702-5.473,6.719-9.235,12.45-9.235h34.157c13.673,0,24.757-11.084,24.757-24.757v-42.266
	C504.5,221.197,493.416,210.113,479.743,210.113z M256,312.628c-31.273,0-56.625-25.352-56.625-56.625s25.352-56.625,56.625-56.625
	s56.625,25.352,56.625,56.625S287.273,312.628,256,312.628z"/>
        <path style="fill:#404B58;" d="M256,169.584c-47.728,0-86.419,38.691-86.419,86.419s38.691,86.419,86.419,86.419
	s86.419-38.691,86.419-86.419S303.728,169.584,256,169.584z M256,312.628c-31.273,0-56.625-25.352-56.625-56.625
	s25.352-56.625,56.625-56.625s56.625,25.352,56.625,56.625S287.273,312.628,256,312.628z"/>
        <g style="opacity:0.2;">
	<circle cx="124.85" cy="256.003" r="14.233"/>
          <circle cx="163.26" cy="348.742" r="14.233"/>
          <circle cx="256" cy="387.153" r="14.233"/>
          <circle cx="348.74" cy="348.742" r="14.233"/>
          <circle cx="387.15" cy="256.003" r="14.233"/>
</g>
        <path style="opacity:0.1;enable-background:new    ;" d="M154.403,89.509l-24.165-24.165c-7.358-7.358-18.192-9.11-27.213-5.269
	c2.833,1.206,5.488,2.958,7.799,5.269l30.431,30.852c4.058,4.058,10.274,4.939,15.353,2.266c1.389-0.731,2.791-1.441,4.2-2.137
	c-0.031-0.03,5.36-2.776,7.956-4.08l0,0C163.888,94.32,158.201,93.306,154.403,89.509z M156.609,413.544
	c-5.079-2.673-11.295-1.793-15.353,2.266l-30.431,30.852c-2.31,2.311-4.966,4.062-7.799,5.269c0.03-0.013,0.071,0.009,0.116,0.05
	c8.997,3.777,19.771,2.008,27.097-5.319l24.165-24.165c3.798-3.798,9.485-4.812,14.362-2.735l0,0
	c-2.596-1.304-7.987-4.05-7.956-4.08C159.4,414.984,157.998,414.276,156.609,413.544z M336.112,414.023
	c-9.88,5.201-33.569,15.747-44.402,19.116c-5.473,1.702-9.235,6.719-9.235,12.451v34.156c0,13.673-11.084,24.757-24.757,24.757
	h19.414c13.673,0,24.757-11.084,24.757-24.757v-34.156c0-5.732,3.762-10.748,9.235-12.451c10.833-3.369,21.238-7.706,31.119-12.907
	c5.079-2.673,11.294-1.793,15.353,2.266C345.603,410.013,341.135,411.379,336.112,414.023z M479.743,210.113h-19.414
	c13.673,0,24.757,11.084,24.757,24.757v42.266c0,13.673-11.084,24.757-24.757,24.757h19.414c13.673,0,24.757-11.084,24.757-24.757
	V234.87C504.5,221.197,493.416,210.113,479.743,210.113z M342.243,91.774c-9.88-5.201-20.286-9.538-31.119-12.907
	c-5.473-1.702-9.235-6.719-9.235-12.45V32.26c0-13.673-11.084-24.757-24.757-24.757h-19.414c13.673,0,24.757,11.084,24.757,24.757
	v34.156c0,5.731,3.762,10.748,9.235,12.45c10.833,3.369,34.522,13.915,44.402,19.116c5.022,2.644,9.49,4.01,21.484-8.475
	C353.538,93.567,347.322,94.447,342.243,91.774z M422.494,357.599c-4.058-4.058-4.939-10.274-2.266-15.353
	c5.201-9.88,9.538-20.286,12.907-31.119c1.698-5.462,6.699-9.218,12.416-9.233c0.007,0,0.013-0.002,0.019-0.002
	c-6.466,0.003-12.932,0.001-19.399,0.001c-5.731,0-10.748,3.762-12.45,9.235c-3.369,10.833-7.706,21.239-12.907,31.119
	c-2.673,5.079-1.793,11.295,2.266,15.353l24.165,24.164c9.668,9.668,9.668,25.344,0,35.012l-29.886,29.886
	c-2.31,2.31-4.966,4.062-7.798,5.269l0.001,0.001c9.021,3.84,19.854,2.088,27.212-5.269l29.886-29.886
	c9.668-9.668,9.668-25.344,0-35.012L422.494,357.599z M427.245,95.23c9.668,9.668,9.668,25.344,0,35.012l-24.165,24.164
	c-4.058,4.058-4.939,10.274-2.266,15.353c5.201,9.88,9.538,20.286,12.907,31.119c1.702,5.473,6.719,9.235,12.451,9.235
	c5.996,0,11.992,0.018,17.988-0.006c0.021-0.025,0.038-0.049,0.058-0.075c-5.148-0.545-9.518-4.122-11.082-9.154
	c-3.369-10.833-7.706-21.239-12.907-31.119c-2.673-5.079-1.793-11.295,2.266-15.353l24.165-24.164
	c9.668-9.668,9.668-25.344,0-35.012l-29.886-29.886c-7.372-7.372-18.235-9.116-27.266-5.246c2.944,1.168,5.614,3.008,7.852,5.246
	L427.245,95.23z"/>
        <path d="M255.999,320.127c35.359,0,64.125-28.766,64.125-64.124c0-35.358-28.766-64.124-64.125-64.124
	c-35.358,0-64.124,28.766-64.124,64.124S220.641,320.127,255.999,320.127z M255.999,206.879c27.087,0,49.125,22.037,49.125,49.124
	c0,27.087-22.037,49.124-49.125,49.124s-49.124-22.037-49.124-49.124C206.875,228.916,228.912,206.879,255.999,206.879z
	 M479.743,202.613h-34.157c-2.427,0-4.552-1.592-5.288-3.962c-3.465-11.139-7.984-22.035-13.433-32.384
	c-1.16-2.205-0.785-4.839,0.932-6.557l11.828-11.828c2.93-2.929,2.93-7.677,0.001-10.606s-7.678-2.93-10.607,0l-11.828,11.827
	c-6.392,6.391-7.838,16.096-3.6,24.15c5.023,9.543,9.19,19.586,12.383,29.853c2.698,8.677,10.58,14.507,19.611,14.507h34.157
	c9.516,0,17.257,7.742,17.257,17.257v42.266c0,9.516-7.741,17.257-17.257,17.257h-34.157c-9.031,0-16.913,5.83-19.612,14.507
	c-3.192,10.267-7.358,20.31-12.382,29.854c-4.238,8.054-2.792,17.759,3.599,24.149l24.165,24.165c6.729,6.729,6.729,17.677,0,24.405
	l-29.887,29.886c-3.259,3.26-7.593,5.055-12.202,5.055s-8.943-1.795-12.202-5.055l-24.165-24.164
	c-6.392-6.393-16.098-7.839-24.149-3.6c-9.544,5.024-19.588,9.19-29.853,12.382c-8.678,2.698-14.508,10.58-14.508,19.612v34.156
	c0,9.516-7.741,17.257-17.257,17.257h-42.266c-9.516,0-17.257-7.741-17.257-17.257v-34.156c0-9.033-5.831-16.914-14.507-19.612
	c-10.266-3.193-20.31-7.359-29.853-12.382c-8.053-4.239-17.76-2.793-24.149,3.599l-24.165,24.165
	c-6.728,6.729-17.677,6.729-24.406,0l-29.885-29.886c-3.26-3.259-5.055-7.593-5.055-12.203c0-4.61,1.795-8.943,5.055-12.203
	l24.165-24.164c6.391-6.392,7.837-16.097,3.599-24.15c-5.023-9.543-9.189-19.586-12.382-29.853
	c-2.699-8.677-10.581-14.507-19.612-14.507H32.257c-9.516,0-17.257-7.742-17.257-17.257V234.87c0-9.516,7.742-17.257,17.257-17.257
	h34.156c9.032,0,16.914-5.83,19.612-14.507c3.193-10.267,7.359-20.31,12.382-29.853c4.239-8.053,2.793-17.758-3.599-24.15
	l-24.165-24.165c-3.26-3.259-5.055-7.593-5.055-12.203c0-4.609,1.795-8.943,5.055-12.203l29.886-29.886
	c6.728-6.728,17.677-6.728,24.406,0L149.1,94.811c6.392,6.393,16.096,7.839,24.15,3.6c9.543-5.023,19.587-9.189,29.852-12.382
	c8.678-2.698,14.508-10.579,14.508-19.612V32.26c0-9.516,7.742-17.257,17.257-17.257h42.266c9.516,0,17.257,7.741,17.257,17.257
	v34.156c0,9.032,5.83,16.914,14.507,19.612c10.266,3.192,20.31,7.358,29.853,12.382c8.052,4.238,17.758,2.793,24.148-3.599
	l24.166-24.165c3.259-3.26,7.593-5.055,12.202-5.055s8.943,1.795,12.202,5.055l29.887,29.886c3.91,3.91,5.716,9.508,4.831,14.977
	c-0.661,4.089,2.118,7.94,6.207,8.601c4.092,0.66,7.939-2.118,8.602-6.206c1.651-10.212-1.726-20.671-9.032-27.978L422.076,60.04
	c-6.093-6.093-14.193-9.448-22.81-9.448s-16.717,3.355-22.81,9.448l-24.165,24.165c-1.717,1.716-4.352,2.091-6.556,0.932
	c-10.352-5.449-21.248-9.968-32.386-13.432c-2.369-0.737-3.961-2.862-3.961-5.289V32.26c0-17.787-14.471-32.257-32.257-32.257
	h-42.266c-17.787,0-32.257,14.47-32.257,32.257v34.156c0,2.426-1.592,4.552-3.962,5.289c-11.138,3.464-22.034,7.983-32.385,13.432
	c-2.203,1.16-4.838,0.785-6.556-0.932L135.541,60.04c-6.093-6.093-14.193-9.448-22.809-9.448s-16.717,3.355-22.81,9.448
	L60.038,89.926c-6.093,6.093-9.448,14.193-9.448,22.81s3.355,16.717,9.448,22.809l24.164,24.165
	c1.718,1.718,2.093,4.353,0.933,6.556c-5.449,10.351-9.968,21.247-13.432,32.385c-0.737,2.37-2.863,3.962-5.289,3.962H32.257
	C14.471,202.613,0,217.084,0,234.87v42.266c0,17.787,14.471,32.257,32.257,32.257h34.156c2.426,0,4.552,1.592,5.289,3.962
	c3.464,11.139,7.983,22.035,13.432,32.385c1.16,2.204,0.785,4.838-0.932,6.556L60.038,376.46
	c-6.093,6.092-9.448,14.193-9.448,22.809c0,8.616,3.355,16.717,9.448,22.809l29.886,29.886c6.093,6.093,14.193,9.448,22.81,9.448
	c8.616,0,16.716-3.355,22.809-9.448l24.165-24.165c1.716-1.717,4.352-2.091,6.555-0.932c10.352,5.449,21.247,9.968,32.386,13.432
	c2.37,0.737,3.962,2.862,3.962,5.289v34.156c0,17.787,14.471,32.257,32.257,32.257h42.266c17.786,0,32.257-14.47,32.257-32.257
	v-34.156c0-2.426,1.592-4.552,3.962-5.289c11.137-3.464,22.033-7.983,32.385-13.432c2.202-1.16,4.838-0.786,6.556,0.932
	l24.165,24.165c6.093,6.093,14.193,9.448,22.81,9.448s16.717-3.355,22.81-9.448l29.887-29.886c12.576-12.577,12.576-33.041,0-45.618
	L427.8,352.294c-1.717-1.717-2.092-4.352-0.932-6.556c5.448-10.351,9.968-21.247,13.432-32.385c0.737-2.37,2.862-3.962,5.289-3.962
	h34.157c17.786,0,32.257-14.471,32.257-32.257v-42.266C512,217.084,497.529,202.613,479.743,202.613z M349.919,256.003
	c0-51.787-42.132-93.919-93.919-93.919s-93.919,42.132-93.919,93.919c0,20.334,6.393,39.686,18.487,55.963
	c2.471,3.324,7.168,4.018,10.493,1.547c3.325-2.47,4.018-7.168,1.547-10.493c-10.158-13.671-15.527-29.929-15.527-47.017
	c0-43.516,35.403-78.919,78.919-78.919s78.919,35.403,78.919,78.919S299.516,334.922,256,334.922
	c-13.463,0-26.75-3.447-38.424-9.968c-3.616-2.019-8.185-0.726-10.206,2.89c-2.02,3.616-0.726,8.186,2.89,10.206
	c13.905,7.767,29.721,11.872,45.739,11.872C307.787,349.922,349.919,307.789,349.919,256.003z M234.267,387.151
	c0,11.983,9.749,21.733,21.732,21.733c11.984,0,21.733-9.749,21.733-21.733c0-11.983-9.749-21.733-21.733-21.733
	C244.016,365.418,234.267,375.168,234.267,387.151z M262.732,387.151c0,3.712-3.02,6.733-6.733,6.733s-6.732-3.021-6.732-6.733
	c0-3.712,3.02-6.733,6.732-6.733C259.711,380.418,262.732,383.439,262.732,387.151z M324.945,133.356v-10.2
	c0-4.142-3.357-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v10.2c0,4.142,3.357,7.5,7.5,7.5S324.945,137.498,324.945,133.356z M348.735,370.471
	c5.805,0,11.263-2.26,15.368-6.365s6.365-9.563,6.365-15.368s-2.261-11.263-6.366-15.368c-4.105-4.104-9.563-6.365-15.367-6.365
	c-5.805,0-11.262,2.261-15.366,6.365c0,0,0,0-0.001,0c-4.104,4.105-6.365,9.563-6.365,15.367c0,5.805,2.261,11.263,6.365,15.368
	C337.473,368.211,342.931,370.471,348.735,370.471z M343.975,343.979C343.976,343.978,343.976,343.978,343.975,343.979
	c1.272-1.272,2.963-1.972,4.761-1.972c1.799,0,3.489,0.7,4.762,1.972c1.271,1.272,1.972,2.962,1.972,4.76
	c0,1.798-0.7,3.489-1.972,4.761c-1.272,1.272-2.963,1.972-4.762,1.972c-1.798,0-3.488-0.7-4.76-1.972s-1.973-2.963-1.973-4.761
	C342.003,346.94,342.703,345.25,343.975,343.979z M387.148,277.736c11.983,0,21.732-9.749,21.732-21.733
	c0-11.983-9.749-21.733-21.732-21.733s-21.733,9.75-21.733,21.733C365.415,267.986,375.165,277.736,387.148,277.736z
	 M387.148,249.27c3.712,0,6.732,3.021,6.732,6.733c0,3.712-3.02,6.733-6.732,6.733c-3.713,0-6.733-3.02-6.733-6.733
	C380.415,252.291,383.435,249.27,387.148,249.27z M256,148.689c8.376,0,16.342-3.627,21.854-9.953
	c2.721-3.122,2.397-7.86-0.727-10.581c-3.123-2.722-7.86-2.396-10.582,0.726c-2.663,3.056-6.507,4.808-10.546,4.808
	c-4.039,0-7.883-1.752-10.545-4.808c-2.721-3.124-7.459-3.448-10.582-0.727s-3.448,7.459-0.727,10.581
	C239.658,145.061,247.623,148.689,256,148.689z M202.054,133.356v-10.2c0-4.142-3.358-7.5-7.5-7.5s-7.5,3.358-7.5,7.5v10.2
	c0,4.142,3.358,7.5,7.5,7.5S202.054,137.498,202.054,133.356z M124.851,234.27c-11.983,0-21.732,9.75-21.732,21.733
	c0,11.983,9.749,21.733,21.732,21.733s21.733-9.749,21.733-21.733C146.584,244.02,136.834,234.27,124.851,234.27z M124.851,262.736
	c-3.712,0-6.732-3.02-6.732-6.733c0-3.712,3.02-6.733,6.732-6.733s6.733,3.021,6.733,6.733
	C131.584,259.715,128.563,262.736,124.851,262.736z M163.264,370.462c5.565,0,11.13-2.119,15.367-6.355
	c4.105-4.105,6.366-9.563,6.366-15.368s-2.261-11.263-6.366-15.367c-8.474-8.474-22.261-8.474-30.734,0
	c-8.473,8.473-8.474,22.261,0,30.735C152.133,368.343,157.698,370.462,163.264,370.462z M158.503,343.978
	c1.313-1.313,3.037-1.969,4.761-1.969s3.448,0.656,4.761,1.969l0,0c1.272,1.272,1.972,2.962,1.972,4.76
	c0,1.798-0.7,3.489-1.972,4.761c-2.626,2.625-6.897,2.625-9.521,0C155.878,350.875,155.878,346.603,158.503,343.978z"/>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
</svg>
      <br>
      <strong>บริหารจัดการข้อมูล</strong>
      </a>
    <hr>
  </div>
  <div class="col-12 mt-1">
    <a href="dashboard/circulation/report" alt="รายงาน">
      <!-- <i class="fas fa-chart-area fa-4x text-dark"></i>-->
      <svg width="100px" height="100px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
           viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
<path style="fill:#6E87F5;" d="M286.026,166.078v59.909h215.976C488.702,112.958,399.054,23.306,286.026,10v87.087"/>
        <path d="M502.002,235.986H286.026c-5.522,0-10-4.478-10-10v-59.909c0-5.522,4.478-10,10-10s10,4.478,10,10v49.909h194.465
	c-16.54-100.072-94.394-177.93-194.465-194.476v75.576c0,5.522-4.478,10-10,10s-10-4.478-10-10V10c0-2.855,1.221-5.575,3.355-7.473
	c2.134-1.897,4.974-2.791,7.813-2.459c56.889,6.697,110.617,32.785,151.288,73.458s66.756,94.402,73.45,151.291
	c0.334,2.837-0.562,5.68-2.459,7.814C507.577,234.766,504.857,235.986,502.002,235.986z"/>
        <path d="M286.026,141.294c-5.522,0-10-4.478-10-10v-0.264c0-5.522,4.478-10,10-10s10,4.478,10,10v0.264
	C296.026,136.816,291.549,141.294,286.026,141.294z"/>
        <path style="fill:#FF5A5A;" d="M242,270.013V38C113.87,38,10,141.87,10,270s103.87,232,232,232s232-103.87,232-232v0.013H242z"/>
        <path d="M235.335,462.774c-0.082,0-0.164-0.001-0.246-0.003c-44.707-1.081-87.017-16.319-122.356-44.067
	c-4.344-3.411-5.101-9.697-1.689-14.041c3.41-4.345,9.695-5.102,14.041-1.689c31.921,25.064,70.127,38.828,110.487,39.804
	c5.521,0.134,9.89,4.718,9.756,10.238C245.195,458.455,240.745,462.774,235.335,462.774z"/>
        <path d="M263.697,461.418c-4.994,0-9.311-3.734-9.917-8.816c-0.654-5.484,3.262-10.46,8.745-11.114l0.213-0.025
	c5.478-0.653,10.459,3.261,11.114,8.745c0.654,5.484-3.262,10.46-8.745,11.114l-0.213,0.025
	C264.492,461.394,264.093,461.418,263.697,461.418z"/>
        <path style="fill:#A5DC69;" d="M429.895,406.087C457.632,367.858,474,320.844,474,270v0.013H242L429.895,406.087z"/>
        <path d="M474,260c-0.177,0-0.353,0.005-0.527,0.014H252V38c0-5.522-4.478-10-10-10c-64.641,0-125.412,25.173-171.12,70.88
	C25.173,144.588,0,205.359,0,270s25.173,125.412,70.88,171.12C116.588,486.827,177.359,512,242,512s125.412-25.173,171.12-70.88
	C458.827,395.412,484,334.641,484,270C484,264.477,479.522,260,474,260z M242,492c-122.411,0-222-99.589-222-222
	c0-119.057,94.216-216.525,212-221.777v221.791c0,3.331,1.635,6.273,4.139,8.09c-0.001,0.003-0.003,0.006-0.004,0.009
	l179.53,130.016C374.957,459.205,312.24,492,242,492z M427.416,391.945L272.858,280.014h190.919
	C461.939,321.221,448.803,359.534,427.416,391.945z"/>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
        <g>
</g>
</svg>
<br>
      <strong>รายงานสถิติ ต่างๆ</strong>

    </a>
    <hr>
  </div>
</div>
