import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-frmuploadimage',
  templateUrl: './frmuploadimage.component.html',
  styleUrls: ['./frmuploadimage.component.scss']
})
export class FrmuploadimageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
