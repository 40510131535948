import {Component, Input, OnInit} from '@angular/core';
import {ApiwebService} from '../../../apiweb.service';

@Component({
  selector: 'app-dashboardheader',
  templateUrl: './dashboardheader.component.html',
  styleUrls: ['./dashboardheader.component.scss']
})
export class DashboardheaderComponent implements OnInit {
  public logoaddress;
  constructor(public apiweb: ApiwebService ) { }

  ngOnInit(): void {
    this.CallConfigsWebsite();
  }
  CallConfigsWebsite(): void {
    this.apiweb.FrontendGetDataId('configs', 'get_configs', 'Faculty', this.apiweb.SiteFaculty).subscribe( (dataresponse: any) => {
      if (dataresponse.status === true){
        const data = dataresponse.data[0];
        this.logoaddress =  this.apiweb.ApiAddress + 'assets/configs/' + data.Logo;

      }
    });
  }

}
