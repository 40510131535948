import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ApiwebService} from '../../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {UploadService} from '../../../upload.service';

@Component({
  selector: 'app-detailtrendyarts',
  templateUrl: './detailtrendyarts.component.html',
  styleUrls: ['./detailtrendyarts.component.scss']
})
export class DetailtrendyartsComponent implements OnInit {
  public contenttype = 'trendyarts' ;
  public DataDetail;
  public Content = true;
  public QRcode;
  public AddressQRcode = 'http://library.cmu.ac.th/mediastore/97/view.html?id=';
  public elementType = 'url';
  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute, public uploadService: UploadService) { }
  ngOnInit(): void {
    if (localStorage.getItem('lang') === 'En'){
      this.Content = false;
    }else{
      this.Content = true;
    }
    this.route.params.subscribe(params => {
      this.CallDataId(params.Id);
    });
  }
  public CallDataId(Id): any {
    this.apiweb.CallData(this.contenttype, 'get_id?Id=' + Id).subscribe( (dataresponse: any) => {
      const data = dataresponse.data[0];
      this.DataDetail = data;
      if (data.Code !== null || data.Code !== ''){
        this.QRcode = this.AddressQRcode + data.Code;
      }else{
        this.QRcode = data.Links;
      }
    });
  }
}
