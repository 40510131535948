import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-gallerydetail',
  templateUrl: './gallerydetail.component.html',
  styleUrls: ['./gallerydetail.component.scss']
})
export class GallerydetailComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
