import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-searchtoolsarticle',
  templateUrl: './searchtoolsarticle.component.html',
  styleUrls: ['./searchtoolsarticle.component.scss']
})
export class SearchtoolsarticleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
