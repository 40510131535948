import { Injectable } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {throwError} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiwebService {
  public site = 'agri';
  public siteName = 'ChiangMai University Agric Library';
  public SiteFaculty = 'เกษตรศาสตร์';
  public dataUser = JSON.parse(localStorage.getItem('datauser'));
  /* master*/
  public CMUOauthClientScope = 'cmuitaccount.basicinfo mishr.self.basicinfo misstd.self.basicinfo';
  public CMUOauthClientID = 'DABSMdYtvgskzQVuNygSPUX3NzVqjVegfQWfgfd6';
  public CMUOauthClientSecret = 'wXUhnJUez4CDVN6WQHdnuHjKcjeV4QmFukw19PSc';
  public CMUOauthClientCallback = 'https://agri.library.cmu.ac.th/api/callback.php';
  public CMUOauthClientHomepage = 'https://agri.library.cmu.ac.th/';
  public ApiAddress = 'https://agri.library.cmu.ac.th/api/';
  public WebsiteAddress = 'https://agri.library.cmu.ac.th/';
  public WebsiteAddressAssets = 'https://agri.library.cmu.ac.th/api/assets/';
  public UploadApiAddress = 'https://agri.library.cmu.ac.th/api/upload';
  public CkuploadApiAddress = 'https://agri.library.cmu.ac.th/api/uploads';
  public CkeditorApiAddress = 'https://agri.library.cmu.ac.th/api/ckeditor/ckeditor.js';

  /* testting
  public CMUOauthClientScope = 'cmuitaccount.basicinfo mishr.self.basicinfo misstd.self.basicinfo';
  public CMUOauthClientID = '6awVneCeJnQamytg4g3dhUHhJuw7pyvwsJcFcycQ';
  public CMUOauthClientSecret = 'KDka8pmsjeMArpZnCpm3VE2k2tVPwYeFdqDRHGjv';
  public CMUOauthClientCallback = 'http://localhost/eng.lib.cmu.ac.th/api/callback.php';
  public CMUOauthClientHomepage = 'http://localhost:4200/';
  public ApiAddress = 'http://localhost/eng.lib.cmu.ac.th/api/';
  public WebsiteAddress = 'http://localhost/eng.lib.cmu.ac.th/';
  public WebsiteAddressAssets = 'http://localhost/eng.lib.cmu.ac.th/api/assets/';
  public UploadApiAddress = 'http://localhost/eng.lib.cmu.ac.th/api/upload';
  public CkuploadApiAddress = 'http://localhost/eng.lib.cmu.ac.th/api/uploads';
  public CkeditorApiAddress = 'http://localhost/eng.lib.cmu.ac.th/api/ckeditor/ckeditor.js';
*/

  public httpOptionsPost = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json;charset=utf-8',
      'Access-Control-Allow-Origin': '*'
      // 'Authorization': 'Basic ' + btoa(this.username + ':' + this.password)
    }), Observable: 'response'
  };
  public httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*'
      // 'Authorization': 'Basic ' + btoa(this.username + ':' + this.password)
    })
  };
  private handleError(error: HttpErrorResponse): any {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      // console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
  constructor( public http: HttpClient) { }
// ระบบ login
  getToken(code): any {
    return this.http.get(this.ApiAddress + 'checkcallback.php?access_token=' + code);
  }
  Calldatauser(code): any {
    return this.http.get(this.ApiAddress + 'checkcallback.php?access_token=' + code);
  }
  Calldataemp(code): any {
    return this.http.get(this.ApiAddress + 'checkcallbackemp.php?access_token=' + code);
  }
  Calldatastudent(code): any {
    return this.http.get(this.ApiAddress + 'checkcallbackstudent.php?access_token=' + code);
  }
  checkcalbackself(email, accesstoken): any {
    return this.http.get(this.ApiAddress + 'checkcallbackself.php?access_token=' + accesstoken + '&email=' + email);
  }
  // เช็ค user ห้องสมุด
  ChkUserLibrary(email): any {
    // http://localhost/eng.lib.cmu.ac.th/api/checkuser/get_personal_by_email?EmailCMU=piyaboot.p@cmu.ac.th
    return this.http.get(this.ApiAddress + 'checkuser/get_personal_by_email?EmailCMU=' + email);
  }

// ระบบ login manual
  login(data): any {
    return this.http.post(this.ApiAddress + 'auth.php', data , this.httpOptionsPost);
  }
  authlogin(username, password): any {
    return this.http.post(this.ApiAddress + 'auth.php', {ausername: username, apassword: password} , this.httpOptionsPost);
  }
  // register
  RegisterCommand(data): any {
    return this.http.post( this.ApiAddress + 'manage_register.php', data, this.httpOptionsPost);
  }
  // checkUser
  RegisterCheckUser(username): any {
    return this.http.get(this.ApiAddress + 'call_user.php?Action=CheckUser&Username=' + username);
  }
  // CheckEmail
  RegisterCheckEmail(username): any {
    return this.http.get(this.ApiAddress + 'call_user.php?Action=CheckEmail&Email=' + username);
  }
///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

// ตั้งค่าเครื่องมือ ปรับแต่งเนื้อหา
  public ConfigEditor4(apiupload, path): any {
    const EditorConfig = {
      // filebrowserBrowseUrl: apiupload + '?path=' + path,
      // filebrowserImageBrowseUrl: apiupload + '?path=' + path,
      filebrowserUploadUrl: apiupload + '?path=' + path,
      filebrowserImageUploadUrl: apiupload + '?path=' + path,
      sanitize : true
    };
    return EditorConfig;
  }
  public ConfigEditor(apiupload, path): any {
    const EditorConfig = {
      plugin: [],
      alignment: {
        options: [ 'left', 'center', 'right' ]
      },
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '25rem',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: 'RSU Text',
      defaultFontSize: '',
      fonts: [
        {class: 'rsutextregular', name: 'RSU Text'},
        {class: 'rsutextbold', name: 'RSU Text Bold'},
        {class: 'times-new-roman', name: 'Times New Roman'}
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        }, {
          name: 'img-fluid',
          class: 'img-fluid',
        },

      ],
      toolbar: {
        items: [
          'heading',
          '|',
          'code',
          'bold',
          'italic',
          'link',
          'bulletedList',
          'numberedList',
          '|',
          'indent',
          'outdent',
          '|',
          'imageUpload',
          'blockQuote',
          'insertTable',
          'fontSize',
          'fontFamily',
          'alignment',
          'fontBackgroundColor',
          'mediaEmbed',
          'undo',
          'redo',
          'highlight',
          'fontColor',
          'underline',
          'exportPdf'
        ]
      },
      image: {
        resizeUnit: '%',
        resizeOptions: [ {
          name: 'imageResize:original',
          value: null
        },
          {
            name: 'imageResize:50',
            value: '50'
          },
          {
            name: 'imageResize:75',
            value: '75'
          }],
        imageSizes: true,
        resize: true,
        file:  apiupload + '?path=' + path ,
        toolbar: [ 'imageResize',  'imageTextAlternative', '|', 'imageStyle:alignLeft', 'imageStyle:full', 'imageStyle:alignRight'],
        styles: [
          'full',
          'alignLeft',
          'alignRight',
        ],
      },
      ckfinder: {
        uploadUrl: apiupload + '?path=' + path
      },
      simpleUpload: {
        uploadUrl: apiupload + '?path=' + path ,
      },
      sanitize : true,
      toolbarPosition: 'top'
    };
    return EditorConfig;
  }
  public AngularConfigEditor(apiupload, path): any {
    const editorConfig = {
      editable: true,
      spellcheck: true,
      height: 'auto',
      minHeight: '300px',
      maxHeight: 'auto',
      width: 'auto',
      minWidth: '0',
      translate: 'yes',
      enableToolbar: true,
      showToolbar: true,
      placeholder: 'Enter text here...',
      defaultParagraphSeparator: '',
      defaultFontName: '',
      defaultFontSize: '',
      fonts: [
        {class: 'arial', name: 'Arial'},
        {class: 'times-new-roman', name: 'Times New Roman'},
        {class: 'calibri', name: 'Calibri'},
        {class: 'comic-sans-ms', name: 'Comic Sans MS'}
      ],
      customClasses: [
        {
          name: 'quote',
          class: 'quote',
        },
        {
          name: 'redText',
          class: 'redText'
        },
        {
          name: 'titleText',
          class: 'titleText',
          tag: 'h1',
        },
      ],
      uploadUrl: apiupload + '?path=' + path ,
      uploadWithCredentials: false,
      sanitize: true,
      toolbarPosition: 'top',
      toolbarHiddenButtons: [
        ['bold', 'italic'],
        ['fontSize']
      ]
    };
    return editorConfig;
  }



  /* Front End */
  FrontendGetDataId(ContentType, Commands, SelectId, Id): any{
    return this.http.get(this.ApiAddress + ContentType + '/' + Commands + '?' + SelectId + '=' + Id);
  }
  FrontendGet(Commands): any {
    return this.http.get(this.ApiAddress + Commands);
  }

  public CallData(path, command): any{
    return this.http.get(this.ApiAddress + path + '/' + command);
  }

  /* Back End */

  public CommandCallDataId(ContentType, Commands, SelectId, Id): any{
    return this.http.get(this.ApiAddress + ContentType + '/' + Commands + '?' + SelectId + '=' + Id);
  }
  public CommandData(path, command, data): any{
    return this.http.post(this.ApiAddress + path + '/' + command, data , this.httpOptionsPost);
  }





// เรียกข้อมูลรูปภาพจาก Id
  CallFileUpload(ItemId): any {
    return this.http.get(this.ApiAddress + 'items/get_upload_item_id?ItemId=' + ItemId);
  }
// เรียกข้อมูลรูปภาพจาก Id
  CallFileUploadId(ItemId): any {
    return this.http.get(this.ApiAddress + 'items/get_upload_item_id?ItemId=' + ItemId);
  }
// บันทึกข้อมูลอัพโหลดไฟล์ไอเท็ม
  CommandSaveFileUpload(data): any {
    return this.http.post( this.ApiAddress + 'items/set_upload_save_item', data, this.httpOptionsPost);
  }

// ลบข้อมูลอัพโหลดไฟล์ไอเท็ม
  CommandDelFileUpload(data): any {
    return this.http.post( this.ApiAddress + 'items/set_upload_del_item', data, this.httpOptionsPost);
  }

// ข้อมูลผู้ใช้
  // ข้อมูล user ภายในระบบทั้งหมด
  CallAllUser(): any {
    return this.http.get(this.ApiAddress + 'user/callallusercirculation');
  }
  CallDataUser(EmailCMU): any {
    return this.http.get(this.ApiAddress + 'user/get_user?EmailCMU=' + EmailCMU);
  }
  // ข้อมูล Account ผู้ใช้
  CallDataUserAccount(CMUitaccount): any {
    return this.http.get(this.ApiAddress + 'user/get_user_account?CMUitaccount=' + CMUitaccount);
  }
  CallDataUserBarcode(CMUitaccount): any {
    return this.http.get(this.ApiAddress + 'user/get_user_barcode?CMUitaccount=' + CMUitaccount);
  }
  // ตรวจสอบสถานะการลงทะเบียนของผู้ใช้บริการ
  CheckdataUserRegister(EmailCMU): any {
    return this.http.get(this.ApiAddress + 'user/get_user_email?EmailCMU=' + EmailCMU);
  }
  // ลงทะเบียนขอเข้าใช้บริการ
  UserRegister(data): any {
    return this.http.post( this.ApiAddress + 'user/set_user_register', data, this.httpOptionsPost);
  }

  // ข้อมูลผู้ใช้ ยืมอุปกรณ์
  CallDataUserItemHold(EmailCMU): any {
    return this.http.get(this.ApiAddress + 'items/get_item_hold_EmailCMU?EmailCMU=' + EmailCMU);
  }
  // ระบยืมคืน

// ตรวจสอบรายการไอเท็มที่ต้องการยืม
  CheckItem(CodeItem): any {
    return this.http.get(this.ApiAddress + 'circulation/CheckItem?CodeItem=' + CodeItem);
  }




// ประเภทสถานะวัตถุ
////////////////// Item Status /////////////////////////////////////
  // เรียกข้อมูลวัตถุทั้งหมด
  CallAllItem(): any {
    return this.http.get(this.ApiAddress + 'items/get_item_all');
  }
  // เรียกข้อมูลวัตถุจาก Id
  CallItemId(Id): any {
    return this.http.get(this.ApiAddress + 'items/get_item_id?Id=' + Id);
  }
  // ลบข้อมูลวัตถุ
  CommandDelItem(data): any {
    return this.http.post(this.ApiAddress + 'items/del_item', data , this.httpOptionsPost);
  }
  // เพิ่มข้อมูลวัตถุ
  CommandAddItem(): any {
    // console.log(this.dataUser);
    const data = { Action: 'Add', UserAdd: this.dataUser.EmailCMU };
    console.log(data);
    return this.http.post( this.ApiAddress + 'items/set_createadd', data, this.httpOptionsPost);
  }
  // แก้ไขข้อมูลวัตถุ
  CommandEditItem(data): any {
    return this.http.post( this.ApiAddress + 'items/set_edit_item', data, this.httpOptionsPost);
  }

  CallAllStatusItem(): any {
    return this.http.get(this.ApiAddress + 'items/get_item_status_all');
  }
  CallItemStatus(id): any {
    return this.http.get(this.ApiAddress + 'items/itemstatus_callid');
  }
  CreateItemSatus(): any {
    return this.http.get(this.ApiAddress + 'items/itemstatus_add');
  }
  CmdItemStatus(data): any {
    return this.http.post( this.ApiAddress + 'items/itemstatus_edit', data, this.httpOptionsPost);
  }

// ประเภทวัตถุ
////////////////// Item Type /////////////////////////////////////
  CallAllTypeItem(): any {
    return this.http.get(this.ApiAddress + 'items/get_item_type_all');
  }
  CallItemType(id): any {
    return this.http.get(this.ApiAddress + 'items/itemtype_callid');
  }
  CreateItemType(): any {
    return this.http.get(this.ApiAddress + 'items/itemtype_add');
  }
  CmdItemType(data): any {
    return this.http.post( this.ApiAddress + 'items/itemtype_edit', data, this.httpOptionsPost);
  }















  // ยืม
  CheckOutItem(data): any {
    return this.http.post( this.ApiAddress + 'circulation/CheckOutItem', data, this.httpOptionsPost);
  }
  // คืน
  CheckInItem(data): any {
    return this.http.post( this.ApiAddress + 'circulation/CheckInItem', data, this.httpOptionsPost);
  }
  CheckInStatusItem(data): any {
    return this.http.post( this.ApiAddress + 'circulation/CheckInStatusItem', data, this.httpOptionsPost);
  }


// new code  //////////////////////////////////////

// Config Website
  CallConfig(Faculty): any {
    return this.http.get(this.ApiAddress + 'configs/get_configs?Faculty=' + Faculty);
  }


//////////////////// Slide // เรียกข้อมูลสไลด์


// เรียกเนื้อหาภายในเว็บไซต์
  CallContentId(Id): any {
    return this.http.get(this.ApiAddress + 'CallContents.php?Action=CallContentFacultyId&Id=' + Id);
  }


  CallIconServicesId(Id): any {
    return this.http.get(this.ApiAddress + 'CallIconServices.php?Action=CallContentFacultyId&Id=' + Id);
  }


// เรียกข้อมูล footerlinks
  CallFooterLinks(Faculty, limits): any {
    return this.http.get(this.ApiAddress + 'CallFooterlinks.php?Action=CallAllFaculty&Faculty=' + Faculty + '&limits=' + limits );
  }

// เรียกข้อมูล otherlinks
  CallOtherLinks(Faculty, limits): any {
    return this.http.get(this.ApiAddress + 'CallOtherlinks.php?Action=CallAllFaculty&Faculty=' + Faculty + '&limits=' + limits );
  }



// เรียกข้อมูล EBooks
/*  CallEBooks(Faculty, limits): any {
    return this.http.get(this.ApiAddress + 'CallEBook.php?Action=CallAllContentFaculty&Faculty=' + Faculty + '&limits=' + limits );
  }

  CallEbooksId(Id): any {
    return this.http.get(this.ApiAddress + 'CallEbook.php?Action=CallContentFacultyId&Id=' + Id);
  }

 */

/*
  CallJournal(Faculty, limits): any {
    return this.http.get(this.ApiAddress + 'CallJournal.php?Action=CallAllContentFaculty&Faculty=' + Faculty + '&limits=' + limits );
  }
  CallJournalId(Id): any {
    return this.http.get(this.ApiAddress + 'CallJournal.php?Action=CallContentFacultyId&Id=' + Id);
  }

  // เรียกข้อมูล Events เรียกข้อมูลกิจกรรม
  CallEvent(Faculty, limits): any {
    return this.http.get(this.ApiAddress + 'CallEvents.php?Action=CallAllContentFaculty&Faculty=' + Faculty + '&limits=' + limits );
  }
  CallEventId(Id): any {
    return this.http.get(this.ApiAddress + 'CallEvents.php?Action=CallContentFacultyId&Id=' + Id);
  }

  // เรียกข้อมูล News เรียกข้อมูลข่าวประชาสัมพันธ์
  CallNews(Faculty, limits): any {
    return this.http.get(this.ApiAddress + 'CallNews.php?Action=CallAllContentFaculty&Faculty=' + Faculty + '&limits=' + limits );
  }
  CallNewsId(Id): any {
    return this.http.get(this.ApiAddress + 'CallNews.php?Action=CallContentFacultyId&Id=' + Id);
  }
*/


////////////////////////////////// Website /////////////////////////////////////////////////////////////////////////////////////

// เรียกข้อมุล เมนู เนวิเกชั่น
  CallMenu(Faculty): any {
    return this.http.get(this.ApiAddress + 'frontend/get_all_menu?Faculty=' + Faculty);
  }
  // slides
  CallSlide(Faculty, limits): any {
    // return this.http.get(this.ApiAddress + 'CallSlides.php?Action=CallAllFaculty&Faculty=' + Faculty + '&limits=' + limits );
    return this.http.get(this.ApiAddress + 'frontend/callslidelimit?Faculty=' + Faculty + '&limits=' + limits );
  }

  // เรียกข้อมูล iconservices
  CallIconServices(Faculty, limits): any {
    // return this.http.get(this.ApiAddress + 'CallIconServices.php?Action=CallAllFaculty&Faculty=' + Faculty + '&limits=' + limits );
    return this.http.get(this.ApiAddress + 'frontend/calliconserviceslimit?Faculty=' + Faculty + '&Limits=' + limits );
  }
  CallAllIconServices(Faculty, limits): any {
    return this.http.get(this.ApiAddress + 'frontend/calliconservicesall?Faculty=' + Faculty );
  }

  // เนื้อหาภายในเว็บไซต์

  // ป้ายโฆษณา

  // ข่าวประชาสัมพันธ์

  // กิจกรรม

  // บทความ

  // termpaper

  // research

  // theses

  // EBook
  CallEbook(Faculty, limits): any {
    return this.http.get(this.ApiAddress + 'frontend/callebooklimit?Faculty=' + Faculty + '&Limits=' + limits );
  }
  CallEbookId(Id): any {
    return this.http.get(this.ApiAddress + 'frontend/callebookId?Id=' + Id );
  }
  // เรียกข้อมูล Books

  CallNewBooks(Faculty, limits): any {
    return this.http.get(this.ApiAddress + 'frontend/callnewbooklimit?Faculty=' + Faculty + '&Limits=' + limits );
  }
  CallNewBooksId(Id): any {
    return this.http.get(this.ApiAddress + 'frontend/callnewbookId?Id=' + Id );
  }

  // journal
  CallJournal(Faculty, limits): any {
    return this.http.get(this.ApiAddress + 'frontend/calljournallimit?Faculty=' + Faculty + '&Limits=' + limits );
  }
  CallJournalId(Id): any {
    return this.http.get(this.ApiAddress + 'frontend/calljournalId?Id=' + Id );
  }
  // journal
  CallEvent(Faculty, limits): any {
    return this.http.get(this.ApiAddress + 'frontend/calleventlimit?Faculty=' + Faculty + '&Limits=' + limits );
  }
  CallEventId(Id): any {
    return this.http.get(this.ApiAddress + 'frontend/calleventId?Id=' + Id );
  }
  // journal
  CallNews(Faculty, limits): any {
    return this.http.get(this.ApiAddress + 'frontend/callnewlimit?Faculty=' + Faculty + '&Limits=' + limits );
  }
  CallNewsId(Id): any {
    return this.http.get(this.ApiAddress + 'frontend/callnewId?Id=' + Id );
  }
  // edatabase

////////////////////////////////// Admin/////////////////////////////////////////////////////////////////////////////////////

// บริหารจัดการ slides
  CallAllSlideFaculty(): any {
    return this.http.get(this.ApiAddress + 'slides/callslidefaculty?Faculty=' + this.SiteFaculty );
  }
  CallSlideFacultyId(Id): any {
    return this.http.get(this.ApiAddress + 'slides/callslideid?Id=' + Id );
  }
  CommandManageSlide(data): any {
    return this.http.post( this.ApiAddress + 'slides/set_edit', data, this.httpOptionsPost);
  }
  CallNewSlideFaculty(): any {
    return this.http.get(this.ApiAddress + 'slides/set_createadd?Faculty=' + this.SiteFaculty  + '&UserAdd=' + this.dataUser.EmailCMU );
  }
  CommandSlideStatuspublish(data): any {
    return this.http.post(this.ApiAddress + 'slides/set_statuspublish', data, this.httpOptionsPost);
  }

// บริหารจัดการเนื้อหาภายในเว็บไซต์
  CreateNewContent(): any {
    const data = { Action: 'Add', UserAdd: this.dataUser.EmailCMU, Faculty : this.SiteFaculty };
    return this.http.post( this.ApiAddress + 'content/set_createadd', data, this.httpOptionsPost);
  }
  CommandManageContents(data): any {
    return this.http.post( this.ApiAddress + 'content/set_edit', data, this.httpOptionsPost);
  }
  CallAllContentFaculty(): any {
    return this.http.get(this.ApiAddress + 'content/get_all_faculty?Faculty=' + this.SiteFaculty);
  }
  CallContentFacultyId(Id): any {
    return this.http.get(this.ApiAddress + 'content/get_id?Id=' + Id );
  }
  CommandContentStatuspublish(data): any {
    return this.http.post(this.ApiAddress + 'content/set_statuspublish', data, this.httpOptionsPost);
  }

// บริหารจัดการ ป้ายโฆษณา
  CallAllBannerFaculty(): any {
    return this.http.get(this.ApiAddress + 'banner/get_all_faculty?Faculty=' + this.SiteFaculty);
  }
  CallBannerFacultyId(Id): any {
    return this.http.get(this.ApiAddress + 'banner/get_id?Id=' + Id );
  }
  CallNewBannerFaculty(): any {
    let dataEmail = '';
    if (this.dataUser.EmailCMU){
      dataEmail = this.dataUser.EmailCMU;
    }
    const data = { Action: 'Add', UserAdd: dataEmail, Faculty : this.SiteFaculty };
    return this.http.post( this.ApiAddress + 'banner/set_createadd', data, this.httpOptionsPost);
  }
  CommandManageBanners(data): any {
    return this.http.post( this.ApiAddress + 'banner/set_edit', data, this.httpOptionsPost);
  }
  CommandBannerStatuspublish(data): any {
    return this.http.post(this.ApiAddress + 'banner/set_statuspublish', data, this.httpOptionsPost);
  }

// บริหารจัดการ ข่าวประชาสัมพันธ์
  CallAllNewFaculty(): any {
    return this.http.get(this.ApiAddress + 'news/get_all_faculty?Faculty=' + this.SiteFaculty);
  }
  CallNewFacultyId(Id): any {
    return this.http.get(this.ApiAddress + 'news/get_id?Id=' + Id );
  }
  CallNewNewFaculty(): any {
    // return this.http.get(this.ApiAddress + 'CallNews.php?Action=CallAddContent&Faculty=' + this.SiteFaculty );
    const data = { Action: 'Add', UserAdd: this.dataUser.EmailCMU, Faculty : this.SiteFaculty };
    return this.http.post( this.ApiAddress + 'news/set_createadd', data, this.httpOptionsPost);
  }
  CommandManageNews(data): any {
    return this.http.post( this.ApiAddress + 'news/set_edit', data, this.httpOptionsPost);
  }
  CommandNewStatuspublish(data): any {
    return this.http.post(this.ApiAddress + 'news/set_statuspublish', data, this.httpOptionsPost);
  }

// บริหารจัดการ กิจกรรม
  CallAllEventFaculty(): any {
    return this.http.get(this.ApiAddress + 'events/get_all_faculty?Faculty=' + this.SiteFaculty);
  }
  CallEventFacultyId(Id): any {
    return this.http.get(this.ApiAddress + 'events/get_id?Id=' + Id );
  }
  CallNewEventFaculty(): any {
    const data = { Action: 'Add', UserAdd: this.dataUser.EmailCMU, Faculty : this.SiteFaculty };
    return this.http.post( this.ApiAddress + 'events/set_createadd', data, this.httpOptionsPost);
  }
  CommandManageEvents(data): any {
    return this.http.post( this.ApiAddress + 'events/set_edit', data, this.httpOptionsPost);
  }
  CommandEventStatuspublish(data): any {
    return this.http.post(this.ApiAddress + 'events/set_statuspublish', data, this.httpOptionsPost);
  }

// บริหารจัดการ บทความ
  CallAllArticleFaculty(): any {
    // return this.http.get(this.ApiAddress + 'CallArticles.php?Action=CallAllContentFaculty&Faculty=' + this.SiteFaculty );
    return this.http.get(this.ApiAddress + 'article/get_all_faculty?Faculty=' + this.SiteFaculty);
  }
  CallArticleFacultyId(Id): any {
    // return this.http.get(this.ApiAddress + 'CallArticles.php?Action=CallContentFacultyId&Id=' + Id );
    return this.http.get(this.ApiAddress + 'article/get_id?Id=' + Id );
  }
  CallNewArticleFaculty(): any {
    // return this.http.get(this.ApiAddress + 'CallArticles.php?Action=CallAdd&Faculty=' + this.dataUser.SiteFaculty + '&UserAdd=' + this.dataUser.EmailCMU  );
    const data = { Action: 'Add', UserAdd: this.dataUser.EmailCMU, Faculty : this.SiteFaculty };
    return this.http.post( this.ApiAddress + 'article/set_createadd', data, this.httpOptionsPost);
  }
  CommandManageArticles(data): any {
    // return this.http.post( this.ApiAddress + 'CommandArticles.php', data, this.httpOptionsPost);
    return this.http.post( this.ApiAddress + 'article/set_edit', data, this.httpOptionsPost);
  }
  CommandArticleStatuspublish(data): any {
    // return this.http.post(this.ApiAddress + 'CommandArticles.php', data, this.httpOptionsPost);
    return this.http.post(this.ApiAddress + 'article/set_statuspublish', data, this.httpOptionsPost);
  }

  // บริหารจัดการ termpaper
  CallAllTermpaperFaculty(): any {
    // return this.http.get(this.ApiAddress + 'CallTermpaper.php?Action=CallAllContentFaculty&Faculty=' + this.SiteFaculty );
    return this.http.get(this.ApiAddress + 'termpaper/get_all_faculty?Faculty=' + this.SiteFaculty);
  }
  CallTermpaperFacultyId(Id): any {
    // return this.http.get(this.ApiAddress + 'CallTermpaper.php?Action=CallContentFacultyId&Id=' + Id );
    return this.http.get(this.ApiAddress + 'termpaper/get_id?Id=' + Id );
  }
  CallNewTermpaperFaculty(): any {
    // return this.http.get(this.ApiAddress + 'CallTermpaper.php?Action=CallAdd&Faculty=' + this.SiteFaculty + '&UserAdd=' + this.dataUser.EmailCMU );
    const data = { Action: 'Add', UserAdd: this.dataUser.EmailCMU, Faculty : this.SiteFaculty };
    return this.http.post( this.ApiAddress + 'termpaper/set_createadd', data, this.httpOptionsPost);
  }
  CommandManageTermpaper(data): any {
    // return this.http.post( this.ApiAddress + 'CommandTermpaper.php', data, this.httpOptionsPost);
    return this.http.post( this.ApiAddress + 'termpaper/set_edit', data, this.httpOptionsPost);
  }
  CommandTermpaperStatuspublish(data): any {
    // return this.http.post(this.ApiAddress + 'CommandTermpaper.php', data, this.httpOptionsPost);
    return this.http.post(this.ApiAddress + 'termpaper/set_statuspublish', data, this.httpOptionsPost);
  }

  // บริหารจัดการ research
  CallAllResearchFaculty(): any {
    // return this.http.get(this.ApiAddress + 'CallResearch.php?Action=CallAllContentFaculty&Faculty=' + this.SiteFaculty );
    return this.http.get(this.ApiAddress + 'research/get_all_faculty?Faculty=' + this.SiteFaculty);
  }
  CallResearchFacultyId(Id): any {
    // return this.http.get(this.ApiAddress + 'CallResearch.php?Action=CallContentFacultyId&Id=' + Id );
    return this.http.get(this.ApiAddress + 'research/get_id?Id=' + Id );
  }
  CallNewResearchFaculty(): any {
    // return this.http.get(this.ApiAddress + 'CallResearch.php?Action=CallAdd&Faculty=' + this.SiteFaculty + '&UserAdd=' + this.dataUser.EmailCMU );
    const data = { Action: 'Add', UserAdd: this.dataUser.EmailCMU, Faculty : this.SiteFaculty };
    return this.http.post( this.ApiAddress + 'research/set_createadd', data, this.httpOptionsPost);
  }
  CommandManageResearch(data): any {
    // return this.http.post( this.ApiAddress + 'CommandResearch.php', data, this.httpOptionsPost);
    return this.http.post( this.ApiAddress + 'research/set_edit', data, this.httpOptionsPost);
  }
  CommandResearchStatuspublish(data): any {
    // return this.http.post(this.ApiAddress + 'CommandResearch.php', data, this.httpOptionsPost);
    return this.http.post(this.ApiAddress + 'research/set_statuspublish', data, this.httpOptionsPost);
  }

  // บริหารจัดการ theses
  CallAllThesesFaculty(): any {
    return this.http.get(this.ApiAddress + 'theses/get_all_faculty?Faculty=' + this.SiteFaculty);
  }
  CallThesesFacultyId(Id): any {
    return this.http.get(this.ApiAddress + 'theses/get_id?Id=' + Id );
  }
  CallNewThesesFaculty(): any {
    const data = { Action: 'Add', UserAdd: this.dataUser.EmailCMU, Faculty : this.SiteFaculty };
    return this.http.post( this.ApiAddress + 'theses/set_createadd', data, this.httpOptionsPost);
  }
  CommandManageTheses(data): any {
    return this.http.post( this.ApiAddress + 'theses/set_edit', data, this.httpOptionsPost);
  }
  CommandThesesStatuspublish(data): any {
    return this.http.post(this.ApiAddress + 'theses/set_statuspublish', data, this.httpOptionsPost);
  }

  // บริหารจัดการ Book
  CallAllBookFaculty(): any {
    return this.http.get(this.ApiAddress + 'books/get_all_faculty?Faculty=' + this.SiteFaculty);
  }
  CallBookFacultyId(Id): any {
    return this.http.get(this.ApiAddress + 'books/get_id?Id=' + Id );
  }
  CallNewBookFaculty(): any {
    const data = { Action: 'Add', UserAdd: this.dataUser.EmailCMU, Faculty : this.SiteFaculty };
    return this.http.post( this.ApiAddress + 'books/set_createadd', data, this.httpOptionsPost);
  }
  CommandManageBook(data): any {
    return this.http.post( this.ApiAddress + 'books/set_edit', data, this.httpOptionsPost);
  }
  CommandBookStatuspublish(data): any {
    return this.http.post(this.ApiAddress + 'books/set_statuspublish', data, this.httpOptionsPost);
  }

  // บริหารจัดการ journal
  CallAllJournalFaculty(): any {
    return this.http.get(this.ApiAddress + 'journal/get_all_faculty?Faculty=' + this.SiteFaculty);
  }
  CallJournalFacultyId(Id): any {
    return this.http.get(this.ApiAddress + 'journal/get_id?Id=' + Id );
  }
  CallNewJournalFaculty(): any {
    const data = { Action: 'Add', UserAdd: this.dataUser.EmailCMU, Faculty : this.SiteFaculty };
    return this.http.post( this.ApiAddress + 'journal/set_createadd', data, this.httpOptionsPost);
  }
  CommandManageJournal(data): any {
    return this.http.post( this.ApiAddress + 'journal/set_edit', data, this.httpOptionsPost);
  }
  CommandJournalStatuspublish(data): any {
    return this.http.post(this.ApiAddress + 'journal/set_statuspublish', data, this.httpOptionsPost);
  }

  // บริหารจัดการ edatabase
  CallAllEdatabaseFaculty(): any {
    return this.http.get(this.ApiAddress + 'edatabase/get_all_faculty?Faculty=' + this.SiteFaculty);
  }
  CallEdatabaseFacultyId(Id): any {
    return this.http.get(this.ApiAddress + 'edatabase/get_id?Id=' + Id );
  }
  CallNewEdatabaseFaculty(): any {
    const data = { Action: 'Add', UserAdd: this.dataUser.EmailCMU, Faculty : this.SiteFaculty };
    return this.http.post( this.ApiAddress + 'edatabase/set_createadd', data, this.httpOptionsPost);
  }
  CommandManageEdatabase(data): any {
    return this.http.post( this.ApiAddress + 'edatabase/set_edit', data, this.httpOptionsPost);
  }
  CommandEdatabaseStatuspublish(data): any {
    return this.http.post(this.ApiAddress + 'edatabase/set_statuspublish', data, this.httpOptionsPost);
  }






// ตั้งค่าเว็บไซต์
  CallWebsiteConfig(): any {
    return this.http.get(this.ApiAddress + 'CallConfigs.php?Action=CallWebsiteConfig&Faculty=' + this.SiteFaculty );
  }
  CallWebsiteConfigFaculty(Faculty): any {
    return this.http.get(this.ApiAddress + 'CallConfigs.php?Action=CallWebsiteConfigFaculty&Faculty=' + Faculty );
  }
  CommandConfig(data): any {
    return this.http.post( this.ApiAddress + 'CallConfigs.php', data, this.httpOptionsPost);
  }

  // บริหารจัดการข้อมูลเมนู
  CallAllMainmenuFaculty(): any {
    return this.http.get(this.ApiAddress + 'mainmenu/get_all?Faculty=' + this.SiteFaculty );
  }
  CommandManageMainmenu(data): any {
    return this.http.post( this.ApiAddress + 'mainmenu/set_menu', data, this.httpOptionsPost);
  }
  CallMainmenuFacultyId(Id): any {
    return this.http.get(this.ApiAddress + 'mainmenu/get_id?Id=' + Id );
  }
  CommandManageMainMenuSortRank(data): any {
    return this.http.post( this.ApiAddress + 'mainmenu/set_mainmenurank', data, this.httpOptionsPost);
  }
  CommandManageSubMainMenuSortRank(data): any {
    return this.http.post( this.ApiAddress + 'mainmenu/set_submainmenurank', data, this.httpOptionsPost);
  }
  // บริหารจัดการข้อมูลเมนู iconservices
  CallAllMainmenuiconservicesFaculty(): any {
    return this.http.get(this.ApiAddress + 'mainmenuiconservices/get_all?Faculty=' + this.SiteFaculty );
  }
  CommandManageMainmenuiconservices(data): any {
    return this.http.post( this.ApiAddress + 'mainmenuiconservices/set_menu', data, this.httpOptionsPost);
  }
  CallMainmenuiconservicesFacultyId(Id): any {
    return this.http.get(this.ApiAddress + 'mainmenuiconservices/get_id?Id=' + Id );
  }
  CommandManageMainMenuiconservicesSortRank(data): any {
    return this.http.post( this.ApiAddress + 'mainmenuiconservices/set_mainmenurank', data, this.httpOptionsPost);
  }
  CommandManageSubMainMenuiconservicesSortRank(data): any {
    return this.http.post( this.ApiAddress + 'mainmenuiconservices/set_submainmenurank', data, this.httpOptionsPost);
  }







  /*
  CommandManageSubMainMenuSortRank(SubMenuId, data): any{
    return this.http.post( this.ApiAddress + 'CommandMainmenu.php?Action=SortRankSubMainMenu&SubMenuId=' + SubMenuId, data, this.httpOptionsPost);
  }
*/
  CallNewMainmenuFaculty(): any {
    return this.http.get(this.ApiAddress + 'CallMainmenu.php?Action=CallAdd&Faculty=' + this.SiteFaculty + '&UserAdd=' + this.dataUser.EmailCMU );
  }

  CommandMainmenuStatuspublish(data): any {
    return this.http.post(this.ApiAddress + 'CommandMainmenu.php', data, this.httpOptionsPost);
  }
  // เรียกเมนูย่อย

  CallSubMainmenuFacultyId(Id): any {
    return this.http.get(this.ApiAddress + 'CallMainmenu.php?Action=CallSubMainmenuFacultyId&Id=' + Id );
  }


// ข้อมูล Checkin
  CallCheckin(Action, Email): any {
    return this.http.get(this.ApiAddress + 'callcheckin.php?Action=' + Action + '&email=' + Email);
  }
  CommandCheckin(data): any {
    return this.http.post( this.ApiAddress + 'commandcheckin.php', data, this.httpOptionsPost);
  }
  // เรียกข้อมูลผู้ล๊อคอิน
  CallHistoryUserCheckIn(Action, Email): any {
    return this.http.get(this.ApiAddress + 'calluser.php?Action=' + Action + '&EmailCMU=' + Email);
  }
  // เรียกข้อมูลผู้ล๊อคอิน สำหรับ ผู้บริหาร
  CallHistoryUserCheckInManager(Action, DateStart, DateEnd, KeySearch, Email, Faculty, FacultyDepartment, Department): any {
    return this.http.get(this.ApiAddress + 'callreports.php?Action=' + Action + '&DateStart=' + DateStart + '&DateEnd=' + DateEnd + '&KeySearch=' + KeySearch + '&EmailCMU=' + Email + '&Faculty=' + Faculty + '&FacultyDepartment=' + FacultyDepartment + '&Department=' + Department);
  }
// uploadfile
// File And Image
  // Upload data to base
  CommandUpload(data): any {
    return this.http.post(this.ApiAddress + 'ManageFileUpload.php', data, this.httpOptionsPost);
  }
  // ส่งจดหมาย
  sendemail(data): any {
    return this.http.post( this.ApiAddress + 'sendemail.php', data, this.httpOptionsPost);
  }


}
