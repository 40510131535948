import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {AuthService} from './auth.service';
import {HttpClient} from '@angular/common/http';
import {ApiwebService} from './apiweb.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  public check;

  constructor(private router: Router, private auth: AuthService, private http: HttpClient, private apiweb: ApiwebService) { }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (localStorage.getItem('access_token')) {
      // logged in so return true
      this.getToken(localStorage.getItem('access_token')).subscribe((data: any[]) => {
        // console.log(localStorage.getItem('access_token'));
        // console.log(data);
        if (data == null) {
          localStorage.clear();
          localStorage.removeItem('access_token');
          this.router.navigate(['login']);
          return false;
        }
        if (data['Message']) {
          localStorage.clear();
          localStorage.removeItem('access_token');
          this.router.navigate(['login']);
          return false;
        }
      });
      return true;
      // return this.check;
      // console.log('true');
    } else {
      // console.log('false');
      localStorage.clear();
      this.router.navigate(['login']);
      return false;
      // this.router.navigate(['login'], {queryParams: {returnUrl: state.url}});
    }
  }

  getToken(code) {
    return this.http.get(this.apiweb.CMUOauthClientCallback + '?access_token=' + code);
  }

  checkDepartment() {
    // console.log( 'test check position');
  }

  logout() {
    localStorage.clear();
    localStorage.removeItem('access_token');
    this.router.navigate(['login']);
  }
}
