<div class="row">
  <div class="container">
    <div class="col-12">
      <i class="fas fa-step-backward fa-2x text-warning" (click)="ClickBack()">Back</i>
    </div>
  </div>
  <div class="container">
    <ng-container *ngIf="ContentTh;else ContentEn">
      <div class="card">
        <div class="container-fliud">
          <div class="wrapper row">
            <div class="preview col-md-6 text-center">
              <div class="preview-pic">
                <div class=""><img src="{{pathimage}}{{DataItem.Image}}"/>
                </div>
              </div>
            </div>

            <div class="details col-md-6">
              <ng-container *ngIf="DataItem.TitleTh; else NoTitleTh">
                <h3 class="product-title">{{DataItem.TitleTh}}</h3>
              </ng-container>
              <ng-template #NoTitleTh>
                <h3 class="product-title">{{DataItem.TitleEn}}</h3>
              </ng-template>

              <p>ISBN13 : {{DataItem.ISBN13}}</p>
              <p>ISSN : {{DataItem.ISSN}}</p>
              <p>ISSUE : {{DataItem.ISSUE}}</p>
              <p>Frequency : {{DataItem.Frequency}}</p>
              <p>FullTextCoverage : {{DataItem.FullTextCoverage}}</p>
              <p>Pages : {{DataItem.Pages}}</p>
              <p>Publication : {{DataItem.Publication}}</p>
              <p>Publisher : {{DataItem.Publisher}}</p>
              <ng-container *ngIf="DataItem.Link;else havelink">
                <p>Link : <a href="{{DataItem.Link}}" target="_blank"><i class="fas fa-book fa-3x"></i></a> </p>
              </ng-container>
              <ng-template #havelink>

              </ng-template>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #ContentEn>
      <div class="card">
        <div class="container-fliud">
          <div class="wrapper row">
            <div class="preview col-md-6 text-center">
              <div class="preview-pic">
                <div id="{{DataItem.Image}}"><img src="{{pathimage}}{{DataItem.Image}}"/>
                </div>
              </div>
            </div>

            <div class="details col-md-6">
              <ng-container *ngIf="DataItem.TitleEn; else Notitleen">
                <h3 class="product-title">{{DataItem.TitleEn}}</h3>
              </ng-container>
              <ng-template #Notitleen>
                <h3 class="product-title">{{DataItem.TitleTh}}</h3>
              </ng-template>

              <p>ISBN13 : {{DataItem.ISBN13}}</p>
              <p>ISSN : {{DataItem.ISSN}}</p>
              <p>ISSUE : {{DataItem.ISSUE}}</p>
              <p>Frequency : {{DataItem.Frequency}}</p>
              <p>FullTextCoverage : {{DataItem.FullTextCoverage}}</p>
              <p>Pages : {{DataItem.Pages}}</p>
              <p>Publication : {{DataItem.Publication}}</p>
              <p>Publisher : {{DataItem.Publisher}}</p>
              <ng-container *ngIf="DataItem.Link;else havelink">

                <p>Link : <a href="{{DataItem.Link}}" target="_blank"><i class="fas fa-book fa-3x"></i></a> </p>
              </ng-container>
              <ng-template #havelink>

              </ng-template>

            </div>
          </div>
        </div>
      </div>

    </ng-template>








  </div>
  <div class="container mt-2">

  </div>
</div>

<!--
<br>
{{PageLang}}<br>
{{DataContents.AuthorEditor}}
{{DataContents.DateAdd}}
{{DataContents.Faculty}}
{{DataContents.Frequency}}
{{DataContents.FullTextCoverage}}
{{DataContents.ISBN13}}
{{DataContents.ISSN}}
{{DataContents.ISSUE}}
{{DataContents.Id}}
{{DataContents.Image}}
{{DataContents.Link}}
{{DataContents.Pages}}
{{DataContents.Publication}}
{{DataContents.Publisher}}
{{DataContents.Status}}
{{DataContents.StatusPublish}}
{{DataContents.TitleEn}}
{{DataContents.TitleTh}}
-->
