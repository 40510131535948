<div class="row pt-2 text-center bg-default">
  <div class="col-12">
    <h2 class="text-white fontKanitRegular"> ระบบ บริหารจัดการข้อมูล เว็บไซต์ ห้องสมุด</h2>
  </div>
</div>

<div class="row">
  <div class="col-2">
    <app-dashboardwebsiteleftmenu></app-dashboardwebsiteleftmenu>
  </div>
  <div class="col-10">
    <router-outlet></router-outlet>
  </div>
</div>
