import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-searchtoolseds',
  templateUrl: './searchtoolseds.component.html',
  styleUrls: ['./searchtoolseds.component.scss']
})
export class SearchtoolsedsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
