import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboardcirculationleftmenu',
  templateUrl: './dashboardcirculationleftmenu.component.html',
  styleUrls: ['./dashboardcirculationleftmenu.component.scss']
})
export class DashboardcirculationleftmenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
