<div class="row">
  <div class="container">
    <div class="row mt-2 p-2" style="background-color: #888888;">
      <div class="col-12">
        <h2 class="">{{txtFrmType}} {{txtHeaderType}}</h2>
      </div>
    </div>
    <form [formGroup]="FormAdd">

      <div class="row mt-2">
        <div class="col-4">
          <div class="form-group">
            <label for="ISBN"><strong>ISBN</strong></label>
            <input type="text" class="form-control" id="ISBN" placeholder="ชื่อเรื่อง" formControlName="ISBN">
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="ISSN"><strong>ISSN</strong></label>
            <input type="text" class="form-control" id="ISSN" placeholder="ISSN" formControlName="ISSN">
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="AuthorEditor"><strong>AuthorEditor</strong></label>
            <input type="text" class="form-control" id="AuthorEditor" placeholder="AuthorEditor" formControlName="AuthorEditor">
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <div class="form-group">
            <label for="Publication"><strong>Publication</strong></label>
            <input type="text" class="form-control" id="Publication" placeholder="Publication" formControlName="Publication">
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="ISSN"><strong>Publisher</strong></label>
            <input type="text" class="form-control" id="Publisher" placeholder="Publisher" formControlName="Publisher">
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="FullTextCoverage"><strong>FullTextCoverage</strong></label>
            <input type="text" class="form-control" id="FullTextCoverage" placeholder="FullTextCoverage" formControlName="FullTextCoverage">
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-4">
          <div class="form-group">
            <label for="Pages"><strong>Pages</strong></label>
            <input type="text" class="form-control" id="Pages" placeholder="Pages" formControlName="Pages">
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="Frequency"><strong>Frequency</strong></label>
            <input type="text" class="form-control" id="Frequency" placeholder="Frequency" formControlName="Frequency">
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="ISSUE"><strong>ISSUE</strong></label>
            <input type="text" class="form-control" id="ISSUE" placeholder="ISSUE" formControlName="ISSUE">
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-12">
          <div class="form-group">
            <label for="titleth"><strong>ชื่อหนังสือ ภาษาไทย</strong></label>
            <input type="text" class="form-control" id="titleth" placeholder="ชื่อเรื่อง" formControlName="TitleTh">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="titleen"><strong>ชื่อหนังสือ ภาษาอังกฤษ</strong></label>
            <input type="text" class="form-control" id="titleen" placeholder="Title" formControlName="TitleEn">
          </div>
        </div>
      </div>


      <div class="row mt-2">
        <div class="col-12">
          <label for="titleen">รูปภาพตัวอย่างหน้าปกวารสาร</label>
          <mat-card>
            <mat-card-content>
              <div class="row" *ngIf="FormAdd.value.ImageCover !== '' || FormAdd.value.ImageCover !== 'null' " >
                <div class="col-10">
                  <img src="{{FileURL}}{{FormAdd.value.ImageCover}}" style="width: 100%; max-width: 250px;">
                </div>
                <div class="col-2">
                  <i class="fas fa-window-close" (click)="OnClickDeleteFile('imagessth')"> ลบรูปภาพตัวอย่างหน้าปกวารสาร</i>
                </div>

              </div>
              <div *ngFor="let file of files">
                <mat-progress-bar [value]="file.progress"></mat-progress-bar>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <button mat-button color="warn" (click)="onClick()">
                <i class="fas fa-cloud-upload-alt fa-2x">Upload</i>
              </button>
            </mat-card-actions>
          </mat-card>
          <input type="file" #fileUpload id="fileUpload" name="upload" accept="image/*" style="display:none;" />

        </div>
      </div>
      <div class="row mt-2 p-2 border border-warning">
        <div class="col-12">
          <div class="form-group">
            <label for="Links">ลิงค์ไปยัง เว็บไซต์ภายนอก หรือลิงค์ไปยังวารสาร </label>
            <input type="text" class="form-control" id="Links" placeholder="Ex: http://google.com" formControlName="Links">
          </div>
          * หมายเหตุ : เมื่อใส่ลิงค์ตรงนี้ จะเป็นการกำหนดลิงค์ไปยังเว็บไซต์ที่กำหนด
        </div>
      </div>


      <div class="row mt-2 mb-2 p-3" >
        <div class="col-12 pt-2 pb-2" style="background-color: #e4e4e4">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input input-lg" id="StatusPublish" [checked]="CheckSwitchTrueFalse(FormAdd.value.StatusPublish)" formControlName="StatusPublish" >
            <label class="custom-control-label" for="StatusPublish">สถานะการเผยแพร่ข้อมูล</label>
          </div>
        </div>
      </div>
    </form>
    <div class="row mb-2">
      <div class="col-12 text-center">
        <span class="btn btn-primary mr-3" (click)="ClickSave()"><i class="far fa-save"></i> บันทึกข้อมูล</span>
        <span class="btn btn-warning" (click)="ClickCancel()"><i class="fas fa-share fa-rotate-180"></i> ยกเลิก</span>
      </div>
    </div>
  </div>

</div>
