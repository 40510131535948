import { Component, OnInit } from '@angular/core';
import {ApiwebService} from '../../../apiweb.service';

@Component({
  selector: 'app-blocknews',
  templateUrl: './blocknews.component.html',
  styleUrls: ['./blocknews.component.scss']
})
export class BlocknewsComponent implements OnInit {
  public DataAll;
  public ContentTh = false;
  public totalIcon;
  public iconmore = false;
  public addressimage;
  constructor(public apiweb: ApiwebService) { }

  ngOnInit(): void {
    this.CallIconServices();
    this.addressimage = this.apiweb.ApiAddress + 'assets/news/';
    if (localStorage.getItem('lang') === 'En'){
      this.ContentTh = false;
    } else {
      localStorage.setItem('lang', 'Th');
      this.ContentTh = true;
    }
  }

  CallIconServices(){
    this.apiweb.CallNews(localStorage.getItem('SiteFaculty'), '12' ).subscribe( (dataresponse: any) => {
      if (dataresponse.status === true){
        const data = dataresponse.data;
        this.DataAll = data;
        this.totalIcon = data[0].totalicon;
        if (data[0].totalicon > 10){
          this.iconmore = true;
        }
      }else{
        this.DataAll = [];
      }
    });
  }
}
