import { Component, OnInit } from '@angular/core';
import {ApiwebService} from '../../apiweb.service';

@Component({
  selector: 'app-layouthome',
  templateUrl: './layouthome.component.html',
  styleUrls: ['./layouthome.component.scss']
})
export class LayouthomeComponent implements OnInit {
  public LogoAddress;
  public siteAddress;
  public SiteName;
  public SiteFaculty;
  public SiteVideotrailer;
  public SiteBackgroundSearchbox;
  public SiteBackgroundSearchboxColor;
  public BackgroundFeeds;
  public BackgroundFeedColors;
  public BackgroundFootters;
  public BackgroundFooterColors;

  constructor(public apiweb: ApiwebService) { }

  ngOnInit(): void {
    this.apiweb.CallConfig(this.apiweb.SiteFaculty).subscribe( (dataresponse: any) => {

      if (dataresponse.status === true){
        const data = dataresponse.data[0];
        this.LogoAddress = this.apiweb.ApiAddress + 'assets/configs/' + data.Logo;
        this.siteAddress = data.SiteAddress;
        this.SiteName = data.Faculty;
        this.SiteFaculty = data.Faculty;
        this.SiteVideotrailer = data.VideoTrailer;
        this.SiteBackgroundSearchbox = data.Faculty;
        this.SiteBackgroundSearchboxColor = data.BackgroundSearchbox;
        this.BackgroundFeeds = data.BackgroundFeeds;
        this.BackgroundFeedColors = data.BackgroundFeedColors;
        this.BackgroundFootters = data.BackgroundFootters;
        this.BackgroundFooterColors = data.BackgroundFooterColors;

        localStorage.setItem('SiteAddress',  data.SiteAddress );
        localStorage.setItem('LogoAddress',  this.LogoAddress );
        localStorage.setItem('SiteLogo', data.Logo);
        localStorage.setItem('SiteName', data.SiteName);
        localStorage.setItem('SiteFaculty', data.Faculty);
        localStorage.setItem('SiteVideotrailer', data.VideoTrailer);
        localStorage.setItem('SiteBackgroundSearchbox', data.BackgroundSearchbox);
        localStorage.setItem('SiteBackgroundSearchboxColor', data.BackgroundSearchboxColor);
        localStorage.setItem('BackgroundFeeds', data.BackgroundFeeds);
        localStorage.setItem('BackgroundFeedColors', data.BackgroundFeedColors);
        localStorage.setItem('BackgroundFootters', data.BackgroundFootters);
        localStorage.setItem('BackgroundFooterColors', data.BackgroundFooterColors);
      }
    });
  }


}
