import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DetailtrendyartsComponent} from './website/trendyarts/detailtrendyarts/detailtrendyarts.component';
import {LayoutstudentComponent} from './layout/layoutstudent/layoutstudent.component';
import {HomeComponent} from './website/home/home.component';
import {CirculationreportuserComponent} from './dashboard/dashboardcirculation/dashboardcirculationreports/circulationreportuser/circulationreportuser.component';
import {EventdetailComponent} from './website/event/eventdetail/eventdetail.component';
import {EmpoloyeeComponent} from './empoloyee/empoloyee.component';
import {BookdeatilComponent} from './website/book/bookdeatil/bookdeatil.component';
import {AuthGuard} from './auth.guard';
import {FrmthesesComponent} from './dashboard/dashboardwebsite/frm/frmtheses/frmtheses.component';
import {FrmtrendyartsComponent} from './dashboard/dashboardwebsite/frm/frmtrendyarts/frmtrendyarts.component';
import {DashboardconfigsComponent} from './dashboard/dashboardwebsite/dashboardconfigs/dashboardconfigs.component';
import {DashboardcirculationmanagementComponent} from './dashboard/dashboardcirculation/dashboardcirculationmanagement/dashboardcirculationmanagement.component';
import {FrmcirculationmangeComponent} from './dashboard/dashboardcirculation/dashboardcirculationfrm/frmcirculationmange/frmcirculationmange.component';
import {CirculationreportchartservicesComponent} from './dashboard/dashboardcirculation/dashboardcirculationreports/circulationreportchartservices/circulationreportchartservices.component';
import {LayoutdashboardComponent} from './layout/layoutdashboard/layoutdashboard.component';
import {LayoutdashboardwebsiteComponent} from './layout/layoutdashboardwebsite/layoutdashboardwebsite.component';
import {FrmeventComponent} from './dashboard/dashboardwebsite/frm/frmevent/frmevent.component';
import {DashboardarticleComponent} from './dashboard/dashboardwebsite/dashboardarticle/dashboardarticle.component';
import {NewsComponent} from './website/news/news.component';
import {LayouthomeComponent} from './layout/layouthome/layouthome.component';
import {FrmjournalComponent} from './dashboard/dashboardwebsite/frm/frmjournal/frmjournal.component';
import {JournaldetailComponent} from './website/journal/journaldetail/journaldetail.component';
import {LayoutdashboardcirculationComponent} from './layout/layoutdashboardcirculation/layoutdashboardcirculation.component';
import {DashboardjournalComponent} from './dashboard/dashboardwebsite/dashboardjournal/dashboardjournal.component';
import {FrmbookComponent} from './dashboard/dashboardwebsite/frm/frmbook/frmbook.component';
import {CirculationreportitemComponent} from './dashboard/dashboardcirculation/dashboardcirculationreports/circulationreportitem/circulationreportitem.component';
import {DashboardtrendyartsComponent} from './dashboard/dashboardwebsite/dashboardtrendyarts/dashboardtrendyarts.component';
import {DashboardslideComponent} from './dashboard/dashboardwebsite/dashboardslide/dashboardslide.component';
import {ServicesComponent} from './website/services/services.component';
import {DashboardtermpaperComponent} from './dashboard/dashboardwebsite/dashboardtermpaper/dashboardtermpaper.component';
import {LogoutComponent} from './website/logout/logout.component';
import {TrendyartsComponent} from './website/trendyarts/trendyarts.component';
import {DashboardwebsiteComponent} from './dashboard/dashboardwebsite/dashboardwebsite.component';
import {FrmbannerComponent} from './dashboard/dashboardwebsite/frm/frmbanner/frmbanner.component';
import {DashboardbookComponent} from './dashboard/dashboardwebsite/dashboardbook/dashboardbook.component';
import {DashboardiconservicesComponent} from './dashboard/dashboardwebsite/dashboardiconservices/dashboardiconservices.component';
import {DashboardcirculationoutComponent} from './dashboard/dashboardcirculation/dashboardcirculationout/dashboardcirculationout.component';
import {DashboardcirculationinComponent} from './dashboard/dashboardcirculation/dashboardcirculationin/dashboardcirculationin.component';
import {FrmtermpaperComponent} from './dashboard/dashboardwebsite/frm/frmtermpaper/frmtermpaper.component';
import {ContentComponent} from './website/content/content.component';
import {EbookComponent} from './website/ebook/ebook.component';
import {FrmcontentComponent} from './dashboard/dashboardwebsite/frm/frmcontent/frmcontent.component';
import {DashboardComponent} from './dashboard/dashboard.component';
import {StudentComponent} from './student/student.component';
import {DashboardnewsComponent} from './dashboard/dashboardwebsite/dashboardnews/dashboardnews.component';
import {DashboardcirculationreportsComponent} from './dashboard/dashboardcirculation/dashboardcirculationreports/dashboardcirculationreports.component';
import {DashboardbannersComponent} from './dashboard/dashboardwebsite/dashboardbanners/dashboardbanners.component';
import {FrmresearchComponent} from './dashboard/dashboardwebsite/frm/frmresearch/frmresearch.component';
import {LoginComponent} from './website/login/login.component';
import {LayoutalumniComponent} from './layout/layoutalumni/layoutalumni.component';
import {DashboardconfigfooterComponent} from './dashboard/dashboardwebsite/dashboardconfigfooter/dashboardconfigfooter.component';
import {AlumniComponent} from './alumni/alumni.component';
import {FrmiconservicesComponent} from './dashboard/dashboardwebsite/frm/frmiconservices/frmiconservices.component';
import {ContentdetailComponent} from './website/content/contentdetail/contentdetail.component';
import {DashboardmenuComponent} from './dashboard/dashboardwebsite/dashboardmenu/dashboardmenu.component';
import {DashboardcirculationComponent} from './dashboard/dashboardcirculation/dashboardcirculation.component';
import {EbookdetailComponent} from './website/ebook/ebookdetail/ebookdetail.component';
import {JournalComponent} from './website/journal/journal.component';
import {BookComponent} from './website/book/book.component';
import {CallbackComponent} from './callback/callback.component';
import {FrmslideComponent} from './dashboard/dashboardwebsite/frm/frmslide/frmslide.component';
import {DashboardedatabaseComponent} from './dashboard/dashboardwebsite/dashboardedatabase/dashboardedatabase.component';
import {LayoutemployeeComponent} from './layout/layoutemployee/layoutemployee.component';
import {DashboardeventsComponent} from './dashboard/dashboardwebsite/dashboardevents/dashboardevents.component';
import {FrmarticleComponent} from './dashboard/dashboardwebsite/frm/frmarticle/frmarticle.component';
import {EventComponent} from './website/event/event.component';
import {DashboardresearchComponent} from './dashboard/dashboardwebsite/dashboardresearch/dashboardresearch.component';
import {CirculationreportservicesComponent} from './dashboard/dashboardcirculation/dashboardcirculationreports/circulationreportservices/circulationreportservices.component';
import {ServicesdetailComponent} from './website/services/servicesdetail/servicesdetail.component';
import {NewsdetailComponent} from './website/news/newsdetail/newsdetail.component';
import {DashboardthesesComponent} from './dashboard/dashboardwebsite/dashboardtheses/dashboardtheses.component';
import {FrmedatabaseComponent} from './dashboard/dashboardwebsite/frm/frmedatabase/frmedatabase.component';
import {DashboardcontentsComponent} from './dashboard/dashboardwebsite/dashboardcontents/dashboardcontents.component';
import {FrmnewComponent} from './dashboard/dashboardwebsite/frm/frmnew/frmnew.component';
import {TermpaperdetailComponent} from './website/termpaper/termpaperdetail/termpaperdetail.component';
import {TermpaperComponent} from './website/termpaper/termpaper.component';
import {DashboardgalleryComponent} from './dashboard/dashboardwebsite/dashboardgallery/dashboardgallery.component';
import {FrmgalleryComponent} from './dashboard/dashboardwebsite/frm/frmgallery/frmgallery.component';

const routes: Routes = [
  {
    path: '',
    component: LayouthomeComponent,
    // canActivate: [AuthGuard],
    children: [
      { path: '', component: HomeComponent},
      { path: 'home', component: HomeComponent},
      { path: 'login', component: LoginComponent},
      { path: 'logout', component: LogoutComponent},
      { path: 'callback', component: CallbackComponent },
      { path: 'callback?:code', component: CallbackComponent },
      { path: 'Content', component: ContentComponent},
      { path: 'Contents', component: ContentdetailComponent},
      { path: 'Contents/:Id', component: ContentdetailComponent},
      { path: 'Services', component: ServicesComponent},
      { path: 'servicesdetail', component: ServicesdetailComponent},
      { path: 'servicesdetail/:Id', component: ServicesdetailComponent},
      { path: 'newbooks', component: BookComponent},
      { path: 'newbooksdetail', component: BookdeatilComponent},
      { path: 'newbooksdetail/:Id', component: BookdeatilComponent},
      { path: 'ebooks', component: EbookComponent},
      { path: 'ebooksdetail', component: EbookdetailComponent},
      { path: 'ebooksdetail/:Id', component: EbookdetailComponent},
      { path: 'journals', component: JournalComponent},
      { path: 'journalsdetail', component: JournaldetailComponent},
      { path: 'journalsdetail/:Id', component: JournaldetailComponent},
      { path: 'events', component: EventComponent},
      { path: 'eventsdetail', component: EventdetailComponent},
      { path: 'eventsdetail/:Id', component: EventdetailComponent},
      { path: 'news', component: NewsComponent},
      { path: 'newsdetail', component: NewsdetailComponent},
      { path: 'newsdetail/:Id', component: NewsdetailComponent},
      { path: 'termpaper', component: TermpaperComponent},
      { path: 'termpaperdetail', component: TermpaperdetailComponent},
      { path: 'termpaperdetail/:Id', component: TermpaperdetailComponent},
/*
    { path: 'trendyarts', component: TrendyartsComponent},
    { path: 'trendyartsdetail', component: DetailtrendyartsComponent},
    { path: 'trendyartsdetail/:Id', component: DetailtrendyartsCompnt},
    { path: 'student', component: StudentComponent },
    { path: 'EmpOutOfServices', component: EmpnosessionComponent },
    { path: 'StuOutOfServices', component: StudentnosessionComponent }
*/
    ]
  },
  {
    path: 'student',
    canActivate: [AuthGuard] ,
    component: LayoutstudentComponent,
    children: [
      { path: '', component: StudentComponent},
    ]
  },
  {
    path: 'alumni',
    component: LayoutalumniComponent,
    canActivate: [AuthGuard] ,
    children: [
      { path: '', component: AlumniComponent},
    ]
  },
  {
    path: 'empoloyee',
    component: LayoutemployeeComponent,
    canActivate: [AuthGuard] ,
    children: [
      { path: '', component: EmpoloyeeComponent},
    ]
  }
  ,
  {
    path: 'dashboard',
    component: LayoutdashboardComponent,
    // canActivate: [AuthGuard] ,
    children: [
      { path: '', component: DashboardComponent},
      { path: 'circulation',
        component: LayoutdashboardcirculationComponent,
        children: [
          { path: '', component: DashboardcirculationComponent},
          { path: 'circulation', component: DashboardcirculationComponent},
          { path: 'circulationin', component: DashboardcirculationinComponent},
          { path: 'circulationout', component: DashboardcirculationoutComponent},
          { path: 'circulationmanage', component: DashboardcirculationmanagementComponent},
          { path: 'manageitems', component: FrmcirculationmangeComponent},
          { path: 'report', component: DashboardcirculationreportsComponent},
          { path: 'ReportItems', component: CirculationreportitemComponent},
          { path: 'ReportServices', component: CirculationreportservicesComponent},
          { path: 'ReportUser', component: CirculationreportuserComponent},
          { path: 'ReportChartServices', component: CirculationreportchartservicesComponent},
        ]
      },
      {
        path: 'website',
        component: LayoutdashboardwebsiteComponent,
        children: [
          { path: '', component: DashboardwebsiteComponent},
          { path: 'slides', component: DashboardslideComponent},
          { path: 'manageslides', component: FrmslideComponent},
          // { path: 'website', component: DashboardwebsiteComponent},
          { path: 'contents', component: DashboardcontentsComponent},
          { path: 'managecontents', component: FrmcontentComponent},
          { path: 'banners', component: DashboardbannersComponent},
          { path: 'managebanners', component: FrmbannerComponent},
          { path: 'news', component: DashboardnewsComponent},
          { path: 'managenews', component: FrmnewComponent},
          { path: 'events', component: DashboardeventsComponent},
          { path: 'manageevents', component: FrmeventComponent},
          { path: 'articles', component: DashboardarticleComponent},
          { path: 'managearticles', component: FrmarticleComponent},
          { path: 'theses', component: DashboardthesesComponent},
          { path: 'managetheses', component: FrmthesesComponent },
          { path: 'termpapers', component: DashboardtermpaperComponent},
          { path: 'managetermpapers', component: FrmtermpaperComponent },
          { path: 'researchs', component: DashboardresearchComponent},
          { path: 'manageresearchs', component: FrmresearchComponent },
          { path: 'journals', component: DashboardjournalComponent},
          { path: 'managejournals', component: FrmjournalComponent },
          { path: 'books', component: DashboardbookComponent},
          { path: 'managebooks', component: FrmbookComponent },
          { path: 'edatabases', component: DashboardedatabaseComponent},
          { path: 'manageedatabase', component: FrmedatabaseComponent },
          { path: 'trendyarts', component: DashboardtrendyartsComponent},
          { path: 'managetrendyarts', component: FrmtrendyartsComponent },
          { path: 'menus', component: DashboardmenuComponent},
          { path: 'gallery', component: DashboardgalleryComponent},
          { path: 'managegallery', component: FrmgalleryComponent },

          { path: 'iconservices', component: DashboardiconservicesComponent},
          { path: 'manageiconservices', component: FrmiconservicesComponent },

          { path: 'configs', component: DashboardconfigsComponent},
          { path: 'configsfooter', component: DashboardconfigfooterComponent},
        ]
      },
    ]
  },
  {
    path: '*', redirectTo: ''
  },
  {
    path: '**', redirectTo: ''
  }];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
