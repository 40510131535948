import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboardwebsiteleftmenu',
  templateUrl: './dashboardwebsiteleftmenu.component.html',
  styleUrls: ['./dashboardwebsiteleftmenu.component.scss']
})
export class DashboardwebsiteleftmenuComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
