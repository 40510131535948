<div class="row">
  <div class="container">
    <div class="row mt-2 p-2 bg-" style="background-color: #888888;">
      <div class="col-12">
        <h2 class="">{{txtFrmType}} {{txtHeaderType}}</h2>
      </div>
    </div>

    <form [formGroup]="FrmGallery">
      <div class="row mt-2">
        <div class="col-4">
          <div class="row p-2">
            <div class="col-12">
          <label for="titleen">รูปภาพตัวอย่างภายในอัลบัม</label>
          <mat-card>
            <mat-card-content>
              <div class="row" *ngIf="FrmGallery.value.ImageSSTh === '' || FrmGallery.value.ImageSSTh === null  ;else haveimages" >

              </div>
              <ng-template #haveimages >
                <div class="col-9">
                  <img src="{{FileURL}}{{FrmGallery.value.ImageSSTh}}" style="width: 100%; max-width: 250px;">
                </div>
                <div class="col-3">
                  <i class="fas fa-window-close" (click)="OnClickDeleteFile('imagessth')"> ลบรูปภาพ</i>
                </div>

              </ng-template>
              <div *ngFor="let file of files">
                <mat-progress-bar [value]="file.progress"></mat-progress-bar>
              </div>

            </mat-card-content>
            <mat-card-actions>
              <div class="row">
                <div class="col-12">
                  <button mat-button color="warn" (click)="onClick()">
                    <i class="fas fa-cloud-upload-alt fa-2x">Upload</i>
                  </button>
                </div>
                <div class="col-12">
                  <strong class="text-danger"></strong>
                </div>
              </div>
            </mat-card-actions>

          </mat-card>
          <input type="file" #fileUpload id="fileUpload" name="upload" accept="image/*" style="display:none;" />
            </div>
          </div>
          <div class="row mt-2 p-2 ">
            <div class="col-12">
              <div class="form-group">
                <label for="EventDate">วันที่จัดกิจกรรม</label>
                <input type="date" class="form-control" id="EventDate" placeholder="Ex: http://google.com" formControlName="EventDate">
              </div>
            </div>
          </div>
          <div class="row mt-2  p-2" >
            <div class="col-12  bg-light">
              <div class="custom-control custom-switch">
                <input type="checkbox" class="custom-control-input input-lg" id="CheckStatusPublish" [checked]="StatusBtn" (change)="checkValue(FrmGallery.value.StatusPublish)" formControlName="CheckStatusPublish" >
                <label class="custom-control-label" for="CheckStatusPublish">สถานะการเผยแพร่ข้อมูล</label>
              </div>
            </div>
          </div>
        </div>
        <div class="col-8">
          <div class="row mt-2">
            <div class="col-12">

              <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item">
                  <a class="nav-link active" id="home-tab" data-toggle="tab" href="#thai" role="tab" aria-controls="thai" aria-selected="true">ภาษาไทย</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" id="profile-tab" data-toggle="tab" href="#eng" role="tab" aria-controls="eng" aria-selected="false">English</a>
                </li>
              </ul>
              <div class="tab-content" id="myTabContent">
                <div class="tab-pane fade show active" id="thai" role="tabpanel" aria-labelledby="thai">
                  <div class="row mt-2">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="titleth"><strong>ชื่ออัลบัม ภาษาไทย</strong></label>
                        <input type="text" class="form-control" id="titleth" placeholder="ชื่อเรื่อง" formControlName="TitleTh">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <strong>รายละเอียดอัลบัม ภาษาไทย</strong>
                      <!-- <ckeditor [editorUrl]="CkeditorApiAddress" [config]="ckeditor4config" formControlName="ContentTh"></ckeditor>-->
                      <textarea type="text" class="form-control" placeholder="คำอธิบายอัลบัมภาพ" formControlName="ContentTh" rows="5"></textarea>
                    </div>
                  </div>
                </div>
                <div class="tab-pane fade" id="eng" role="tabpanel" aria-labelledby="eng">
                  <div class="row">
                    <div class="col-12">
                      <div class="form-group">
                        <label for="titleen"><strong>ชื่ออัลบัม ภาษาอังกฤษ</strong></label>
                        <input type="text" class="form-control" id="titleen" placeholder="Title" formControlName="TitleEn">
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <label><strong>รายละเอียดอัลบัม ภาษาอังกฤษ</strong></label>
                      <textarea type="text" class="form-control" placeholder="คำอธิบายอัลบัมภาพ ภาษาอังกฤษ" formControlName="ContentEn" rows="5"></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </form>

    <div class="row mb-2 p-2 bg-light text-center">
      <div class="col-12 ">
        <span class="btn btn-primary mr-3" (click)="ClickSave()"><i class="far fa-save"></i> บันทึกข้อมูลรายละเอียด</span>
        <span class="btn btn-warning" (click)="ClickCancel()"><i class="fas fa-share fa-rotate-180"></i>ยกเลิก</span>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12 text-left">
        <span class="btn btn-success"> + เพิ่มรูปภาพในอัลบัม</span>
      </div>
      <div class="col-12">
        list images
      </div>
    </div>


  </div>

</div>
