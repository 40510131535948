import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ApiwebService} from '../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {UploadService} from '../../upload.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-facebook',
  templateUrl: './facebook.component.html',
  styleUrls: ['./facebook.component.scss']
})
export class FacebookComponent implements OnInit {
  public DataFaceBooks;
  constructor(public apiweb: ApiwebService, public sanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.apiweb.CallConfig(this.apiweb.SiteFaculty).subscribe( (dataresponse: any) => {
      if (dataresponse.status === true){
        const data = dataresponse.data[0];
        this.DataFaceBooks = data.FacebookCode;
      }
    });
  }

}
