import { Component, OnInit } from '@angular/core';
import {ApiwebService} from '../../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-contentdetail',
  templateUrl: './contentdetail.component.html',
  styleUrls: ['./contentdetail.component.scss']
})
export class ContentdetailComponent implements OnInit {
  public DataContents;
  public ContentTh;
  constructor(public apiweb: ApiwebService, public router: Router, public dialog: MatDialog, public datePipe: DatePipe, public route: ActivatedRoute) { }

  ngOnInit(): void {
    if (localStorage.getItem('lang') === 'En'){
      this.ContentTh = false;
    } else {
      localStorage.setItem('lang', 'Th');
      this.ContentTh = true;
    }
    this.route.params.subscribe(params => {
      console.log(params);
      this.SearchContent(params.Id);
    });

  }

  ClickManageData(Id): any{
    this.router.navigate(['Content/' + Id ]);
  }
  /*
  ClickManageData(Id, Title){
    this.router.navigate(['Contents/' + Id , { Title }]);
  }
  */

  SearchContent(Id): any{
    this.apiweb.FrontendGetDataId('content', 'get_id', 'Id', Id).subscribe( (dataresponse: any) => {
      console.log(dataresponse.data);
      if (dataresponse.status === true){
        const data = dataresponse.data[0];
        this.DataContents = data;
      }
    });
  }
}
