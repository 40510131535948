<form action="http://search.lib.cmu.ac.th/patroninfo/" name="patform" method="post" target="_blank" ngNoForm>

  <div class="w3-col" style="max-width: 50px;">
    <p>
      <img src="http://library.cmu.ac.th/cmul/sites/default/files/logo_cmu.png" alt="cmu library" style="width: 100%;"/>
    </p>
  </div>
  <div class="w3-rest w3-container">
    <p>
      ตรวจสอบข้อมูลการยืม / ยืมต่อ
    </p>
    <p>
      <input name="name" value="" class="w3-input w3-hover-sand" placeholder="ชื่อ">
    </p>
    <p>
      <input name="code" type="password"  class="w3-input w3-hover-sand" placeholder="Barcode">
    </p>
    <p>
      <input name="submit" type="submit" value="ตรวจสอบ" class="w3-btn w3-theme-d3" >
    </p>
    <p>
      พิมพ์ชื่อ-สกุล (ไม่ระบุคำนำหน้านาม เช่น "นายรักเรียน มีอนาคต" พิมพ์ชื่อสกุล รักเรียน มีอนาคต)<br>Type Name "Jane Smith".<br>
      <a href="http://library.cmu.ac.th/cmul/sites/default/files/web/RenewOnline.pdf" target="_blank"><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
      <!--
        {{text_renew}}
      -->
      </a>
    </p>
  </div>

</form>
