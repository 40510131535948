<div class="row bg_dashboardcirculationbread p-1">
  <div class="col-12"><h2>ข้อมูล วัตถุ-ครุภัณฑ์</h2></div>
</div>
<div class="row">
  <div class="col-12 mt-2">
    <mat-form-field>
      <mat-label>ค้นหาข้อมูล</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ปลั๊กไฟ, 00001">
    </mat-form-field>
  </div>
  <div class="col-12 mt-2">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <!-- Position Column
      <ng-container matColumnDef="No">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
        <td mat-cell *matCellDef="let element">
        </td>
      </ng-container>-->
      <ng-container matColumnDef="No">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
        <td mat-cell *matCellDef="let element"> {{dataSource.filteredData.indexOf(element)+1}} </td>
      </ng-container>


      <ng-container matColumnDef="CodeItem">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Code </th>
        <td mat-cell *matCellDef="let element"> {{element.CodeItem}} </td>
      </ng-container>


      <ng-container matColumnDef="ItemName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
        <td mat-cell *matCellDef="let element"> {{element.ItemName}} </td>
      </ng-container>


      <ng-container matColumnDef="ItemStatus">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
        <td mat-cell *matCellDef="let element"> {{element.StatusName}} </td>
      </ng-container>
      <ng-container matColumnDef="Command">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element"><i class="fas fa-search fa-2x text-info" ></i> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10,25,50,100]" showFirstLastButtons></mat-paginator>
  </div>

</div>
<div class="row">
  <div class="col-12">
    <a href="dashboard/circulation/report"><i class="fas fa-backward fa-2x">ย้อนกลับ</i></a>
  </div>
</div>
