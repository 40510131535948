<div class="row bg_dashboardcirculationbread p-1">
  <div class="col-12">
    <h2>รายงาน สถิติการใช้งานด้านต่าง ๆ </h2>
  </div>
</div>
<div class="row">
  <div class="col-4 mt-2">
    <div class="card text-center pt-2">
      <a href="dashboard/circulation/ReportItems">
        <!-- Generator: Adobe Illustrator 19.0.0, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->
        <svg width="100px" height="100px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 511.998 511.998" style="enable-background:new 0 0 511.998 511.998;" xml:space="preserve">
<polygon style="fill:#9BBEFF;" points="501.875,141.027 9.997,141.027 59.185,37.053 453.338,37.053 "/>
          <g>
	<rect x="108.503" y="37.05" style="fill:#FF8C8C;" width="98.376" height="103.974"/>
            <rect x="305.254" y="37.05" style="fill:#FF8C8C;" width="98.376" height="103.974"/>
</g>
          <polyline style="fill:#C8F5FF;" points="36.99,141.027 36.99,474.944 474.882,474.944 474.882,141.027 "/>
          <rect x="36.99" y="141.024" style="fill:#AAE6FF;" width="162.96" height="333.917"/>
          <path style="fill:#7D9BFF;" d="M502.002,141.027c0,27.165-22.023,49.188-49.188,49.188s-49.188-22.023-49.188-49.188
	c0,27.165-22.023,49.188-49.188,49.188s-49.188-22.023-49.188-49.188c0,27.165-22.023,49.188-49.188,49.188
	s-49.188-22.023-49.188-49.188c0,27.165-22.023,49.188-49.188,49.188S108.5,168.193,108.5,141.027
	c0,27.165-22.023,49.188-49.188,49.188s-49.188-22.023-49.188-49.188"/>
          <g>
	<path style="fill:#FF5A5A;" d="M206.875,141.027c0,27.165-22.023,49.188-49.188,49.188S108.5,168.193,108.5,141.027"/>
            <path style="fill:#FF5A5A;" d="M403.626,141.027c0,27.165-22.023,49.188-49.188,49.188s-49.188-22.023-49.188-49.188"/>
</g>
          <rect x="199.95" y="388.963" style="fill:#F5C86E;" width="274.932" height="85.979"/>
          <path d="M501.103,464.947h-16.224V326.868c0-5.522-4.477-9.997-9.998-9.997s-9.998,4.476-9.998,9.997v52.101H209.947V223.033
	c0-5.522-4.477-9.998-9.998-9.998c-5.521,0-9.998,4.476-9.998,9.998v241.914H46.988V198.913c3.978,0.846,8.098,1.3,12.324,1.3
	c20.476,0,38.557-10.452,49.188-26.301c10.63,15.85,28.711,26.301,49.187,26.301s38.557-10.452,49.188-26.301
	c10.63,15.85,28.712,26.301,49.188,26.301c20.476,0,38.557-10.452,49.188-26.301c10.63,15.85,28.712,26.301,49.188,26.301
	c20.476,0,38.556-10.452,49.187-26.301c10.63,15.85,28.712,26.301,49.188,26.301c4.136,0,8.172-0.43,12.07-1.241v63.358
	c0,5.522,4.477,9.998,9.998,9.998s9.998-4.476,9.998-9.998v-71.595c16.302-10.552,27.12-28.885,27.12-49.708
	c0-2.049-0.619-3.953-1.677-5.54L462.397,32.824c-1.644-3.519-5.176-5.769-9.06-5.769H59.185c-3.865,0-7.384,2.228-9.037,5.723
	L0.96,136.752c-0.917,1.939-1.173,4.086-0.787,6.13c0.619,19.945,11.143,37.429,26.819,47.689v274.375H10.768
	c-5.521,0-9.998,4.476-9.998,9.998c0,5.522,4.477,9.997,9.998,9.997H36.99h437.891h26.223c5.521,0,9.997-4.476,9.997-9.997
	C511.102,469.423,506.625,464.947,501.103,464.947z M59.312,180.219c-18.155,0-33.462-12.411-37.894-29.193h75.787
	C92.774,167.808,77.466,180.219,59.312,180.219z M393.848,131.03h-78.455V47.051h78.455V131.03z M295.398,131.03h-78.453V47.051
	h78.454v83.979H295.398z M196.951,131.03h-78.454V47.051h78.454V131.03z M157.686,180.219c-18.154,0-33.461-12.411-37.893-29.193
	h75.786C191.148,167.808,175.842,180.219,157.686,180.219z M256.063,180.219c-18.156,0-33.462-12.411-37.894-29.193h75.787
	C289.525,167.808,274.218,180.219,256.063,180.219z M354.439,180.219c-18.156,0-33.462-12.411-37.894-29.193h75.786
	C387.899,167.808,372.593,180.219,354.439,180.219z M414.921,151.025h75.787c-4.432,16.782-19.738,29.193-37.894,29.193
	C434.659,180.218,419.352,167.808,414.921,151.025z M486.175,131.03h-72.332V47.051h33.129L486.175,131.03z M65.515,47.051h32.987
	v83.979H25.786L65.515,47.051z M209.947,464.947v-65.984h254.937v65.984H209.947z"/>
          <path d="M151.002,319.803c-1.86,1.86-2.93,4.429-2.93,7.068c0,2.629,1.07,5.209,2.93,7.068c1.859,1.86,4.438,2.929,7.067,2.929
	c2.63,0,5.209-1.07,7.068-2.929c1.86-1.86,2.929-4.439,2.929-7.068c0-2.639-1.069-5.209-2.929-7.068
	c-1.861-1.86-4.429-2.929-7.068-2.929C155.44,316.874,152.861,317.943,151.002,319.803z"/>
          <path d="M346.256,241.724c-3.905-3.904-10.233-3.904-14.139,0l-67.722,67.722c-3.904,3.904-3.904,10.234,0,14.139
	c1.953,1.953,4.511,2.928,7.069,2.928c2.558,0,5.117-0.976,7.069-2.928l67.722-67.722
	C350.161,251.959,350.161,245.628,346.256,241.724z"/>
          <path d="M248.138,329.85c-2.63,0-5.209,1.06-7.068,2.919c-1.86,1.87-2.929,4.439-2.929,7.068c0,2.639,1.069,5.209,2.929,7.068
	c1.86,1.87,4.438,2.929,7.068,2.929c2.629,0,5.209-1.06,7.067-2.929c1.86-1.86,2.93-4.429,2.93-7.068c0-2.629-1.07-5.209-2.93-7.068
	C253.347,330.91,250.767,329.85,248.138,329.85z"/>
          <path d="M354.147,317.974c2.559,0,5.117-0.976,7.069-2.928l68.248-68.248c3.904-3.904,3.904-10.234,0-14.139
	c-3.905-3.904-10.233-3.904-14.139,0l-68.248,68.248c-3.904,3.904-3.904,10.234,0,14.139
	C349.03,316.998,351.587,317.974,354.147,317.974z"/>
          <path d="M474.882,284.322c-2.629,0-5.209,1.07-7.068,2.929s-2.929,4.429-2.929,7.068c0,2.629,1.069,5.209,2.929,7.068
	c1.861,1.86,4.439,2.929,7.068,2.929c2.629,0,5.209-1.07,7.067-2.929c1.86-1.859,2.93-4.439,2.93-7.068
	c0-2.629-1.07-5.209-2.93-7.068C480.09,285.392,477.511,284.322,474.882,284.322z"/>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
</svg>
        <br>
        <strong>วัสดุ ครุภัณฑ์</strong></a>
    </div>
  </div>
  <div class="col-4 mt-2">
    <div class="card text-center pt-2">
      <a href="dashboard/circulation/ReportUser">
        <svg width="100px" height="100px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 512 512" style="enable-background:new 0 0 512 512;" xml:space="preserve">
<g>
	<path style="fill:#FFE6C3;" d="M150.541,194.583H164v-56h-13.459c-10.24,0-18.541,8.301-18.541,18.541v18.917
		C132,186.281,140.301,194.583,150.541,194.583z"/>
  <path style="fill:#FFE6C3;" d="M361.459,138.583H348v56h13.459c10.24,0,18.541-8.301,18.541-18.541v-18.917
		C380,146.884,371.699,138.583,361.459,138.583z"/>
</g>
          <path style="fill:#FFCDAC;" d="M270.333,267.6h-28.667c-42.894,0-77.667-34.773-77.667-77.667V87.667
	C164,44.773,198.773,10,241.667,10h28.667C313.227,10,348,44.773,348,87.667v102.267C348,232.827,313.227,267.6,270.333,267.6z"/>
          <path style="fill:#FF824B;" d="M270.333,10h-28.667C198.772,10,164,44.772,164,87.667v50.546l1.134,0.37h50.343
	c47.495,0,93.044-18.867,126.627-52.451l0,0c0,0,2.384-2.193,5.572-5.376C344.178,41.102,310.897,10,270.333,10z"/>
          <path style="fill:#FFCDAC;" d="M207.896,261.822v8.232c0,22.725-15.816,42.387-38.014,47.257l-34.195,7.501
	C84.484,336.045,48,381.4,48,433.82V502h416v-68.117c0-52.449-36.523-97.821-87.762-109.024l-34.616-7.569
	c-22.213-4.857-38.046-24.526-38.046-47.264v-8.36"/>
          <path style="fill:#AAE6FF;" d="M376.238,324.859l-34.615-7.569c-7.834-1.713-14.869-5.275-20.702-10.158
	c-0.415,0.328-0.641,0.507-0.641,0.507c-35.501,35.5-93.058,35.5-128.559,0l-1.232-0.425c-5.812,4.849-12.813,8.387-20.606,10.097
	l-34.195,7.501C84.484,336.044,48,381.4,48,433.82V502h416v-68.117C464,381.434,427.477,336.062,376.238,324.859z"/>
          <path d="M425.017,362.822c1.96,2.003,4.554,3.008,7.15,3.008c2.521,0,5.046-0.948,6.991-2.851
	c3.948-3.862,4.019-10.193,0.157-14.142l-0.72-0.729c-3.896-3.914-10.229-3.927-14.143-0.031s-3.928,10.229-0.031,14.143
	L425.017,362.822z"/>
          <path d="M502,492h-28v-58.116c0-19.004-4.525-37.985-13.088-54.893c-2.495-4.928-8.515-6.898-13.438-4.403
	c-4.928,2.495-6.898,8.512-4.403,13.438c7.15,14.12,10.93,29.978,10.93,45.857V492H58v-58.18c0-47.356,33.573-89.093,79.829-99.24
	l34.195-7.502c6.509-1.428,12.576-3.95,18.034-7.335c18.32,15.864,41.465,24.521,65.941,24.521c24.388,0,47.454-8.595,65.742-24.349
	c5.384,3.29,11.35,5.747,17.744,7.145l34.616,7.568c8.533,1.865,16.781,4.813,24.515,8.762c1.456,0.743,3.009,1.096,4.539,1.096
	c3.638,0,7.146-1.992,8.914-5.455c2.512-4.919,0.56-10.941-4.359-13.453c-9.259-4.728-19.13-8.256-29.337-10.488l-34.615-7.568
	c-17.489-3.824-30.183-19.593-30.183-37.495v-3.857c22.453-12.786,38.735-35.201,43.193-61.586h4.69
	c15.737,0,28.541-12.804,28.541-28.542v-18.917c0-15.737-12.804-28.541-28.541-28.541H358V87.667C358,39.327,318.673,0,270.333,0
	h-28.666C193.327,0,154,39.327,154,87.667v40.916h-3.459c-15.737,0-28.541,12.804-28.541,28.541v18.917
	c0,15.738,12.804,28.542,28.541,28.542h4.69c4.423,26.178,20.485,48.45,42.665,61.285v4.186c0,17.89-12.683,33.656-30.156,37.489
	l-34.195,7.502c-26.983,5.919-51.379,21.033-68.693,42.558C47.536,379.128,38,406.195,38,433.82V492H10c-5.522,0-10,4.478-10,10
	s4.478,10,10,10h38h416h38c5.522,0,10-4.478,10-10S507.522,492,502,492z M361.459,148.583c4.71,0,8.541,3.831,8.541,8.541v18.917
	c0,4.71-3.831,8.542-8.541,8.542H358v-36L361.459,148.583L361.459,148.583z M241.667,20h28.666c33.826,0,61.935,24.95,66.891,57.413
	c-0.783,0.438-1.523,0.982-2.189,1.647c-31.935,31.936-74.395,49.522-119.557,49.522H174V87.667C174,50.355,204.355,20,241.667,20z
	 M142,176.041v-18.917c0-4.71,3.831-8.541,8.541-8.541H154v36h-3.459C145.831,184.583,142,180.751,142,176.041z M174,189.934
	v-41.351h41.478c24.956,0,49.301-4.843,72.357-14.393c18.353-7.602,35.166-17.899,50.165-30.68v35.073v51.351
	c0,37.312-30.355,67.666-67.667,67.666h-28.666C204.355,257.6,174,227.245,174,189.934z M241.667,277.6h28.666
	c8.109,0,15.963-1.112,23.422-3.182c0.916,11.948,5.52,23.095,12.772,32.12c-14.297,11.479-31.928,17.728-50.527,17.728
	c-18.735,0-36.493-6.335-50.845-17.976c7.162-9.019,11.697-20.121,12.573-32.011C225.34,276.441,233.371,277.6,241.667,277.6z"/>
          <path d="M111.04,370.047c-19.617,15.525-30.868,38.77-30.868,63.773c0,5.522,4.478,10,10,10s10-4.478,10-10
	c0-18.854,8.485-36.382,23.28-48.09c4.33-3.428,5.063-9.717,1.636-14.048C121.661,367.354,115.373,366.62,111.04,370.047z"/>
          <path d="M144.036,354.414l-0.463,0.103c-5.392,1.198-8.791,6.54-7.593,11.931c1.035,4.661,5.168,7.833,9.752,7.833
	c0.719,0,1.448-0.078,2.179-0.24l0.438-0.097c5.393-1.19,8.799-6.527,7.608-11.921C154.767,356.63,149.432,353.226,144.036,354.414z
	"/>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
</svg>
        <br>User</a>

    </div>
  </div>
  <div class="col-4 mt-2">
    <div class="card text-center pt-2">
      <a href="dashboard/circulation/ReportServices">
        <svg width="100px" height="100px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
             viewBox="0 0 512.001 512.001" style="enable-background:new 0 0 512.001 512.001;" xml:space="preserve">
<path style="fill:#FFFFFF;" d="M170.001,49.001H92.759c-21.406,0-38.758,17.353-38.758,38.758v375.484
	c0,21.406,17.353,38.758,38.758,38.758h326.483c21.406,0,38.758-17.353,38.758-38.758V87.759c0-21.406-17.353-38.758-38.758-38.758
	H340C340,49.001,170.001,49.001,170.001,49.001z"/>
          <path d="M400.003,172H256.001c-5.523,0-10-4.477-10-10s4.477-10,10-10h144.002c5.523,0,10,4.477,10,10S405.526,172,400.003,172z"/>
          <path style="fill:#9BBEFF;" d="M300.48,32h-15.566c-3.502-12.683-15.116-22-28.913-22c-13.797,0-25.411,9.317-28.913,22h-15.587
	c-28.443,0-51.5,23.058-51.5,51.5V90H351.98v-6.5C351.98,55.058,328.923,32,300.48,32z"/>
          <path d="M357.941,220.001c-2.63,0-5.21-1.07-7.07-2.93c-1.86-1.86-2.93-4.44-2.93-7.07s1.07-5.21,2.93-7.07s4.44-2.93,7.07-2.93
	s5.21,1.07,7.07,2.93c1.86,1.86,2.93,4.44,2.93,7.07s-1.07,5.21-2.93,7.07S360.571,220.001,357.941,220.001z"/>
          <path d="M255.991,63.001c-2.63,0-5.21-1.07-7.07-2.93s-2.93-4.44-2.93-7.07s1.07-5.21,2.93-7.07c1.86-1.86,4.44-2.93,7.07-2.93
	c2.63,0,5.21,1.07,7.07,2.93c1.86,1.86,2.93,4.44,2.93,7.07s-1.07,5.21-2.93,7.07C261.201,61.931,258.621,63.001,255.991,63.001z"/>
          <path d="M315.878,220h-59.877c-5.523,0-10-4.477-10-10s4.477-10,10-10h59.877c5.523,0,10,4.477,10,10S321.401,220,315.878,220z"/>
          <path d="M400.003,280H256.001c-5.523,0-10-4.477-10-10s4.477-10,10-10h144.002c5.523,0,10,4.477,10,10S405.526,280,400.003,280z"/>
          <path d="M357.941,328.001c-2.63,0-5.21-1.07-7.07-2.93c-1.86-1.86-2.93-4.44-2.93-7.07s1.07-5.21,2.93-7.07
	c1.86-1.86,4.44-2.93,7.07-2.93s5.21,1.07,7.07,2.93c1.86,1.86,2.93,4.44,2.93,7.07s-1.07,5.21-2.93,7.07
	S360.571,328.001,357.941,328.001z"/>
          <path d="M315.878,328h-59.877c-5.523,0-10-4.477-10-10s4.477-10,10-10h59.877c5.523,0,10,4.477,10,10S321.401,328,315.878,328z"/>
          <path d="M400.003,388H256.001c-5.523,0-10-4.477-10-10s4.477-10,10-10h144.002c5.523,0,10,4.477,10,10S405.526,388,400.003,388z"/>
          <path d="M357.941,436.001c-2.63,0-5.21-1.07-7.07-2.93c-1.86-1.86-2.93-4.44-2.93-7.07s1.07-5.21,2.93-7.07
	c1.86-1.86,4.44-2.93,7.07-2.93s5.21,1.07,7.07,2.93c1.86,1.86,2.93,4.44,2.93,7.07s-1.07,5.21-2.93,7.07
	S360.571,436.001,357.941,436.001z"/>
          <path d="M315.878,436h-59.877c-5.523,0-10-4.477-10-10s4.477-10,10-10h59.877c5.523,0,10,4.477,10,10S321.401,436,315.878,436z"/>
          <g>
	<rect x="120" y="378" style="fill:#F5C86E;" width="48" height="48"/>
            <rect x="120" y="270" style="fill:#F5C86E;" width="48" height="48"/>
</g>
          <path d="M419.243,39.001h-76.379C331.823,28.48,316.898,22,300.479,22h-8.76C285.022,8.742,271.263,0,256,0s-29.021,8.742-35.719,22
	H211.5c-16.419,0-31.343,6.48-42.384,17.001H92.759c-26.885,0-48.758,21.873-48.758,48.758v375.484
	c0,26.885,21.873,48.758,48.758,48.758h326.483c26.885,0,48.758-21.873,48.758-48.758V87.759
	C468.001,60.874,446.128,39.001,419.243,39.001z M211.501,42h15.586c4.498,0,8.442-3.003,9.639-7.338
	C239.111,26.029,247.037,20,256.001,20s16.89,6.029,19.274,14.662c1.197,4.335,5.142,7.338,9.639,7.338h15.565
	c21.705,0,39.571,16.75,41.354,38.001H170.147C171.93,58.75,189.797,42,211.501,42z M448.001,463.244
	c0,15.857-12.901,28.758-28.758,28.758H92.759c-15.857,0-28.758-12.901-28.758-28.758V87.759c0-15.857,12.901-28.758,28.758-28.758
	h62.347c-3.276,7.512-5.105,15.794-5.105,24.5v6.5c0,5.523,4.477,10,10,10H351.98c5.523,0,10-4.477,10-10v-6.5
	c0-8.705-1.829-16.988-5.105-24.5h62.368c15.857,0,28.758,12.901,28.758,28.758V463.244z"/>
          <path d="M178.268,149.595l-42.762,42.763l-13.173-13.174c-3.905-3.904-10.237-3.904-14.143,0c-3.905,3.905-3.905,10.237,0,14.143
	l20.245,20.245c1.953,1.953,4.512,2.929,7.071,2.929s5.119-0.976,7.071-2.929l49.833-49.833c3.905-3.905,3.905-10.237,0-14.143
	C188.505,145.691,182.173,145.691,178.268,149.595z"/>
          <path d="M168.001,368h-48c-5.523,0-10,4.477-10,10v48c0,5.523,4.477,10,10,10h48c5.523,0,10-4.477,10-10v-48
	C178.001,372.477,173.524,368,168.001,368z M158.001,416h-28v-28h28V416z"/>
          <path d="M168.001,260h-48c-5.523,0-10,4.477-10,10v48c0,5.523,4.477,10,10,10h48c5.523,0,10-4.477,10-10v-48
	C178.001,264.477,173.524,260,168.001,260z M158.001,308h-28v-28h28V308z"/>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
          <g>
</g>
</svg>

        <br><strong>ข้อมูลการให้บริการ Services</strong>
        </a>
    </div>
  </div>

  <div class="col-4 mt-2">
    <div class="card text-center pt-2">
      <a href="dashboard/circulation/ReportChartServices">
        <svg id="color" enable-background="new 0 0 24 24" height="100" viewBox="0 0 24 24" width="100" xmlns="http://www.w3.org/2000/svg"><path d="m19.25 24h-14.5c-1.517 0-2.75-1.233-2.75-2.75v-18.5c0-1.517 1.233-2.75 2.75-2.75h14.5c1.517 0 2.75 1.233 2.75 2.75v18.5c0 1.517-1.233 2.75-2.75 2.75z" fill="#eceff1"/><path d="m12 0h-7.25c-1.517 0-2.75 1.233-2.75 2.75v18.5c0 1.517 1.233 2.75 2.75 2.75h7.25z" fill="#cdd0d2"/><path d="m18.25 21.25h-12.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h12.5c.414 0 .75.336.75.75s-.336.75-.75.75z" fill="#90a4ae"/><path d="m12 19.75h-6.25c-.414 0-.75.336-.75.75s.336.75.75.75h6.25z" fill="#7d8f97"/><path d="m15 13c0 2.758-2.242 5-5 5-1.383 0-2.633-.559-3.533-1.467l3.533-3.533v-5c2.758 0 5 2.242 5 5z" fill="#2196f3"/><path d="m10 8v5l-3.533 3.533c-.909-.9-1.467-2.15-1.467-3.533 0-2.758 2.242-5 5-5z" fill="#90caf9"/><path d="m10 13-3.533 3.533c.9.908 2.15 1.467 3.533 1.467 1.524 0 2.891-.685 3.808-1.762-.002 0-.003 0-.003 0z" fill="#1d83d4"/><path d="m5.536 10.75h-.001c-.342.677-.535 1.441-.535 2.25 0 1.383.558 2.633 1.467 3.533l3.533-3.533z" fill="#7db0d9"/><g fill="#cfd8dc"><path d="m18.25 12.25h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m18.25 9.5h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m18.25 15.25h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/><path d="m18.25 18h-1.5c-.414 0-.75-.336-.75-.75s.336-.75.75-.75h1.5c.414 0 .75.336.75.75s-.336.75-.75.75z"/></g><path d="m17.5 10.75h-.75c-.414 0-.75.336-.75.75s.336.75.75.75h.75z" fill="#b4bcc0"/><path d="m17.5 8h-.75c-.414 0-.75.336-.75.75s.336.75.75.75h.75z" fill="#b4bcc0"/><path d="m17.5 13.75h-.75c-.414 0-.75.336-.75.75s.336.75.75.75h.75z" fill="#b4bcc0"/><path d="m17.5 16.5h-.75c-.414 0-.75.336-.75.75s.336.75.75.75h.75z" fill="#b4bcc0"/></svg>
        <br>
        <strong>สรุปรายงาน ผลการให้บริการของวัสดุแต่ละชิ้น </strong>
      </a>
    </div>
  </div>

</div>
