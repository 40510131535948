<a href="https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id={{clientID}}&redirect_uri={{callback}}&scope={{scope}}&state=xyz" >
  <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="30px" height="30px" x="0px" y="0px"
       viewBox="0 0 591.146 591.146" style="enable-background:new 0 0 591.146 591.146;" xml:space="preserve">
<g>
	<g>
		<g id="filled_outline_25_">
			<path style="fill:#C7CACC;" d="M472.917,206.901H78.819c-21.764,0-39.41,17.646-39.41,39.41v334.983h472.917V246.311
				C512.326,224.547,494.681,206.901,472.917,206.901z"/>
      <path style="fill:#4398D1;" d="M472.917,561.589V266.016c0-10.887-8.818-19.705-19.705-19.705H98.524
				c-10.887,0-19.705,8.818-19.705,19.705v295.573H472.917z"/>
      <path style="fill:#3E8CC7;" d="M472.917,561.589V266.016c0-10.887-8.818-19.705-19.705-19.705h-19.705L118.229,561.589H472.917z"
      />
      <rect x="137.934" y="364.54" style="fill:#87CED9;" width="275.868" height="78.819"/>
      <rect x="167.491" y="394.097" style="fill:#126099;" width="19.705" height="19.705"/>
      <rect x="206.901" y="394.097" style="fill:#126099;" width="19.705" height="19.705"/>
      <rect x="246.311" y="394.097" style="fill:#126099;" width="19.705" height="19.705"/>
      <rect x="285.72" y="394.097" style="fill:#126099;" width="19.705" height="19.705"/>
      <path style="fill:#FDB62F;" d="M328.707,157.639h229.306c12.857,0,23.281,10.424,23.281,23.281v120.929
				c0,12.857-10.424,23.281-23.281,23.281H328.707c-12.857,0-23.281-10.424-23.281-23.281V180.92
				C305.425,168.063,315.849,157.639,328.707,157.639z"/>
      <path style="fill:#DADDDF;" d="M443.359,9.852c-59.854,0-108.377,48.523-108.377,108.377v39.41h39.41v-39.41
				c0-38.09,30.878-68.967,68.967-68.967s68.967,30.878,68.967,68.967v39.41h39.41v-39.41
				C551.736,58.376,503.213,9.852,443.359,9.852z"/>
      <rect x="177.344" y="157.639" style="fill:#87CED9;" width="19.705" height="19.705"/>
      <rect x="177.344" y="118.229" style="fill:#87CED9;" width="19.705" height="19.705"/>
      <rect x="177.344" y="78.819" style="fill:#87CED9;" width="19.705" height="19.705"/>
      <rect x="177.344" y="39.41" style="fill:#87CED9;" width="19.705" height="19.705"/>
      <rect x="216.753" y="39.41" style="fill:#87CED9;" width="19.705" height="19.705"/>
      <rect x="256.163" y="39.41" style="fill:#87CED9;" width="19.705" height="19.705"/>
      <rect x="295.573" y="39.41" style="fill:#87CED9;" width="19.705" height="19.705"/>
      <path style="fill:#DADDDF;" d="M512.326,581.293H39.41c-16.325,0-29.557-13.232-29.557-29.557l0,0v-29.557h206.901
				c6.749,0,12.926,3.813,15.941,9.852l0,0c3.015,6.04,9.192,9.852,15.941,9.852h56.789c6.749,0,12.926-3.813,15.941-9.852l0,0
				c3.015-6.039,9.192-9.852,15.941-9.852h204.576v29.557C541.884,568.062,528.652,581.293,512.326,581.293L512.326,581.293z"/>
      <path style="fill:#FFA230;" d="M565.638,159.018c-66.809,99.51-175.925,143.156-250.104,162.013
				c3.872,2.67,8.463,4.099,13.173,4.099h229.306c12.857,0,23.281-10.424,23.281-23.281V180.92
				C581.274,171.028,574.988,162.24,565.638,159.018z"/>
      <path style="fill:#556575;" d="M463.064,236.458c0.049-10.887-8.729-19.744-19.616-19.794
				c-10.887-0.049-19.744,8.729-19.794,19.616c-0.03,7.074,3.724,13.616,9.852,17.153v32.286h19.705v-32.286
				C459.291,249.936,463.045,243.473,463.064,236.458z"/>
      <rect x="334.983" y="137.934" style="fill:#C7CACC;" width="39.41" height="19.705"/>
      <rect x="512.326" y="137.934" style="fill:#C7CACC;" width="39.41" height="19.705"/>
      <rect x="137.934" y="364.54" style="fill:#71C4D1;" width="275.868" height="19.705"/>
      <path d="M39.41,591.146h472.917c21.764,0,39.41-17.646,39.41-39.41v-29.557c0-5.439-4.414-9.852-9.852-9.852h-19.705V354.688
				h-19.705v157.639h-19.705V354.688h-19.705v157.639H337.308c-10.493-0.039-20.089,5.902-24.749,15.301
				c-1.34,2.719-4.108,4.424-7.133,4.404h-56.789c-3.025,0.02-5.793-1.695-7.123-4.404c-4.66-9.399-14.266-15.34-24.759-15.301
				H88.672V266.016c0-5.439,4.414-9.852,9.852-9.852h177.344v-19.705H98.524c-16.325,0-29.557,13.232-29.557,29.557v246.311H49.262
				V246.311c0-16.325,13.232-29.557,29.557-29.557h197.049v-19.705H78.819c-27.193,0.03-49.233,22.069-49.262,49.262v266.016H9.852
				c-5.439,0-9.852,4.414-9.852,9.852v29.557C0,573.5,17.646,591.146,39.41,591.146z M19.705,532.031h197.049
				c3.025-0.01,5.783,1.695,7.133,4.394c4.65,9.409,14.256,15.35,24.749,15.311h56.789c10.503,0.039,20.099-5.902,24.759-15.311
				c1.34-2.709,4.099-4.414,7.123-4.394h194.723v19.705c0,10.887-8.818,19.705-19.705,19.705H39.41
				c-10.887,0-19.705-8.818-19.705-19.705V532.031z"/>
      <path d="M128.082,364.54v78.819c0,5.439,4.414,9.852,9.852,9.852h275.868c5.439,0,9.852-4.414,9.852-9.852V364.54
				c0-5.439-4.414-9.852-9.852-9.852H137.934C132.495,354.688,128.082,359.101,128.082,364.54z M147.786,374.392H403.95v59.115
				H147.786V374.392z"/>
      <rect x="167.491" y="394.097" width="19.705" height="19.705"/>
      <rect x="206.901" y="394.097" width="19.705" height="19.705"/>
      <rect x="246.311" y="394.097" width="19.705" height="19.705"/>
      <rect x="285.72" y="394.097" width="19.705" height="19.705"/>
      <path d="M561.589,148.151v-29.922C561.589,52.937,508.651,0,443.359,0S325.13,52.937,325.13,118.229v29.922
				c-16.769,1.783-29.498,15.902-29.557,32.759v120.948c0.02,18.286,14.848,33.104,33.134,33.124h229.306
				c18.286-0.02,33.114-14.838,33.134-33.124V180.91C591.087,164.053,578.357,149.934,561.589,148.151z M344.835,118.229
				c0-54.415,44.109-98.524,98.524-98.524s98.524,44.109,98.524,98.524v29.557h-19.705v-29.557
				c0-43.528-35.291-78.819-78.819-78.819s-78.819,35.291-78.819,78.819v29.557h-19.705V118.229z M384.245,147.786v-29.557
				c0-32.651,26.464-59.115,59.115-59.115s59.115,26.464,59.115,59.115v29.557H384.245z M571.441,301.859
				c-0.01,7.409-6.02,13.409-13.429,13.419H328.707c-7.409-0.01-13.419-6.01-13.429-13.419V180.91
				c0.01-7.409,6.02-13.409,13.429-13.419h229.306c7.409,0.01,13.419,6.01,13.429,13.419V301.859z"/>
      <path d="M443.359,206.901c-16.286-0.03-29.518,13.143-29.547,29.429c-0.02,12.532,7.872,23.705,19.695,27.873v21.518h19.705
				v-21.518c15.36-5.409,23.429-22.247,18.02-37.607C467.064,214.773,455.892,206.881,443.359,206.901z M443.359,246.311
				c-5.439,0-9.852-4.414-9.852-9.852s4.414-9.852,9.852-9.852c5.439,0,9.852,4.414,9.852,9.852S448.798,246.311,443.359,246.311z"
      />
      <rect x="177.344" y="157.639" width="19.705" height="19.705"/>
      <rect x="177.344" y="118.229" width="19.705" height="19.705"/>
      <rect x="177.344" y="78.819" width="19.705" height="19.705"/>
      <rect x="177.344" y="39.41" width="19.705" height="19.705"/>
      <rect x="216.753" y="39.41" width="19.705" height="19.705"/>
      <rect x="256.163" y="39.41" width="19.705" height="19.705"/>
      <rect x="295.573" y="39.41" width="19.705" height="19.705"/>
		</g>
	</g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
    <g>
</g>
</svg>

</a>
