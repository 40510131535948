import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-searchtools',
  templateUrl: './searchtools.component.html',
  styleUrls: ['./searchtools.component.scss']
})
export class SearchtoolsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
