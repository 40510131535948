<div class="row text-center bg-login">
  <div class="col-12">
    <div class="bgtitle" style="background-color: #2b2b2b; opacity: 0.8;">
    <h1 class="display-4 text-white">บริการ ออนไลน์</h1>
    <p class="lead text-white"></p>
    </div>
    <p> </p>
    <p class="lead">
      <a href="https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id={{clientID}}&redirect_uri={{callback}}&scope={{scope}}&state=xyz" class="btn btn-pink btn-user btn-block">
        <button class="btn btn-secondary fa-2x">Login</button></a>
    </p>
  </div>
  <div class="col-12">
    <div class="bgtitle" style="background-color: #2b2b2b; opacity: 0.8;">

      <h1 class="lead text-white"></h1>
      <h1 class="lead text-white"></h1>
      <h1 class="lead text-white"></h1>

    </div>
  </div>

</div>

