import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {ApiwebService} from '../../../apiweb.service';
import {UploadService} from '../../../upload.service';

export interface DialogData {
  Action: string;
  Id: string;
}
@Component({
  selector: 'app-dashboardarticle',
  templateUrl: './dashboardarticle.component.html',
  styleUrls: ['./dashboardarticle.component.scss']
})
export class DashboardarticleComponent implements OnInit {
  public email = localStorage.getItem('EmailCMU');
  public department = localStorage.getItem('Department');

  public type = 'articles';
  // public localbasesite = this.apiweb.localbasesite;
  public pathlocation =  this.type + '/';
  // public URLFileUpload = this.localbasesite + this.pathlocation;

  public FileURL;
  // ข้อมูลตาราง
  displayedColumns: string[] = ['No', 'SoArticle', 'TopicArticle', 'FileUpload', 'StatusPublish', 'Status' ];
  public dataSource = new MatTableDataSource();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;
  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute ) { }

  ngOnInit(): void {
    this.FileURL = this.apiweb.WebsiteAddress + '/api/assets/' + this.type + '/' ;
    this.CallArticle();
  }

  CallArticle(){
    this.apiweb.CallAllArticleFaculty().subscribe( (dataresponse: any) => {
      // console.log(data);
      if (dataresponse.status === true){
        const data = dataresponse.data;
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
      }else {
        this.dataSource.data = [];
        this.dataSource.paginator = this.paginator;
      }

    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }



  ClickManageData(Action, Id){
    this.router.navigate(['/dashboard/website/managearticles/', { Action, Id }]);
  }
  CheckSwitchTrueFalse(Dataval){
    if (Dataval === '1'){
      return true;
    }
    if (Dataval === '0' || Dataval === ''  || Dataval === 'null' ){
      return false;
    }
  }
  checkCheckBoxvalue(Id, event){
    // console.log(event.target.checked);
    // console.log(Id);
    let valchange ;
    if (event.target.checked === true){
      valchange = 1;
    }else{
      valchange = 0;
    }
    let data ;
    data = { Id, StatusPublish: valchange, Action: 'StatusPublish'} ;
    this.apiweb.CommandArticleStatuspublish(data).subscribe(datasend => {
      console.log(data);
      console.log(datasend);
    });

  }
  openDialogDel(Id): void {
    const dialogRef = this.dialog.open(DialogDelarticleDialog, {

      height: '300px',
      data: { Id, Action: 'Del'}
    });
    dialogRef.afterClosed().subscribe(result => {
      // console.log('The dialog was closed');
      this.CallArticle();
    });
  }
}


// Location
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-delarticle-dialog',
  templateUrl: 'dialog-delarticle-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogDelarticleDialog implements OnInit  {

  public FormDel: FormGroup;
  public btadd = false;
  public btedit = false;
  public btdel = false;
  public btcancel = false;
  public btaddlocation = true;
  public DialogDataLocation;

  // this.UploadUrl + this.pathlocation
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<DialogData>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public apiweb: ApiwebService, public uploadService: UploadService) {}
  ngOnInit() {

    this.FormDel = this.formBuilder.group({
      Id: this.data.Id,
      Action: 'Del'
    });
  }


  onClickCancel() {
    this.btaddlocation = true;
    this.btadd = false;
    this.btedit = false;
    this.btdel = false;
    this.btcancel = false;
    this.FormDel.patchValue({
      Id: ''
    });
  }

  SaveDelete() {
    // console.log(this.FormDel.value);
    this.apiweb.CommandManageArticles(this.FormDel.value).subscribe(data => {
      this.clearForm();
      this.dialogRef.close();
    });
  }

  clearForm() {
    this.FormDel.reset();
  }

  onNoClick(): void {
    this.dialogRef.close();
    // console.log(this.FormAddLocation.value);
  }
  onOkClick(): void {
    // console.log(this.FormAddLocation.value);
    /* this.apiweb.deldata(this.data, this.data.TypePost).subscribe( (data: any[]) => {});
     this.dialogRef.close();
     window.location.reload();

     */
  }

}
