<ng-container *ngIf="DisplayDelete;else otheraction">
  <h1 mat-dialog-title class="fontAdelleSanTHA-Regular">{{txtMemuAction}}</h1>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-12">
        โปรดระมัดระวังการลบข้อมูล
      </div>
    </div>

  </div>
  <mat-dialog-actions align="end" class="fontAdelleSanTHA-Regular">
    <span class="btn btn-success mr-3" (click)="onOkClickDel()" ><i class="fas fa-plus-circle" aria-hidden="true">ยืนยัน </i> </span>
    <span class="btn btn-warning" (click)="onNoClick()">ยกเลิก</span>
  </mat-dialog-actions>
</ng-container>
<ng-template #otheraction>
  <h1 mat-dialog-title class="fontAdelleSanTHA-Regular">{{txtMemuAction}}</h1>
  <div mat-dialog-content>
    <div class="row">
      <div class="col-12">

        <form [formGroup]="FormAddMainmenu">
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label for="MenuNameTh"><strong>ชื่อเมนู ภาษาไทย</strong></label>
                <input type="text" class="form-control" id="MenuNameTh" placeholder="ชื่อเรื่อง ภาษาไทย" formControlName="MenuNameTh">
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label for="MenuNameEn"><strong>ชื่อเมนู ภาษาอังกฤษ</strong></label>
                <input type="text" class="form-control" id="MenuNameEn" placeholder="ชื่อเรื่อง ภาษาอังกฤษ" formControlName="MenuNameEn">
              </div>
            </div>
          </div>
          <div class="row mt-2" *ngIf="BoxSubmenu === true">
            <div class="col-12 ">
              <div class="form-group">
                <label for="Links"><strong>Links</strong></label>
                <input type="text" class="form-control" id="Links" placeholder="Links" formControlName="Links">
              </div>
            </div>
            <div class="col-12">
              <div class="form-group">
                <div class="form-check">
                  <label class="form-check-label">
                    <mat-checkbox class="example-margin" formControlName="StatusLinksNewPage">เปิดลิงค์ที่หน้าใหม่</mat-checkbox>

                  </label>
                </div>
              </div>
            </div>

          </div>
          <div class="row mt-2">
            <div class="col-12 border-warning border">
              <div class="form-group">
                <label><strong>เลือกเมนูย่อย</strong></label>
                <div class="form-check">
                  <label class="form-check-label">

                    <mat-checkbox class="example-margin" formControlName="SubMenu" (change)="CheckSubmenu()">มีเมนูย่อย</mat-checkbox>

                  </label>
                </div>
              </div>
            </div>
          </div>




        </form>
      </div>
    </div>

  </div>
  <mat-dialog-actions align="end" class="fontAdelleSanTHA-Regular">
    <span class="btn btn-success mr-3" (click)="onOkClick()" ><i class="fas fa-plus-circle" aria-hidden="true">ยืนยัน </i> </span>
    <span class="btn btn-warning" (click)="onNoClick()">ยกเลิก</span>
  </mat-dialog-actions>
</ng-template>

