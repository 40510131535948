import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { EmpoloyeeComponent } from './empoloyee/empoloyee.component';
import { StudentComponent } from './student/student.component';
import { WebsiteComponent } from './website/website.component';
import { LoginComponent } from './website/login/login.component';
import { LogoutComponent } from './website/logout/logout.component';
import { HomeComponent } from './website/home/home.component';
import { CallbackComponent } from './callback/callback.component';
import { LayoutdashboardComponent } from './layout/layoutdashboard/layoutdashboard.component';
import { LayoutemployeeComponent } from './layout/layoutemployee/layoutemployee.component';
import { LayouthomeComponent } from './layout/layouthome/layouthome.component';
import { LayoutstudentComponent } from './layout/layoutstudent/layoutstudent.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatTreeModule} from '@angular/material/tree';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatInputModule} from '@angular/material/input';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatMenuModule} from '@angular/material/menu';
import {MatGridListModule} from '@angular/material/grid-list';
import {MAT_DATE_LOCALE, MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatSortModule} from '@angular/material/sort';
import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatTableModule} from '@angular/material/table';
import {MatCardModule} from '@angular/material/card';
import {MatTabsModule} from '@angular/material/tabs';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatButtonModule} from '@angular/material/button';
import {MatSelectModule} from '@angular/material/select';
import {MatIconModule} from '@angular/material/icon';
import {MatListModule} from '@angular/material/list';
import {MatSidenavModule} from '@angular/material/sidenav';
import {MatStepperModule} from '@angular/material/stepper';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {MatRadioModule} from '@angular/material/radio';
import {MatChipsModule} from '@angular/material/chips';
import {MatSliderModule} from '@angular/material/slider';
import {DatePipe} from '@angular/common';
import {MatBadgeModule} from '@angular/material/badge';
import {MatBottomSheetModule} from '@angular/material/bottom-sheet';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';

import { LayoutalumniComponent } from './layout/layoutalumni/layoutalumni.component';
import { DashboardheaderComponent } from './layout/layoutdashboard/dashboardheader/dashboardheader.component';
import { DashboardfooterComponent } from './layout/layoutdashboard/dashboardfooter/dashboardfooter.component';
import { DashboardnavigationtopComponent } from './layout/layoutdashboard/dashboardnavigationtop/dashboardnavigationtop.component';
import { DashboardwebsiteComponent } from './dashboard/dashboardwebsite/dashboardwebsite.component';
import { DashboardcirculationComponent } from './dashboard/dashboardcirculation/dashboardcirculation.component';
import { DashboardcirculationleftmenuComponent } from './dashboard/dashboardcirculation/dashboardcirculationleftmenu/dashboardcirculationleftmenu.component';
import { DashboardcirculationinComponent } from './dashboard/dashboardcirculation/dashboardcirculationin/dashboardcirculationin.component';
import { DashboardcirculationoutComponent } from './dashboard/dashboardcirculation/dashboardcirculationout/dashboardcirculationout.component';
import { DashboardcirculationfrmComponent } from './dashboard/dashboardcirculation/dashboardcirculationfrm/dashboardcirculationfrm.component';
import {
  DialogItemstatusDialog, DialogItemtypeDialog,
  FrmcirculationmangeComponent
} from './dashboard/dashboardcirculation/dashboardcirculationfrm/frmcirculationmange/frmcirculationmange.component';
import {
  DashboardcirculationmanagementComponent,
  DialogDelitemsDialog, DialogDetailItems
} from './dashboard/dashboardcirculation/dashboardcirculationmanagement/dashboardcirculationmanagement.component';
import {CKEditorModule} from 'ckeditor4-angular';
import {NgxBarcode6Module} from 'ngx-barcode6';
import {AlumniComponent} from './alumni/alumni.component';
import { LayoutcontentComponent } from './layout/layoutcontent/layoutcontent.component';
import { ContentComponent } from './website/content/content.component';
import { HeaderhomeComponent } from './layout/layouthome/headerhome/headerhome.component';
import { FooterhomeComponent } from './layout/layouthome/footerhome/footerhome.component';
import { HomenavigationtopComponent } from './layout/layouthome/homenavigationtop/homenavigationtop.component';
import { SwitchlangComponent } from './website/switchlang/switchlang.component';
import {BlocksearchtoolsComponent} from './website/searchtools/blocksearchtools/blocksearchtools.component';
import {BlocknewbookComponent} from './website/book/blocknewbook/blocknewbook.component';
import {SearchtoolseverytingComponent} from './website/searchtools/searchtoolseveryting/searchtoolseveryting.component';
import {OtherlinkComponent} from './website/otherlink/otherlink.component';
import {SearchtoolsedsComponent} from './website/searchtools/searchtoolseds/searchtoolseds.component';
import {BlockbannerslidewithindicatorComponent} from './website/banner/blockbannerslidewithindicator/blockbannerslidewithindicator.component';
import {EventdetailComponent} from './website/event/eventdetail/eventdetail.component';
import {BlogfooterlinksComponent} from './website/block/blogfooterlinks/blogfooterlinks.component';
import {SlidesComponent} from './website/slides/slides.component';
import {SearchtoolscoursereserveComponent} from './website/searchtools/searchtoolscoursereserve/searchtoolscoursereserve.component';
import {BookdeatilComponent} from './website/book/bookdeatil/bookdeatil.component';
import {ResearchComponent} from './website/research/research.component';
import {Blogofsearchv1Component} from './website/searchtools/blogofsearchv1/blogofsearchv1.component';
import {NewsComponent} from './website/news/news.component';
import {JournaldetailComponent} from './website/journal/journaldetail/journaldetail.component';
import {ServicesComponent} from './website/services/services.component';
import {SearchtoolsrenewComponent} from './website/searchtools/searchtoolsrenew/searchtoolsrenew.component';
import {SearchtoolsarticleComponent} from './website/searchtools/searchtoolsarticle/searchtoolsarticle.component';
import {BlockfeedsComponent} from './website/feeds/blockfeeds/blockfeeds.component';
import {BlockComponent} from './website/block/block.component';
import {BlockbannerslideonlyComponent} from './website/banner/blockbannerslideonly/blockbannerslideonly.component';
import {SearchtoolsopacComponent} from './website/searchtools/searchtoolsopac/searchtoolsopac.component';
import {BlockebookComponent} from './website/ebook/blockebook/blockebook.component';
import {BlockbannerComponent} from './website/banner/blockbanner/blockbanner.component';
import {EbookComponent} from './website/ebook/ebook.component';
import {BannerComponent} from './website/banner/banner.component';
import {Blockservicesicon3dComponent} from './website/services/blockservicesicon3d/blockservicesicon3d.component';
import {Blogofsearchv2Component} from './website/searchtools/blogofsearchv2/blogofsearchv2.component';
import {TermpaperComponent} from './website/termpaper/termpaper.component';
import {BlogslidewithcaptionComponent} from './website/slides/blogslidewithcaption/blogslidewithcaption.component';
import {SearchtoolsbooksComponent} from './website/searchtools/searchtoolsbooks/searchtoolsbooks.component';
import {FeedsComponent} from './website/feeds/feeds.component';
import {BlockjournalComponent} from './website/journal/blockjournal/blockjournal.component';
import {SearchtoolsComponent} from './website/searchtools/searchtools.component';
import {BlockotherlinksComponent} from './website/otherlink/blockotherlinks/blockotherlinks.component';
import {BlockbannerslidewithcontrolComponent} from './website/banner/blockbannerslidewithcontrol/blockbannerslidewithcontrol.component';
import {EbookdetailComponent} from './website/ebook/ebookdetail/ebookdetail.component';
import {JournalComponent} from './website/journal/journal.component';
import {BlockservicesiconComponent} from './website/services/blockservicesicon/blockservicesicon.component';
import {BlocknewsComponent} from './website/news/blocknews/blocknews.component';
import {BookComponent} from './website/book/book.component';
import {ArticleComponent} from './website/article/article.component';
import {BlockvideosingleComponent} from './website/videos/blockvideosingle/blockvideosingle.component';
import {EventComponent} from './website/event/event.component';
import {BlockbannerslidewithcaptionComponent} from './website/banner/blockbannerslidewithcaption/blockbannerslidewithcaption.component';
import {PaperComponent} from './website/paper/paper.component';
import {BlockeventComponent} from './website/event/blockevent/blockevent.component';
import {NewsdetailComponent} from './website/news/newsdetail/newsdetail.component';
import {ServicesdetailComponent} from './website/services/servicesdetail/servicesdetail.component';
import {VideosComponent} from './website/videos/videos.component';
import {ContentdetailComponent} from './website/content/contentdetail/contentdetail.component';
import {DashboardarticleComponent, DialogDelarticleDialog} from './dashboard/dashboardwebsite/dashboardarticle/dashboardarticle.component';
import {DashboardjournalComponent, DialogDel่journalDialog} from './dashboard/dashboardwebsite/dashboardjournal/dashboardjournal.component';
import {DashboardconfigsComponent} from './dashboard/dashboardwebsite/dashboardconfigs/dashboardconfigs.component';
import {FrmthesesComponent} from './dashboard/dashboardwebsite/frm/frmtheses/frmtheses.component';
import {FrmeventComponent} from './dashboard/dashboardwebsite/frm/frmevent/frmevent.component';
import {DashboardeventsComponent, DialogDeleventsDialog} from './dashboard/dashboardwebsite/dashboardevents/dashboardevents.component';
import {FrmjournalComponent} from './dashboard/dashboardwebsite/frm/frmjournal/frmjournal.component';
import {DashboardsubmenuComponent} from './dashboard/dashboardwebsite/dashboardmenu/dashboardsubmenu/dashboardsubmenu.component';
import {DashboardbannersComponent, DialogDelbannersDialog} from './dashboard/dashboardwebsite/dashboardbanners/dashboardbanners.component';
import {FrmbookComponent} from './dashboard/dashboardwebsite/frm/frmbook/frmbook.component';
import {FrmuploadfileComponent} from './dashboard/dashboardwebsite/frmuploadfile/frmuploadfile.component';
import {DashboardslideComponent, DialogDelslidesDialog} from './dashboard/dashboardwebsite/dashboardslide/dashboardslide.component';
import {
  DashboardtermpaperComponent,
  DialogDeltermpaperDialog
} from './dashboard/dashboardwebsite/dashboardtermpaper/dashboardtermpaper.component';
import {DashboardmenuComponent, DialogAddMainMenuDialog} from './dashboard/dashboardwebsite/dashboardmenu/dashboardmenu.component';
import {FrmbannerComponent} from './dashboard/dashboardwebsite/frm/frmbanner/frmbanner.component';
import {DashboardbookComponent, DialogDelbookDialog} from './dashboard/dashboardwebsite/dashboardbook/dashboardbook.component';
import {
  DashboardcontentsComponent,
  DialogDelcontentsDialog
} from './dashboard/dashboardwebsite/dashboardcontents/dashboardcontents.component';
import {FrmtermpaperComponent} from './dashboard/dashboardwebsite/frm/frmtermpaper/frmtermpaper.component';
import {FrmcontentComponent} from './dashboard/dashboardwebsite/frm/frmcontent/frmcontent.component';
import {FrmmenuComponent} from './dashboard/dashboardwebsite/frm/frmmenu/frmmenu.component';
import {DashboardnewsComponent, DialogDelnewsDialog} from './dashboard/dashboardwebsite/dashboardnews/dashboardnews.component';
import {
  DashboardedatabaseComponent, DialogDeledatabaseDialog
} from './dashboard/dashboardwebsite/dashboardedatabase/dashboardedatabase.component';
import {FrmresearchComponent} from './dashboard/dashboardwebsite/frm/frmresearch/frmresearch.component';
import {FrmComponent} from './dashboard/dashboardwebsite/frm/frm/frm.component';
import {FrmuploadimageComponent} from './dashboard/dashboardwebsite/frmuploadimage/frmuploadimage.component';
import {ConfigmenuComponent} from './dashboard/dashboardwebsite/dashboardconfigs/configmenu/configmenu.component';
import {DashboardthesesComponent, DialogDelthesesDialog} from './dashboard/dashboardwebsite/dashboardtheses/dashboardtheses.component';
import {FrmslideComponent} from './dashboard/dashboardwebsite/frm/frmslide/frmslide.component';
import {DashboardfooterlinksComponent} from './dashboard/dashboardwebsite/dashboardfooterlinks/dashboardfooterlinks.component';
import {FrmarticleComponent} from './dashboard/dashboardwebsite/frm/frmarticle/frmarticle.component';
import {
  DashboardresearchComponent,
  DialogDelresearchDialog
} from './dashboard/dashboardwebsite/dashboardresearch/dashboardresearch.component';
import {FrmedatabaseComponent} from './dashboard/dashboardwebsite/frm/frmedatabase/frmedatabase.component';
import {FrmnewComponent} from './dashboard/dashboardwebsite/frm/frmnew/frmnew.component';
import { LayoutdashboardwebsiteComponent } from './layout/layoutdashboardwebsite/layoutdashboardwebsite.component';
import { DashboardwebsiteleftmenuComponent } from './layout/layoutdashboardwebsite/dashboardwebsiteleftmenu/dashboardwebsiteleftmenu.component';
import { LayoutdashboardcirculationComponent } from './layout/layoutdashboardcirculation/layoutdashboardcirculation.component';
import { DashboardcirculationreportsComponent } from './dashboard/dashboardcirculation/dashboardcirculationreports/dashboardcirculationreports.component';
import { CirculationreportitemComponent } from './dashboard/dashboardcirculation/dashboardcirculationreports/circulationreportitem/circulationreportitem.component';
import { CirculationreportuserComponent } from './dashboard/dashboardcirculation/dashboardcirculationreports/circulationreportuser/circulationreportuser.component';
import { CirculationreportservicesComponent } from './dashboard/dashboardcirculation/dashboardcirculationreports/circulationreportservices/circulationreportservices.component';
import { CirculationreportchartservicesComponent } from './dashboard/dashboardcirculation/dashboardcirculationreports/circulationreportchartservices/circulationreportchartservices.component';
import {
  DashboardiconservicesComponent,
  DialogAddIconServicesDialog
} from './dashboard/dashboardwebsite/dashboardiconservices/dashboardiconservices.component';
import { FrmiconservicesComponent } from './dashboard/dashboardwebsite/frm/frmiconservices/frmiconservices.component';
import { IconloginComponent } from './website/login/iconlogin/iconlogin.component';
import {DragDropModule} from '@angular/cdk/drag-drop';
import {DashboardconfigfooterComponent} from './dashboard/dashboardwebsite/dashboardconfigfooter/dashboardconfigfooter.component';
import { CkeditorPipe } from './ckeditor.pipe';
import {
  DashboardtrendyartsComponent,
  DialogDeltrendyartsDialog
} from './dashboard/dashboardwebsite/dashboardtrendyarts/dashboardtrendyarts.component';
import { FrmtrendyartsComponent } from './dashboard/dashboardwebsite/frm/frmtrendyarts/frmtrendyarts.component';
import { TrendyartsComponent } from './website/trendyarts/trendyarts.component';
import { BlocktrendyartsComponent } from './website/trendyarts/blocktrendyarts/blocktrendyarts.component';
import { DetailtrendyartsComponent } from './website/trendyarts/detailtrendyarts/detailtrendyarts.component';
import {NgxQRCodeModule} from '@techiediaries/ngx-qrcode';
import {HttpClientModule} from '@angular/common/http';
import { TermpaperdetailComponent } from './website/termpaper/termpaperdetail/termpaperdetail.component';
import { FacebookComponent } from './website/facebook/facebook.component';
import {DashboardgalleryComponent, DialogDelgalleryDialog} from './dashboard/dashboardwebsite/dashboardgallery/dashboardgallery.component';
import { FrmgalleryComponent } from './dashboard/dashboardwebsite/frm/frmgallery/frmgallery.component';
import { GalleryComponent } from './website/gallery/gallery.component';
import { GallerydetailComponent } from './website/gallery/gallerydetail/gallerydetail.component';
import { GalleryblogComponent } from './website/gallery/galleryblog/galleryblog.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    EmpoloyeeComponent,
    StudentComponent,
    WebsiteComponent,
    LoginComponent,
    LogoutComponent,
    HomeComponent,
    CallbackComponent,
    LayoutdashboardComponent,
    LayoutemployeeComponent,
    LayouthomeComponent,
    LayoutstudentComponent,
    AlumniComponent,
    LayoutalumniComponent,
    DashboardheaderComponent,
    DashboardfooterComponent,
    DashboardnavigationtopComponent,
    DashboardwebsiteComponent,
    DashboardcirculationComponent,
    DashboardcirculationleftmenuComponent,
    DashboardcirculationinComponent,
    DashboardcirculationoutComponent,
    DashboardcirculationmanagementComponent,
    DialogDelitemsDialog,
    DashboardcirculationfrmComponent,
    FrmcirculationmangeComponent,
    DialogDetailItems,
    LayoutcontentComponent,
    ContentComponent,
    HeaderhomeComponent,
    FooterhomeComponent,
    HomenavigationtopComponent,
    SwitchlangComponent,










    BannerComponent,
    BlockbannerComponent,
    VideosComponent,
    BlockvideosingleComponent,
    BlockbannerslideonlyComponent,
    BlockbannerslidewithcontrolComponent,
    BlockbannerslidewithindicatorComponent,
    BlockbannerslidewithcaptionComponent,
    SearchtoolsComponent,
    ServicesComponent,
    BlockservicesiconComponent,
    BlocksearchtoolsComponent,
    FeedsComponent,
    BlockfeedsComponent,
    SearchtoolsbooksComponent,
    SearchtoolsarticleComponent,
    SearchtoolscoursereserveComponent,
    SearchtoolsedsComponent,
    SearchtoolseverytingComponent,
    SearchtoolsopacComponent,
    SearchtoolsrenewComponent,
    Blockservicesicon3dComponent,
    BookComponent,
    EbookComponent,
    JournalComponent,
    PaperComponent,
    TermpaperComponent,
    ArticleComponent,
    EventComponent,
    NewsComponent,
    ResearchComponent,
    BlocknewbookComponent,
    OtherlinkComponent,
    BlockotherlinksComponent,
    BlockebookComponent,
    BlockjournalComponent,
    BlockeventComponent,
    SlidesComponent,
    BlogslidewithcaptionComponent,
    BlockComponent,
    BlogfooterlinksComponent,
    BlocknewsComponent,
    Blogofsearchv1Component,
    Blogofsearchv2Component,
    EventdetailComponent,
    NewsdetailComponent,
    JournaldetailComponent,
    BookdeatilComponent,
    ServicesdetailComponent,
    ContentdetailComponent,
    EbookdetailComponent,


    DashboardcontentsComponent,
    DashboardbannersComponent,
    DashboardarticleComponent,
    DashboardjournalComponent,
    DashboardthesesComponent,

    DashboardnewsComponent,
    DialogDelarticleDialog,
    DialogDelcontentsDialog,
    DialogDelbannersDialog,
    DialogDeleventsDialog,
    DialogDelnewsDialog,
    DialogDelthesesDialog,
    DialogDeltermpaperDialog,
    DialogDelresearchDialog,
    DialogDelbookDialog,
    DialogDeledatabaseDialog,
    DialogDel่journalDialog,
    DialogDelslidesDialog,
    DialogAddMainMenuDialog,
    FrmuploadfileComponent,
    FrmuploadimageComponent,
    FrmcontentComponent,
    DashboardconfigsComponent,
    FrmbannerComponent,
    FrmnewComponent,
    FrmeventComponent,
    DashboardeventsComponent,
    ConfigmenuComponent,
    FrmarticleComponent,
    FrmthesesComponent,
    FrmComponent,
    FrmtermpaperComponent,
    FrmresearchComponent,
    DashboardresearchComponent,
    FrmjournalComponent,
    DashboardtermpaperComponent,
    DashboardbookComponent,
    DashboardedatabaseComponent,
    FrmbookComponent,
    FrmedatabaseComponent,
    DashboardmenuComponent,
    FrmmenuComponent,
    DashboardsubmenuComponent,
    DashboardfooterlinksComponent,
    DashboardslideComponent,
    FrmslideComponent,
    LayoutdashboardwebsiteComponent,
    DashboardwebsiteleftmenuComponent,
    LayoutdashboardcirculationComponent,
    DashboardcirculationreportsComponent,
    DialogItemstatusDialog,
    DialogItemtypeDialog,
    CirculationreportitemComponent,
    CirculationreportuserComponent,
    CirculationreportservicesComponent,
    CirculationreportchartservicesComponent,
    DashboardiconservicesComponent,
    FrmiconservicesComponent,
    IconloginComponent,

    DialogAddIconServicesDialog,
    DashboardconfigfooterComponent,
    CkeditorPipe,
    DashboardtrendyartsComponent,
    FrmtrendyartsComponent,
    TrendyartsComponent,
    BlocktrendyartsComponent,
    DetailtrendyartsComponent,
    DialogDeltrendyartsDialog,
    TermpaperdetailComponent,
    FacebookComponent,
    DashboardgalleryComponent,
    FrmgalleryComponent,
    GalleryComponent,
    GallerydetailComponent,
    GalleryblogComponent,
    DialogDelgalleryDialog
  ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        FormsModule,
        ReactiveFormsModule,
        BrowserAnimationsModule,
        MatAutocompleteModule,
        MatBadgeModule,
        MatBottomSheetModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatCardModule,
        MatCheckboxModule,
        MatChipsModule,
        MatDatepickerModule,
        MatDialogModule,
        MatDividerModule,
        MatExpansionModule,
        MatFormFieldModule,
        MatGridListModule,
        MatIconModule,
        MatInputModule,
        MatListModule,
        MatMenuModule,
        MatNativeDateModule,
        MatPaginatorModule,
        MatProgressBarModule,
        MatProgressSpinnerModule,
        MatRadioModule,
        MatRippleModule,
        MatSelectModule,
        MatSidenavModule,
        MatSliderModule,
        MatSlideToggleModule,
        MatSnackBarModule,
        MatSortModule,
        MatStepperModule,
        MatTableModule,
        MatTabsModule,
        MatToolbarModule,
        MatTooltipModule,
        MatTreeModule,
        CKEditorModule,
        DragDropModule,
        NgxBarcode6Module,
        NgxQRCodeModule
    ],
  providers: [
    DatePipe,
    {provide: MAT_DATE_LOCALE, useValue: 'th-TH'}
  ],
  entryComponents: [
    DialogDelitemsDialog,
    DialogDetailItems,
    DialogDelarticleDialog,
    DialogDelcontentsDialog,
    DialogDelbannersDialog,
    DialogDeleventsDialog,
    DialogDelnewsDialog,
    DialogDelthesesDialog,
    DialogDeltermpaperDialog,
    DialogDelresearchDialog,
    DialogDelbookDialog,
    DialogDel่journalDialog,
    DialogDeledatabaseDialog,
    DialogAddMainMenuDialog,
    DialogDelslidesDialog,
    DialogItemstatusDialog,
    DialogItemtypeDialog,
    DialogAddIconServicesDialog,
    DialogDeltrendyartsDialog,
    DialogDelgalleryDialog
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
