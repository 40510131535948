<div class="row">
  <div class="col-12">
    <H1>บริหารจัดการข้อมูลส่วนท้ายเว็บไซต์</H1>
  </div>
</div>
<form [formGroup]="FormAdd">
<div class="row">
  <div class="container">

  <div class="row mt-2">
    <div class="col-12">

      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item">
          <a class="nav-link active" id="home-tab" data-toggle="tab" href="#thai" role="tab" aria-controls="thai" aria-selected="true">ภาษาไทย</a>
        </li>
        <li class="nav-item">
          <a class="nav-link" id="profile-tab" data-toggle="tab" href="#eng" role="tab" aria-controls="eng" aria-selected="false">English</a>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="thai" role="tabpanel" aria-labelledby="thai">
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label><strong>Fakebook code </strong></label>
                <textarea formControlName="FooterContentTh1" rows="5" cols="200"></textarea>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label><strong>เนื้อหาท้ายเว็บส่วนที่ 1</strong></label>
                <ckeditor [editorUrl]="CkeditorApiAddress" [config]="ckeditor4config" formControlName="FooterContentTh1"></ckeditor>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label><strong>เนื้อหาท้ายเว็บส่วนที่ 2</strong></label>
                <ckeditor [editorUrl]="CkeditorApiAddress" [config]="ckeditor4config" formControlName="FooterContentTh2"></ckeditor>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label><strong>เนื้อหาท้ายเว็บส่วนที่ 2</strong></label>
                <ckeditor [editorUrl]="CkeditorApiAddress" [config]="ckeditor4config" formControlName="FooterContentTh2"></ckeditor>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label><strong>เนื้อหาท้ายเว็บส่วนที่ 3</strong></label>
                <ckeditor [editorUrl]="CkeditorApiAddress" [config]="ckeditor4config" formControlName="FooterContentTh3"></ckeditor>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label><strong>เนื้อหาท้ายเว็บส่วนที่ 4</strong></label>
                <ckeditor [editorUrl]="CkeditorApiAddress" [config]="ckeditor4config" formControlName="FooterContentTh4"></ckeditor>
              </div>
            </div>
          </div>
        </div>

        <div class="tab-pane fade" id="eng" role="tabpanel" aria-labelledby="eng">

          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label><strong>เนื้อหาท้ายเว็บส่วนที่ 1 ภาษาอังกฤษ ส่วนเฟสบุ๊คใส่ code facebook</strong></label>
                <textarea formControlName="FooterContentEn1" rows="5" cols="200"></textarea>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label><strong>เนื้อหาท้ายเว็บส่วนที่ 2 ภาษาอังกฤษ</strong></label>
                <ckeditor [editorUrl]="CkeditorApiAddress" [config]="ckeditor4config" formControlName="FooterContentEn2"></ckeditor>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label><strong>เนื้อหาท้ายเว็บส่วนที่ 3 ภาษาอังกฤษ</strong></label>
                <ckeditor [editorUrl]="CkeditorApiAddress" [config]="ckeditor4config" formControlName="FooterContentEn3"></ckeditor>
              </div>
            </div>
          </div>
          <div class="row mt-2">
            <div class="col-12">
              <div class="form-group">
                <label><strong>เนื้อหาท้ายเว็บส่วนที่ 4 ภาษาอังกฤษ</strong></label>
                <ckeditor [editorUrl]="CkeditorApiAddress" [config]="ckeditor4config" formControlName="FooterContentEn4"></ckeditor>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>
  </div>
</div>
</form>
<div class="row">
  <div class="container">
    <div class="row text-center">
      <div class="col-12">
        <span class="btn btn-success" (click)="BtSave()"> Save </span>
      </div>
    </div>
  </div>
</div>
