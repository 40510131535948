import { Component, OnInit } from '@angular/core';
import {ApiwebService} from '../../apiweb.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public StyleSearchBox;
  public StyleBackgroundFeed;
  public loadpageth = false;

  /* ตั้งค่าหน้าเพจ */

  public SiteBackgroundSearchbox ;
  public SiteBackgroundSearchboxColor ;
  public BackgroundFeeds;
  public BackgroundFeedColors;

  constructor(public apiweb: ApiwebService) { }

  ngOnInit(): void {
    this.apiweb.FrontendGetDataId('configs', 'get_configs', 'Faculty', this.apiweb.SiteFaculty).subscribe( (dataresponse: any) => {
      if (dataresponse.status === true){
        const data = dataresponse.data[0];
        this.SiteBackgroundSearchbox = data.BackgroundSearchbox;
        this.SiteBackgroundSearchboxColor = data.BackgroundSearchboxColor;
        this.BackgroundFeeds = data.BackgroundFeeds;
        this.BackgroundFeedColors = data.BackgroundFeedColors;
        this.loadpageth = true;
        // console.log(this.SearchBoxBackgroundColor);
        if (this.SiteBackgroundSearchbox === '' || this.SiteBackgroundSearchbox === null){
          this.StyleSearchBox = {
            'background-color': this.SiteBackgroundSearchboxColor
          };
        }else {
          this.StyleSearchBox = {
            'background-image': 'url("' + this.apiweb.ApiAddress + 'assets/configs/' + this.SiteBackgroundSearchbox + '"'
          };
        }

        if (this.BackgroundFeeds === '' || this.BackgroundFeeds === null){
          this.StyleBackgroundFeed = {
            'background-color': this.BackgroundFeedColors
          };
        }else {
          this.StyleBackgroundFeed = {
            'background-image': 'url("' + this.apiweb.ApiAddress + 'assets/configs/' + this.BackgroundFeeds + '"'
          };
        }
      }
    });

  }

}
