<div class="row bg-light ">
  <div class="col-12 p-3">
  <div class="row">
    <div class="col-9"><h3>ศิษย์เก่า มหาวิทยาลัยเชียงใหม่</h3></div>
    <div class="col-3 text-right ">  <button class="btn text-white" (click)="Clicklogout()" style="background-color: #9c3328;"> ออกจากระบบ </button></div>
  </div>
  </div>
</div>

<div class="row mt-3">
  <div class="col-12">
    <router-outlet></router-outlet>
  </div>
</div>



<div class="row mt-3 text-white text-center p-3" style="background-color: #9c3328;">
  <div class="col-12">
    <h4>ห้องสมุด มหาวิทยาลัยเชียงใหม่</h4>
  </div>
</div>
