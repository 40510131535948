<div class="row mt-2" style="background-color: rgba(170,17,17,0.84)">
  <div class="container">
    <div class="row">
      <div class="col-6">
        <ng-container *ngIf="displaylang=='Th';else noTh">
          <h1 class="text-white">Journals</h1>
        </ng-container>
        <ng-template #noTh>
          <h1 class="text-white">Journals</h1>
        </ng-template>
      </div>
      <div class="col-6 text-right">
        <i class="fab fa-buromobelexperte fa-3x mr-3 text-white" (click)="OnclickShowStyleBox()"></i><i class="far fa-list-alt fa-3x text-white mr-2" (click)="OnclickShowStylelist()"></i>
      </div>
    </div>
  </div>


</div>
<div class="row">

  <div class="container">
    <div class="row mt-2">
      <div class="searchboxfilter mat-card">
        <div class="mat-card-content">
          <mat-form-field>
            <mat-label>ค้นหาข้อมูล</mat-label>
            <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
          </mat-form-field>
        </div>
      </div>

    </div>

    <ng-container *ngIf="ShowStyle;else Boxlist">

      <div class="mat-elevation-z8">
        <table mat-table [dataSource]="dataSource" >
          <ng-container matColumnDef="no">
            <th mat-header-cell *matHeaderCellDef>ลำดับที่</th>
            <td mat-cell *matCellDef="let element">{{dataSource.filteredData.indexOf(element)+1}}</td>
          </ng-container>

          <!-- Name Column -->
          <ng-container matColumnDef="ImageCover">
            <th mat-header-cell *matHeaderCellDef></th>
            <td mat-cell *matCellDef="let element"><img src="{{pathimage}}{{element.ImageCover}}" style="max-height: 150px;width: auto; max-width: 150px;" (click)="ClickLink(element.Id)"></td>
          </ng-container>
          <!-- Name Column -->
          <ng-container matColumnDef="TitleTh">
            <th mat-header-cell *matHeaderCellDef> Title </th>
            <td mat-cell *matCellDef="let element"><span (click)="ClickLink(element.Id)">{{element.TitleTh}}</span></td>
          </ng-container>

          <!-- Weight Column -->
          <ng-container matColumnDef="DateAdd">
            <th mat-header-cell *matHeaderCellDef>Publish Date </th>
            <td mat-cell *matCellDef="let element"> {{element.DateAdd}} </td>
          </ng-container>


          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>

      </div>



    </ng-container>
    <ng-template #Boxlist>
      <div class="row">
        <ng-container *ngFor="let data of datafiltering">
          <div class="col-4" style="padding: 10px;">
            <mat-card class="example-card">
              <div class="row text-center">
                <div class="col">
                  <img mat-card-image src="{{pathimage}}{{data.ImageCover}}" style="max-height: 150px; width: auto; max-width: 150px;" (click)="ClickLink(data.Id)">
                </div>
              </div>
              <mat-card-content>
                <p (click)="ClickLink(data.Id)">{{data.TitleTh}}</p>
              </mat-card-content>
              <mat-card-actions>
                {{data.DateAdd}}
              </mat-card-actions>
            </mat-card>
          </div>
        </ng-container>
      </div>
    </ng-template>
    <div class="row">
      <div class="col-12">
        <mat-paginator [pageSizeOptions]="[9, 25, 50]" showFirstLastButtons (page)="onPaginateChange($event)" ></mat-paginator>
      </div>
    </div>
  </div>

</div>

