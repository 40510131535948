<h1 mat-dialog-title class="">บริหารจัดการข้อมูล สถานะ วัตถุ</h1>
  <div mat-dialog-content>

    <ng-container *ngIf="btnadd">
      <button class="btn btn-success" (click)="ClickAdd()"> เพิ่มข้อมูล สถานะของวัตถุ</button>
    </ng-container>
    <ng-container *ngIf="displayfrmdel">
      <h1> ยืนยันการลบข้อมูล สถานะ ของ วัตถุ </h1>
      <h1>ชื่อสถานะ : {{FormItemstatus.value.StatusName}} </h1>
    </ng-container>
    <ng-container *ngIf="displayfrm">

      <form [formGroup]="FormItemstatus" class="manageitem-form">
        <div class="row">
          <div class="col-12">

            <mat-form-field appearance="outline" class="manageitem-full-width">
              <mat-label>สถานะของวัตถุ</mat-label>
              <input matInput placeholder="" formControlName="StatusName">
              <mat-hint> </mat-hint>
            </mat-form-field>

          </div>
        </div>
      </form>
    </ng-container>




      <div class="col-12 text-center" style="background-color: #dddddd;">
        <ng-container *ngIf="btnsave">
          <button mat-button (click)="ClickSave()" style="background-color: #098e1b" class="mr-2">Save</button>
        </ng-container>
        <ng-container *ngIf="btncancel">
          <button mat-button (click)="ClickCancel()" style="background-color: #ffe959">Cancel</button>
        </ng-container>
      </div>

    <div class="row pt-2 pb-2 mt-2">
    <div class="col-12">
      <table matTableExporter mat-table [dataSource]="dataSource" >
        <ng-container matColumnDef="Id">
          <th mat-header-cell *matHeaderCellDef>ลำดับที่</th>
          <td mat-cell *matCellDef="let element">
            {{dataSource.filteredData.indexOf(element)+1}}
          </td>
        </ng-container>
        <ng-container matColumnDef="Title">
          <th mat-header-cell *matHeaderCellDef>สถานะ</th>
          <td mat-cell *matCellDef="let element">
            {{element.StatusName}}
          </td>
        </ng-container>
        <ng-container matColumnDef="editdelete" style="max-width: 70px;">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="text-right">
            <!-- <i class="fas fa-search fa-2x" (click)="openDialogDetail(element.Id)"></i>   -->
            <i class="fas fa-edit fa-2x text-primary" (click)="ClickMangeData('Edit', element.Id, element.StatusName)"></i>
            <i class="fas fa-eraser fa-2x text-danger" (click)="ClickMangeData('Del', element.Id, element.StatusName)"></i>
          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
      <div class="row mt-2">
        <div class="col-12">
          <mat-paginator [pageSizeOptions]="[10,25,50]"></mat-paginator>
        </div>
      </div>
    </div>
  </div>
  </div>
<mat-dialog-actions align="center">
  <span class="btn btn-warning" (click)="onNoClick()">Close</span>
</mat-dialog-actions>




