import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ApiwebService} from '../../../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {UploadService} from '../../../../upload.service';

@Component({
  selector: 'app-frmcontent',
  templateUrl: './frmcontent.component.html',
  styleUrls: ['./frmcontent.component.scss']
})

export class FrmcontentComponent implements OnInit {


  // ข้อมูลพื้นฐานเว็บไซต์
  public dataUser = JSON.parse(localStorage.getItem('datauser'));

  public SiteAddress = this.apiweb.WebsiteAddress;
  public EmailCMU = this.apiweb.dataUser.EmailCMU;
  public Faculty = this.apiweb.dataUser.Faculty;

  public settimeshow = false;
  public contenttype = 'contents' ;

  // ฟอร์มกรอกข้อมูล
  public FormAdd: FormGroup;
  public txtHeaderType;
  public txtFrmType;

  public ckeditor4config = this.apiweb.ConfigEditor4(this.apiweb.CkuploadApiAddress , this.contenttype);
  public CkeditorApiAddress = this.apiweb.CkeditorApiAddress;

/////////////////////////////////////////////////////////////////////

  // กำหนดข้อมูลการบันทึกข้อมูลลงฐานข้อมูล
  @ViewChild('fileUpload') fileUpload: ElementRef; files  = [];
  public Status;


  // ตั้งค่าเช็คบ๊อค
  public theCheckbox = false;
  public StatusBtn: boolean;
  // public allComplete = false;


  // ตั้งค่าอัพโหลด
  public ServerUploadURL ;
  public ServerUploadCKEDITORURL ;
  public FileURL;
  public yourFile: File;

  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute, public uploadService: UploadService) { }

  ngOnInit(): void {

    // ตั้งค่าอัพโหลดไฟล์
    this.ServerUploadURL = this.apiweb.UploadApiAddress + '?path=' + this.contenttype ;
    this.ServerUploadCKEDITORURL = this.apiweb.CkuploadApiAddress + '?path=' + this.contenttype ;
    // ตำแหน่งที่ตั้งไฟล์
    this.FileURL = this.SiteAddress + 'api/assets/' + this.contenttype + '/' ;

    this.txtHeaderType = 'เนื้อหาภายในเว็บไซต์';
    this.FormAdd = this.formBuilder.group({
      Id: '',
      Faculty: this.Faculty,
      TitleTh: '',
      TitleEn: '',
      ContentTh: '',
      ContentEn: '',
      ImageSSTh: '',
      ImageSSEn: '',
      ExternalLink: '',
      DatePublish: '',
      DateUnpublish: '',
      UserAdd: this.apiweb.dataUser.EmailCMU,
      UserModify:  this.apiweb.dataUser.EmailCMU,
      StatusPublish: '',
      Status: '',
      Action: ''
    });


    this.route.params.subscribe(params => {
      // console.log(params);
      if (params.Action === 'Add'){
        this.txtFrmType = 'เพิ่ม';
        this.FormAdd.patchValue({
          Action: 'Add'
        });
        setTimeout(() => {
          this.CreateContent();
        }, 2000);
      }
      if (params.Action === 'Edit'){
        this.txtFrmType = 'แก้ไข';
        this.EditContent(params.Id);
      }
    });
  }


// สร้างเนื้อหาก่อนเพื่อเตรียมเพิ่มเนื้อหา
  CreateContent(): void {
    this.apiweb.CreateNewContent().subscribe( (dataresponse: any) => {
      // console.log(dataresponse[0]);
      if (dataresponse.data === true){
        const data = dataresponse.data[0];
        this.FormAdd.patchValue({
          Id: data.Id,
          Faculty: data.Faculty,
          TitleTh: data.TitleTh,
          TitleEn: data.TitleEn,
          ContentTh: data.ContentTh,
          ContentEn: data.ContentEn,
          ImageSSTh: data.ImageSSTh,
          ImageSSEn: data.ImageSSEn,
          ExternalLink: data.ExternalLink,
          DatePublish: data.DatePublish,
          DateUnpublish: data.DateUnpublish,
          UserAdd:  this.apiweb.dataUser.EmailCMU,
          UserModify:  this.apiweb.dataUser.EmailCMU,
          StatusPublish: data.StatusPublish,
          Action: 'Add'
        });
      }

    });
  }

  EditContent(ContentId): void {
    this.apiweb.CallContentFacultyId(ContentId).subscribe( (dataresponse: any) => {
      if (dataresponse.status === true){
        const data = dataresponse.data[0];
        this.FormAdd.patchValue({
          Id: data.Id,
          Faculty: data.Faculty,
          TitleTh: data.TitleTh,
          TitleEn: data.TitleEn,
          ContentTh: data.ContentTh,
          ContentEn: data.ContentEn,
          ImageSSTh: data.ImageSSTh,
          ImageSSEn: data.ImageSSEn,
          ExternalLink: data.ExternalLink,
          DatePublish: data.DatePublish,
          DateUnpublish: data.DateUnpublish,
          UserAdd:  this.apiweb.dataUser.EmailCMU,
          UserModify:  this.apiweb.dataUser.EmailCMU,
          StatusPublish: data.StatusPublish,
          Action: 'Edit'
        });
        if (this.FormAdd.value.StatusPublish === '1' || this.FormAdd.value.StatusPublish === true  ){
          this.StatusBtn = true;
          this.theCheckbox = true;
        }else{
          this.StatusBtn = false;
          this.theCheckbox = false;
        }
      }
    });
  }

  toggleVisibility(e): void  {
    this.StatusBtn = e.target.checked;
  }

  ClickSave(): void {
    // console.log(this.FormAdd.value);
    this.apiweb.CommandManageContents(this.FormAdd.value).subscribe((data: any[]) => {
      let linkback;
      linkback = 'dashboard/website/' + this.contenttype;
      this.router.navigate([linkback]);
    });
  }
  ClickCancel(): void {
    let linkback;
    linkback = 'dashboard/website/' + this.contenttype;
    this.router.navigate([linkback]);
  }


















  // ตรวจสอบสถานะเผยแพร่ข้อมูล
  CheckSwitchTrueFalse(Dataval): any {
    if (Dataval === '1'){
      return true;
    }
    if (Dataval === 0 || Dataval === ''  || Dataval === null ){
      return false;
    }
  }

  checkValue(event: any): any {
    // console.log(event);
    // console.log(this.FormAdd.value);
  }
/*
  SwitchStatusPublishChange(){
    if (this.FormAdd.value.StatusPublish === '0'){
      this.FormAdd.patchValue({
        StatusPublish: '1'
      });
    }else{
      this.FormAdd.patchValue({
        StatusPublish: '0'
      });
    }
  }

 */


  //////////////////////////////////////////////////////////////////////////
  uploadFile(file): any  {
    const formData = new FormData();
    formData.append('file', file.data);
    formData.append('path', this.contenttype);
    file.inProgress = true;
    this.uploadService.upload(formData, this.ServerUploadURL).pipe(
      map(event => {
        // console.log(event);
        this.FormAdd.patchValue({
          ImageSSTh: event.NewName
        });
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
      if (typeof (event) === 'object') {
        // console.log(event.body);
      }

    });

  }
  uploadFiles(): any  {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach(file => {
      this.uploadFile(file);
    });
    // console.log(this.files);
  }
  onClick(): any  {
    const fileUpload = this.fileUpload.nativeElement; fileUpload.onchange = () => {
      this.files  = [];
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0});
      }
      this.uploadFiles();
    };
    fileUpload.click();

  }
  getFile(fileInput): any {
    this.yourFile = fileInput.target.files[0];
    // console.log(this.yourFile);
  }
  OnClickDeleteFile(imagessslang): any {
    // console.log('delete files select');
    // console.log(this.FormAdd.value);
    if ( imagessslang === 'imagessth'){
      this.FormAdd.patchValue({
        ImageSSTh: ''
      });
    }
    if ( imagessslang === 'imagessth'){
      this.FormAdd.patchValue({
        ImageSSEn: ''
      });
    }


  }

  togglesettime(): any {
    this.settimeshow = !this.settimeshow;
  }
}
