import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiwebService} from '../../../apiweb.service';
import {DatePipe} from '@angular/common';
import {DomSanitizer} from '@angular/platform-browser';
declare var jQuery: any;

@Component({
  selector: 'app-blogofsearchv1',
  templateUrl: './blogofsearchv1.component.html',
  styleUrls: ['./blogofsearchv1.component.scss']
})
export class Blogofsearchv1Component implements OnInit {

  public blogwordcat = true;
  public blogopac = false;
  public blogeds = false;

  public FrmOpac: FormGroup;
  public FrmEds: FormGroup;

  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router, private datePipe: DatePipe, private route: ActivatedRoute, private sanitizer: DomSanitizer) {
    this.functionEDS();
  }

  ngOnInit(): void {
    this.functionEDS();
    this.FrmOpac = this.formBuilder.group({
      searchtype: 'ชื่อเรื่อง',
      searcharg: '',
      SUBMIT: 'Search'
    });
    this.FrmEds = this.formBuilder.group({
      bquery: '',
      soption: 'Keyword',
      action: '',
      direct: true,
      scope: 'site',
      site: 'eds-live',
      authtype: 'ip%2Cguest',
      custid: 's5150876',
      groupid: 'main',
      profile: 'eds',
    defaultdb: ''
    });
  }
  onclickblogwordcat(){
      this.blogwordcat = true;
      this.blogopac = false;
      this.blogeds = false;
  }
  onclickblogopac(){
      this.blogwordcat = false;
      this.blogopac = true;
      this.blogeds = false;
  }
  onclickblogeds(){
      this.blogwordcat = false;
      this.blogopac = false;
      this.blogeds = true;
  }
  SearchOpac(){
    // console.log(this.FrmOpac.value);
    let searchtype: string;
    let linkredirect: string;
    if (this.FrmOpac.value.searchtype === 'ชื่อเรื่อง'){
      searchtype = 't';
    }
    if (this.FrmOpac.value.searchtype === 'ชื่อวารสาร'){
      searchtype = 's';
    }
    if (this.FrmOpac.value.searchtype === 'หัวเรื่อง'){
      searchtype = 'd';
    }
    if (this.FrmOpac.value.searchtype === 'KEYWORD'){
      searchtype = 'Y';
    }
    if (this.FrmOpac.value.searchtype === 'หัวเรื่องทางการแพทย์'){
      searchtype = 'j';
    }
    if (this.FrmOpac.value.searchtype === 'เลขเรียกหนังสือ'){
      searchtype = 'm';
    }
    if (this.FrmOpac.value.searchtype === 'LC CALL NO'){
      searchtype = 'c';
    }
    if (this.FrmOpac.value.searchtype === 'NLM CALL NO'){
      searchtype = 'l';
    }
    if (this.FrmOpac.value.searchtype === 'เลขมาตรฐานสากล'){
      searchtype = 'i';
    }
    if (this.FrmOpac.value.searchtype === 'PROF/TA'){
      searchtype = 'p';
    }
    if (this.FrmOpac.value.searchtype === 'COURSE'){
      searchtype = 'r';
    }
    linkredirect = 'http://search.lib.cmu.ac.th/search~S0/?searchtype=' + searchtype + '&searcharg=' + this.FrmOpac.value.searcharg;
    this.router.navigate([]).then(result => {  window.open(linkredirect, '_blank'); });
  }

  SearchEDS(){
    // console.log(this.FrmEds.value);
    let soption;
    let linkredirect: string;
    const insoption = this.FrmEds.value.soption;
    // console.log(insoption);
    if ( insoption === 'Keyword'){
      soption = '1';
      linkredirect = 'http://search.ebscohost.com/login.aspx?bquery=' + this.FrmEds.value.bquery + '&option=1&action=&direct=true&scope=site&site=eds-live&authtype=ip%2Cguest&custid=s5150876&groupid=main&profile=eds&defaultdb=';
    }else if (insoption === 'Title'){
      soption = '2';
      linkredirect = 'http://search.ebscohost.com/login.aspx?bquery=Ti+' + this.FrmEds.value.bquery + '&option=2&action=&direct=true&scope=site&site=eds-live&authtype=ip%2Cguest&custid=s5150876&groupid=main&profile=eds&defaultdb=';
    }else if (insoption === 'Author'){
      soption = '3';
      linkredirect = 'http://search.ebscohost.com/login.aspx?bquery=AU+' + this.FrmEds.value.bquery + '&option=3&action=&direct=true&scope=site&site=eds-live&authtype=ip%2Cguest&custid=s5150876&groupid=main&profile=eds&defaultdb=';
    }
    // console.log(this.FrmEds.value.soption);
    // http://search.ebscohost.com/login.aspx? bquery=test&option=1&action=&direct=true&scope=site&site=eds-live&authtype=ip%2Cguest&custid=s5150876&groupid=main&profile=eds&defaultdb=
    // http://search.ebscohost.com/login.aspx?%20bquery=&option=undefined&action=&direct=true&scope=site&site=eds-live&authtype=ip%2Cguest&custid=s5150876&groupid=main&profile=eds&defaultdb=
    // http://eds.b.ebscohost.com/eds/Search/PerformSearch?sid=f28c3c64-7747-4ad3-aa9d-f800e6cc3975@pdc-v-sessmgr02&vid=2

    // console.log(linkredirect);
    // this.router.navigate([]).then(result => {  window.open(linkredirect, '_blank'); });
  }

  transform(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }




  functionEDS(){
    (
      // tslint:disable-next-line:only-arrow-functions
      function($) {
        function getURL()
        {
          // @ts-ignore
          if (document.getElementById('TI').checked) {
            // Search Title
            // @ts-ignore
            const searchStr = document.getElementById('searchterm').value;
            const Title = 'TI (';
            const closetag = ')';
            const searchFull1 = Title.concat(searchStr);
            const searchFull = searchFull1.concat(closetag);
            // @ts-ignore
            document.getElementById('searchterm').value = searchFull;
          }
          else {
            // @ts-ignore
            if (document.getElementById('AU').checked) {
                        // Search Author
                        // @ts-ignore
              const searchStr = document.getElementById('searchterm').value;
              const Author = 'AU (';
              const closetag = ')';
              const searchFull1 = Author.concat(searchStr);
              const searchFull = searchFull1.concat(closetag);
              // @ts-ignore
              document.getElementById('searchterm').value = searchFull;
                      }
          }


          // @ts-ignore
          // tslint:disable-next-line:triple-equals
          if (document.getElementById('defaultdb').value == 'JN') {
            // Search Title
            // @ts-ignore
            const searchStr = document.getElementById('searchterm').value;
            const Title = 'PT Academic Journal AND (';
            const closetag = ')';
            const searchFull1 = Title.concat(searchStr);
            const searchFull = searchFull1.concat(closetag);
            // @ts-ignore
            document.getElementById('searchterm').value = searchFull;
          }

        }
    })(jQuery);


  }




}
