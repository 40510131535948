<h1 mat-dialog-title class="fontAdelleSanTHA-Regular">ยืนยันการ ลบข้อมูล</h1>
<div mat-dialog-content>

  <H2> การลบข้อมูล โปรดใช้ความระมัดระวังในการลบข้อมูล </H2>
  <span class="fontAdelleSanTHA-Regular"> กดปุ่ม ยืนยันอีกครั้ง เพื่อทำการลบข้อมูล </span>

</div>
<mat-dialog-actions align="end" class="fontAdelleSanTHA-Regular">
  <span class="btn btn-success mr-3" (click)="onOkClick()" ><i class="fas fa-plus-circle" aria-hidden="true">ยืนยัน </i> </span>
  <span class="btn btn-warning" (click)="onNoClick()">ยกเลิก</span>
</mat-dialog-actions>



