<ng-container *ngIf="StatusRegister ; else Registered">
  <div class="row">
    <div class="container">
      <h2>ยังไม่ได้สมัครลงทะเบียนเข้าใช้งาน</h2>
      <span>ข้อกำหนด และเงื่อนไข ในการขอใช้บริการ </span>

      <div class="row text-center mt-3">
        <div class="col-12">
          <button class="btn btn-success mr-3" (click)="ClickRegister()"> ยอมรับ </button>
          <button class="btn btn-warning" (click)="Clicklogout()"> ยกเลิก </button>
        </div>
      </div>

    </div>
  </div>

</ng-container>
<ng-template #Registered>
  <div class="row">
    <div class="container">
      <div class="row">
        <mat-card>
          <mat-card-content>
            <div class="col-12">
              <ngx-barcode6 [bc-format]= barcodetype [bc-value]= FormUser.value.CMUitaccount [bc-display-value]="true" style="width: 100%" ></ngx-barcode6>
            </div>
            <div class="col-12">
              <!--
                                {{FormUser.value.Id}}
                                {{FormUser.value.CMUitaccount}}
                                {{FormUser.value.EmailCMU}}
              -->
              <strong>รหัสนักศึกษา : {{FormUser.value.StudentId}}</strong><br>
              <strong>ชื่อ-นามสกุล : {{FormUser.value.Name}}</strong><br>
              <strong>คณะ : {{FormUser.value.Faculty}} </strong><br>
              <strong>วันที่เริ่มใช้บริการ : {{FormUser.value.DateAdd}} </strong><br>
              <strong>ค่าปรับค้างชำระ : {{FormUser.value.PriceOverdue}}</strong><br>
            </div>
          </mat-card-content>
        </mat-card>
      </div>

      <div class="row mt-3 mb-2">
        <div class="col-6">
          <h3 class="text-info">ข้อมูลวัสดุที่ยืม</h3>
        </div>
        <div class="col-6 text-right">
          <i class="btn btn-info fas fa-retweet fa-2x" (click)="ClickRefreshData()">Refresh</i>
        </div>
      </div>


      <div class="row pt-2 pb-2" style="background-color: #9c3328;">
        <div class="col-12">
          <table matTableExporter mat-table [dataSource]="dataSource" >
            <ng-container matColumnDef="Id">
              <th mat-header-cell *matHeaderCellDef>ลำดับที่</th>
              <td mat-cell *matCellDef="let element">
                {{dataSource.filteredData.indexOf(element)+1}}
              </td>
            </ng-container>
            <!--
            <ng-container matColumnDef="CodeItem">
              <th mat-header-cell *matHeaderCellDef>Barcode</th>
              <td mat-cell *matCellDef="let element">
                {{element.CodeItem}}
              </td>
            </ng-container>
            -->
            <ng-container matColumnDef="Title">
              <th mat-header-cell *matHeaderCellDef>ชื่อวัสดุ</th>
              <td mat-cell *matCellDef="let element">
                {{element.ItemName}}
              </td>
            </ng-container>

            <ng-container matColumnDef="overduedate" style="max-width: 50px;">
              <th mat-header-cell *matHeaderCellDef>กำหนดส่งคืน</th>
              <td mat-cell *matCellDef="let element" class="text-left">
                {{element.DateExpire}}

              </td>
            </ng-container>
            <ng-container matColumnDef="Dateoverduedate" style="max-width: 50px;">
              <th mat-header-cell *matHeaderCellDef>จำนวนวันที่เกินกำหนดส่งคืน</th>
              <td mat-cell *matCellDef="let element" class="text-left">
                {{CallDateOverdue(element.DateExpire)}}
              </td>
            </ng-container>

            <ng-container matColumnDef="Priceoverduedate" style="max-width: 50px;">
              <th mat-header-cell *matHeaderCellDef>ค่าปรับ</th>
              <td mat-cell *matCellDef="let element" class="text-left">
                {{CallPriceOverdue(CallDateOverdue(element.DateExpire),element.PricesOverdue)}}
              </td>
            </ng-container>

            <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
            <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
          </table>
          <div class="row mt-2">
            <div class="col-12">
              <mat-paginator [pageSizeOptions]="[50,100,200,250]"></mat-paginator>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</ng-template>
