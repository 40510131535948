<div class="row mt-2 p-2 dashboardwebsiteheaderform" style="background-color: #f1f1f1;">
  <div class="col-9">
    <h2 class="fontAdelleSanTHA-Regular">บริหารจัดการข้อมูล ป้ายประกาศ</h2>
  </div>
  <div class="col-3">
    <div class="row text-right">
      <div class="col-12">
        <h2 class="text-success"><i class="fas fa-plus" (click)="ClickManageData('Add','')">เพิ่มข้อมูล</i></h2>
      </div>
    </div>
  </div>
</div>

<div class="row">
  <div class="container">
    <div class="row mt-2" style="background-color: #cccccc;">
      <div class="col-12">
        <div class="row pt-2 pb-2" style="background-color: #888888;">
          <div class="col-12">
            <table matTableExporter mat-table [dataSource]="dataSource" >
              <ng-container matColumnDef="Id">
                <th mat-header-cell *matHeaderCellDef>ลำดับที่</th>
                <td mat-cell *matCellDef="let element">
                  {{dataSource.filteredData.indexOf(element)+1}}
                </td>
              </ng-container>
              <ng-container matColumnDef="Title">
                <th mat-header-cell *matHeaderCellDef>หัวข้อ</th>
                <td mat-cell *matCellDef="let element">
                  {{element.TitleTh}}
                </td>
              </ng-container>
              <ng-container matColumnDef="StatusPublish">
                <th mat-header-cell *matHeaderCellDef>สถานะการเผยแพร่</th>
                <td mat-cell *matCellDef="let element">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="status{{dataSource.filteredData.indexOf(element)+1}}" [checked]="CheckSwitchTrueFalse(element.StatusPublish)"
                           (change)="checkCheckBoxvalue(element.Id,$event)">
                    <label class="custom-control-label" for="status{{dataSource.filteredData.indexOf(element)+1}}">&nbsp;</label>
                  </div>
                </td>
              </ng-container>

              <ng-container matColumnDef="edit" style="max-width: 70px;">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element">
                  <i class="fas fa-edit fa-2x text-primary" (click)="ClickManageData('Edit', element.Id)"></i>
                </td>
              </ng-container>
              <ng-container matColumnDef="delete" style="max-width: 70px;">
                <th mat-header-cell *matHeaderCellDef ></th>
                <td mat-cell *matCellDef="let element">
                  <i class="fas fa-eraser fa-2x text-danger" (click)="openDialogDel('Del', element.Id)"></i>
                </td>
              </ng-container>
              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div class="row mt-2">
              <div class="col-12">
                <mat-paginator [pageSizeOptions]="[50,100,200,250]"></mat-paginator>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>

  </div>
</div>
