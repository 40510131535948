import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layoutdashboard',
  templateUrl: './layoutdashboard.component.html',
  styleUrls: ['./layoutdashboard.component.scss']
})
export class LayoutdashboardComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
