import { Component, OnInit } from '@angular/core';
import {ApiwebService} from '../../../apiweb.service';

@Component({
  selector: 'app-blockotherlinks',
  templateUrl: './blockotherlinks.component.html',
  styleUrls: ['./blockotherlinks.component.scss']
})
export class BlockotherlinksComponent implements OnInit {
  public DataIconServices;
  public ContentTh = false;
  public totalIcon;
  public iconmore = false;
  public addressimage;
  constructor(public apiweb: ApiwebService) { }

  ngOnInit(): void {
    this.CallIconServices();
    this.addressimage = this.apiweb.ApiAddress + 'assets/otherlinks/';
    if (localStorage.getItem('lang') === 'En'){
      this.ContentTh = false;
    } else {
      localStorage.setItem('lang', 'Th');
      this.ContentTh = true;
    }
  }

  CallIconServices(){
    this.apiweb.CallOtherLinks(localStorage.getItem('SiteFaculty'), '' ).subscribe( (data: any[]) => {
      this.DataIconServices = data;
      this.totalIcon = data[0].totalicon;
      if (data[0].totalicon > 10){
        this.iconmore = true;
      }
      // console.log(data);
    });
  }
}
