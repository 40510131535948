<div class="row">
  <div class="container">
    <div class="col-12">
      <i class="fas fa-step-backward fa-2x text-warning" (click)="ClickBack()">Back</i>
    </div>
  </div>
  <div class="container">
    <ng-container *ngIf="ContentTh;else ContentEn">
      <div class="card">
        <div class="container-fliud">
          <div class="wrapper row">
            <div class="preview col-md-6 text-center">
              <div class="preview-pic">
                <div class=""><img src="{{pathimage}}{{DataItem.ImageCover}}"/>
                </div>
              </div>
            </div>

            <div class="details col-md-6">
              <ng-container *ngIf="DataItem.TitleTh; else NoTitleTh">
                <h3 class="product-title">{{DataItem.TitleTh}}</h3>
              </ng-container>
              <ng-template #NoTitleTh>
                <h3 class="product-title">{{DataItem.TitleEn}}</h3>
              </ng-template>
              <p>เลขเรียกหนังสือ : {{DataItem.CallNumber}}</p>
              <p>ISBN : {{DataItem.ISBN}}</p>
              <p>Links : {{DataItem.Links}}</p>
              <p>ผู้แต่ง : {{DataItem.AuthorEditor}}</p>
              <p>วันที่เผยแพร่ : {{DataItem.BookPublishDate}}</p>
              <ng-container *ngIf="DataItem.DetailTh; else NoDetailTh">
                <p>รายละเอียด : {{DataItem.DetailTh}}</p>
              </ng-container>
              <ng-template #NoDetailTh>
                <p>รายละเอียด : {{DataItem.DetailEn}}</p>
              </ng-template>

            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-template #ContentEn>
      <div class="card">
          <div class="container-fliud">
            <div class="wrapper row">
              <div class="preview col-md-6 text-center">
                <div class="preview-pic">
                  <div id="{{DataItem.ImageCover}}"><img src="{{pathimage}}{{DataItem.ImageCover}}"/>
                  </div>
                </div>
              </div>

              <div class="details col-md-6">
                <ng-container *ngIf="DataItem.TitleEn; else Notitleen">
                  <h3 class="product-title">{{DataItem.TitleEn}}</h3>
                </ng-container>
                <ng-template #Notitleen>
                  <h3 class="product-title">{{DataItem.TitleTh}}</h3>
                </ng-template>
                <p>CallNumber : {{DataItem.CallNumber}}</p>
                <p>ISBN : {{DataItem.ISBN}}</p>
                <p>Links : {{DataItem.Links}}</p>
                <p>Author : {{DataItem.AuthorEditor}}</p>
                <p>Publish Date : {{DataItem.BookPublishDate}}</p>
                <ng-container *ngIf="DataItem.DetailEn; else NoDetailEn">
                  <p>Detail : {{DataItem.DetailEn}}</p>
                </ng-container>
                <ng-template #NoDetailEn>
                  <p>รายละเอียด ภาษาไทย : {{DataItem.DetailTh}}</p>
                </ng-template>

              </div>
            </div>
          </div>
        </div>

    </ng-template>








  </div>
  <div class="container mt-2">

  </div>
</div>
