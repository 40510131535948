import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private statelogin;
  constructor(private http: HttpClient, private router: Router) { }

  // login normal
  public login(username, password): boolean {
    if ( username === '' && password === '') {
      localStorage.setItem('acess_token', username);
      return true;
    }
    return false;
  }

  public getUser(): any {
    return localStorage.getItem('access_token');
  }

  public isLoggedIn(): boolean {
    return this.getUser() !== null;
  }

  sendToken(token: string) {
    localStorage.setItem('access_token', token);
  }

  isLoggednIn() {
    return this.getToken() !== null;
  }

  getToken() {
    return localStorage.getItem('access_token');
  }

  logout() {
    localStorage.clear();
    localStorage.removeItem('access_token');
    // this.router.navigate(['login']);
  }

  public get loggedIn(): boolean {
    return (localStorage.getItem('access_token') !== null);
  }



}
