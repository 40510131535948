
<nav class="navbar navbar-expand-lg navbar-custom navigationtopeng">
  <!--
    <a class="navbar-brand" href="#">Navbar</a>
  -->
  <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div class="collapse navbar-collapse" id="navbarNavDropdown">
    <ul class="navbar-nav mr-auto">
      <!--
            <li class="nav-item active">
              <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Features</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Pricing</a>
            </li>
            <li class="nav-item dropdown">
              <a class="nav-link dropdown-toggle" href="#" id="navbarDropdownMenuLink" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                Dropdown link
              </a>
              <div class="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <a class="dropdown-item" href="#">Action</a>
                <a class="dropdown-item" href="#">Another action</a>
                <a class="dropdown-item" href="#">Something else here</a>
              </div>
            </li>
            -->
      <ng-container *ngFor="let datamenu of DataMenuNavigation">
        <ng-container *ngIf="datamenu.SubMenu === '1'  ; else nosubmenu">
          <li class="nav-item dropdown">
            <a class="nav-link dropdown-toggle" href="#" [id]="'navbarDropdown'+datamenu.Id" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{datamenu.MenuNameTh}}</a>
            <div class="dropdown-menu multi-level" [attr.aria-labelledby]="'navbarDropdown'+datamenu.Id">
              <ng-container *ngFor="let datasubmenu1 of datamenu.submemulist">
                <ng-container *ngIf="datasubmenu1.SubMenu === '1' ; else nosubmenu1">
                  <a class="dropdown-toggle dropdown-item abc" href="#" [id]="'navbarDropdownx'+datasubmenu1.Id" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{datasubmenu1.MenuNameTh}}</a>
                  <div class="dropdown-menu  dropdown-menu-right" [attr.aria-labelledby]="'navbarDropdownx'+datasubmenu1.Id">
                    <ng-container *ngFor="let datasubmenu2 of datasubmenu1.submemulist">
                      <a class="dropdown-item" [href]="datasubmenu2.Links" [id]="'navbarDropdown'+datasubmenu2.Id" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">{{datasubmenu2.MenuNameTh}}</a>
                    </ng-container>
                  </div>
                </ng-container>
                <ng-template #nosubmenu1>
                  <ng-container *ngIf="datasubmenu1.StatusLinksNewPage==1;else nonextpage2">
                    <a class="dropdown-item" [href]="datasubmenu1.Links" target="_blank" style="color: black;">{{datasubmenu1.MenuNameTh}}</a>
                  </ng-container>
                  <ng-template #nonextpage2>
                    <a class="dropdown-item" [href]="datasubmenu1.Links"  style="color: black;">{{datasubmenu1.MenuNameTh}}</a>
                  </ng-template>

                </ng-template>

              </ng-container>

            </div>


          </li>
        </ng-container>
        <ng-template #nosubmenu>
          <ng-container *ngIf="datamenu.StatusLinksNewPage==1;else nonextpage">
            <li class="nav-item"><a class="nav-link" [href]="datamenu.Links" target="_blank">{{datamenu.MenuNameTh}}</a></li>
          </ng-container>
          <ng-template #nonextpage>
            <li class="nav-item"><a class="nav-link" [href]="datamenu.Links">{{datamenu.MenuNameTh}}</a></li>
          </ng-template>

        </ng-template>
      </ng-container>



    </ul>
    <span class="navbar-text">
      <app-switchlang></app-switchlang>
    </span>

  </div>
</nav>
<!--
<nav class="navbar navbar-dark navbar-expand bg-primary justify-content-between">
  <div class="container-fluid">
    <ul class="navbar-nav">
      <li class="nav-item text-center">
        <a href="#" class="nav-link" data-toggle="modal" data-target="#"><span class="fa fa-map"></span><span class="d-none d-sm-inline d-xl-block px-1">Map</span></a>
      </li>
      <li class="nav-item dropdown text-center">
        <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><span class="fa fa-list"></span><span class="d-xl-block d-none"></span><span class="d-none d-sm-inline px-1">Dropdown</span> </a>
        <div class="dropdown-menu" aria-labelledby="navbarDropdown">
          <a class="dropdown-item" href="#">Action</a>
          <a class="dropdown-item" href="#">Another action</a>
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" href="#">Something else here</a>
        </div>
      </li>
    </ul>
    <ul class="nav navbar-nav">
      <li class="nav-item text-center" id="signup-btn">
        <a href="#" class="nav-link" data-toggle="modal" data-target="#"><span class="fa fa-user"></span><span class="d-none d-sm-inline d-xl-block px-1">Sign Up</span></a>
      </li>
      <li class="nav-item text-center" id="login-btn">
        <a href="#" class="nav-link" data-toggle="modal" data-target="#"><span class="fa fa-sign-in"></span><span class="d-none d-sm-inline d-xl-block px-1">Log In</span></a>
      </li>
    </ul>
  </div>
</nav>
-->









