<div class="row bg_dashboardcirculationbread p-1">
  <div class="col-12"><h2>บริหารจัดการข้อมูล วัสดุ</h2></div>
</div>
<div class="row p-3">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <div fxLayout fxLayoutAlign="center center">
          <mat-form-field fxFlex="40%">
            <input matInput type="text" (keyup)="doFilter($event.target.value)" placeholder="ค้นหาวัสดุ">
          </mat-form-field>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-12">
        <div class="row">
          <div class="col-6"> ตารางข้อมูลวัสดุ </div>
          <div class="col-6 text-right">
            <i class="fas fa-plus text-success" (click)="ClickManageData('Add','')">เพิ่มข้อมูลวัสดุ</i>
          </div>
        </div>
      </div>
    <div class="col-12">
    <div class="row mt-2" style="background-color: #cccccc;">
      <div class="col-12">
        <div class="row pt-2 pb-2" style="background-color: #888888;">
          <div class="col-12">
            <table matTableExporter mat-table [dataSource]="dataSource" >
              <ng-container matColumnDef="Id">
                <th mat-header-cell *matHeaderCellDef>ลำดับที่</th>
                <td mat-cell *matCellDef="let element">
                  {{dataSource.filteredData.indexOf(element)+1}}
                </td>
              </ng-container>
              <!--
              -->
              <ng-container matColumnDef="CodeItem">
                <th mat-header-cell *matHeaderCellDef>Barcode</th>
                <td mat-cell *matCellDef="let element">
                  {{element.CodeItem}}
                  {{element.Id}}
                </td>
              </ng-container>
              <ng-container matColumnDef="Title">
                <th mat-header-cell *matHeaderCellDef>ชื่อวัสดุ</th>
                <td mat-cell *matCellDef="let element">
                  {{element.ItemName}}
                </td>
              </ng-container>

              <ng-container matColumnDef="editdelete" style="max-width: 70px;">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let element" class="text-right">
                  <i class="fas fa-search fa-2x" (click)="openDialogDetail(element.Id)"></i>
                  <i class="fas fa-edit fa-2x text-primary" (click)="ClickManageData('Edit', element.Id)"></i>
                  <i class="fas fa-eraser fa-2x text-danger" (click)="openDialogDel('Del', element.Id)"></i>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>
            <div class="row mt-2">
              <div class="col-12">
                <mat-paginator [pageSizeOptions]="[25,50,100,200,250]"></mat-paginator>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  </div>

    </div>
  </div>
</div>
