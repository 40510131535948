import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder} from '@angular/forms';
import {ApiwebService} from '../../../../apiweb.service';
import * as Chart from 'chart.js';
@Component({
  selector: 'app-circulationreportchartservices',
  templateUrl: './circulationreportchartservices.component.html',
  styleUrls: ['./circulationreportchartservices.component.scss']
})
export class CirculationreportchartservicesComponent implements OnInit {
  canvas: any;
  ctx: any;

  public DataUser;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;

  displayedColumns: string[] = ['No', 'StudentId', 'Name', 'Faculty', 'EmailCMU', 'itaccounttype_EN', 'DateAdd', 'Command'];
  dataSource = new MatTableDataSource();

  constructor(public formBuilder: FormBuilder, public apiweb: ApiwebService) { }
  ngOnInit(): void {
    // @ts-ignore
    this.DataUser = this.apiweb.CallAllUser().subscribe( (dataresponse: any) => {
      this.DataUser = dataresponse.data;
      this.dataSource.data = dataresponse.data;
      console.log(dataresponse.data);
    });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;


    this.canvas = document.getElementById('myChart');
    this.ctx = this.canvas.getContext('2d');
    const myChart = new Chart(this.ctx, {
      type: 'bar',
      data: {
        labels: ['USA', 'Spain', 'Italy', 'France', 'Germany', 'UK', 'Turkey', 'Iran', 'China', 'Russia', 'Brazil', 'Belgium', 'Canada', 'Netherlands', 'Switzerland', 'India', 'Portugal', 'Peru', 'Ireland', 'Sweden'],
        datasets: [{
          label: 'Total cases.',
          data: [886789, 213024, 189973, 158183, 153129, 138078, 101790, 87026, 82804, 62773, 50036, 42797, 42110, 35729, 28496, 23502, 22353, 20914, 17607, 16755],
          backgroundColor: ['red', , , , , , , , 'orange'],
          borderWidth: 1
        }]
      },
      options: {
        legend: {
          display: true
        },
        responsive: false,
        display: true
      }
    });


  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
