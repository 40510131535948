
<div class="row text-center" style="background-color: #aa1111;">
  <div class="col-12">
    <h2 class="text-white fontKanitRegular"> ระบบ บริหารจัดการข้อมูล ห้องสมุด</h2>
  </div>
</div>

<div class="row text-center" style="background-color: rgba(170,17,17,0.44);">
  <div class="col-12">

  </div>
</div>
