<div class="row">
  <div class="container">
    <div class="row mt-2 p-2" style="background-color: #888888;">
      <div class="col-12">
        <h2 class="">{{txtFrmType}} Trendy arts Books & New Books</h2>
      </div>
    </div>
    <form [formGroup]="FormAdd">
    <div class="row pt-2">
      <div class="col-12">
        <ul class="nav nav-tabs" id="myTab" role="tablist">
          <li class="nav-item">
            <a class="nav-link active" id="home-tab" data-toggle="tab" href="#thai" role="tab" aria-controls="thai" aria-selected="true">ภาษาไทย</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" id="profile-tab" data-toggle="tab" href="#eng" role="tab" aria-controls="eng" aria-selected="false">English</a>
          </li>
        </ul>
      </div>
        <div class="tab-content" id="myTabContent">
          <div class="tab-pane fade show active" id="thai" role="tabpanel" aria-labelledby="thai">
            <div class="row mt-2">
              <div class="col-12">
                <div class="form-group">
                  <label for="titleth"><strong>ชื่อเรื่องภาษาไทย</strong></label>
                  <input type="text" class="form-control" id="titleth" placeholder="ชื่อเรื่อง" formControlName="TitleTh">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <strong>รายละเอียด</strong>
                <ckeditor [editorUrl]="CkeditorApiAddress" [config]="ckeditor4config" formControlName="DetailTh"></ckeditor>
              </div>
            </div>
          </div>
          <div class="tab-pane fade" id="eng" role="tabpanel" aria-labelledby="eng">
            <div class="row">
              <div class="col-12">
                <div class="form-group">
                  <label for="titleen"><strong>ชื่อเรื่อง ภาษาอังกฤษ</strong></label>
                  <input type="text" class="form-control" id="titleen" placeholder="Title" formControlName="TitleEn">
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <label><strong>รายละเอียด ภาษาอังกฤษ</strong></label>
                <ckeditor [editorUrl]="CkeditorApiAddress" [config]="ckeditor4config" formControlName="DetailEn"></ckeditor>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2 border border-warning p-4" style="background-color: #f6eac8;">
        <div class="col-4 align-items-center p-2">
          <strong> รหัส คอลเลคชั่นบน มีเดียสโตร :</strong>
        </div>
        <div class="col-8">
          <input type="text" class="form-control" id="Code" placeholder="Code" formControlName="Code">
        </div>
      </div>
      <div class="row mt-2 border border-warning p-4">
        <div class="col-2 align-items-center p-2">
          <h4>ประเภท :</h4>
        </div>
        <div class="col-10">
          <mat-form-field appearance="fill" style="width: 100%;">
            <mat-label>Select</mat-label>
            <mat-select  formControlName="TypeContent">
              <mat-option value="TrendyArtBooks">Trendy Art Books</mat-option>
              <mat-option value="NewBooks">New Books</mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-12">
          <div class="form-group">
            <label for="Links"><strong>ลิงค์ไปยังหน้าเว็บไซต์</strong></label>
            <input type="text" class="form-control" id="Links" placeholder="Ex: http://google.com" formControlName="Links">
          </div>
          <span class="text-danger">* หมายเหตุ : เมื่อใส่ลิงค์ตรงนี้ จะเป็นการกำหนดลิงค์ไปยังเว็บไซต์ที่กำหนด</span>
        </div>
      </div>
      <div class="row mt-2 mb-2 p-3" >
        <div class="col-12 pt-2 pb-2" style="background-color: #e4e4e4">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input input-lg" id="StatusPublish" [checked]="CheckSwitchTrueFalse(FormAdd.value.StatusPublish)" formControlName="StatusPublish" >
            <label class="custom-control-label" for="StatusPublish">สถานะการเผยแพร่ข้อมูล</label>
          </div>
        </div>
      </div>
    </form>
    <div class="row mb-2">
      <div class="col-12 text-center">
        <span class="btn btn-primary mr-3" (click)="ClickSave()"><i class="far fa-save"></i> บันทึกข้อมูล</span>
        <span class="btn btn-warning" (click)="ClickCancel()"><i class="fas fa-share fa-rotate-180"></i> ยกเลิก</span>
      </div>
    </div>
  </div>
</div>
