<div class="row">
  <div class="col-12">
    <H1>บริหารจัดการข้อมูล บทความ (Articles)</H1>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <div class="row border border-info align-items-center">
      <div class="col-8">
        <mat-form-field>
          <mat-label>ค้นหาข้อมูล</mat-label>
          <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
        </mat-form-field>
      </div>
      <div class="col-4">
        <i class="fas fa-plus fa-2x" (click)="ClickManageData('Add','')">เพิ่มข้อมูล</i>
      </div>
    </div>
    <table mat-table [dataSource]="dataSource" >
      <ng-container matColumnDef="No">
        <th mat-header-cell *matHeaderCellDef> ลำดับที่ </th>
        <td mat-cell *matCellDef="let element">{{dataSource.filteredData.indexOf(element)+1}}</td>
      </ng-container>
      <ng-container matColumnDef="Record">
        <th mat-header-cell *matHeaderCellDef>Record</th>
        <td mat-cell *matCellDef="let element"> {{element.Record}} </td>
      </ng-container>
      <ng-container matColumnDef="Title">
        <th mat-header-cell *matHeaderCellDef>ชื่อบทความ</th>
        <td mat-cell *matCellDef="let element"> {{element.Title}} </td>
      </ng-container>
      <ng-container matColumnDef="Author">
        <th mat-header-cell *matHeaderCellDef>ผู้แต่ง</th>
        <td mat-cell *matCellDef="let element">
          {{element.Author}}
        </td>
      </ng-container>
      <ng-container matColumnDef="Year">
        <th mat-header-cell *matHeaderCellDef>Year</th>
        <td mat-cell *matCellDef="let element">
          {{element.Year}}
        </td>
      </ng-container>
      <ng-container matColumnDef="StatusPublish">
        <th mat-header-cell *matHeaderCellDef>สถานะการเผยแพร่</th>
        <td mat-cell *matCellDef="let element">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input" id="status{{dataSource.filteredData.indexOf(element)+1}}" [checked]="CheckSwitchTrueFalse(element.StatusPublish)"
                   (change)="checkCheckBoxvalue(element.Id,$event)">
            <label class="custom-control-label" for="status{{dataSource.filteredData.indexOf(element)+1}}">&nbsp;</label>
          </div>
        </td>
      </ng-container>
      <ng-container matColumnDef="Status">
        <th mat-header-cell *matHeaderCellDef>บริหารจัดการข้อมูล</th>
        <td mat-cell *matCellDef="let element">  <i class="fas fa-edit fa-2x text-primary" (click)="ClickManageData('Edit', element.Id)"></i>  <i class="fas fa-eraser fa-2x text-danger" (click)="openDialogDel(element.Id)"></i></td>
      </ng-container>
      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10,25 , 50, 100]" showFirstLastButtons></mat-paginator>
  </div>
</div>
