<footer class="custom-footer">
  <div class="row p-4 bg-image-full" [ngStyle]="StyleFooter">
    <div class="col-3 col-sm-12 col-md-6 col-lg-3 col-xl-3 p-2 ">
        <div [innerHTML]="BoxFooterTh1 | ckeditor: 'html'"></div>
    </div>
    <div class="col-3 col-sm-12 col-md-6 col-lg-3 col-xl-3 p-2 ">
          <div class="row first_block">
            <div class="col-12">
              <div [innerHTML]="BoxFooterTh2 | ckeditor: 'html'"></div>
            </div>
          </div>
    </div>
    <div class="col-3 col-sm-12 col-md-6 col-lg-3 col-xl-3 p-2 ">
          <div class="row second_block">
            <div class="col-12">
              <div [innerHTML]="BoxFooterTh3 | ckeditor: 'html'"></div>
            </div>
          </div>

        </div>
    <div class="col-3 col-sm-12 col-md-6 col-lg-3 col-xl-3 p-2 ">
      <div class="row third_block">
        <div class="col-12">
          <div [innerHTML]="BoxFooterTh4 | ckeditor: 'html'"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="row pt-2 bg-footer-eng">
    <div class="col-4">
      &nbsp;
    </div>
    <div class="col-4 text-center">
      <p class="copyright"><small class="text-white-60">© 2020. All Rights Reserved.</small></p>
    </div>
    <div class="col-4">
      <div class="row text-right pr-2">
        <div class="col-12">
          <app-iconlogin></app-iconlogin>
        </div>
      </div>
    </div>
  </div>
</footer>
