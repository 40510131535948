import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ApiwebService} from '../../apiweb.service';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-ebook',
  templateUrl: './ebook.component.html',
  styleUrls: ['./ebook.component.scss']
})
export class EbookComponent implements OnInit {
  public displaylang = localStorage.getItem('lang');
  public pathimage = this.apiweb.WebsiteAddressAssets + 'ebooks/';
  public ShowStyle = true;
  public displayedColumns: string[] = ['no', 'Image', 'TitleTh', 'Publication'];
  public dataSource = new MatTableDataSource();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  public datafiltering = [];
  public startslices;
  public endslices;
  public checktypes;

  constructor(public apiweb: ApiwebService , public dialog: MatDialog, private datePipe: DatePipe, private router: Router, private route: ActivatedRoute ) { }

  ngOnInit(): void {

    // console.log(this.displaylang);
    this.apiweb.CallEbook(this.apiweb.SiteFaculty, '0' ).subscribe( (dataresponse: any) => {
      const data = dataresponse.data;
      // console.log(data);
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;

      this.datafiltering = this.dataSource.data.slice(0 , 9);
    });
  }
  applyFilter(event: Event): any {
    const filterValue = (event.target as HTMLInputElement).value;
    // console.log(this.dataSource);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // console.log(this.dataSource);
    this.datafiltering = this.dataSource.filteredData.slice(this.startslices , this.endslices);

  }
  /*
    applyFilter(filterValue: string) {
      filterValue = filterValue.trim(); // Remove whitespace
      filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
      this.dataSource.filter = filterValue;

    }

  */
  ClickLink(Id): any{
    this.router.navigate(['ebooksdetail', { Id }]);
  }
  OnclickShowStylelist(): any{
    this.ShowStyle = true;
  }
  OnclickShowStyleBox(): any{
    this.ShowStyle = false;
  }

  onPaginateChange(event): any{
    this.startslices = (event.pageIndex * event.pageSize);
    if (this.startslices === 0) {
      this.endslices = event.pageSize;
    } else {
      this.endslices = (event.pageIndex * event.pageSize) + event.pageSize;
    }
    this.datafiltering = this.dataSource.data.slice(this.startslices , this.endslices);
  }
}
