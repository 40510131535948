<div class="row pt-3 pb-3">
  <div class="container">

<div class="row mr-auto pt-3 blogservices" style="font-family: 'Kanit-Regular'">

  <ng-container *ngIf="ContentTh;else contenten">
    <ng-container *ngFor="let data of DataIconServices">
      <div class="col-2 pt-3">
        <div class="card pt-3 text-center">
          <img src="{{addresspath}}{{data.ImageSSTh}}" style="width: 100%;">
          <H2 class="text-white">{{data.TitleTh}}</H2>
          <span>{{data.ContentTh}}</span>
        </div>
      </div>
    </ng-container>
  </ng-container>
  <ng-template #contenten>
    <ng-container *ngFor="let data of DataIconServices">
      <div class="col-2 pt-3">
        <div class="card pt-3 text-center">
          <img src="{{addresspath}}{{data.ImageSSEn}}" style="width: 100%;">
          <H2 class="text-white">{{data.TitleEn}}</H2>
          <span>{{data.ContentEn}}</span>
        </div>
      </div>
    </ng-container>
  </ng-template>
</div>
    <!--
<div *ngIf="iconmore" class="row text-right p-2">
  <div class="col-12">
    <a href="Services"><span class="btn btn-success">More..</span></a>
  </div>
</div>
-->
  </div>
</div>
