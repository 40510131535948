
<div class="row">
  <div class="col-12 col-sm-3 col-md-3">
    <div class="row m-1 p-2 bgtopbuttomsearch">
      <div class="col-12" (click)="onclickblogwordcat()">
        <H4>Worldwide Search (OCLC)</H4>
      </div>
    </div>
    </div>
  <div class="col-12 col-sm-3 col-md-3">
    <div class="row m-1 p-2 bgtopbuttomsearch">
      <div class="col-12" (click)="onclickblogopac()">
        <H4>OPAC</H4>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-3 col-md-3">
    <div class="row m-1 p-2 bgtopbuttomsearch text-center">
      <div class="col-12 text-center">
        <a href="http://apps2.lib.cmu.ac.th/renew/" target="_blank"><H4>Renew Online</H4> </a>
      </div>
    </div>
  </div>
  <div class="col-12 col-sm-3 col-md-3">
    <div class="row m-1 p-2 bgtopbuttomsearch">
      <div class="col-12" (click)="onclickblogeds()">
        <H4>EDS</H4>
      </div>
    </div>
  </div>
</div>


<div class="row">
  <div class="col-12">

  </div>
</div>
<ng-container *ngIf="blogwordcat">

  <form action="https://cmu.on.worldcat.org/search" class="form-wc-search" target="_blank" method="get" ngNoForm>
    <section class="search-banner text-white py-3 form-arka-plan" id="search-banner">
      <div class="container py-4 my-4">
        <div class="row text-center pb-4">
          <div class="col-md-12">
            <h2 class="text-white siyah-cerceve">Worldwide Search (OCLC)</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card acik-renk-form">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-2">
                    <div class="form-group ">
                      <select name="format" id="format" class="form-control" >
                        <option value="" selected>Everythings</option>
                        <option value="Book">Books</option>
                        <option value="Artchap">Article</option>
                        <option value="Course">Course Reserves</option>
                      </select>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-group ">
                      <!-- <label for="LimitResult">Limit results to:</label>-->
                      <select name="scope" id="scope" class="form-control" >
                        <option value="" selected>Libraries Worldwide</option>
                        <option value="sz:36683">Thai Libraries Consortium</option>
                        <option value="wz:59814" selected>Chiang Mai University Library</option>
                      </select>
                    </div>
                  </div>

                  <div class="col-md-5">
                    <div class="form-group ">
                      <input type="text" name="queryString" id="queryString" class="form-control" placeholder="Find books, e-books, journals, articles, dissertations and more">
                      <div class="row">
                        <div class="col-12 text-right">
                          <a href="http://cmu.on.worldcat.org/advancedsearch" target="_blank"><span class="text-dark small"><strong>advance search</strong></span> </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1">

                    <button type="submit" class="btn btn-warning pl-5 pr-5">Search</button>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </form>
</ng-container>
<ng-container *ngIf="blogopac">
 <!--  <form name="searchtool" action="http://search.lib.cmu.ac.th/search/d/index.php" method="POST" target="_blank" ngNoForm>-->
  <form [formGroup]="FrmOpac">
    <section class="search-banner text-white py-3 form-arka-plan" id="search-opac" >
      <div class="container py-4 my-4">
        <div class="row text-center pb-4">
          <div class="col-md-12">
            <h2 class="text-white siyah-cerceve">CMUL OPAC</h2>
          </div>
        </div>
        <div class="row">
          <div class="col-md-12">
            <div class="card acik-renk-form">
              <div class="card-body">
                <div class="row">
                  <div class="col-md-3">
                    <div class="form-group ">
                      <!-- <label for="LimitResult">Limit results to:</label>-->
                      <select class="form-control" formControlName="searchtype">
                        <option>ผู้แต่ง</option>
                        <option>ชื่อเรื่อง</option>
                        <option>ชื่อวารสาร</option>
                        <option>หัวเรื่อง</option>
                        <option>KEYWORD</option>
                        <option>หัวเรื่องทางการแพทย์</option>
                        <option>เลขเรียกหนังสือ</option>
                        <option>LC CALL NO</option>
                        <option>NLM CALL NO</option>
                        <option>เลขมาตรฐานสากล</option>
                        <option>PROF/TA</option>
                        <option>COURSE</option>
                      </select>
                    </div>
                  </div>


                  <div class="col-md-5">
                    <div class="form-group ">

                      <input type="text" class="form-control" placeholder="คำค้น" formControlName="searcharg">

                      <div class="row">
                        <div class="col-12 text-right">
                          <a href="http://search.lib.cmu.ac.th/" target="_blank"><span class="text-dark small"><strong>หน้าหลักในการสืบค้น</strong></span> </a>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-1">

                    <span class="btn btn-warning pl-5 pr-5" (click)="SearchOpac()">Search</span>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </form>
</ng-container>

<ng-container *ngIf="blogeds">
  <section class="search-banner text-white py-3 form-arka-plan" id="search-eds" ngNoForm>
    <div class="container py-4 my-4">
    <form [formGroup]="FrmEds">
    <div class="row text-center pb-4">
      <div class="col-md-12">
        <h2 class="text-white siyah-cerceve">Search CMU Discovery Service</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card acik-renk-form">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <div class="form-group ">
                  <!-- <label for="LimitResult">Limit results to:</label>-->
                  <select class="form-control" formControlName="soption">
                    <option>Keyword</option>
                    <option>Title</option>
                    <option>Author</option>
                  </select>
                </div>
              </div>


              <div class="col-md-5">
                <div class="form-group ">
                  <input type="text" class="form-control" placeholder="คำค้น" formControlName="bquery">
                </div>
              </div>

              <div class="col-md-1">
                <span class="btn btn-warning pl-5 pr-5" (click)="SearchEDS()">Search</span>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    </form>
    </div>
  </section>
</ng-container>

<!-- <iframe [src]="transform('https://apps.lib.cmu.ac.th/eds')" frameborder="0" allowfullscreen style="width: 100%;min-height: 300px;"></iframe>
<div class="container py-4 my-4 bg-white">
  <iframe src="http://apps.lib.cmu.ac.th/eds" frameborder="0" allowfullscreen style="width: 100%;min-height: 250px;"></iframe>
</div>
-->

<!--
    <div class="col s12 m9 l10">
      <h5 id="eds-title" style="color:#444">Search CMU Discovery Service</h5>
      <input id="searchterm" name="bquery" type="text" placeholder="Search Keyword...">
      <div class="row">
        <div class="col s12 m8 l6 hide-on-small-only" style="padding-left:0px;margin-bottom:15px">
          <div class="col s12 m4 l3" style="padding-left:0px">
            <input type="radio" name="option" id="KEY" value="1" checked="" onclick="chOption(1)"><label for="KEY">Keyword</label>
          </div>
          <div class="col s12 m4 l3" style="padding-left:0px">
            <input type="radio" name="option" id="TI" value="2" onclick="chOption(2)"><label for="TI">Title</label>
          </div>
          <div class="col s12 m4 l3" style="padding-left:0px">
            <input type="radio" name="option" id="AU" value="3" onclick="chOption(3)"><label for="AU">Author</label>
          </div>
        </div>
        <div class="col s12 hide-on-med-and-up" style="padding:0px 0px;margin-bottom:10px">
          <label>Search Field</label>
          <select class="browser-default" id="selOption" onclick="chOption2()">
            <option value="1">Keyword</option>
            <option value="2">Title</option>
            <option value="3">Author</option>
          </select>
        </div>
        <div class="col s12 m4 l3 offset-l3" style="padding:0px 0px">
          <button class="btn waves-effect waves-light" type="submit" name="action" id="btn-submit" style="width:100%">Search
            <i class="material-icons right">search</i>
          </button>
        </div>
      </div>
    </div>


    http://search.ebscohost.com/login.aspx? bquery=test&option=1&action=&direct=true&scope=site&site=eds-live&authtype=ip%2Cguest&custid=s5150876&groupid=main&profile=eds&defaultdb=
-->
<!--
  <form action="http://search.ebscohost.com/login.aspx"  onsubmit="getURL()" method="get" ngNoForm>


      <input id="searchterm" name="bquery" type="text" size="20" style="font-size:8pt;padding-left:5px;margin-left:0px;" />
      <input name="submit" type="submit" style="font-size:9pt;padding-left:5px;" value="Search" /> <br>


      <input name="direct" value="true" type="hidden">
      <input name="scope" value="site" type="hidden">
      <input name="site" value="eds-live" type="hidden">
      <input name="authtype" value="ip,guest" type="hidden">
      <input name="custid" value="s5150876" type="hidden">
      <input name="groupid" value="main" type="hidden">
      <input name="profile" value="eds" type="hidden">
      <input name="defaultdb" value="" type="hidden">

      <input type="radio" name="option" id="KEY" value="1" checked="checked" /> Keyword
      <input type="radio" name="option" id="TI" value="2" /> Title
      <input type="radio" name="option" id="AU" value="3" /> Author

  </form>
-->
