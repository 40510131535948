<!--
<div class="row">
  <div class="col-12">
    Images User
  </div>
</div>
-->
<div class="row mt-2 pl-2">
  <div class="col-12">
      <ul class="list-group border border-success">
        <a href="dashboard/website/contents"><li class="list-group-item"  style="background-color: #dff5c6;">เนื้อหาภายในเว็บไซต์</li></a>
        <a href="dashboard/website/slides"><li class="list-group-item" style="background-color: #dff5c6;">ป้ายสไลด์</li></a>
        <a href="dashboard/website/banners"><li class="list-group-item" style="background-color: #dff5c6;">ป้ายประกาศ</li></a>
        <a href="dashboard/website/news"><li class="list-group-item" style="background-color: #dff5c6;">ข่าวประชาสัมพันธ์</li></a>
        <a href="dashboard/website/events"><li class="list-group-item" style="background-color: #dff5c6;">กิจกรรม</li></a>
      </ul>
  </div>
</div>


<div class="row mt-2 pl-2">
  <div class="col-12">
    <ul class="list-group border border-primary">
      <a href="dashboard/website/articles"><li class="list-group-item" style="background-color: #dff5c6;">บทความ</li></a>
      <a href="dashboard/website/researchs"><li class="list-group-item" style="background-color: #dff5c6;">Research</li></a>
      <a href="dashboard/website/theses"><li class="list-group-item" style="background-color: #dff5c6;">Theses</li></a>

    </ul>
  </div>
</div>
<div class="row mt-2 pl-2">
  <div class="col-12">
    <ul class="list-group border border-primary">
      <a href="dashboard/website/books"><li class="list-group-item" style="background-color: #dff5c6;">หนังสือใหม่</li></a>
      <a href="dashboard/website/journals"><li class="list-group-item" style="background-color: #dff5c6;">วารสารน่าสนใจ</li></a>
      <a href="dashboard/website/edatabases"><li class="list-group-item" style="background-color: #dff5c6;">ฐานข้อมูลอิเล็กทรอนิกส์</li></a>
      <a href="dashboard/website/gallery"><li class="list-group-item" style="background-color: #dff5c6;">อัลบัมรูปภาพ</li></a>
    </ul>
  </div>
</div>

<div class="row mt-2 pl-2">
  <div class="col-12">
    <strong>Special Function</strong>
  </div>
  <div class="col-12">
    <ul class="list-group border border-warning">
      <a href="dashboard/website/termpapers"><li class="list-group-item" style="background-color: #dff5c6;">Term paper</li></a>

    </ul>
  </div>

</div>
<div class="row mt-2 pl-2">
  <div class="col-12">
    <strong>ตั้งค่าเว็บไซต์</strong>
  </div>
  <div class="col-12">
    <ul class="list-group border border-warning">
      <a href="dashboard/website/iconservices"><li class="list-group-item" style="background-color: #dff5c6;">icon บริการ</li></a>
      <a href="dashboard/website/menus"><li class="list-group-item" style="background-color: #dff5c6;">เมนูลิงค์ด้านบน</li></a>
      <a href="dashboard/website/configsfooter"><li class="list-group-item" style="background-color: #dff5c6;">เนื้อหาส่วน ท้ายเว็บไซต์</li></a>
    </ul>
  </div>

</div>


