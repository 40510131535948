import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blogofsearchv2',
  templateUrl: './blogofsearchv2.component.html',
  styleUrls: ['./blogofsearchv2.component.scss']
})
export class Blogofsearchv2Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
