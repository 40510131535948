<h1 mat-dialog-title class="fontAdelleSanTHA-Regular">รายละเอียดวัสดุ</h1>
<div mat-dialog-content>
  <div class="row">
   <div class="col-12">
     <strong>ชื่อวัสดุ: </strong>{{dataItemDetail.ItemName}}<br>
     <strong>หมายเลขครุภัณฑ์ : </strong>{{dataItemDetail.InventoryCode}}<br>
     <!-- : {{dataItemDetail.DateAdd}}<br>-->
     <strong>วันที่เพิ่มข้อมูล : </strong>{{dataItemDetail.DateTimeAdd | date}}<br>
     <!-- : {{dataItemDetail.UserAdd}}<br>-->
     <strong>สถานะอุปกรณ์ : </strong>{{dataItemDetail.StatusName}}<br>
     <strong>ประเภทวัสดุ : </strong>{{dataItemDetail.TypeName}}<br>
     <!-- : {{dataItemDetail.ItemLimit}}<br>-->
     <strong>หมายเหตุ: </strong>{{dataItemDetail.ItemComment}}<br>
     <!-- : {{dataItemDetail.Status}}<br>-->
   </div>
    <div class="col-12">
      <H4>Barcode</H4>
      <ngx-barcode6 [bc-format]= barcodetype [bc-value]= dataItemDetail.CodeItem [bc-display-value]="true" ></ngx-barcode6>
    </div>
  </div>

</div>
<mat-dialog-actions align="end" class="fontAdelleSanTHA-Regular text-right">
  <span class="btn btn-warning" (click)="onNoClick()">ปิด</span>
</mat-dialog-actions>



