<div class="row">
  <div class="col-12">
    <app-dashboardheader></app-dashboardheader>
  </div>
</div>

<!--
<div class="row">
  <div class="col-12">
    <app-dashboardnavigationtop></app-dashboardnavigationtop>
  </div>
</div>
-->

<div class="row">
  <div class="col-12">
    <router-outlet></router-outlet>
  </div>
</div>

<div class="row">
  <div class="col-12">
    <app-dashboardfooter></app-dashboardfooter>
  </div>
</div>
