<div class="row bg_dashboardcirculationbread p-1">
      <div class="col-12"><h2>คืน วัสดุ</h2></div>
</div>


<div class="row mt-3">
  <div class="container">
    <div class="row">
      <div class="col-5">
        <mat-card>
          <mat-card-content>
            <ng-container *ngIf="InputBarcodeUser">


              <div class="row">
                <div class="col-3">
                  <i class="fa fa-barcode fa-4x"></i>
                </div>
                <div class="col-9">
                  <form [formGroup]="FormUser">
                  <mat-form-field appearance="outline" class="manageitem-full-width">
                    <mat-label>Barcode User</mat-label>
                    <input matInput id="inputBarcode" name="inputBarcode" placeholder="" formControlName="Barcode" (change)="ScanBarcode()" >
                    <mat-hint></mat-hint>
                  </mat-form-field>
                </form>
                </div>
              </div>


            </ng-container>

            <ng-container *ngIf="InputItem">
              <form [formGroup]="FormCirculation">
                <mat-form-field appearance="outline" class="manageitem-full-width">
                  <mat-label>Barcode Item</mat-label>
                  <input matInput placeholder="" id="inputItem" formControlName="CodeItem" (change)="ScanBarcodeItem()" >
                  <mat-hint></mat-hint>
                </mat-form-field>
              </form>
            </ng-container>
            <!--
            <ng-container *ngIf="BarcodeScanValue">
              <ngx-barcode6 [bc-format]= barcodetype [bc-value]= FormUser.value.Barcode [bc-display-value]="true" [bc-width]="1.5" [bc-height]="50"></ngx-barcode6>
            </ng-container>
            -->
          </mat-card-content>
        </mat-card>
      </div>
      <div class="col-7">
        <div class="card" *ngIf="btnCloseUser">
          <div class="card-body">
            <ng-container *ngIf="DataUserCirculation">
              <strong>รหัสนักศึกษา : {{DataUserCirculation.StudentId}}</strong><br>
              <strong>ชื่อ-นามสกุล : {{DataUserCirculation.Name}}</strong><br>
              <strong>คณะ : {{DataUserCirculation.Faculty}} </strong><br>
              <strong>วันที่เริ่มใช้บริการ : {{DataUserCirculation.DateAdd}} </strong><br>
              <strong>ค่าปรับค้างชำระ : {{DataUserCirculation.PriceOverdue}}</strong> <button class="btn btn-success">ชำระค่า ปรับ</button> <br>
              <button class="btn btn-warning mt-2" (click)="CloseUser()">Close</button>
            </ng-container>
          </div>
        </div>

        <ng-container *ngIf="FormUser.value.Name !== '' || FormUser.value.Name !== null  ">

        </ng-container>
      </div>
    </div>


    <div class="row mt-2">
      <div class="col-9">
        <h3>รายการวัสดุ ที่ยังไม่ได้ทำรายการส่งคืน</h3>
      </div>
      <div class="col-3 text-right">
        <h3></h3>
      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12">

      </div>
    </div>

    <div class="row pt-2 pb-2" style="background-color: #9c3328;">
      <div class="col-12">
        <table matTableExporter mat-table [dataSource]="dataSource" >
          <ng-container matColumnDef="Id">
            <th mat-header-cell *matHeaderCellDef>ลำดับที่</th>
            <td mat-cell *matCellDef="let element">
              {{dataSource.filteredData.indexOf(element)+1}}
            </td>
          </ng-container>
          <!--
          <ng-container matColumnDef="CodeItem">
            <th mat-header-cell *matHeaderCellDef>Barcode</th>
            <td mat-cell *matCellDef="let element">
              {{element.CodeItem}}
            </td>
          </ng-container>
          -->
          <ng-container matColumnDef="Title">
            <th mat-header-cell *matHeaderCellDef>ชื่อวัสดุ</th>
            <td mat-cell *matCellDef="let element">
              {{element.ItemName}}
            </td>
          </ng-container>

          <ng-container matColumnDef="overduedate" style="max-width: 50px;">
            <th mat-header-cell *matHeaderCellDef>กำหนดส่งคืน</th>
            <td mat-cell *matCellDef="let element" class="text-left">
              {{element.DateExpire}}
            </td>
          </ng-container>

          <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
          <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
        </table>
        <div class="row mt-2">
          <div class="col-12">
            <mat-paginator [pageSizeOptions]="[50,100,200,250]"></mat-paginator>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>





