import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {FormBuilder} from '@angular/forms';
import {ApiwebService} from '../../../../apiweb.service';

@Component({
  selector: 'app-circulationreportitem',
  templateUrl: './circulationreportitem.component.html',
  styleUrls: ['./circulationreportitem.component.scss']
})
export class CirculationreportitemComponent implements OnInit {
  // public dataSource = new MatTableDataSource();

  public DataItem;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;

  displayedColumns: string[] = ['No', 'CodeItem', 'ItemName', 'ItemStatus', 'Command'];
  dataSource = new MatTableDataSource();

  constructor(public formBuilder: FormBuilder, public apiweb: ApiwebService) { }
  ngOnInit(): void {
    // @ts-ignore
    this.DataItem = this.apiweb.CallAllItem().subscribe( (data: any) => {
      this.DataItem = data;
      this.dataSource.data = data;
    });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
