import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ApiwebService} from '../../../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {UploadService} from '../../../../upload.service';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {of} from 'rxjs';
@Component({
  selector: 'app-frmbanner',
  templateUrl: './frmbanner.component.html',
  styleUrls: ['./frmbanner.component.scss']
})
export class FrmbannerComponent implements OnInit {
  public settimeshow = false;
  public contenttype = 'banners' ;
  public ckeditor4config = this.apiweb.ConfigEditor4(this.apiweb.CkuploadApiAddress , this.contenttype);
  public CkeditorApiAddress = this.apiweb.CkeditorApiAddress;

  // ฟอร์มกรอกข้อมูล
  public FormAdd: FormGroup;
  public txtHeaderType = 'ป้ายโฆษณา';
  public txtFrmType;

  // กำหนดข้อมูลการบันทึกข้อมูลลงฐานข้อมูล
  public Status;


  @ViewChild('fileUpload') fileUpload: ElementRef; files  = [];
  // ตั้งค่าอัพโหลด
  public SiteAddress = this.apiweb.WebsiteAddress;
  public ServerUploadURL ;
  public ServerUploadCKEDITORURL ;
  public FileURL;
  public yourFile: File;

  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute, public uploadService: UploadService) { }

  ngOnInit(): void {
    // ตั้งค่าอัพโหลดไฟล์
    this.ServerUploadURL = this.apiweb.UploadApiAddress + '?path=' + this.contenttype ;
    this.ServerUploadCKEDITORURL = this.apiweb.CkuploadApiAddress + '?path=' + this.contenttype ;
    // ตำแหน่งที่ตั้งไฟล์
    this.FileURL = this.SiteAddress + 'api/assets/' + this.contenttype + '/' ;

    this.FormAdd = this.formBuilder.group({
      Id: '',
      Faculty: '',
      TitleTh: '',
      TitleEn: '',
      ContentTh: '',
      ContentEn: '',
      ImageSSTh: '',
      ImageSSEn: '',
      ExternalLink: '',
      DatePublish: '',
      DateUnpublish: '',
      UserAdd: '',
      UserModify: '',
      StatusPublish: '',
      Status: '',
      Action: ''
    });

    this.route.params.subscribe(params => {
      // console.log(params);

      if (params.Action === 'Add'){
        this.txtFrmType = 'เพิ่ม';
        setTimeout(() => {
          this.CreateContent();
        }, 500);

      }
      if (params.Action === 'Edit'){
        this.txtFrmType = 'แก้ไข';
        // console.log(params.Action);
        this.EditContent(params.Id);
      }
    });
  }



  CreateContent(): void{
    this.apiweb.CallNewBannerFaculty().subscribe( ( dataresponse: any[]) => {
      // console.log(dataresponse);
      this.FormAdd.patchValue({
        Id: dataresponse[0].Id,
        Faculty: dataresponse[0].Faculty,
        TitleTh: dataresponse[0].TitleTh,
        TitleEn: dataresponse[0].TitleEn,
        ContentTh: dataresponse[0].ContentTh,
        ContentEn: dataresponse[0].ContentEn,
        ImageSSTh: dataresponse[0].ImageSSTh,
        ImageSSEn: dataresponse[0].ImageSSEn,
        ExternalLink: dataresponse[0].ExternalLink,
        DatePublish: dataresponse[0].DatePublish,
        DateUnpublish: dataresponse[0].DateUnpublish,
        UserAdd:  this.apiweb.dataUser.EmailCMU,
        UserModify:  this.apiweb.dataUser.EmailCMU,
        StatusPublish: dataresponse[0].StatusPublish,
        Action: 'Add'
      });
    });

  }
  EditContent(ContentId): void{
    this.apiweb.CallBannerFacultyId(ContentId).subscribe( (dataresponse: any) => {
      const data = dataresponse.data;
      // console.log(data);
      this.FormAdd.patchValue({
        Id: data[0].Id,
        Faculty: data[0].Faculty,
        TitleTh: data[0].TitleTh,
        TitleEn: data[0].TitleEn,
        ContentTh: data[0].ContentTh,
        ContentEn: data[0].ContentEn,
        ImageSSTh: data[0].ImageSSTh,
        ImageSSEn: data[0].ImageSSEn,
        ExternalLink: data[0].ExternalLink,
        DatePublish: data[0].DatePublish,
        DateUnpublish: data[0].DateUnpublish,
        UserAdd:  this.apiweb.dataUser.EmailCMU,
        UserModify:  this.apiweb.dataUser.EmailCMU,
        StatusPublish: data[0].StatusPublish,
        Action: 'Edit'
      });
    });
  }
  ClickSave(): void{
    this.apiweb.CommandManageBanners(this.FormAdd.value).subscribe((data: any[]) => {
      // console.log(data);
      let linkback;
      linkback = 'dashboard/website/' + this.contenttype;
      this.router.navigate([linkback]);
    });
  }

  ClickCancel(): void{
    let linkback;
    linkback = 'dashboard/website/' + this.contenttype;
    this.router.navigate([linkback]);
  }

  // ตรวจสอบสถานะเผยแพร่ข้อมูล
  CheckSwitchTrueFalse(Dataval): any{
    if (Dataval === '1'){
      return true;
    }
    if (Dataval === '0' || Dataval === ''  || Dataval === 'null' ){
      return false;
    }
  }

  SwitchStatusPublishChange(): void{
    if (this.FormAdd.value.StatusPublish === '0'){
      this.FormAdd.patchValue({
        StatusPublish: '1'
      });
    }else{
      this.FormAdd.patchValue({
        StatusPublish: '0'
      });
    }
  }


  //////////////////////////////////////////////////////////////////////////
  uploadFile(file): void{
    const formData = new FormData();
    formData.append('file', file.data);
    formData.append('path', this.contenttype);
    // console.log(file.data);
    file.inProgress = true;
    this.uploadService.upload(formData, this.ServerUploadURL).pipe(
      map(event => {
        this.FormAdd.patchValue({
          ImageSSTh: event.NewName
        });
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
      if (typeof (event) === 'object') {
        // console.log(event.body);
      }
    });

  }
  private uploadFiles(): void{
    this.fileUpload.nativeElement.value = '';
    this.files.forEach(file => {
      this.uploadFile(file);
    });
    // console.log(this.files);
  }
  onClick(): void{
    const fileUpload = this.fileUpload.nativeElement; fileUpload.onchange = () => {
      this.files  = [];
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0});
      }
      this.uploadFiles();
    };
    fileUpload.click();

  }
  getFile(fileInput): void{
    this.yourFile = fileInput.target.files[0];
    // console.log(this.yourFile);
  }
  OnClickDeleteFile(imagessslang): void{
    // console.log('delete files select');
    // console.log(this.FormAdd.value);
    if ( imagessslang === 'imagessth'){
      this.FormAdd.patchValue({
        ImageSSTh: ''
      });
    }
    if ( imagessslang === 'imagessth'){
      this.FormAdd.patchValue({
        ImageSSEn: ''
      });
    }


  }

  togglesettime(): void{
    this.settimeshow = !this.settimeshow;
  }
}
