import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ApiwebService} from '../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-layoutalumni',
  templateUrl: './layoutalumni.component.html',
  styleUrls: ['./layoutalumni.component.scss']
})
export class LayoutalumniComponent implements OnInit {

  constructor(public dialog: MatDialog, private datePipe: DatePipe, public apiweb: ApiwebService, private router: Router , private route: ActivatedRoute ) { }

  ngOnInit(): void {
  }
  Clicklogout(){
    this.router.navigate(['/logout']);
  }
}
