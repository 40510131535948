

<div class="row">
  <div class="container">

    <div class="row mt-2 p-2" style="background-color: #888888;">
      <div class="col-12">
        <h2 class="">{{txtFrmType}} {{txtHeaderType}}</h2>
      </div>
    </div>
    <form [formGroup]="FormAdd">











      <div class="row mt-2">
        <div class="col-4">
          <div class="form-group">
            <label for="Codes"><strong>Codes</strong></label>
            <input type="text" class="form-control" id="Codes" placeholder="Codes" formControlName="Codes">
          </div>
          <span class="text-danger" >* หมายเหตุ : สำหรับห้องสมุด ที่มีการจัดทำรหัสหนังสือเพิ่มเติม</span>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="Calls"><strong>Call Numbers</strong></label>
            <input type="text" class="form-control" id="Calls" placeholder="Calls" formControlName="Calls">
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="Years"><strong>Year</strong></label>
            <input type="number" class="form-control" id="Years" placeholder="Years" formControlName="Years"  min="4" max="4">
          </div>
        </div>
      </div>


      <div class="row mt-2">
        <div class="col-4">
          <div class="form-group">
            <label for="Department"><strong>Department</strong></label>
            <input type="text" class="form-control" id="Department" placeholder="Department" formControlName="Department">
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="Author"><strong>Author</strong></label>
            <input type="text" class="form-control" id="Author" placeholder="Author" formControlName="Author">
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="Imprints"><strong>Imprints</strong></label>
            <input type="Imprints" class="form-control" id="Imprints" placeholder="Imprints" formControlName="Imprints">
          </div>
        </div>
      </div>


      <div class="row mt-2">
        <div class="col-12">
          <div class="form-group">
            <label for="titleth"><strong>ชื่อเรื่อง</strong></label>
            <input type="text" class="form-control" id="titleth" placeholder="ชื่อเรื่อง" formControlName="TitleTh">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="titleen"><strong>ชื่อเรื่อง ภาษาอังกฤษ</strong></label>
            <input type="text" class="form-control" id="titleen" placeholder="Title" formControlName="TitleEn">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="Note"><strong>Note</strong></label>
            <textarea class="form-control" id="Note" placeholder="Title" formControlName="Note"></textarea>
          </div>
        </div>
      </div>

      <div class="row mt-2 p-2 border border-warning">
        <div class="col-6 border-right border-danger">
          <div class="form-group">
            <label for="Links">ลิงค์ไปไฟล์</label>
            <input type="text" class="form-control" id="Links" placeholder="Ex: http://google.com" formControlName="Links">
          </div>
          * หมายเหตุ : เมื่อใส่ลิงค์ตรงนี้ จะเป็นการกำหนดลิงค์ไปยัง แอดเดรสที่ต้องการ
        </div>

        <div class="col-6">
          <label for="fileUpload">อัพโหลด ไฟล์เอกสาร</label>
          <mat-card>
            <mat-card-content>
              <div class="row" *ngIf="FormAdd.value.FileUpload !== '' || FormAdd.value.FileUpload !== 'null' " >
                <div class="col-10">
                  <a href="{{FileURL}}{{FormAdd.value.FileUpload}}" target="_blank">{{FormAdd.value.FileUpload}}</a>
                </div>
                <div class="col-2">
                  <i class="fas fa-window-close" (click)="OnClickDeleteFile('imagessth')"> ลบไฟล์เอกสาร </i>
                </div>

              </div>
              <div *ngFor="let file of files">
                <mat-progress-bar [value]="file.progress"></mat-progress-bar>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <button mat-button color="warn" (click)="onClick()">
                <i class="fas fa-cloud-upload-alt fa-2x">Upload</i>
              </button>
            </mat-card-actions>
          </mat-card>
          <input type="file" #fileUpload id="fileUpload" name="upload" accept="pdf/*" style="display:none;" />

        </div>
      </div>






      <div class="row mt-2 mb-2 p-3" >

        <div class="col-6 pt-2 pb-2" style="background-color: #e4e4e4">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input input-lg" id="StatusPublish" [checked]="CheckSwitchTrueFalse(FormAdd.value.StatusPublish)" formControlName="StatusPublish" >
            <label class="custom-control-label" for="StatusPublish">สถานะการเผยแพร่ข้อมูล</label>
          </div>
        </div>
      </div>
    </form>
    <div class="row mb-2">
      <div class="col-12 text-center">
        <span class="btn btn-primary mr-3" (click)="ClickSave()"><i class="far fa-save"></i> บันทึกข้อมูล</span>
        <span class="btn btn-warning" (click)="ClickCancel()"><i class="fas fa-share fa-rotate-180"></i> ยกเลิก</span>
      </div>
    </div>
  </div>

</div>
