import { Component, OnInit } from '@angular/core';
import {ApiwebService} from '../../apiweb.service';


@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.scss']
})
export class ServicesComponent implements OnInit {
  public DataIconServices;
  public ContentTh = false;
  public totalIcon;
  public addresspath = this.apiweb.ApiAddress + 'assets/services/';
  constructor(public apiweb: ApiwebService) { }

  ngOnInit(): void {
    this.CallIconServices();
    if (localStorage.getItem('lang') === 'En'){
      this.ContentTh = false;
    } else {
      localStorage.setItem('lang', 'Th');
      this.ContentTh = true;
    }
  }

  CallIconServices(){
    this.apiweb.CallAllIconServices(localStorage.getItem('SiteFaculty'), '' ).subscribe( (dataresponse: any) => {
      const data = dataresponse.data;
      this.DataIconServices = data;
      this.totalIcon = data.length;
    });
  }
}
