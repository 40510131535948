import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboardfooter',
  templateUrl: './dashboardfooter.component.html',
  styleUrls: ['./dashboardfooter.component.scss']
})
export class DashboardfooterComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
