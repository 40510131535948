<div class="row pt-2 text-center" style="background-color: #aa1111;">
  <div class="col-12">
    <h2 class="text-white fontKanitRegular">ระบบ ยืม-คืน วัสดุ-อุปกรณ์</h2>
  </div>
</div>

<div class="row mt-2">
  <div class="col-2">
    <app-dashboardcirculationleftmenu></app-dashboardcirculationleftmenu>
  </div>
  <div class="col-10">
    <router-outlet></router-outlet>
  </div>
</div>
