import { Injectable } from '@angular/core';
// tslint:disable-next-line:import-spacing
import {HttpClient, HttpEvent, HttpErrorResponse, HttpEventType, HttpHeaders} from '@angular/common/http';
// tslint:disable-next-line:import-spacing
import { map } from  'rxjs/operators';
import {ApiwebService} from './apiweb.service';

@Injectable({
  providedIn: 'root'
})
export class UploadService {
  public httpOptionsPostfile = {
    headers: new HttpHeaders({
      'Access-Control-Allow-Origin': '*',
      'Content-Type': 'application/json',
      'Accept': 'application/json',
      'Access-Control-Allow-Headers': 'Content-Type',
      // 'Authorization': 'Basic ' + btoa(this.username + ':' + this.password)
    }),reportProgress: true
  };

  constructor( public http: HttpClient , private apiweb: ApiwebService) { }
  public upload(formData, ServerURL) {
    // @ts-ignore
    return this.http.post<any>(ServerURL, formData );
  }
  // File And Image
  CallFilelist(ApiAddress, Useradd, FacultyEn, Action, Id){
    return this.http.get(ApiAddress + 'CallFileUpload.php?' + 'Action=' + Action + '&FacultyEn=' + FacultyEn + '&UserAdd=' + Useradd + '&ContentId=' + Id);
  }

  // File And Image
  // Upload data to base
  CommandUpload(data){
    return this.http.post(this.apiweb.ApiAddress + 'ManageFileUpload.php', data, this.apiweb.httpOptionsPost);
  }
}
