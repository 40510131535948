<div class="row">
  <div class="col-12">
    <app-headerhome [SiteName]="SiteName" [LogoAddress]="LogoAddress" ></app-headerhome>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <router-outlet></router-outlet>
  </div>
</div>
<div class="row">
  <div class="col-12">
    <app-footerhome></app-footerhome>
  </div>
</div>
