<div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
  <div class="example-box" *ngFor="let data of DataSubMenu" cdkDrag>
    <div class="example-custom-placeholder" *cdkDragPlaceholder></div>

    <div class="row">
      <div class="col-4">
        {{data.MenuNameTh}}
      </div>
      <div class="col-8 border border-success">

        <div class="row" *ngIf="data.SubMenu == 1 || data.SubMenu === true || data.SubMenu !== null ">
          <div class="col-12">
            <app-dashboardsubmenu [MainMenuId]="data.SubMenu"></app-dashboardsubmenu>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

{{mainMenuId}}
