import {Component, OnInit, ViewChild} from '@angular/core';
import {ApiwebService} from '../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {MatTableDataSource} from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';

@Component({
  selector: 'app-book',
  templateUrl: './book.component.html',
  styleUrls: ['./book.component.scss']
})
export class BookComponent implements OnInit {
  public displaylang = localStorage.getItem('lang');
  public pathimage = this.apiweb.WebsiteAddressAssets + 'newbooks/';
  public ShowStyle = true;
  public displayedColumns: string[] = ['no', 'ImageCover', 'TitleTh', 'BookPublishDate'];
  public dataSource = new MatTableDataSource();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;


  public datafiltering = [];
  public startslices;
  public endslices;
  public checktypes;

  constructor(public apiweb: ApiwebService , public dialog: MatDialog, private datePipe: DatePipe, private router: Router, private route: ActivatedRoute ) { }

  ngOnInit(): void {

    console.log(this.displaylang);
    this.apiweb.CallNewBooks(this.apiweb.SiteFaculty, '0' ).subscribe( (dataresponse: any) => {
      const data = dataresponse.data;
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;

      this.datafiltering = this.dataSource.data.slice(0 , 9);
    });
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    console.log(this.dataSource);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    console.log(this.dataSource);
    this.datafiltering = this.dataSource.filteredData.slice(this.startslices , this.endslices);

  }
/*
  applyFilter(filterValue: string) {
    filterValue = filterValue.trim(); // Remove whitespace
    filterValue = filterValue.toLowerCase(); // MatTableDataSource defaults to lowercase matches
    this.dataSource.filter = filterValue;

  }

*/
  ClickLink(Id){
      this.router.navigate(['/newbooksdetail/', { Id }]);
  }
  OnclickShowStylelist(){
    this.ShowStyle = true;
  }
  OnclickShowStyleBox(){
    this.ShowStyle = false;
  }

  onPaginateChange(event) {
    this.startslices = (event.pageIndex * event.pageSize);
    if (this.startslices === 0) {
      this.endslices = event.pageSize;
    } else {
      this.endslices = (event.pageIndex * event.pageSize) + event.pageSize;
    }
    this.datafiltering = this.dataSource.data.slice(this.startslices , this.endslices);
  }
}
