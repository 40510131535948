import { Component, OnInit } from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {MatDialog} from '@angular/material/dialog';
import {ApiwebService} from '../../apiweb.service';
import {Router} from '@angular/router';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-dashboardwebsite',
  templateUrl: './dashboardwebsite.component.html',
  styleUrls: ['./dashboardwebsite.component.scss']
})
export class DashboardwebsiteComponent implements OnInit {
  public SiteAddress = this.apiweb.WebsiteAddress;
  constructor(private formBuilder: FormBuilder, private apiweb: ApiwebService, private datePipe: DatePipe, private router: Router , public dialog: MatDialog) { }

  ngOnInit(): void {
    // console.log(this.datawebsite);

  }

}
