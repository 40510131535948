  <div class="row">
    <div class="col-12 col-xs-12 ">
      <nav>
        <div class="nav nav-tabs nav-fill" id="nav-tab" role="tablist">
          <a class="nav-item nav-link active" id="nav-NewBooks-tab" data-toggle="tab" href="#nav-NewBooks" role="tab" aria-controls="nav-NewBooks" aria-selected="true">New Books</a>
          <a class="nav-item nav-link" id="nav-EBooks-tab" data-toggle="tab" href="#nav-EBooks" role="tab" aria-controls="nav-EBooks" aria-selected="false">E-Books</a>
          <a class="nav-item nav-link" id="nav-journal-tab" data-toggle="tab" href="#nav-journal" role="tab" aria-controls="nav-journal" aria-selected="false">Journal</a>
          <a class="nav-item nav-link" id="nav-event-tab" data-toggle="tab" href="#nav-event" role="tab" aria-controls="nav-event" aria-selected="false">Events</a>
          <a class="nav-item nav-link" id="nav-News-tab" data-toggle="tab" href="#nav-News" role="tab" aria-controls="nav-event" aria-selected="false">News</a>
        </div>
      </nav>
      <div class="tab-content py-3 px-3 px-sm-0" id="nav-tabContent">
        <div class="tab-pane fade show active" id="nav-NewBooks" role="tabpanel" aria-labelledby="nav-NewBooks-tab">
            <app-blocknewbook></app-blocknewbook>
        </div>
        <div class="tab-pane fade" id="nav-EBooks" role="tabpanel" aria-labelledby="nav-EBooks-tab">
          <app-blockebook></app-blockebook>
        </div>
        <div class="tab-pane fade" id="nav-journal" role="tabpanel" aria-labelledby="nav-journal-tab">
          <app-blockjournal></app-blockjournal>
        </div>
        <div class="tab-pane fade" id="nav-event" role="tabpanel" aria-labelledby="nav-event-tab">
          <app-blockevent></app-blockevent>
        </div>
        <div class="tab-pane fade" id="nav-News" role="tabpanel" aria-labelledby="nav-News-tab">
          <app-blocknews></app-blocknews>
        </div>
      </div>

    </div>
  </div>



