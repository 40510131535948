import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ApiwebService} from '../../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-dashboardcirculationin',
  templateUrl: './dashboardcirculationin.component.html',
  styleUrls: ['./dashboardcirculationin.component.scss']
})
export class DashboardcirculationinComponent implements OnInit {
  public dataUser = JSON.parse(localStorage.getItem('datauser'));
  public FormUser: FormGroup;
  public FormCirculation: FormGroup;

  // ตั้งค่า Barcode
  public barcodetype = 'CODE128';
  // public barcodetype = 'CODE39';
  // public barcodetype = 'MSI';
  public BarcodeScanValue = false;
  public DataUserCirculation;

  public InputBarcodeUser = true;
  public InputItem = false;
  public btnCloseUser = false;

  displayedColumns: string[] = ['Id', 'Title', 'overduedate'];
  public dataSource = new MatTableDataSource();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;

  @ViewChild('inputBarcode') inputBarcode: ElementRef;
  @ViewChild('inputItem') inputItem: ElementRef;

  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute ) { }

  ngOnInit(): void {
    console.log(this.dataUser);
    this.FormUser = this.formBuilder.group({
      Id: '',
      Barcode: '',
      EmailCMU: '',
      Name: '',
      Faculty: ''
    });

    this.FormCirculation = this.formBuilder.group({
      Id: '',
      EmailCMU: '',
      CodeItem: '',
      UserAdd: this.dataUser.EmailCMU,
      Limits: ''
    });
  }

  ScanBarcode(){
    // console.log(this.FormUser.value);
    this.BarcodeScanValue = true;
    this.CheckdataUser();
  }


  CallAllItem(){
    // console.log(this.dataUser);
    this.apiweb.CallDataUserItemHold(this.FormUser.value.Barcode).subscribe( (data: any[]) => {
      if (data.length > 0){
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
      }else{
        this.dataSource.data = [];
        this.dataSource.paginator = this.paginator;
      }
    });
  }
  CheckdataUser(){
    /* */
    this.apiweb.CallDataUserBarcode(this.FormUser.value.Barcode).subscribe( (dataresult: any) => {
      console.log(dataresult.data[0]);
      if (dataresult.status === true){
        this.DataUserCirculation = dataresult.data[0];
        this.InputBarcodeUser = false;
        this.InputItem = true;
        this.CallAllItem();
        this.btnCloseUser = true;
      }else {
        this.DataUserCirculation = [];
        this.InputBarcodeUser = true;
        this.InputItem = false;
        this.btnCloseUser = false;
      }

    });
    /*
    this.apiweb.CallDataUserAccount(this.FormUser.value.Barcode).subscribe( (dataresult: any) => {
      console.log(dataresult.data[0]);
      if (dataresult.status === true){
        this.DataUserCirculation = dataresult.data[0];
        this.InputBarcodeUser = false;
        this.InputItem = true;
        this.CallAllItem();
        this.btnCloseUser = true;
        // this.inputItem.nativeElement.focus();
      }else {
        this.DataUserCirculation = [];
        this.InputBarcodeUser = true;
        this.InputItem = false;
        // this.inputBarcode.nativeElement.focus();
        this.btnCloseUser = false;
      }

    });
     */
  }
  ClearUser(){
    this.FormUser.reset();
    this.FormUser.patchValue({
      Id: '',
      Barcode: '',
      EmailCMU: '',
      Name: '',
      Faculty: ''
    });
    this.BarcodeScanValue = false;
    this.btnCloseUser = false;
  }
  CloseUser(){
    this.ClearUser();
    this.BarcodeScanValue = false;
    this.DataUserCirculation = [];
    this.InputBarcodeUser = true;
    this.InputItem = false;
    this.dataSource.data = [];
    this.dataSource.paginator = this.paginator;
    // this.elements.inputBarcode.focus();
    // this.inputBarcode.nativeElement.focus();
  }





// แสกนบาร์โค๊ดคืนของ
  ScanBarcodeItem(){
    console.log(this.FormCirculation.value);
    // ตรวจสอบสถานะของ Item
    this.apiweb.CheckItem(this.FormCirculation.value.CodeItem).subscribe( (dataresult: any) => {
     // console.log(dataresult);
      if (dataresult.status === true){
        const dataitem = dataresult.data[0];
        this.FormCirculation.patchValue({
          EmailCMU: this.FormUser.value.Barcode,
          CodeItem: dataitem.CodeItem,
          Id: dataitem.Id,
          Limits: this.DataUserCirculation.Limits
        });
        if (dataitem.StatusItem === '1'){
          // แก้ไข สถานะ Item ว่าได้รับคืนแล้วเพื่อให้ยืมต่อได้
          this.apiweb.CheckInStatusItem(this.FormCirculation.value).subscribe(data => console.log(data));
          // แก้ไขข้อมูลในระเบียน Circulation
          this.apiweb.CheckInItem(this.FormCirculation.value).subscribe( (datacheckout: any) => {
            console.log(datacheckout);
            this.FormCirculation.patchValue({
              CodeItem: ''
            });
          });
          alert('รับ วัสดุคืน');
          this.CallAllItem();
        } else {
          alert('ไม่สามารถทำรายการรับคืน ได้เนื่องจากวัสดุยังไม่ได้ยืมออก');
          this.ClearBarcodeItem();
          this.CallAllItem();
        }
      }else{
        alert('ไม่พบรายการ Item ที่ต้องการยืม');
        this.ClearBarcodeItem();
        this.CallAllItem();
      }
    });

    // บันทึกข้อมูลการยืมวัสดุ และ บันทึกสถานะของ Item

    // ดึงข้อมูลรายการวัตถุที่ยืมแล้วมาแสดง จากวันที่ ยืม

    // reset value input

  }

  // clear barcode
  ClearBarcodeItem() {
    this.FormCirculation.patchValue({
      CodeItem: ''
    });
  }
  ItemHold(){

  }
  CallDataItemCheckOut(){

  }




}
