import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ApiwebService} from '../../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {UploadService} from '../../../upload.service';

@Component({
  selector: 'app-dashboardconfigfooter',
  templateUrl: './dashboardconfigfooter.component.html',
  styleUrls: ['./dashboardconfigfooter.component.scss']
})
export class DashboardconfigfooterComponent implements OnInit {
  public contenttype = 'configs' ;
  public ckeditor4config = this.apiweb.ConfigEditor4(this.apiweb.CkuploadApiAddress , this.contenttype);
  public CkeditorApiAddress = this.apiweb.CkeditorApiAddress;
  // ฟอร์มกรอกข้อมูล
  public FormAdd: FormGroup;
  public txtHeaderType = 'ตั้งค่าเว็บไซต์ส่วนท้ายเว็บ';
  public txtFrmType;

  // กำหนดข้อมูลการบันทึกข้อมูลลงฐานข้อมูล
  public Status;
  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute, public uploadService: UploadService) { }

  ngOnInit(): void {
    this.FormAdd = this.formBuilder.group({
      Faculty: this.apiweb.SiteFaculty,
      FacebookCode: '',
      FooterContentTh1: '',
      FooterContentTh2: '',
      FooterContentTh3: '',
      FooterContentTh4: '',
      FooterContentEn1: '',
      FooterContentEn2: '',
      FooterContentEn3: '',
      FooterContentEn4: '',
      Action: 'Edit'
    });
    this.CallConfigs();
  }

  public CallConfigs(): any{
    this.apiweb.CallConfig(this.apiweb.SiteFaculty).subscribe( (dataresponse: any) => {
      if (dataresponse.status === true){
        const data = dataresponse.data[0];
        this.FormAdd.patchValue({
          FacebookCode: data.FacebookCode,
          FooterContentTh1: data.FooterContentTh1,
          FooterContentTh2: data.FooterContentTh2,
          FooterContentTh3: data.FooterContentTh3,
          FooterContentTh4: data.FooterContentTh4,
          FooterContentEn1: data.FooterContentEn1,
          FooterContentEn2: data.FooterContentEn2,
          FooterContentEn3: data.FooterContentEn3,
          FooterContentEn4: data.FooterContentEn4,
        });
      }
    });
  }

  public EditContent(): any{
    this.apiweb.CommandData('configs', 'command_configcontentfooter', this.FormAdd.value).subscribe( (dataresponse: any) => {
      // console.log(dataresponse);
    });
  }
  public BtSave(): any {
    this.apiweb.CommandData('configs', 'command_configcontentfooter', this.FormAdd.value).subscribe( (dataresponse: any) => {
      // console.log(dataresponse);
    });
  }

}
