<div class="row bg_dashboardcirculationbread p-1">
  <div class="col-12"><h2>ข้อมูลผู้สมัครใช้งาน</h2></div>
</div>
<div class="row">
  <div class="col-12 mt-2">
    <mat-form-field>
      <mat-label>ค้นหาข้อมูล</mat-label>
      <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ชื่อ-นามสกุล , รหัสนักศึกษา">
    </mat-form-field>
  </div>

  <div class="col-12 mt-2">
    <table mat-table [dataSource]="dataSource" matSort class="mat-elevation-z8">
      <!-- Position Column
      <ng-container matColumnDef="No">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
        <td mat-cell *matCellDef="let element">
        </td>
      </ng-container>-->
      <ng-container matColumnDef="No">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> No. </th>
        <td mat-cell *matCellDef="let element"> {{dataSource.filteredData.indexOf(element)+1}} </td>
      </ng-container>


      <ng-container matColumnDef="StudentId">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> รหัสนักศึกษา </th>
        <td mat-cell *matCellDef="let element"> {{element.StudentId}} </td>
      </ng-container>


      <ng-container matColumnDef="Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ชื่อ-นามสกุล </th>
        <td mat-cell *matCellDef="let element"> {{element.Name}} </td>
      </ng-container>


      <ng-container matColumnDef="Faculty">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> คณะ </th>
        <td mat-cell *matCellDef="let element"> {{element.Faculty}} </td>
      </ng-container>
      <ng-container matColumnDef="EmailCMU">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email CMU </th>
        <td mat-cell *matCellDef="let element"> {{element.EmailCMU}} </td>
      </ng-container>
      <ng-container matColumnDef="itaccounttype_EN">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> ประเภทผู้ใช้งาน </th>
        <td mat-cell *matCellDef="let element"> {{element.itaccounttype_EN}} </td>
      </ng-container>
      <ng-container matColumnDef="DateAdd">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> วันที่เริ่มเข้าใช้งาน </th>
        <td mat-cell *matCellDef="let element"> {{element.DateAdd}} </td>
      </ng-container>
      <ng-container matColumnDef="Command">
        <th mat-header-cell *matHeaderCellDef mat-sort-header></th>
        <td mat-cell *matCellDef="let element"><i class="fas fa-search fa-2x text-info" ></i> </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
    <mat-paginator [pageSizeOptions]="[10,25,50,100]" showFirstLastButtons></mat-paginator>
  </div>

</div>
<div class="row">
  <div class="col-12">
    <a href="dashboard/circulation/report"><i class="fas fa-backward fa-2x">ย้อนกลับ</i></a>
  </div>
</div>
