import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ApiwebService} from '../../../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {UploadService} from '../../../../upload.service';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {of} from 'rxjs';
@Component({
  selector: 'app-frmresearch',
  templateUrl: './frmresearch.component.html',
  styleUrls: ['./frmresearch.component.scss']
})
export class FrmresearchComponent implements OnInit {
  public settimeshow = false;
  public contenttype = 'researchs' ;


  // ฟอร์มกรอกข้อมูล
  public FormAdd: FormGroup;
  public txtHeaderType;
  public txtFrmType;

/////////////////////////////////////////////////////////////////////
  public Status;
  @ViewChild('fileUpload') fileUpload: ElementRef; files  = [];
  // ตั้งค่าอัพโหลด
  public SiteAddress = this.apiweb.WebsiteAddress;
  public ServerUploadURL ;
  public ServerUploadCKEDITORURL ;
  public FileURL;
  public yourFile: File;

  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute, public uploadService: UploadService) { }

  ngOnInit(): void {
    // ตั้งค่าอัพโหลดไฟล์
    this.ServerUploadURL = this.apiweb.UploadApiAddress + '?path=' + this.contenttype ;
    this.ServerUploadCKEDITORURL = this.apiweb.CkuploadApiAddress + '?path=' + this.contenttype ;
    // ตำแหน่งที่ตั้งไฟล์
    this.FileURL = this.SiteAddress + 'api/assets/' + this.contenttype + '/' ;
    this.FormAdd = this.formBuilder.group({
      Id: '',
      Faculty: '',
      Department: '',
      Codes: '',
      CallNumber: '',
      Years: '',
      TitleTh: '',
      TitleEn: '',
      Author: '',
      Imprints: '',
      Note: '',
      Links: '',
      FileUpload: '',
      DateAdd: '',
      UserAdd: this.apiweb.dataUser.EmailCMU,
      StatusPublish: '',
      Status: '',
      Action: ''
    });

    this.route.params.subscribe(params => {
      this.ChkTypeContent(this.contenttype);
      if (params.Action === 'Add'){
        this.txtFrmType = 'เพิ่ม';
        setTimeout(() => {
          this.CreateContent();
        }, 2000);
      }
      if (params.Action === 'Edit'){
        this.txtFrmType = 'แก้ไข';
        this.EditContent(params.Id);
      }
    });
  }
  // เช็คข้อมูลประเภทเนื้อหาที่ต้องการจะเพิ่ม
  ChkTypeContent(typecontent){
    if (typecontent === 'researchs'){
      this.txtHeaderType = 'Research';
    }
  }
  CreateContent() {
    this.apiweb.CallNewResearchFaculty().subscribe( ( dataresponse: any[]) => {
      console.log(dataresponse);
      this.FormAdd.patchValue({
        Id: dataresponse[0].Id,
        Faculty: dataresponse[0].Faculty,
        Department: dataresponse[0].Department,
        Codes: dataresponse[0].Codes,
        CallNumber: dataresponse[0].CallNumber,
        Years: dataresponse[0].Years,
        TitleTh: dataresponse[0].TitleTh,
        TitleEn: dataresponse[0].TitleEn,
        Author: dataresponse[0].Author,
        Imprints: dataresponse[0].Imprints,
        Note: dataresponse[0].Note,
        Links: dataresponse[0].Links,
        FileUpload: dataresponse[0].FileUpload,
        UserAdd: this.apiweb.dataUser.EmailCMU,
        StatusPublish: dataresponse[0].StatusPublish,
        Action: 'Add'
      });
    });

  }
  EditContent(ContentId){
    this.apiweb.CallResearchFacultyId(ContentId).subscribe( (dataresponse: any) => {
      const data = dataresponse.data[0];
      console.log(data);
      this.FormAdd.patchValue({
        Id: data.Id,
        Faculty: data.Faculty,
        Department: data.Department,
        Codes: data.Codes,
        CallNumber: data.CallNumber,
        Years: data.Years,
        TitleTh: data.TitleTh,
        TitleEn: data.TitleEn,
        Author: data.Author,
        Imprints: data.Imprints,
        Note: data.Note,
        Links: data.Links,
        FileUpload: data.FileUpload,
        UserAdd: this.apiweb.dataUser.EmailCMU,
        StatusPublish: data.StatusPublish,
        Action: 'Edit'
      });
    });
  }

  ClickSave(){
    this.apiweb.CommandManageResearch(this.FormAdd.value).subscribe((data: any[]) => {
      let linkback;
      linkback = 'dashboard/website/' + this.contenttype;
      this.router.navigate([linkback]);
    });
  }
  ClickCancel(){
      let linkback;
      linkback = 'dashboard/website/' + this.contenttype;
      this.router.navigate([linkback]);
  }

  // ตรวจสอบสถานะเผยแพร่ข้อมูล
  CheckSwitchTrueFalse(Dataval){
    if (Dataval === '1'){
      return true;
    }
    if (Dataval === '0' || Dataval === ''  || Dataval === 'null' ){
      return false;
    }
  }

  SwitchStatusPublishChange(){
    if (this.FormAdd.value.StatusPublish === '0'){
      this.FormAdd.patchValue({
        StatusPublish: '1'
      });
    }else{
      this.FormAdd.patchValue({
        StatusPublish: '0'
      });
    }
  }


  //////////////////////////////////////////////////////////////////////////
  uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file.data);
    formData.append('path', this.contenttype);
    // console.log(file.data);
    file.inProgress = true;
    this.uploadService.upload(formData, this.ServerUploadURL).pipe(
      map(event => {
        this.FormAdd.patchValue({
          FileUpload: event.NewName
        });
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
      if (typeof (event) === 'object') {
        // console.log(event.body);
      }

    });

  }
  private uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach(file => {
      this.uploadFile(file);
    });
    // console.log(this.files);
  }
  onClick() {
    const fileUpload = this.fileUpload.nativeElement; fileUpload.onchange = () => {
      this.files  = [];
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0});
      }
      this.uploadFiles();
    };
    fileUpload.click();

  }

  getFile(fileInput) {
    this.yourFile = fileInput.target.files[0];
    // console.log(this.yourFile);
  }

  OnClickDeleteFile(imagessslang){
    // console.log('delete files select');
    // console.log(this.FormAdd.value);
    if ( imagessslang === 'imagessth'){
      this.FormAdd.patchValue({
        FileUpload: ''
      });
    }
    if ( imagessslang === 'imagessth'){
      this.FormAdd.patchValue({
        FileUpload: ''
      });
    }


  }

  togglesettime(){
    this.settimeshow = !this.settimeshow;
  }
}
