import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboardfooterlinks',
  templateUrl: './dashboardfooterlinks.component.html',
  styleUrls: ['./dashboardfooterlinks.component.scss']
})
export class DashboardfooterlinksComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
