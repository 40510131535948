import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dashboardcirculationfrm',
  templateUrl: './dashboardcirculationfrm.component.html',
  styleUrls: ['./dashboardcirculationfrm.component.scss']
})
export class DashboardcirculationfrmComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
