<div class="row ml-2 blogservices">
  <ng-container *ngFor="let data of DataAllMenu">
    <div class="col-6 col-sm-6 col-md-4 col-lg-2 p-2">
      <ng-container *ngIf="data['SubMenu']==1;">
        <div class="card text-center">
          <div class="dropright">
            <div class="" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <H2 class="text-white">{{data.MenuNameTh}}</H2>
            </div>
            <div class="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <ng-container *ngFor="let datalist of data['submemulist']">
                <a class="dropdown-item" href="{{datalist['Links']}}"><h2>{{datalist['MenuNameTh']}}</h2></a>
              </ng-container>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</div>
