import { Component, OnInit } from '@angular/core';
import {ApiwebService} from '../../../apiweb.service';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-termpaperdetail',
  templateUrl: './termpaperdetail.component.html',
  styleUrls: ['./termpaperdetail.component.scss']
})
export class TermpaperdetailComponent implements OnInit {
  public DataTermpaper;
  public ContentShow = true;
  constructor(public apiweb: ApiwebService , public dialog: MatDialog, private datePipe: DatePipe, private router: Router, private route: ActivatedRoute ) { }

  ngOnInit(): void {
    this.route.params.subscribe(params => {
      if ( params.Id ){
        this.CallSearch(params.Id);
        this.ContentShow = true;
      }else{
        this.DataTermpaper = '';
        this.ContentShow = false;
      }
    });
  }
  CallSearch(Id): any {
    const datasearch = 'get_termpaper_id?Id=' + Id;
    this.apiweb.CallData('frontend', datasearch).subscribe( (dataresponse: any) => {
      if (dataresponse.status === true){
        const data = dataresponse.data[0];
        this.DataTermpaper = data;
      }
    });
  }
}
