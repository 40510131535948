<form action="https://cmu.on.worldcat.org/search" class="form-wc-search" target="_blank" method="get" ngNoForm>
<section class="search-banner text-white py-3 form-arka-plan" id="search-banner">
  <div class="container py-5 my-5">
    <div class="row text-center pb-4">
      <div class="col-md-12">
        <h2 class="text-white siyah-cerceve">Worldwide Search (OCLC)</h2>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card acik-renk-form">
          <div class="card-body">
            <div class="row">
              <div class="col-md-2">
                <div class="form-group ">
                  <select id="iller" class="form-control" >
                    <option selected>Everythings</option>
                    <option>Books</option>
                    <option>Article</option>
                    <option>Course Reserves</option>
                  </select>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-group ">
                  <!-- <label for="LimitResult">Limit results to:</label>-->
                  <select id="LimitResult" class="form-control" >
                    <option value="" selected>Libraries Worldwide</option>
                    <option value="sz:36683">Thai Libraries Consortium</option>
                    <option value="wz:59814" selected>Chiang Mai University Library</option>
                  </select>
                </div>
              </div>

              <div class="col-md-5">
                <div class="form-group ">
                  <input type="text" id="queryString" class="form-control" placeholder="Find books, e-books, journals, articles, dissertations and more">
                  <div class="row">
                    <div class="col-12 text-right">
                      <a href="http://cmu.on.worldcat.org/advancedsearch" target="_blank"><span class="text-dark small"><>advance search</span> </a>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-1">

                <button type="button" class="btn btn-warning pl-5 pr-5">Search</button>
              </div>

            </div>
            <!-- <p class="font-weight-light text-dark">Key Search</p>-->
            <div class="row">
              <div class="container text-left">
                คู่มือ การใช้งาน
              </div>

            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
</form>
<!--
  <div class="col-md-4">
                <div class="form-group ">
                  <select id="ilceler" class="form-control" >
                    <option selected>Tüm İlçeler</option>
                    <option>BMW</option>
                    <option>Audi</option>
                    <option>Maruti</option>
                    <option>Tesla</option>
                  </select>
                </div>
              </div>
-->
