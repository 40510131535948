<div class="row p-2">
  <div class="col-sm-12 col-md-12 col-lg-4">
    <a href="/" >
      <img [src]=LogoAddress alt="ห้องสมุด คณะ {{SiteName}}" style="max-width: 400px; max-height: 100px;width: 100%;">
    </a>
  </div>
  <div class="col-sm-12 col-md-12 col-lg-8">&nbsp;</div>
</div>
<div class="row bg-eng">
  <div class="container">
    <app-homenavigationtop></app-homenavigationtop>
  </div>
</div>
