import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-frmiconservices',
  templateUrl: './frmiconservices.component.html',
  styleUrls: ['./frmiconservices.component.scss']
})
export class FrmiconservicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
