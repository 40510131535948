import { Component, OnInit } from '@angular/core';
import {ApiwebService} from '../../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-journaldetail',
  templateUrl: './journaldetail.component.html',
  styleUrls: ['./journaldetail.component.scss']
})
export class JournaldetailComponent implements OnInit {
  public DataItem;
  public ContentTh;
  public pathimage = this.apiweb.WebsiteAddressAssets + 'journals/';
  constructor(public apiweb: ApiwebService , public dialog: MatDialog, private datePipe: DatePipe, private router: Router, private route: ActivatedRoute ) { }

  ngOnInit(): void {
    if (localStorage.getItem('lang') === 'En'){
      this.ContentTh = false;
    } else {
      localStorage.setItem('lang', 'Th');
      this.ContentTh = true;
    }
    this.route.params.subscribe(params => {
      // console.log(params.Id);
      this.apiweb.CallJournalId(params.Id).subscribe( (dataresponse: any) => {
        const data = dataresponse.data[0];
        // console.log(data);
        this.DataItem = data;
      });
    });
  }
  ClickLink(Id){
    this.router.navigate(['/journalsdetail/', { Id }]);
  }
  ClickBack(){
    this.router.navigate(['/journals/']);
  }

}
/*
Id
TitleTh
TitleEn
ISSN
ISBN
Pages
AuthorEditor
Publication
Publisher
Links
ImageCover
Faculty
DateAdd
FullTextCoverage
Frequency
ISSUE
StatusPublish
Status
UserAdd
*/
