import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ApiwebService} from '../../../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {UploadService} from '../../../../upload.service';

@Component({
  selector: 'app-frmtrendyarts',
  templateUrl: './frmtrendyarts.component.html',
  styleUrls: ['./frmtrendyarts.component.scss']
})
export class FrmtrendyartsComponent implements OnInit {
  public settimeshow = false;
  public contenttype = 'trendyarts' ;
  public ckeditor4config = this.apiweb.ConfigEditor4(this.apiweb.CkuploadApiAddress , this.contenttype);
  public CkeditorApiAddress = this.apiweb.CkeditorApiAddress;  // ฟอร์มกรอกข้อมูล
  public FormAdd: FormGroup;
  public txtHeaderType;
  public txtFrmType;
  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute, public uploadService: UploadService) { }

  ngOnInit(): void {
    this.FormAdd = this.formBuilder.group({
      Id: '',
      TitleTh: '',
      TitleEn: '',
      Links: '',
      DateAdd: '',
      DateTimeAdd: '',
      DetailTh: '',
      DetailEn: '',
      StatusPublish: 0,
      Status: '',
      TypeContent: '',
      Code: '',
      Action: ''
    });
    this.route.params.subscribe(params => {
      this.ChkTypeContent(this.contenttype);
      if (params.Action === 'Add'){
        this.txtFrmType = 'เพิ่ม';
        this.CreateContent();
      }
      if (params.Action === 'Edit'){
        this.txtFrmType = 'แก้ไข';
        this.EditContent(params.Id);
      }
    });
  }

  // เช็คข้อมูลประเภทเนื้อหาที่ต้องการจะเพิ่ม
  ChkTypeContent(typecontent){
    if (typecontent === 'edatabases'){
      this.txtHeaderType = 'ฐานข้อมูลอิเล็กทรอนิกส์';
    }

  }

  CreateContent() {
      this.FormAdd.patchValue({
        Id: '',
        TitleTh: '',
        TitleEn: '',
        Links: '',
        DateAdd: '',
        DateTimeAdd: '',
        DetailTh: '',
        DetailEn: '',
        StatusPublish: 0,
        Status: '',
        TypeContent: 'TrendyArtBooks',
        Action: 'Add'
      });
  }
  EditContent(Id){
    this.apiweb.CallData(this.contenttype, 'get_id?Id=' + Id).subscribe( (dataresponse: any) => {
      const data = dataresponse.data[0];
      this.FormAdd.patchValue({
        Id: data.Id,
        TitleTh: data.TitleTh,
        TitleEn: data.TitleEn,
        Links: data.Links,
        DetailTh: data.DetailTh,
        DetailEn: data.DetailEn,
        StatusPublish: data.StatusPublish,
        Status: data.Status,
        TypeContent: data.TypeContent,
        Code: data.Code,
        Action: 'Edit'
      });
    });

  }
  ClickSave(){
    // console.log(this.FormAdd.value);
    this.apiweb.CommandData( this.contenttype, 'set_command', this.FormAdd.value).subscribe((data: any[]) => {
      let linkback;
      linkback = 'dashboard/website/' + this.contenttype;
      this.router.navigate([linkback]);
    });
  }
  ClickCancel(){
    let linkback;
    linkback = 'dashboard/website/' + this.contenttype;
    this.router.navigate([linkback]);
  }

  // ตรวจสอบสถานะเผยแพร่ข้อมูล
  CheckSwitchTrueFalse(Dataval){
    if (Dataval === '1'){
      return true;
    }
    if (Dataval === '0' || Dataval === ''  || Dataval === 'null' ){
      return false;
    }
  }

  SwitchStatusPublishChange(){
    if (this.FormAdd.value.StatusPublish === '0'){
      this.FormAdd.patchValue({
        StatusPublish: 1
      });
    }else{
      this.FormAdd.patchValue({
        StatusPublish: 0
      });
    }
  }

}
