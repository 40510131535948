<div class="row bg_dashboardcirculationbread p-1">
  <div class="col-12">
    <H3>{{txtFrmType}} รายการวัสดุ</H3>
  </div>
</div>
<div class="row">
  <div class="container">
    <form [formGroup]="FormAdd" class="manageitem-form">

      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline" class="manageitem-full-width">
            <mat-label>รหัสครุภัณฑ์</mat-label>
            <input matInput placeholder="" formControlName="InventoryCode">
            <mat-hint>รหัสครุภัณฑ์ ตัวอย่างเช่น สม.7110-017-014 </mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline" class="manageitem-full-width">
            <mat-label>Barcode</mat-label>
            <input matInput placeholder="" formControlName="CodeItem">
            <mat-hint> </mat-hint>
          </mat-form-field>
          <div style="text-align:center">
            <ngx-barcode6 [bc-format]= barcodetype [bc-value]= FormAdd.value.CodeItem [bc-display-value]="true" ></ngx-barcode6>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline" class="manageitem-full-width">
              <mat-label>ชื่อวัสดุ</mat-label>
                <input matInput placeholder="" formControlName="ItemName">
              <mat-hint></mat-hint>
          </mat-form-field>
        </div>
      </div>

      <div class="row">
        <div class="col-10">
          <mat-form-field appearance="fill" class="manageitem-full-width">
            <mat-label>สถานะของ วัสดุ</mat-label>
            <mat-select formControlName="ItemStatusId">
              <mat-option *ngFor="let data of dataitemstatus" [value]="data.Id">
                {{data.StatusName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-2">
          <i class="fas fa-plus-circle fa-2x" (click)="openDialogManageItemStatus()"></i>
        </div>
      </div>

      <div class="row">
        <div class="col-10">
          <mat-form-field appearance="fill" class="manageitem-full-width">
            <mat-label>ประเภทของ วัสดุ</mat-label>
            <mat-select formControlName="ItemTypeId">
              <mat-option *ngFor="let data of dataitemtype" [value]="data.Id">
                {{data.TypeName}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="col-2">
          <i class="fas fa-plus-circle fa-2x" (click)="openDialogManageItemtype()"></i>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline" class="manageitem-full-width">
            <mat-label>ค่าปรับต่อวัน / บาท</mat-label>
            <input type="number" matInput placeholder="" formControlName="PricesOverdue">
            <mat-hint></mat-hint>
          </mat-form-field>
        </div>

      </div>
      <!--
      <div class="row">
        <div class="col-4">
          <label for="ItemLimit" class="col-sm-2 col-form-label">ItemLimit</label>
        </div>
        <div class="col-8">
          <input type="text" class="form-control" id="ItemLimit" placeholder="" formControlName="ItemLimit">
        </div>
      </div>
      -->
      <div class="row">
        <div class="col-12">
          <mat-form-field appearance="outline" class="manageitem-full-width">
            <mat-label>หมายเหตุ</mat-label>
            <textarea matInput placeholder="Ex. เพิ่ม รายละเอียด แตกหักสูญหาย ต่างๆ " formControlName="ItemComment"></textarea>
            <mat-hint></mat-hint>
          </mat-form-field>
        </div>
      </div>
    </form>
    <div class="row mt-2">

      <div class="col-12">
        <label>รูปภาพวัสดุ</label>
            <ng-container *ngFor="let datafile of dataListFile">
              <div class="row">
                <div class="col-12">
                  <img [src]=datafile.ImagesLocation style="max-width: 200px;"> <span (click)="DelFileUploadId(datafile.Id)"><i class="fas fa-times fa-2x text-danger"></i></span>
                  <hr>
                </div>
              </div>
            </ng-container>
      </div>

      <div class="col-12">
        <mat-card>
          <mat-card-content>
            <div *ngFor="let file of files">
              <mat-progress-bar [value]="file.progress"></mat-progress-bar>
            </div>
          </mat-card-content>
          <mat-card-actions>
            <button mat-button color="warn" (click)="onClick()">
              <i class="fas fa-cloud-upload-alt fa-2x">Upload</i>
            </button>
          </mat-card-actions>
        </mat-card>
        <input type="file" #fileUpload id="fileUpload" name="upload" accept="image/*" style="display:none;" />

      </div>
    </div>
    <div class="row text-center mt-3">
      <div class="col-12">
        <button class="btn btn-success mr-3" (click)="Clicksave()">บันทึก</button>
        <button class="btn btn-warning" (click)="Clickcancel()">ยกเลิก</button>
      </div>
    </div>

  </div>
</div>

