import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {HttpClient} from '@angular/common/http';
import {ApiwebService} from '../apiweb.service';
import {AuthService} from '../auth.service';


@Component({
  selector: 'app-callback',
  templateUrl: './callback.component.html',
  styleUrls: ['./callback.component.scss']
})
export class CallbackComponent implements OnInit {
  public code: string = this.route.snapshot.queryParamMap.get('code');
  public error: string;
  constructor(private route: ActivatedRoute, private http: HttpClient, private router: Router, private apiweb: ApiwebService, private auth: AuthService) { }
  ngOnInit(): void {
    // console.log(this.code);
    this.apiweb.getToken(this.code).subscribe((data: any[]) => {
        // console.log(data);
        if (data == null) {
          localStorage.clear();
          localStorage.removeItem('access_token');
          this.router.navigate(['login']);
          return false;
        } else {
          localStorage.setItem('currentUser', JSON.stringify(data));
          // console.log(localStorage.getItem('currentUser'));
          localStorage.setItem('access_token', this.route.snapshot.queryParamMap.get('code'));
          localStorage.setItem('refresh_token', this.route.snapshot.queryParamMap.get('refresh_token'));
          localStorage.setItem('itaccounttype_EN', data['itaccounttype_EN']);
          localStorage.setItem('cmuitaccount', data['cmuitaccount']);
          localStorage.setItem('cmuitaccount_name', data['cmuitaccount_name']);
          localStorage.setItem('student_id', data['student_id']);
          localStorage.setItem('prename_TH', data['prename_TH']);
          localStorage.setItem('firstname_TH', data['firstname_TH']);
          localStorage.setItem('lastname_TH', data['lastname_TH']);
          localStorage.setItem('prename_EN', data['prename_EN']);
          localStorage.setItem('firstname_EN', data['firstname_EN']);
          localStorage.setItem('lastname_EN', data['lastname_EN']);
          localStorage.setItem('organization_name_EN', data['organization_name_EN']);
          localStorage.setItem('organization_name_TH', data['organization_name_TH']);
          /* itaccounttype_EN: "Student Account"
             itaccounttype_TH: "นักศึกษาปัจจุบัน"
             itaccounttype_id: "StdAcc"
             organization_code: "05"
             prename_id: "MR"
          */
          // console.log(data);
          // console.log(data['itaccounttype_EN']);
          if (localStorage.getItem('itaccounttype_EN') === 'Alumni Account') {
            localStorage.setItem('datauser', JSON.stringify(data));
            this.router.navigate(['alumni']);
            return ;
          }

          if (localStorage.getItem('itaccounttype_EN') === 'Student Account') {
            localStorage.setItem('datauser', JSON.stringify(data));
            this.router.navigate(['student']);
            return ;
          }

          if (localStorage.getItem('itaccounttype_EN') === 'MIS Employee') {
            this.apiweb.ChkUserLibrary(data['cmuitaccount']).subscribe( (dataemail: any) => {
              console.log(dataemail.data);
              if (dataemail.status === true ){
                localStorage.setItem('datauser', JSON.stringify(dataemail.data));
                this.router.navigate(['dashboard']);
                return ;
              }else {
                localStorage.setItem('datauser', JSON.stringify(data));
                this.router.navigate(['empoloyee']);
                return false;
              }
            });

            // this.router.navigate(['empoloyee']);
            return ;
          }
        }
        if (data['Message']) {
          localStorage.removeItem('access_token');
          this.router.navigate(['login']);
          return false;
        }
      },
      (err) => {
        this.auth.logout();
        localStorage.removeItem('access_token');
        this.router.navigate(['login']);
        return false;
      });
  }



}

