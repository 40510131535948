import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiwebService} from '../../../apiweb.service';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {DatePipe} from '@angular/common';
import {UploadService} from '../../../upload.service';

export interface DialogData {
  Id: string;
  Action: string;
}
@Component({
  selector: 'app-dashboardcirculationmanagement',
  templateUrl: './dashboardcirculationmanagement.component.html',
  styleUrls: ['./dashboardcirculationmanagement.component.scss']
})
export class DashboardcirculationmanagementComponent implements OnInit {

  displayedColumns: string[] = ['Id', 'CodeItem', 'Title', 'editdelete'];
  public dataSource = new MatTableDataSource();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;

  public DataMeeting;
  public FormContents: FormGroup;
  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute ) { }

  ngOnInit(): void {
     this.CallAllItem();
  }
  public doFilter = (value: string) => {
    this.dataSource.filter = value.trim().toLocaleLowerCase();
  }
  CallAllItem(){
    this.apiweb.CallAllItem().subscribe( (data: any[]) => {
      // console.log(data);
      if (data.length > 0){
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
      }else{
        this.dataSource.data = [];
        this.dataSource.paginator = this.paginator;
      }

    });
  }
  ClickManageData(Action, Id){
    this.router.navigate(['/dashboard/circulation/manageitems/', { Action, Id }]);
  }

  openDialogDel(Action, Id): void {

    console.log(Id);
    const dialogRef = this.dialog.open(DialogDelitemsDialog, {
      data: { Action, Id}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.CallAllItem();
    });
  }

  openDialogDetail(Id): void {
    console.log(Id);
    const dialogRef = this.dialog.open(DialogDetailItems, {
      data: { Id },
      width: 'auto',
      height: 'auto',
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.CallAllItem();
    });
  }
}

// เพิ่ม ลบ แก้ไข
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-delitem-dialog',
  templateUrl: 'dialog-delitem-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogDelitemsDialog implements OnInit  {
  public FormDel: FormGroup;
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<DialogData>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public apiweb: ApiwebService, public uploadService: UploadService) {}
  ngOnInit() {
    this.FormDel = this.formBuilder.group({
      Id: this.data.Id,
      Action: this.data.Action
    });
  }
  clearFormDel() {
    this.FormDel.reset();
  }
  onNoClick(): void {
    this.clearFormDel();
    this.dialogRef.close();
  }
  onOkClick(): void {
    this.apiweb.CommandDelItem(this.FormDel.value).subscribe( data => {
      // console.log(data);
    });
    this.dialogRef.close();
  }
}

// เพิ่ม ลบ แก้ไข
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-detailitem-dialog',
  templateUrl: 'dialog-detailitem-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogDetailItems implements OnInit  {
  public dataItemDetail;
  public barcodetype = 'CODE39';
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<DialogData>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public apiweb: ApiwebService, public uploadService: UploadService) {}
  ngOnInit() {
    console.log(this.data);
    this.apiweb.CallItemId(this.data.Id).subscribe((dataresult: any) => {
      this.dataItemDetail = dataresult[0];
      console.log(this.dataItemDetail);
    });
  }

  onNoClick(): void {
    this.dialogRef.close();
  }

}
