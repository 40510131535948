

<mat-card>
  <mat-card-content>

    <div *ngFor="let listfile of FileListUpload">
      <div class="card" *ngIf="TypeInput === 'Files' ;else typeimages">
        <a href="/{{listfile.ImageLocation}}" target="_blank">
          <span>{{listfile.ImageNameOld}}</span>
        </a>
      </div>
     <span id="file-label1" (click)="OnClickDeleteFile(listfile.ImageNameOld, listfile.Id)"> Delete X </span><br>
      <ng-template #typeimages>

      </ng-template>
    </div>

    <ul>
      <li *ngFor="let file of files">
        <span>{{file.data.name}}</span>
        <mat-progress-bar [value]="file.progress"></mat-progress-bar>
        <span id="file-label" (click)="OnClickDeleteFile(file.data.name, file)"> Delete X </span>
      </li>
    </ul>

  </mat-card-content>
  <mat-card-actions>
    <button mat-button color="warn" (click)="onClick()">
      <mat-icon>file_upload</mat-icon>
      Upload
    </button>
  </mat-card-actions>
</mat-card>
<input type="file" #fileUpload id="fileUpload" name="fileUpload" multiple="multiple" accept="image/*" style="display:none;" />
