<div class="row">
  <div class="col-12">

<ul class="nav nav-tabs" id="myTab" role="tablist">
  <li class="nav-item">
    <a class="nav-link active" id="Everything-tab" data-toggle="tab" href="#Everything" role="tab" aria-controls="Everything" aria-selected="true"><i class="fa fa-university" aria-hidden="true"> Worldwide Search (OCLC) </i></a>
  </li>

  <li class="nav-item">
    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#Books" role="tab" aria-controls="Books" aria-selected="false"><i class="fa fa-book" aria-hidden="true"></i> Books</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="contact-tab" data-toggle="tab" href="#Article" role="tab" aria-controls="Article" aria-selected="false">Article</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="CourseReserves-tab" data-toggle="tab" href="#CourseReserves" role="tab" aria-controls="CourseReserves" aria-selected="false">Course Reserves</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="OPAC-tab" data-toggle="tab" href="#OPAC" role="tab" aria-controls="OPAC" aria-selected="false">OPAC</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="Renew-tab" data-toggle="tab" href="#Renew" role="tab" aria-controls="Renew" aria-selected="false">Renew</a>
  </li>
  <li class="nav-item">
    <a class="nav-link" id="EDS-tab" data-toggle="tab" href="#EDS" role="tab" aria-controls="EDS" aria-selected="false">EDS</a>
  </li>
</ul>
<div class="tab-content" id="myTabContent">
  <div class="tab-pane fade show active" id="Everything" role="tabpanel" aria-labelledby="Everything-tab"><app-searchtoolseveryting></app-searchtoolseveryting></div>
  <div class="tab-pane fade" id="Books" role="tabpanel" aria-labelledby="Books-tab"><app-searchtoolsbooks></app-searchtoolsbooks></div>
  <div class="tab-pane fade" id="Article" role="tabpanel" aria-labelledby="contact-tab"><app-searchtoolsarticle></app-searchtoolsarticle></div>
  <div class="tab-pane fade" id="CourseReserves" role="tabpanel" aria-labelledby="CourseReserves-tab"><app-searchtoolscoursereserve></app-searchtoolscoursereserve></div>
  <div class="tab-pane fade" id="OPAC" role="tabpanel" aria-labelledby="OPAC-tab"><app-searchtoolsopac></app-searchtoolsopac></div>
  <div class="tab-pane fade" id="Renew" role="tabpanel" aria-labelledby="Renew-tab"><app-searchtoolsrenew></app-searchtoolsrenew></div>
  <div class="tab-pane fade" id="EDS" role="tabpanel" aria-labelledby="EDS-tab"><app-searchtoolseds></app-searchtoolseds></div>
</div>
  </div>
</div>
