import { Component, OnInit } from '@angular/core';
import {ApiwebService} from '../../../apiweb.service';

@Component({
  selector: 'app-blockservicesicon',
  templateUrl: './blockservicesicon.component.html',
  styleUrls: ['./blockservicesicon.component.scss']
})
export class BlockservicesiconComponent implements OnInit {

  public DataIconServices;
  public ContentTh = false;
  public totalIcon;
  public iconmore = false;
  public Addresspaht;

  public DataAllMenu;
  constructor(public apiweb: ApiwebService) { }

  ngOnInit(): void {
    this.Addresspaht = this.apiweb.ApiAddress + 'assets/services/';
    this.CallServicesIcon();
  }

  CallIconServices(): void{
    this.apiweb.CallIconServices(localStorage.getItem('SiteFaculty'), 6 ).subscribe( (dataresponse: any) => {
      this.DataIconServices = dataresponse.data;
      this.totalIcon = dataresponse.data.length;
      // console.log(dataresponse.totals);
      if (dataresponse.totals >= 6){
        this.iconmore = true;
      }
    });
  }

  public CallServicesIcon(): any{
    this.apiweb.CallData('mainmenuiconservices', 'get_all?Faculty=' + this.apiweb.SiteFaculty).subscribe( (dataresponse: any) => {

      if (dataresponse.status === true) {
        const data = dataresponse.data;
        this.DataAllMenu = dataresponse.data;
        // console.log(this.DataAllMenu);
      }
    });
  }

}
