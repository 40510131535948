<div class="row">
  <div class="container">
    <div class="row">
      <div class="col-12">
        <a href="trendyarts"><span><i class="fas fa-backward">กลับไปหน้าหลัก</i></span></a>
      </div>
    </div>
    <div class="row border border-danger mt-2 mb-2 p-3 ">
      <div class="col-12">
        <div class="row" style="background-color: rgba(252,6,6,0.6);">
          <div class="col-12">
            <ng-container *ngIf="Content;else contenten">
              <h2>{{DataDetail.TitleTh}}</h2>
            </ng-container>
            <ng-template #contenten>
              <h2>{{DataDetail.TitleEn}}</h2>
            </ng-template>
          </div>
        </div>
        <div class="row justify-content-md-center">
          <div class="col-md-auto">
            <ngx-qrcode
              [elementType]="elementType"
              [value]="QRcode"
              cssClass="aclass"
              errorCorrectionLevel="L">
            </ngx-qrcode>
          </div>
          <div class="col col-lg-8">
            <ng-container *ngIf="Content;else contenten">
              <div [innerHTML]="DataDetail.DetailTh | ckeditor: 'html'"></div>
            </ng-container>
            <ng-template #contenten>
              <div [innerHTML]="DataDetail.DetailEn | ckeditor: 'html'"></div>
            </ng-template>
          </div>

        </div>
      </div>
    </div>


  </div>
</div>
