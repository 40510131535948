import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {FormBuilder} from '@angular/forms';
import {ApiwebService} from '../../../../apiweb.service';

@Component({
  selector: 'app-circulationreportuser',
  templateUrl: './circulationreportuser.component.html',
  styleUrls: ['./circulationreportuser.component.scss']
})
export class CirculationreportuserComponent implements OnInit {
  public DataUser;
  @ViewChild(MatSort, {static: true}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;

  displayedColumns: string[] = ['No', 'StudentId', 'Name', 'Faculty', 'EmailCMU', 'itaccounttype_EN', 'DateAdd', 'Command'];
  dataSource = new MatTableDataSource();

  constructor(public formBuilder: FormBuilder, public apiweb: ApiwebService) { }
  ngOnInit(): void {
    // @ts-ignore
    this.DataUser = this.apiweb.CallAllUser().subscribe( (dataresponse: any) => {
      this.DataUser = dataresponse.data;
      this.dataSource.data = dataresponse.data;
      // console.log(dataresponse.data);
    });
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

}
