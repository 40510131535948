import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ApiwebService} from '../../../apiweb.service';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-bookdeatil',
  templateUrl: './bookdeatil.component.html',
  styleUrls: ['./bookdeatil.component.scss']
})
export class BookdeatilComponent implements OnInit {
  public DataItem;
  public ContentTh;
  public pathimage = this.apiweb.WebsiteAddressAssets + 'newbooks/';
  constructor(public apiweb: ApiwebService , public dialog: MatDialog, private datePipe: DatePipe, private router: Router, private route: ActivatedRoute ) { }

  ngOnInit(): void {
    if (localStorage.getItem('lang') === 'En'){
      this.ContentTh = false;
    } else {
      localStorage.setItem('lang', 'Th');
      this.ContentTh = true;
    }
    this.route.params.subscribe(params => {
      console.log(params.Id);
      this.apiweb.CallNewBooksId(params.Id).subscribe( (dataresponse: any) => {
        const data = dataresponse.data[0];
        console.log(data);
        this.DataItem = data;
      });
    });
  }
  ClickLink(Id){
    this.router.navigate(['/newbooksdetail/', { Id }]);
  }
  ClickBack(){
    this.router.navigate(['/newbooks/']);
  }

}
