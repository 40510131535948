<form name="searchtool" action="http://search.lib.cmu.ac.th/search/d/index.php" method="POST" target="_blank" ngNoForm>
  <div class="w3-row">
    <div class="w3-col" style="max-width: 50px;"><p>
      <img src="http://library.cmu.ac.th/cmul/sites/default/files/logo_cmu.png" alt="cmu library" style="width: 100%;"/></p>
    </div>
    <div class="w3-rest w3-container">
      <p>
        <a href="http://search.lib.cmu.ac.th" target="_parent">ค้นหาหนังสือ วารสาร โสตทัศนวัสดุ และหนังสืออิเล็กทรอนิกส์ </a>
      </p>
      <p> <select name="searchtype" id="searchtype" class="w3-select w3-border-theme">
        <option value="a"> ผู้แต่ง</option>
        <option value="t"> ชื่อเรื่อง</option>
        <option value="s"> ชื่อวารสาร</option>
        <option value="d"> หัวเรื่อง</option>
        <option value="Y"> KEYWORD</option>
        <option value="j"> หัวเรื่องทางการแพทย์</option>
        <option value="m"> เลขเรียกหนังสือ</option>
        <option value="c"> LC CALL NO</option>
        <option value="l"> NLM CALL NO</option>
        <option value="i"> เลขมาตรฐานสากล</option>
        <option value="p"> PROF/TA</option>
        <option value="r"> COURSE</option>
      </select></p>
      <p>
        <input type="text" name="searcharg" id="searcharg"  value="" class="w3-input w3-hover-sand" placeholder="คำค้น">
      </p>
      <p>
        <input type="submit" name="SUBMIT" value="Search" class="w3-btn w3-theme-d3" >
      </p>
      <p>
        <a href="http://library.cmu.ac.th/cmul/sites/default/files/web/CMU-CMULOPAC.pdf" target="_blank"><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
        <!--
          {{text_opac}}
        -->
        </a>
      </p>

    </div>
  </div>
</form> <br>
