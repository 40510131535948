import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-layoutstudent',
  templateUrl: './layoutstudent.component.html',
  styleUrls: ['./layoutstudent.component.scss']
})
export class LayoutstudentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
