import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ApiwebService} from '../../../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {UploadService} from '../../../../upload.service';
import {catchError, map} from 'rxjs/operators';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {of} from 'rxjs';

@Component({
  selector: 'app-frmgallery',
  templateUrl: './frmgallery.component.html',
  styleUrls: ['./frmgallery.component.scss']
})
export class FrmgalleryComponent implements OnInit {
  public settimeshow = false;
  public ContentType = 'gallery' ;
  public ckeditor4config = this.apiweb.ConfigEditor4(this.apiweb.CkuploadApiAddress , this.ContentType);
  public CkeditorApiAddress = this.apiweb.CkeditorApiAddress;

  // ฟอร์มกรอกข้อมูล
  public FrmGallery: FormGroup;
  public txtHeaderType = 'อัลบัมภาพ';
  public txtFrmType;
  // ตั้งค่าเช็คบ๊อค
  public StatusBtn = false;

  // กำหนดข้อมูลการบันทึกข้อมูลลงฐานข้อมูล
  public Status;
  @ViewChild('fileUpload') fileUpload: ElementRef; files  = [];
  // ตั้งค่าอัพโหลด
  public SiteAddress = this.apiweb.WebsiteAddress;
  public ServerUploadURL ;
  public ServerUploadCKEDITORURL ;
  public FileURL;
  public yourFile: File;

  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute, public uploadService: UploadService) { }

  ngOnInit(): void {
    // ตั้งค่าอัพโหลดไฟล์
    this.ServerUploadURL = this.apiweb.UploadApiAddress + '?path=' + this.ContentType ;
    this.ServerUploadCKEDITORURL = this.apiweb.CkuploadApiAddress + '?path=' + this.ContentType ;
    // ตำแหน่งที่ตั้งไฟล์
    this.FileURL = this.SiteAddress + 'api/assets/' + this.ContentType + '/' ;

    this.FrmGallery = this.formBuilder.group({
      Id: '',
      TitleTh: '',
      TitleEn: '',
      ContentTh: '',
      ContentEn: '',
      Faculty: '',
      EventDate: '',
      UserAdd: '',
      ImageSS: '',
      StatusPublish: '',
      CheckStatusPublish: '',
      Status: '',
      Action: ''
    });

    this.route.params.subscribe(params => {
      // console.log(params);

      if (params.Action === 'Add'){
        this.txtFrmType = 'เพิ่ม';
        setTimeout(() => {
          this.CreateContent();
        }, 500);

      }
      if (params.Action === 'Edit'){
        this.txtFrmType = 'แก้ไข';
        // console.log(params.Action);
        this.EditContent(params.Id);
      }
    });
  }



  CreateContent(): void{
    const dataadd = { Faculty: this.apiweb.SiteFaculty, Action: 'Add' };
    this.apiweb.CommandData(this.ContentType, 'set_create', dataadd).subscribe( (dataresponse: any) => {
      if (dataresponse.status === true){
        const data = dataresponse.data[0];
        console.log(data);
        this.FrmGallery.patchValue({
          Id: data.Id,
          TitleTh: data.TitleTh,
          TitleEn: data.TitleEn,
          ContentTh: data.ContentTh,
          ContentEn: data.ContentEn,
          Faculty: data.Faculty,
          EventDate: data.EventDate,
          UserAdd: data.UserAdd,
          ImageSS: data.ImageSS,
          StatusPublish: data.StatusPublish,
          CheckStatusPublish: this.StatusBtn,
          Status: data.Status,
          Action: 'Add'
        });
        this.CheckSwitchTrueFalse(data.StatusPublish);
      }
    });

  }
  EditContent(ContentId): void{

    this.apiweb.CommandCallDataId(this.ContentType, 'get_id', 'Id', ContentId).subscribe( (dataresponse: any) => {
      const data = dataresponse.data[0];
      // console.log(data);
      this.FrmGallery.patchValue({
        Id: data.Id,
        TitleTh: data.TitleTh,
        TitleEn: data.TitleEn,
        ContentTh: data.ContentTh,
        ContentEn: data.ContentEn,
        Faculty: data.Faculty,
        EventDate: data.EventDate,
        UserAdd: data.UserAdd,
        ImageSS: data.ImageSS,
        StatusPublish: data.StatusPublish,
        CheckStatusPublish: this.StatusBtn,
        Status: data.Status,
        Action: 'Edit'
      });
      this.CheckValueCheckBox(this.FrmGallery.value.CheckStatusPublish);

    });
  }
  ClickSave(): void{
    this.apiweb.CommandData(this.ContentType, 'set_command' , this.FrmGallery.value).subscribe((dataresponse: any) => {
        console.log(dataresponse);
        let linkback;
        linkback = 'dashboard/website/' + this.ContentType;
        this.router.navigate([linkback]);
    });
/*
    this.apiweb.CommandManageBanners(this.FrmGallery.value).subscribe((data: any[]) => {
      let linkback;
      linkback = 'dashboard/website/' + this.ContentType;
      this.router.navigate([linkback]);
    });*/
  }

  ClickCancel(): void{
    let linkback;
    linkback = 'dashboard/website/' + this.ContentType;
    this.router.navigate([linkback]);
  }

  toggleVisibility(e): void  {
    this.StatusBtn = e.target.checked;
  }
  checkValue(event): any {
     console.log(event);
     this.CheckValueCheckBox(this.FrmGallery.value.CheckStatusPublish);
  }
  CheckValueCheckBox(data): void{
    if (data === 1 || data === '1' || data === true){
      this.StatusBtn = true;
      this.FrmGallery.patchValue({
        StatusPublish: 1
      });
      console.log(this.FrmGallery.value);
    }else{
      this.StatusBtn = false;
      this.FrmGallery.patchValue({
        StatusPublish: 0
      });
      console.log(this.FrmGallery.value);
    }
  }



  // ตรวจสอบสถานะเผยแพร่ข้อมูล
  CheckSwitchTrueFalse(Dataval): any{
    if (Dataval === '1'){
      return true;
    }
    if (Dataval === '0' || Dataval === ''  || Dataval === 'null' ){
      return false;
    }
  }

  SwitchStatusPublishChange(): void{
    if (this.FrmGallery.value.StatusPublish === '0'){
      this.FrmGallery.patchValue({
        StatusPublish: '1'
      });
      console.log(this.FrmGallery.value);
    }else{
      this.FrmGallery.patchValue({
        StatusPublish: '0'
      });

      console.log(this.FrmGallery.value);
    }
  }


  //////////////////////////////////////////////////////////////////////////
  uploadFile(file): void{
    const formData = new FormData();
    formData.append('file', file.data);
    formData.append('path', this.ContentType);
    // console.log(file.data);
    file.inProgress = true;
    this.uploadService.upload(formData, this.ServerUploadURL).pipe(
      map(event => {
        this.FrmGallery.patchValue({
          ImageSS: event.NewName
        });
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
      if (typeof (event) === 'object') {
        // console.log(event.body);
      }
    });

  }
  private uploadFiles(): void{
    this.fileUpload.nativeElement.value = '';
    this.files.forEach(file => {
      this.uploadFile(file);
    });
    // console.log(this.files);
  }
  onClick(): void{
    const fileUpload = this.fileUpload.nativeElement; fileUpload.onchange = () => {
      this.files  = [];
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0});
      }
      this.uploadFiles();
    };
    fileUpload.click();

  }
  getFile(fileInput): void{
    this.yourFile = fileInput.target.files[0];
    // console.log(this.yourFile);
  }
  OnClickDeleteFile(imagessslang): void{
    // console.log('delete files select');
    // console.log(this.FrmGallery.value);
    if ( imagessslang === 'ImageSS'){
      this.FrmGallery.patchValue({
        ImageSS: ''
      });
    }
  }

  togglesettime(): void{
    this.settimeshow = !this.settimeshow;
  }




}
