

<div class="row mt-1 pl-3 pr-3">
  <div class="col-sm-12 col-md-3 col-lg-3 col-xl-3">
    <div class="row card-rigth-eng">
      <div class="col-12">
        <ul class="list-group">
              <li class="list-group-item active">Search and Find</li>
              <li class="list-group-item"><a href="http://library.cmu.ac.th/cmul/en/content/e-databases" target="_blank"> e-database</a></li>
              <li class="list-group-item"><a href="http://library.cmu.ac.th/e-database/reference_database/redirect.php?DBID=32" target="_blank">Science Direct</a></li>
              <li class="list-group-item"><a href="http://library.cmu.ac.th/e-database/reference_database/redirect.php?DBID=19" target="_blank">IEEE</a></li>
              <li class="list-group-item"><a href="http://library.cmu.ac.th/e-database/reference_database/redirect.php?DBID=40" target="_blank">Web of Science</a></li>
            </ul>
      </div>
    </div>
    <div class="row mt-2 card-rigth-eng">
      <div class="col-12">
        <ul class="list-group">
              <li class="list-group-item active">Quick Services</li>
              <li class="list-group-item"><a href="http://library.cmu.ac.th/journalselector" target="_blank">บริการสนับสนุนการตีพิมพ์ผลงานวิชาการ</a></li>
              <li class="list-group-item"><a href="http://library.cmu.ac.th/cmul/en/content/renew-online" target="_blank" title="บริการยืมต่อด้วยตนเอง">Renew Online</a></li>
              <li class="list-group-item"><a href="http://library.cmu.ac.th/rsc/?subjectguide_list.php&groupid=2&gid=4&p=2" target="_blank" title="บริการรวบรวมแหล่งสารสนเทศไว้ในที่เดียวกัน">Subject Guides</a></li>
            </ul>
      </div>
    </div>

    <div class="row mt-2 card-rigth-eng">
      <div class="col-12">
            <ul class="list-group">
              <li class="list-group-item active">Web Links</li>
              <li class="list-group-item"><a href="http://library.cmu.ac.th/cmul/" target="_blank" title="สำนักหอสมุดมหาวิทยาลัยเชียงใหม่">Chiang Mai University Library</a></li>
              <li class="list-group-item"><a href="http://library.cmu.ac.th/rsc/" target="_blank" title="บรรณารักษ์พบนักวิจัย">Research Support</a></li>
              <li class="list-group-item"><a href="http://library.cmu.ac.th/ntic" target="_blank" title="ศูนย์สนเทศภาคเหนือ">Northern Thai Information Center</a></li>
              <li class="list-group-item"><a href="http://www.cmu.ac.th/" target="_blank" title="มหาวิทยาลัยเชียงใหม่">Chiang Mai University</a></li>
            </ul>
      </div>
    </div>
    <div class="row mt-2 card-rigth-eng">
      <div class="col-12">
            <ul class="list-group">
              <li class="list-group-item active">Science Technology Libraries,CMU</li>
              <li class="list-group-item"><a href="http://library.cmu.ac.th/faculty/agro" target="_blank">Faculty of  Agro-industry Library</a></li>
              <li class="list-group-item"><a href="http://lib.arc.cmu.ac.th/2013" target="_blank">Faculty of  Architecture Library</a></li>
              <li class="list-group-item"><a href="http://library.cmu.ac.th/faculty/agric" target="_blank" title="คณะเกษตรศาสตร์">Faculty of Agriculture Library</a></li>
              <li class="list-group-item"><a href="http://www1.science.cmu.ac.th/library/" target="_blank">Faculty of  Science Library</a></li>
            </ul>
      </div>
    </div>

  </div>
  <div class="col-sm-12 col-md-9 col-lg-9 col-xl-9">
    <div class="row">
      <div class="container">

    <div class="row">
      <div class="col-12">
        <div class="row p-3 of-searchbox bg-image-full" [ngStyle]="StyleSearchBox">
          <div class="container">
            <div class="row justify-content-center">
              <div class="col-12 text-center">
                <app-blogofsearchv1></app-blogofsearchv1>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <app-blogslidewithcaption></app-blogslidewithcaption>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <app-facebook></app-facebook>
      </div>
    </div>
      </div>
    </div>
  </div>
</div>


<!--
    <div class="row mt-2">
      <div class="col-12">
        <app-blockservicesicon></app-blockservicesicon>
      </div>
    </div>
-->

<div class="row mt-2 bg-iconservices p-3">
  <div class="col-12">
    <div class="card">
      <div class="card-header">
        <h3>Related Links</h3>
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-2">
            <a href="http://cmuir.cmu.ac.th/">
              <img src="api/assets/services/dspace-logo-only.png" alt="cmuir" class="w3-margin-top" style="width: 100%;">
            </a>
          </div>
          <div class="col-2">
            <a href="http://www.cmu.ac.th">
              <img src="api/assets/services/logo_cmu.png" alt="chiang mai university" class="w3-margin-top" style="width: 100%;">
            </a>
          </div>
          <div class="col-2">
            <a href="http://library.cmu.ac.th">
              <img src="api/assets/services/cmul_short_logo.png" alt="cmu library" class="w3-margin-top" style="width: 100%;">
            </a>
          </div>
          <div class="col-2">
            <a href="http://www.agri.cmu.ac.th">
              <img src="api/assets/services/logo_agri_cmu_short.png" alt="agri cmu" class="w3-margin-top" style="width: 100%;">
            </a>
          </div>
          <div class="col-2">
            <a href="http://library.cmu.ac.th/faculty/agric/bee">
              <img src="api/assets/services/bee_datatbase_2.png" alt="bee databases" class="w3-margin-top" style="width: 100%">
            </a>
          </div>
          <div class="col-2">
            <a href="http://library.cmu.ac.th/journalsupport" target="_blank">
              <img src="api/assets/services/banner_academic.jpg" alt="journal support" class="w3-margin-top" style="width: 100%">
            </a>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
