<form action="https://cmu.on.worldcat.org/courseReserves/search" target="_blank" method="get" ngNoForm>

  <div class="w3-row"><h3>Find <span>Course</span></h3></div>
  <div class="w3-row">
    <div class="w3-row w3-right-align">
      <a class="w3-hover w3-rigth w3-tiny w3-text-purple" title="Staff Sign in" href="https://cmu.on.worldcat.org/signin?postAuth=https://cmu.on.worldcat.org/discovery" target="_blank">Staff Sign In</a>
    </div>
  </div>
  <div class="w3-row">
    <div class="w3-col w3-theme-d1" style="width:90%">
      <input type="hidden" name="limiter" value="any">
      <input type="text" placeholder="Find course...." id="query5" class="w3-input w3-border " name="queryString"/>
    </div>
    <div class="w3-col"  style="width:10%;">
      <button class="w3-botton w3-white w3-left w3-xlarge w3-theme-d1"><span class="fa fa-search w3-xlarge"></span></button>
      <input type="hidden" name="lang" value="en">
    </div>
  </div>
  <div class="w3-row">
    <label class="w3-tiny">Search for:</label>
    <select class="w3-select" name="courseSearchField">
      <option value="courseName">Course Name</option>
      <option value="instructorName">Instructor Name</option>
      <option value="department">Department</option>
      <option value="prefix">Prefix</option>
    </select>

  </div>


</form>
<div class="w3-row w3-margin">
  <a href="Course-Reserves.pdf" target="_blank" class="left"><i class="fa fa-file-pdf-o" aria-hidden="true"></i>
  <!--
    {{text_courseReserves}}
    -->
  </a>

</div>
