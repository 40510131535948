import { Component, OnInit } from '@angular/core';
import {ApiwebService} from '../../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-servicesdetail',
  templateUrl: './servicesdetail.component.html',
  styleUrls: ['./servicesdetail.component.scss']
})
export class ServicesdetailComponent implements OnInit {
  public DataContents;
  public ContentTh;
  constructor(public apiweb: ApiwebService, public router: Router, public dialog: MatDialog, public datePipe: DatePipe, public route: ActivatedRoute) { }

  ngOnInit(): void {

    this.route.params.subscribe(params => {
      // console.log(params);
      this.SearchContent(params.Id);
    });

    if (localStorage.getItem('lang') === 'En'){
      this.ContentTh = false;
    } else {
      localStorage.setItem('lang', 'Th');
      this.ContentTh = true;
    }

  }

  ClickManageData(Id){
    this.router.navigate(['Contents/' + Id ]);
  }

  /*
  ClickManageData(Id, Title){
    this.router.navigate(['Contents/' + Id , { Title }]);
  }
  */

  SearchContent(Id){
    this.apiweb.CallIconServicesId(Id).subscribe( (data: any[]) => {
      this.DataContents = data[0];
      // console.log(data);
    });
  }
}
