

<div class="row">
  <div class="container">

    <div class="row mt-2 p-2" style="background-color: #888888;">
      <div class="col-12">
        <h2 class="">{{txtFrmType}} บทความ</h2>
      </div>
    </div>
    <form [formGroup]="FormAdd">



        <div class="row mt-2">
          <div class="col-12">
            <div class="form-group">
              <label for="ArticleTitle"><strong>ชื่อบทความ</strong></label>
              <input type="text" class="form-control" id="ArticleTitle" placeholder="ชื่อบทความ" formControlName="ArticleTitle">
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            <div class="form-group">
              <label for="ArticleSo"><strong>Imprint</strong></label>
              <input type="text" class="form-control" id="ArticleSo" placeholder="Title" formControlName="ArticleSo">
            </div>
          </div>
        </div>

      <div class="row mt-2">
        <div class="col-12">
          <label>ไฟล์ประกอบเนื้อหา</label>
          <mat-card>
            <mat-card-content>
              <div class="row" *ngIf="FormAdd.value.Filelocation !== '' || FormAdd.value.Filelocation === 'null' " >
                <div class="col-10">
                  <a href="{{FileURL}}{{FormAdd.value.Filelocation}}" target="_blank"><span class="">{{FormAdd.value.Filelocation}}</span></a>
                </div>
                <div class="col-2">
                  <i class="fas fa-window-close" (click)="OnClickDeleteFile('Filelocation')"> ลบไฟล์</i>
                </div>

              </div>
              <div *ngFor="let file of files">
                <mat-progress-bar [value]="file.progress"></mat-progress-bar>
              </div>
            </mat-card-content>
            <mat-card-actions>
              <button mat-button color="warn" (click)="onClick()">
                <i class="fas fa-cloud-upload-alt fa-2x">Upload</i>
              </button>
            </mat-card-actions>
          </mat-card>
          <input type="file" #fileUpload id="fileUpload" name="upload" accept="pdf/*" style="display:none;" />

        </div>
      </div>

      <div class="col-12 pt-2 pb-2" style="background-color: #e4e4e4">
      <div class="custom-control custom-switch">
        <input type="checkbox" class="custom-control-input input-lg" id="StatusPublish" [checked]="CheckSwitchTrueFalse(FormAdd.value.StatusPublish)" formControlName="StatusPublish" >
        <label class="custom-control-label" for="StatusPublish">สถานะการเผยแพร่ข้อมูล</label>
      </div>
    </div>
    </form>
    <div class="row mb-2">
      <div class="col-12 text-center">
        <span class="btn btn-primary mr-3" (click)="ClickSave()"><i class="far fa-save"></i> บันทึกข้อมูล</span>
        <span class="btn btn-warning" (click)="ClickCancel()"><i class="fas fa-share fa-rotate-180"></i> ยกเลิก</span>
      </div>
    </div>
  </div>

</div>
