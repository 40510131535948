import {Component, Input, OnInit} from '@angular/core';
import {ApiwebService} from '../../../apiweb.service';

@Component({
  selector: 'app-headerhome',
  templateUrl: './headerhome.component.html',
  styleUrls: ['./headerhome.component.scss']
})
export class HeaderhomeComponent implements OnInit {

  // tslint:disable-next-line:no-input-rename
  @Input('LogoAddress') LogoAddress;
  // tslint:disable-next-line:no-input-rename
  @Input('SiteName') SiteName;

  constructor(public apiweb: ApiwebService) { }

  ngOnInit(): void {
  }

}
