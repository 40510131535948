
<div class="row">
  <div class="col-12"><h1>บริหารจัดการเมนู ส่วนบนของเว็บไซต์ (Top Navigations) </h1></div>
</div>
<div class="row">
  <div class="col-6 border border-success">
    <div class="row">
      <div class="col-6">
        <h2>เมนูหลัก</h2>
      </div>
      <div class="col-6">
        <span (click)="openDialogAddMainMenu('Add', '', '')"><h2><i class="fas fa-plus-circle text-success">เพิ่มเมนูใหม่</i></h2></span>
      </div>
    </div>
    <div cdkDropList class="example-list" (cdkDropListDropped)="drop($event)">
      <div class="example-box" *ngFor="let data of Datamainmenu" cdkDrag>
        <div class="example-custom-placeholder" *cdkDragPlaceholder></div>

        <div class="row">
          <div class="col-8">
            {{data.MenuNameTh}}
          </div>
          <div class="col-4 text-left">
            <i class="fas fa-edit fa-2x text-primary mr-1" (click)="openDialogAddMainMenu('Edit', data.Id, '')" ></i>
            <i class="fas fa-eraser fa-2x text-danger mr-1" (click)="openDialogAddMainMenu('Del', data.Id, '')"></i>

            <span *ngIf="data.submemulist" (click)="CallDataSubMainMenu(data.submemulist,data.Id)"><i class="fas fa-list-ol fa-2x text-primary"></i></span>

            <!-- <span *ngIf="data.SubMenu===true || data.SubMenu==='1' " (click)="CallSubMainMenu(data.Id)"><i class="fas fa-list-ol fa-2x text-primary"></i></span>-->
          </div>

        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12"><button class="btn btn-success" (click)="SaveListRank()"> Save</button></div>
    </div>
  </div>

  <div class="col-6 border border-success" *ngIf="ShowHideSubMenu">

    <div class="row">
      <div class="col-6">
        <h2>เมนูย่อย</h2>
      </div>
      <div class="col-6">
        <span (click)="openDialogAddMainMenu('Add', '', MainMenuId)"><h2><i class="fas fa-plus-circle text-success">เพิ่มเมนูย่อย</i></h2></span>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        รายการเมนูย่อยของ {{SubMenuID}}
      </div>
    </div>
    <div cdkDropList class="example-list" (cdkDropListDropped)="dropSubMenu($event)">
      <div class="SubMenu" *ngFor="let data of DataSubMainMenu" cdkDrag>
        <div class="example-custom-placeholder" *cdkDragPlaceholder>

        </div>
        <div class="row">
          <div class="col-6">
            {{data.MenuNameTh}}
          </div>
          <div class="col-3 text-right">
            <i class="fas fa-edit fa-2x text-primary" (click)="openDialogAddMainMenu('Edit', data.Id, MainMenuId)" ></i>
            <i class="fas fa-eraser fa-2x text-danger" (click)="openDialogAddMainMenu('Del', data.Id, MainMenuId)" ></i>
          </div>
          <div class="col-3 text-right">
            <!--
            <span *ngIf="data.SubMenu===true || data.SubMenu==='1' " (click)="CallSubMainMenu(data.Id)"><i class="fas fa-plus-circle fa-2x text-primary"></i><i class="fas fa-list-ol fa-2x text-primary"></i></span>
            -->
          </div>
        </div>

      </div>
    </div>

    <div class="row mt-2">
      <div class="col-12"><button class="btn btn-success" (click)="SaveListRankSubMainMenu()"> Save Submenu</button></div>
    </div>
  </div>
</div>

<!--


<div class="row border border-warning" *ngFor="let data of Datamainmenu">
  <div class="col-4">
      <div class="row ">
        <div class="col-12">
          {{data.MenuNameTh}}<br>
          {{data.MenuNameEn}}
        </div>
      </div>
  </div>
  <div class="col-8">
    <div class="row border border-success">
      <div class="col-12">
        sub menu 1
      </div>
    </div>
  </div>
</div>

  <div class="row">
    <div class="col-6"><button class="btn btn-success" (click)="SaveListRank()"> Save</button></div>
    <div class="col-6"><button class="btn btn-success" (click)="SaveListRankSubMainMenu()"> Save Submenu</button></div>
  </div>
  -->
