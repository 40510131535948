

<div class="row">
  <div class="container">

    <div class="row mt-2 p-2" style="background-color: #888888;">
      <div class="col-12">
        <h2 class="">{{txtFrmType}} {{txtHeaderType}}</h2>
      </div>
    </div>
    <form [formGroup]="FormAdd">
      <div class="row mt-2">
        <div class="col-12">

          <ul class="nav nav-tabs" id="myTab" role="tablist">
            <li class="nav-item">
              <a class="nav-link active" id="home-tab" data-toggle="tab" href="#thai" role="tab" aria-controls="thai" aria-selected="true">ภาษาไทย</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" id="profile-tab" data-toggle="tab" href="#eng" role="tab" aria-controls="eng" aria-selected="false">English</a>
            </li>
          </ul>
          <div class="tab-content" id="myTabContent">
            <div class="tab-pane fade show active" id="thai" role="tabpanel" aria-labelledby="thai">
              <div class="row mt-2">
                <div class="col-12">
                  <div class="form-group">
                    <label for="titleth"><strong>ชื่อเรื่อง</strong></label>
                    <input type="text" class="form-control" id="titleth" placeholder="ชื่อเรื่อง" formControlName="TitleTh">
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  <strong>เนื้อหา</strong>
                  <ckeditor  editorUrl="https://finearts.library.cmu.ac.th/api/ckeditor/ckeditor.js" formControlName="ContentTh"></ckeditor>
                </div>
              </div>

            </div>

            <div class="tab-pane fade" id="eng" role="tabpanel" aria-labelledby="eng">

              <div class="row">
                <div class="col-12">
                  <div class="form-group">
                    <label for="titleen"><strong>ชื่อเรื่อง ภาษาอังกฤษ</strong></label>
                    <input type="text" class="form-control" id="titleen" placeholder="Title" formControlName="TitleEn">
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-12">
                  <label><strong>เนื้อหาภาษาอังกฤษ</strong></label>
                  <ckeditor  editorUrl="https://finearts.library.cmu.ac.th/api/ckeditor/ckeditor.js" formControlName="ContentEn"></ckeditor>
                </div>
              </div>


            </div>
          </div>
        </div>
      </div>

      <div class="row mt-2">
        <div class="col-12">
          <label for="titleen">รูปภาพตัวอย่างประกอบเนื้อหา</label>
          <mat-card>
            <mat-card-content>
              <div class="row" *ngIf="FormAdd.value.ImageSSTh === '' || FormAdd.value.ImageSSTh === null  ;else haveimages" >

              </div>
              <ng-template #haveimages >
                <div class="col-9">
                  <img src="{{FileURL}}{{FormAdd.value.ImageSSTh}}" style="width: 100%; max-width: 250px;">
                </div>
                <div class="col-3">
                  <i class="fas fa-window-close" (click)="OnClickDeleteFile('imagessth')"> ลบรูปภาพ</i>
                </div>

              </ng-template>
              <div *ngFor="let file of files">
                <mat-progress-bar [value]="file.progress"></mat-progress-bar>
              </div>

            </mat-card-content>
            <mat-card-actions>
              <div class="row">
                <div class="col-12">
                  <button mat-button color="warn" (click)="onClick()">
                    <i class="fas fa-cloud-upload-alt fa-2x">Upload</i>
                  </button>
                </div>
                <div class="col-12">
                  <strong class="text-danger">ขนาดรูปภาพที่เหมาะสมในการอัพโหลด 1920 * 500 pixel</strong>
                </div>
              </div>
            </mat-card-actions>

          </mat-card>

          <input type="file" #fileUpload id="fileUpload" name="upload" accept="image/*" style="display:none;" />

        </div>
      </div>
      <div class="row mt-2 p-2 border border-warning">
        <div class="col-12">
          <div class="form-group">
            <label for="ExternalLink">ลิงค์ไปยังเว็บไซต์ภายนอก</label>
            <input type="text" class="form-control" id="ExternalLink" placeholder="Ex: http://google.com" formControlName="ExternalLink">
          </div>
          * หมายเหตุ : เมื่อใส่ลิงค์ตรงนี้ เมื่อเปิดมายังเนื้อหาภายในหน้านี้ จะลิงค์ไปยังเว็บไซต์ที่กำหนด
        </div>
      </div>




      <div class="row mt-2 p-2 border border-warning"  *ngIf="settimeshow">
        <div class="col-12">
          <strong>ตั้งเวลาการเผยแพร่ข้อมูล</strong>
        </div>
        <div class="col-6">
          <div class="form-group form-inline">
            <label for="DatePublish">วันที่ต้องการเริ่มเผยแพร่</label>
            <input type="date" class="form-control" id="DatePublish"  formControlName="DatePublish">
          </div>
        </div>
        <div class="col-6">
          <div class="form-group form-inline">
            <label for="DateUnpublish">วันที่ต้องการยกเลิกเผยแพร่</label>
            <input type="date" class="form-control" id="DateUnpublish"  formControlName="DateUnpublish">
          </div>
        </div>
        <div class="row">
          <div class="col-12">
            * หมายเหตุ : เป็นระบบกำหนด ระยะเวลาการเผยแพร่ข้อมูลหากไม่ได้ กำหนดไว้ เนื้อหาจะไม่มีวันหมดอายุการเผยแพร่ข้อมูล
          </div>
        </div>
      </div>

      <div class="row mt-2 mb-2 p-3" >
        <div class="col-6 pt-2 pb-2" style="background-color: #e4e4e4">
            <span class="btn btn-info" (click)="togglesettime()" id="bt">
        ตั้งเวลาการเผยแพร่ข้อมูล
      </span>
        </div>
        <div class="col-6 pt-2 pb-2" style="background-color: #e4e4e4">
          <div class="custom-control custom-switch">
            <input type="checkbox" class="custom-control-input input-lg" id="StatusPublish" [checked]="CheckSwitchTrueFalse(FormAdd.value.StatusPublish)" formControlName="StatusPublish" >
            <label class="custom-control-label" for="StatusPublish">สถานะการเผยแพร่ข้อมูล</label>
          </div>
        </div>
      </div>
    </form>
    <div class="row mb-2">
      <div class="col-12 text-center">
        <span class="btn btn-primary mr-3" (click)="ClickSave()"><i class="far fa-save"></i> บันทึกข้อมูล </span>
        <span class="btn btn-warning" (click)="ClickCancel()"><i class="fas fa-share fa-rotate-180"></i> ยกเลิก </span>
      </div>
    </div>
  </div>

</div>
