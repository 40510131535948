

<div class="row">
  <div class="container">

    <div class="row mt-2 p-2" style="background-color: #888888;">
      <div class="col-12">
        <h2 class="">{{txtFrmType}} Theses</h2>
      </div>
    </div>
    <form [formGroup]="FormAdd">










      <div class="row mt-2">
        <div class="col-4">
          <div class="form-group">
            <label for="Record"><strong>Record</strong></label>
            <input type="text" class="form-control" id="Record" placeholder="Record" formControlName="Record">
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="Code"><strong>Code</strong></label>
            <input type="text" class="form-control" id="Code" placeholder="Record" formControlName="Code">
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="Date"><strong>Date</strong></label>
            <input type="date" class="form-control" id="Date" placeholder="Record" formControlName="DateAdd">
          </div>
        </div>
        <div class="col-4">
          <div class="form-group">
            <label for="Year"><strong>Year</strong></label>
            <input type="text" class="form-control" id="Year" placeholder="Record" formControlName="YearAdd">
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="Author"><strong>Author</strong></label>
            <input type="text" class="form-control" id="Author" placeholder="Author" formControlName="Author">
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12">
          <div class="form-group">
            <label for="Title"><strong>Title</strong></label>
            <input type="text" class="form-control" id="Title" placeholder="Title" formControlName="Title">
          </div>
        </div>
      </div>


      <div class="col-12 pt-2 pb-2" style="background-color: #e4e4e4">
        <div class="custom-control custom-switch">
          <input type="checkbox" class="custom-control-input input-lg" id="StatusPublish" [checked]="CheckSwitchTrueFalse(FormAdd.value.StatusPublish)" formControlName="StatusPublish" >
          <label class="custom-control-label" for="StatusPublish">สถานะการเผยแพร่ข้อมูล</label>
        </div>
      </div>
    </form>
    <div class="row mb-2">
      <div class="col-12 text-center">
        <span class="btn btn-primary mr-3" (click)="ClickSave()"><i class="far fa-save"></i> บันทึกข้อมูล</span>
        <span class="btn btn-warning" (click)="ClickCancel()"><i class="fas fa-share fa-rotate-180"></i> ยกเลิก</span>
      </div>
    </div>
  </div>

</div>
