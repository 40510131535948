import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ApiwebService} from '../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-student',
  templateUrl: './student.component.html',
  styleUrls: ['./student.component.scss']
})
export class StudentComponent implements OnInit {
  public TodayDate = new Date();
  public today =  this.datePipe.transform(this.TodayDate, 'yyyy-MM-dd');
  public dataUser = JSON.parse(localStorage.getItem('datauser'));

  public FormUser: FormGroup;
  public FormCirculation: FormGroup;

  // ตั้งค่า Barcode
  public barcodetype = 'CODE128';
  // public barcodetype = 'CODE39';
  // public barcodetype = 'MSI';

  displayedColumns: string[] = ['Id', 'Title', 'overduedate', 'Dateoverduedate', 'Priceoverduedate'];
  public dataSource = new MatTableDataSource();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;

  // ตรวจสอบสถานะการลงทะเบียนเข้าใช้งาน
  public StatusRegister = false;
  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute ) { }

  ngOnInit(): void {

    this.CheckDataUserRegister();

    /*
    cmuitaccount: "piyaboot_panyadee@cmu.ac.th"
    cmuitaccount_name: "piyaboot_panyadee"
    firstname_EN: "PIYABOOT"
    firstname_TH: "ปิยะบุตร"
    itaccounttype_EN: "Alumni Account"
    itaccounttype_TH: "นักศึกษาเก่า"
    itaccounttype_id: "AlumAcc"
    lastname_EN: "PANYADEE"
    lastname_TH: "ปัญญาดี"
    organization_code: "05"
    organization_name_EN: "Faculty of Science"
    organization_name_TH: "คณะวิทยาศาสตร์"
    prename_EN: "Mr."
    prename_TH: "นาย"
    prename_id: "MR"
    student_id: "570532056"
     */
    this.FormUser = this.formBuilder.group({
      Id: '',
      CMUitaccount: '',
      DateAdd: '',
      EmailCMU: '',
      Faculty: '',
      Name: '',
      PriceOverdue: '',
      Status: '',
      StudentId: '',
      itaccounttype_EN: '',
      Limits: '',
    });

    this.CallAllItem();

  }

  CheckDataUserRegister(){
    // console.log(this.dataUser);
    this.apiweb.CheckdataUserRegister(this.dataUser.cmuitaccount_name).subscribe( (dataresult: any) => {

      if ( dataresult.status === false ){
        this.StatusRegister = true;
      }else {
        // console.log(dataresult.data[0]);
        const Userdata = dataresult.data[0];
        this.StatusRegister = false;
        this.FormUser.patchValue({
          Id: Userdata.Id,
          CMUitaccount: Userdata.CMUitaccount,
          DateAdd: Userdata.DateAdd,
          EmailCMU: Userdata.EmailCMU,
          Faculty: Userdata.Faculty,
          Name: Userdata.Name,
          PriceOverdue: Userdata.PriceOverdue,
          Status: Userdata.Status,
          StudentId: Userdata.StudentId,
          itaccounttype_EN: Userdata.itaccounttype_EN,
          Limits: Userdata.Limits

        });
      }
    });
  }

  CallAllItem(){
    // console.log(this.dataUser);
    this.apiweb.CallDataUserItemHold(this.dataUser.cmuitaccount_name).subscribe( (data: any[]) => {
      if (data.length > 0){
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
      }else{
        this.dataSource.data = [];
        this.dataSource.paginator = this.paginator;
      }
    });
  }

// คำนวณวันสำหรับปรับ
  CallDateOverdue(DateHold){
    console.log(this.today);
    const Datestart = DateHold;
    const Dateend = this.today;
    // To set two dates to two variables
    const date1 = new Date(DateHold);
    const date2 = new Date(this.today);
    // To calculate the time difference of two dates
    // tslint:disable-next-line:variable-name
    const Difference_In_Time = date2.getTime() - date1.getTime();

    // To calculate the no. of days between two dates
    // tslint:disable-next-line:variable-name
    const Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24);
    console.log(Difference_In_Days);
    if (Difference_In_Days < 0){
      return 0;
    }else {
      return Difference_In_Days;
    }
  }
  // คำนวนค่าปรับ
  CallPriceOverdue(dayoverdue, PricesOverdue){
    let priceOverdue = 0 ;
    priceOverdue = (dayoverdue * PricesOverdue);
    return priceOverdue;
  }

  ClickRefreshData(){
    this.CallAllItem();
  }

  ClickRegister(){
    // this.router.navigate(['/logout']);
    console.log('register');
    this.apiweb.UserRegister(this.dataUser).subscribe( dataresult => {
      console.log(dataresult);
      window.location.reload();
    });


  }




  Clicklogout(){
    this.router.navigate(['/logout']);
  }

}
