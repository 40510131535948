import { Component, OnInit } from '@angular/core';
import {ApiwebService} from '../../../apiweb.service';

@Component({
  selector: 'app-blogfooterlinks',
  templateUrl: './blogfooterlinks.component.html',
  styleUrls: ['./blogfooterlinks.component.scss']
})
export class BlogfooterlinksComponent implements OnInit {
  public DataIconServices;
  public ContentTh = false;
  public totalIcon;
  public iconmore = false;
  public addressimage;
  constructor(public apiweb: ApiwebService) { }

  ngOnInit(): void {
    this.CallDatas();
    this.addressimage = this.apiweb.ApiAddress + 'otherlinks/';
    if (localStorage.getItem('lang') === 'En'){
      this.ContentTh = false;
    } else {
      localStorage.setItem('lang', 'Th');
      this.ContentTh = true;
    }
  }

  CallDatas(){
    this.apiweb.CallFooterLinks(localStorage.getItem('SiteFaculty'), '' ).subscribe( (data: any[]) => {
      this.DataIconServices = data;
      this.totalIcon = data[0].totalicon;
      if (data[0].totalicon > 10){
        this.iconmore = true;
      }
      // console.log(data);
    });
  }
}
