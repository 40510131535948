import { Component, OnInit } from '@angular/core';
import {ApiwebService} from '../../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-eventdetail',
  templateUrl: './eventdetail.component.html',
  styleUrls: ['./eventdetail.component.scss']
})
export class EventdetailComponent implements OnInit {
  public DataItem;
  public ContentTh;
  public pathimage = this.apiweb.WebsiteAddressAssets + 'events/';
  constructor(public apiweb: ApiwebService , public dialog: MatDialog, private datePipe: DatePipe, private router: Router, private route: ActivatedRoute ) { }

  ngOnInit(): void {
    if (localStorage.getItem('lang') === 'En'){
      this.ContentTh = false;
    } else {
      localStorage.setItem('lang', 'Th');
      this.ContentTh = true;
    }
    this.route.params.subscribe(params => {
      // console.log(params.Id);
      this.apiweb.CallEventId(params.Id).subscribe( (dataresponse: any) => {
        const data = dataresponse.data[0];
        // console.log(data);
        this.DataItem = data;
      });
    });
  }
  ClickLink(Id){
    this.router.navigate(['/eventsdetail/', { Id }]);
  }
  ClickBack(){
    this.router.navigate(['/events/']);
  }

}
/*
Id
Faculty
TitleTh
TitleEn
ContentTh
ContentEn
ImageSSTh
ImageSSEn
ExternalLink
DateAdd
DatePublish
DateUnpublish
DateModify
UserAdd
UserModify
StatusPublish
Status
 */
