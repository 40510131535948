<ng-container *ngIf="ContentTh;else contenten">

  <div id="carouselExampleIndicators" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">

      <ng-container *ngFor="let data of DataIconServices ; let i = index">
        <li data-target="#carouselExampleIndicators" [attr.data-slide-to]=i class="active"></li>
      </ng-container>
    </ol>
    <div class="carousel-inner" role="listbox">



      <ng-container *ngFor="let data of DataIconServices ; let i = index">
          <ng-container *ngIf="i==0;else nexts">
            <div class="carousel-item active">
              <img class="d-block img-fluid" src="{{addressimage}}{{data.ImageSSTh}}" alt="{{data.TitleTh}}">
              <div class="carousel-caption d-none d-md-block">
                <!-- <h3>...</h3>
                <p>...</p>-->
              </div>
            </div>
          </ng-container>
        <ng-template #nexts>
          <div class="carousel-item">
            <img class="d-block img-fluid" src="{{addressimage}}{{data.ImageSSTh}}" alt="{{data.TitleTh}}">
            <div class="carousel-caption d-none d-md-block">
              <!-- <h3>...</h3>
                <p>...</p>-->
            </div>
          </div>
        </ng-template>


      </ng-container>
    </div>

    <a class="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>



</ng-container>
<ng-template #contenten>

  <div id="carouselExampleIndicatorsen" class="carousel slide" data-ride="carousel">
    <ol class="carousel-indicators">
      <li data-target="#carouselExampleIndicatorsen" data-slide-to="0" class="active"></li>
      <li data-target="#carouselExampleIndicatorsen" data-slide-to="1"></li>
      <li data-target="#carouselExampleIndicatorsen" data-slide-to="2"></li>
    </ol>
    <div class="carousel-inner" role="listbox">
      <div class="carousel-item active">
        <img class="d-block img-fluid" src="http://localhost/library.cmu.ac.th/faculty/finearts/api/configs/finearts_bg1.jpg" alt="First slide">
        <div class="carousel-caption d-none d-md-block">
          <h3>...</h3>
          <p>...</p>
        </div>
      </div>
      <div class="carousel-item">
        <img class="d-block img-fluid" src="http://localhost/library.cmu.ac.th/faculty/finearts/api/configs/fofalib_logo.png" alt="Second slide">
        <div class="carousel-caption d-none d-md-block">
          <h3>...</h3>
          <p>...</p>
        </div>
      </div>
      <div class="carousel-item">
        <img class="d-block img-fluid" src="http://localhost/library.cmu.ac.th/faculty/finearts/api/configs/fofalib_logo.png" alt="Third slide">
        <div class="carousel-caption d-none d-md-block">
          <h3>...</h3>
          <p>...</p>
        </div>
      </div>
    </div>
    <a class="carousel-control-prev" href="#carouselExampleIndicatorsen" role="button" data-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="sr-only">Previous</span>
    </a>
    <a class="carousel-control-next" href="#carouselExampleIndicatorsen" role="button" data-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="sr-only">Next</span>
    </a>
  </div>
</ng-template>
