import { Component, OnInit } from '@angular/core';
import {ApiwebService} from '../../../apiweb.service';

@Component({
  selector: 'app-blogslidewithcaption',
  templateUrl: './blogslidewithcaption.component.html',
  styleUrls: ['./blogslidewithcaption.component.scss']
})
export class BlogslidewithcaptionComponent implements OnInit {
  public DataIconServices;
  public ContentTh = false;
  public totalIcon;
  public iconmore = false;
  public addressimage;
  constructor(public apiweb: ApiwebService) { }

  ngOnInit(): void {
    this.CallIconServices();
    this.addressimage = this.apiweb.ApiAddress + 'assets/slides/';
    if (localStorage.getItem('lang') === 'En'){
      this.ContentTh = false;
    } else {
      localStorage.setItem('lang', 'Th');
      this.ContentTh = true;
    }
  }

  CallIconServices(): any {
    const CommandsSlide = 'frontend/callslidelimit?Faculty=' + this.apiweb.SiteFaculty + '&limits=10';
    this.apiweb.FrontendGet(CommandsSlide).subscribe( (dataresponse: any) => {
      if (dataresponse.status === true){
        const data = dataresponse.status[0];
        this.DataIconServices = dataresponse.data;
        this.totalIcon = dataresponse.data.length;
        if (dataresponse.data.length > 10){
          this.iconmore = true;
        }
      }
    });
  }
}
