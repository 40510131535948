import {Component, Inject, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {DialogDel่journalDialog} from '../dashboardjournal/dashboardjournal.component';
import {FormBuilder, FormGroup} from '@angular/forms';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';
import {ApiwebService} from '../../../apiweb.service';
import {UploadService} from '../../../upload.service';

export interface DialogData {
  Id: string;
  SubMenuId: string;
  Action: string;
}

@Component({
  selector: 'app-dashboardmenu',
  templateUrl: './dashboardmenu.component.html',
  styleUrls: ['./dashboardmenu.component.scss']
})
export class DashboardmenuComponent implements OnInit {

  public Datamainmenu;
  public DataSubMainMenu;
  public SubMenuID;
  public FrmRankMenu: FormGroup;
  public ShowHideSubMenu = false;
  public MainMenuId;
  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute ) { }

  ngOnInit(): void {

    this.FrmRankMenu = this.formBuilder.group({
      Action : 'RankMainmenu',
      Datarank : []
    });
    this.CAllDataMainMenu();
  }

  drop(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.Datamainmenu, event.previousIndex, event.currentIndex);
    // console.log(this.Datamainmenu);
  }

  dropSubMenu(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.DataSubMainMenu, event.previousIndex, event.currentIndex);
    // console.log(this.DataSubMainMenu);
  }

  // Dialog Add Main Menu
  openDialogAddMainMenu(Action, Id, SubMenuId): void {
    const dialogRef = this.dialog.open(DialogAddMainMenuDialog, {
      data: { Action, Id, SubMenuId }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.CAllDataMainMenu();
    });
  }

  CAllDataMainMenu(): void{
    this.apiweb.CallAllMainmenuFaculty().subscribe( (dataresponse: any) => {
      const data = dataresponse.data;
      this.Datamainmenu = data;
      // console.log(this.Datamainmenu);
      if (this.MainMenuId){
        this.CallDataSubMainMenu(data.submemulist, this.MainMenuId);
      }
    });
  }

  CallDataSubMainMenu(datasubmemulist, MainMenuId): void{
    this.ShowHideSubMenu = !this.ShowHideSubMenu;
    // console.log(datasubmemulist);
    this.DataSubMainMenu = datasubmemulist;
    this.MainMenuId = MainMenuId;
  }

  SaveListRank(): void{
    this.apiweb.CommandManageMainMenuSortRank(this.Datamainmenu).subscribe(data => {
      // console.log(data);
    });
  }
  SaveListRankSubMainMenu(): void{
    // console.log(this.SubMenuID);
    // console.log(this.DataSubMainMenu);
    this.apiweb.CommandManageSubMainMenuSortRank(this.DataSubMainMenu).subscribe(data => {
      // console.log(data);
    });
  }







  onclickhidesubmenu(): void{
    this.ShowHideSubMenu = !this.ShowHideSubMenu;
  }

}


// เพิ่ม ลบ แก้ไข
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-addmainmenu-dialog',
  templateUrl: 'dialog-addmainmenu-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogAddMainMenuDialog implements OnInit  {
  public FormAddMainmenu: FormGroup;
  public txtMemuAction;
  public BoxSubmenu = true;
  public DisplayDelete = false;
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<DialogData>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public apiweb: ApiwebService, public uploadService: UploadService) {}

  ngOnInit(): void{
    if (this.data.Action === 'Del'){
      this.DisplayDelete = true;
    }else{
      this.DisplayDelete = false;
    }
    this.FormAddMainmenu = this.formBuilder.group({
      Id: '',
      MenuNameTh: '',
      MenuNameEn: '',
      Links: '',
      StatusLinksNewPage: '',
      StatusLinks: '',
      Faculty: this.apiweb.SiteFaculty,
      SubMenu: false,
      SubMenuId: '',
      StatusPublish: '',
      Status: '',
      Action: this.data.Action
    });
    if (this.data.Action === 'Add'){
      this.txtMemuAction = 'เพิ่มเมนูหลัก';
      // this.CreateMainMenu();
      if (this.data.SubMenuId !== ''){
           this.txtMemuAction = 'เพิ่มเมนูย่อย';
           this.FormAddMainmenu.patchValue({
             SubMenuId: this.data.SubMenuId
           });
      }
    }

    if (this.data.Action === 'Edit'){
      this.txtMemuAction = 'แก้ไขเมนูหลัก';
      if (this.data.SubMenuId !== ''){
        this.txtMemuAction = 'แก้ไขเมนูย่อย';
      }
      this.EditMainMenu();
    }
  }
  EditMainMenu(): void{
    this.apiweb.CallMainmenuFacultyId(this.data.Id).subscribe( (dataresponse: any) => {
      const data = dataresponse.data;
      this.FormAddMainmenu.patchValue({
        Id: data[0].Id,
        MenuNameTh:  data[0].MenuNameTh,
        MenuNameEn:  data[0].MenuNameEn,
        Links:  data[0].Links,
        StatusLinksNewPage:  data[0].StatusLinksNewPage,
        StatusLinks:  data[0].StatusLinks,
        Faculty:  data[0].Faculty,
        SubMenu:  data[0].SubMenu,
        SubMenuId:  data[0].SubMenuId,
        StatusPublish:  data[0].StatusPublish,
        Status:  data[0].Status,
      });
      if (data[0].SubMenu === '0' || data[0].SubMenu === null){
        this.FormAddMainmenu.patchValue({
          SubMenu:  ''
        });
      }
      this.CheckSubmenu();
    });

  }

  CheckSubmenu(): void{
    // console.log(this.FormAddMainmenu.value.SubMenu);
    if (this.FormAddMainmenu.value.SubMenu === false || this.FormAddMainmenu.value.SubMenu === ' ' || this.FormAddMainmenu.value.SubMenu === '0' || this.FormAddMainmenu.value.SubMenu === '' || this.FormAddMainmenu.value.SubMenu === null){
      this.BoxSubmenu = true;
      this.FormAddMainmenu.patchValue({
        Links: '',
        StausLinksNewPage: ''
      });
    }else {
      this.BoxSubmenu = false;
    }
  }

  clearFormAddMainmenu(): void {
    this.FormAddMainmenu.reset();
    this.FormAddMainmenu.patchValue({
      Id: '',
      MenuNameTh: '',
      MenuNameEn: '',
      Links: '',
      StatusLinksNewPage: '',
      StatusLinks: '',
      Faculty: '',
      SubMenu: '',
      SubMenuId: '',
      StatusPublish: '',
      Status: '',
      Action: ''
    });
  }
  onNoClick(): void {
    this.clearFormAddMainmenu();
    this.dialogRef.close();
  }
  onOkClick(): void {
    // console.log(this.FormAddMainmenu.value);
    this.apiweb.CommandManageMainmenu(this.FormAddMainmenu.value).subscribe((data: any[]) => {
      this.clearFormAddMainmenu();
    });
    this.dialogRef.close();
  }
  onOkClickDel(): void {
    const datasend = {Action: 'Del', Id: this.data.Id};
    this.apiweb.CommandManageMainmenu(datasend).subscribe((data: any[]) => {
    });
    this.dialogRef.close();
  }
}

