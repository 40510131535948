


<div class="row mt-1">
  <div class="col-4">
    <div class="card">
      <div class="card-header">
        <strong>ข้อมูลรายละเอียดเว็บไซต์</strong>
      </div>
      <div class="card-body">
        Website Address: <a [href]="SiteAddress" target="_blank">{{SiteAddress}}</a>
        <hr>
      </div>
    </div>
  </div>
  <div class="col-4">
        <ul class="list-group">
          <li class="list-group-item active">ลิงค์หน้าต่างๆภายในเว็บไซต์ เมนูพิเศษต่างๆ</li>
          <li class="list-group-item"><a href="{{SiteAddress}}/Content" target="_blank">เนื้อหาภายในเว็บไซต์</a></li>
          <li class="list-group-item"><a href="{{SiteAddress}}/Services" target="_blank">บริการ</a></li>
          <li class="list-group-item"><a href="{{SiteAddress}}/newbooks" target="_blank">หนังสือใหม่</a></li>
          <li class="list-group-item"><a href="{{SiteAddress}}/ebooks" target="_blank">หนังสืออิเล็กทรอนิกส์</a></li>
          <li class="list-group-item"><a href="{{SiteAddress}}/journals" target="_blank">วารสาร บทความ</a></li>
          <li class="list-group-item"><a href="{{SiteAddress}}/events" target="_blank">กิจกรรม</a></li>
          <li class="list-group-item"><a href="{{SiteAddress}}/news" target="_blank">ข่าวประชาสัมพันธ์</a></li>
          <li class="list-group-item"><a href="{{SiteAddress}}/termpaper" target="_blank">Term paper</a></li>
        </ul>
  </div>
</div>
<!--
<div class="row mt-3">
  <div class="col-4">
    <div class="card">
      <div class="card-header">ข้อมูลเนื้อหาที่อัพโหลดล่าสุด</div>
    </div>

  </div>
</div>
-->

