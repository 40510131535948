import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {DatePipe} from '@angular/common';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {ApiwebService} from '../../../apiweb.service';
import {UploadService} from '../../../upload.service';

export interface DialogData {
  Id: string;
  Action: string;
}
@Component({
  selector: 'app-dashboardbanners',
  templateUrl: './dashboardbanners.component.html',
  styleUrls: ['./dashboardbanners.component.scss']
})
export class DashboardbannersComponent implements OnInit {

  displayedColumns: string[] = ['Id', 'Title', 'StatusPublish', 'edit', 'delete'];
  public dataSource = new MatTableDataSource();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;

  public DataMeeting;
  public FormContents: FormGroup;
  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute ) { }

  ngOnInit(): void {
    this.FormContents = this.formBuilder.group({
      Id: '',
      Faculty: this.apiweb.dataUser.Faculty,
      TitleTh: '',
      TitleEn: '',
      ContentTh: '',
      ContentEn: '',
      ImageSSTh: '',
      ImageSSEn: '',
      ExternalLinkTh: '',
      PublishDate: '',
      UnpublishDate: '',
      AddDate: '',
      DateStart: '',
      DateEnd: '',
      ModifyDate: '',
      UserAdd: this.apiweb.dataUser.EmailCMU,
      UserModify: '',
      StatusPublish: '',
      Status: '',
      Action: ''
    });
    this.CallAllBannerFaculty();
  }

  CallAllBannerFaculty(): void{
    this.apiweb.CallAllBannerFaculty().subscribe( (dataresponse: any) => {
      if (dataresponse.status === true){
        const dataresult = dataresponse.data;
        this.dataSource.data = dataresult;
        this.dataSource.paginator = this.paginator;
      }else{
        this.dataSource.data = [];
        this.dataSource.paginator = this.paginator;
      }
    });
  }

  ClickManageData(Action, Id): void{
    this.router.navigate(['/dashboard/website/managebanners/', { Action, Id }]);
  }
  CheckSwitchTrueFalse(Dataval): any{
    if (Dataval === '1'){
      return true;
    }
    if (Dataval === '0' || Dataval === ''  || Dataval === 'null' ){
      return false;
    }
  }
  checkCheckBoxvalue(Id, event): void{
    // console.log(event.target.checked);
    // console.log(Id);
    let valchange ;
    if (event.target.checked === true){
      valchange = 1;
    }else{
      valchange = 0;
    }
    let data ;
    data = { Id, StatusPublish: valchange, Action: 'StatusPublish'} ;
    this.apiweb.CommandBannerStatuspublish(data).subscribe(datasend => {
      // console.log(data);
      console.log(datasend);
    });

  }
// Dialog Del
  openDialogDel(Action, Id): void {
    const dialogRef = this.dialog.open(DialogDelbannersDialog, {
      data: { Action, Id}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.CallAllBannerFaculty();
    });
  }
}

// เพิ่ม ลบ แก้ไข
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-delbanners-dialog',
  templateUrl: 'dialog-delbanners-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogDelbannersDialog implements OnInit  {
  public FormDel: FormGroup;
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<DialogData>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public apiweb: ApiwebService, public uploadService: UploadService) {}
  ngOnInit(): void {
    this.FormDel = this.formBuilder.group({
      Id: this.data.Id,
      Action: this.data.Action
    });
  }
  clearFormDel(): void {
    this.FormDel.reset();
  }
  onNoClick(): void {
    this.clearFormDel();
    this.dialogRef.close();
  }
  onOkClick(): void {
    console.log(this.FormDel.value);
    this.apiweb.CommandManageBanners(this.FormDel.value).subscribe( data => {
      console.log(data);
    });
    this.dialogRef.close();
  }
}
