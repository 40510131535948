<ng-container *ngIf="ContentTh;else ContentEn">
  <div class="row pt-2">
    <div class="container">
      <h1>{{DataContents.TitleTh}}</h1>
      <div class="row">
        <div class="col-12" [innerHTML]=DataContents.ContentTh>
        </div>
      </div>
    </div>
  </div>
</ng-container>
<ng-template #ContentEn>
  <div class="row pt-2">
    <div class="container">

      <ng-container *ngIf="DataContents.TitleEn === '' || DataContents.TitleEn === null;else Notitleeng">
        <h1>{{DataContents.TitleTh}}</h1>
      </ng-container>

      <ng-template #Notitleeng>
        <h1>{{DataContents.TitleEn}}</h1>
      </ng-template>

      <ng-container *ngIf="DataContents.ContentEn === '' || DataContents.ContentEn === null;else Nocontenteng">
        <div class="row">
          <div class="col-12" [innerHTML]=DataContents.ContentTh>
          </div>
        </div>
      </ng-container>

      <ng-template #Nocontenteng>
        <div class="row">
          <div class="col-12" [innerHTML]=DataContents.ContentEn>
          </div>
        </div>
      </ng-template>

    </div>
  </div>
</ng-template>

