import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiwebService} from '../../../../apiweb.service';
import {UploadService} from '../../../../upload.service';
import {DatePipe} from '@angular/common';
import {HttpErrorResponse, HttpEventType} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';
import {of} from 'rxjs';
import {DialogData, DialogDeleventsDialog} from '../../../dashboardwebsite/dashboardevents/dashboardevents.component';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';

@Component({
  selector: 'app-frmcirculationmange',
  templateUrl: './frmcirculationmange.component.html',
  styleUrls: ['./frmcirculationmange.component.scss']
})
export class FrmcirculationmangeComponent implements OnInit {
// ข้อมูลพื้นฐานเว็บไซต์
  public SiteAddress = this.apiweb.WebsiteAddress;
// ข้อมูลบุคคลพื้นฐาน
  public dataUser = JSON.parse(localStorage.getItem('datauser'));
  public EmailCMU = this.dataUser.EmailCMU;
  public Faculty = this.dataUser.Faculty;

  public settimeshow = false;

  // ฟอร์มกรอกข้อมูล
  public contenttype = 'item' ;
  public FormAdd: FormGroup;
  public txtHeaderType;
  public txtFrmType;

  public dataitemtype;
  public dataitemstatus;


  // กำหนดข้อมูลการบันทึกข้อมูลลงฐานข้อมูล
  @ViewChild('fileUpload') fileUpload: ElementRef; files  = [];

  public yourFile: File;

  // ตัังค่าอัพโหลดไฟล์
  public ServerUploadURL = this.apiweb.UploadApiAddress;
  // ตำแหน่งที่ตั้งไฟล์
  public FileURL = this.SiteAddress + 'api/assets/item/' ;
  public FrmFileupload: FormGroup;
  public dataListFile;
////////////////////////////////////////////////////

  // ตั้งค่าเช็คบ๊อค
  public theCheckbox = false;
  public StatusBtn: boolean;
  // public allComplete = false;

// http://localhost/finearts.lib.cmu.ac.th/api/upload
// this.ServerUploadURL = this.SiteAddress + 'api/upload_poster.php?path=' + this.contenttype ;

  // ตั้งค่า Barcode
  // public barcodetype = 'CODE128';
  public barcodetype = 'CODE39';
  // public barcodetype = 'MSI';


  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute, public uploadService: UploadService) { }

  ngOnInit(): void {
    this.CallItemType();
    this.CallItemStatus();

/*
Id
CodeItem
ItemName
DateAdd
DateTimeAdd
UserAdd
ItemStatusId
ItemTypeId
ItemLimit
ItemComment
Status
InventoryCode
PricesOverdue


Id
ItemId
ImagesLocation
ImagesName
Rank
Status

 */
    this.FormAdd = this.formBuilder.group({
      Id: '',
      CodeItem: '',
      InventoryCode: '',
      ItemName: '',
      UserAdd: this.EmailCMU,
      ItemStatusId: '',
      ItemTypeId: '',
      ItemLimit: '',
      ItemComment: '',
      PricesOverdue: '',
      Status: '',
      Action: ''
    });

    this.FrmFileupload = this.formBuilder.group({
      Id: '',
      ItemId: '',
      ImagesLocation: '',
      ImagesName: '',
      Rank: '',
      Status: '',
    });

    this.route.params.subscribe(params => {
      if (params.Action === 'Add'){
        this.txtFrmType = 'เพิ่ม';
        this.FormAdd.patchValue({
          Action: 'Add'
        });
        setTimeout(() => {
          this.CreateItem();
        }, 200);
      }
      if (params.Action === 'Edit'){
        this.txtFrmType = 'แก้ไข';
        this.EditItem(params.Id);
      }
    });
  }

  CreateItem(){
    this.apiweb.CommandAddItem().subscribe( (dataresult: any) => {
      // console.log(dataresult.data);
      const data = dataresult.data[0];
      this.FormAdd.patchValue({
        Id: data.Id,
        Action: 'Edit'
      });
    });
  }
  EditItem(Id){
    this.apiweb.CallItemId(Id).subscribe((dataresult: any) => {
      // console.log(dataresult);
      const data = dataresult[0];
      // console.log(data);
      this.FormAdd.patchValue({
        Id: data.Id,
        CodeItem: data.CodeItem,
        ItemName: data.ItemName,
        DateAdd: data.DateAdd,
        DateTimeAdd: data.DateTimeAdd,
        UserAdd: data.UserAdd,
        ItemStatusId: data.ItemStatusId,
        ItemTypeId: data.ItemTypeId,
        ItemLimit: data.ItemLimit,
        ItemComment: data.ItemComment,
        Status: data.Status,
        InventoryCode: data.InventoryCode,
        PricesOverdue: data.PricesOverdue,
        Action: 'Edit'
      });
      setTimeout(() => {
        this.CallFileUploadId();
      }, 200);
    });
  }
  CallItemType(){
    this.apiweb.CallAllTypeItem().subscribe(data => {
      // console.log(data);
      this.dataitemtype = data;
    });
  }
  CallItemStatus(){
    this.apiweb.CallAllStatusItem().subscribe(data => {
      // console.log(data);
      this.dataitemstatus = data;
    });
  }
  Clicksave(){
    console.log(this.FormAdd.value);
    this.apiweb.CommandEditItem(this.FormAdd.value).subscribe( data => {
      console.log(data);
      this.router.navigate(['/dashboard/circulation/circulationmanage']);
    });
  }
  Clickcancel(){
    this.router.navigate(['/dashboard/circulation/circulationmanage/']);
  }

  //////////////////////////////////////////////////////////////////////////
  // เรียกข้อมูลไฟล์อัพโหลด
  CalllistFileUpload(){
    this.dataListFile = [];
  }
  // บันทึกข้อมูลไฟล์อัพโหลด
  SaveFileUpload(){

  }
  // เคลียร์ฟอร์มไฟล์อัพโหลด
  ClearFormfileupload(){
    this.FrmFileupload.reset();
  }
  // เรียกข้อมูลไฟล์รูปภาพ
  CallFileUploadId(){
    this.apiweb.CallFileUploadId(this.FormAdd.value.Id).subscribe( (data: any[]) => {
      // console.log(data);
      if (data.length > 0){
        this.dataListFile = data;
      }else{
        this.dataListFile = [];
      }
    });
  }

  // ลบรูปภาพ
  DelFileUploadId(Id){
    const data = { Id , Action: 'Del' };
    this.apiweb.CommandDelFileUpload(data).subscribe(dataresult => {
      // console.log(dataresult);
      this.CallFileUploadId();
    });
  }
  //////////////////////////////////////////////////////////////////////////
  uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file.data);
    formData.append('path', this.contenttype);
    // console.log(file.data);
    file.inProgress = true;
    this.uploadService.upload(formData, this.ServerUploadURL).pipe(
      map(event => {
        // console.log(event);
        this.FrmFileupload.patchValue({
          ItemId: this.FormAdd.value.Id,
          ImagesLocation: event.imageUrl,
          ImagesName: event.NewName,
          Rank: ''
        });
        this.apiweb.CommandSaveFileUpload(this.FrmFileupload.value).subscribe(data => {
          // console.log(data);
          this.CallFileUploadId();
        });
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
      if (typeof (event) === 'object') {
        // console.log(event.body);
      }
      this.FormAdd.patchValue({
        ImageSSTh: file.data.name,
        path: this.contenttype
      });
    });

  }
  private uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach(file => {
      this.uploadFile(file);
    });
    // console.log(this.files);
  }
  onClick() {
    const fileUpload = this.fileUpload.nativeElement; fileUpload.onchange = () => {
      this.files  = [];
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0});
      }
      this.uploadFiles();
    };
    fileUpload.click();

  }
  getFile(fileInput) {
    this.yourFile = fileInput.target.files[0];
    // console.log(this.yourFile);
  }
  OnClickDeleteFile(imagessslang){
    // console.log('delete files select');
    // console.log(this.FormAdd.value);
    if ( imagessslang === 'imagessth'){
      this.FormAdd.patchValue({
        ImageSSTh: '',
        path: ''
      });
    }
    if ( imagessslang === 'imagessth'){
      this.FormAdd.patchValue({
        ImageSSEn: '',
        path: ''
      });
    }


  }
// Dialog Item Status

  openDialogManageItemtype(): void {
    const dialogRef = this.dialog.open(DialogItemtypeDialog, {
      data: { }
    });
    dialogRef.afterClosed().subscribe(result => {
      // this.CallAllBannerFaculty();
    });
  }
// Dialog Item Status
  openDialogManageItemStatus(): void {
    const dialogRef = this.dialog.open(DialogItemstatusDialog, {
      width: '80%' ,
      height: '80%',
      data: { }
    });
    dialogRef.afterClosed().subscribe(result => {
      this.CallItemStatus();
    });
  }
}

// เพิ่ม ลบ แก้ไข สถานะวัตถุ
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-itemstatus-dialog',
  templateUrl: 'dialog-itemstatus-dialog.html',
  styleUrls: ['./frmcirculationmange.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class DialogItemstatusDialog implements OnInit  {
  displayedColumns: string[] = ['Id', 'Title', 'editdelete'];
  public dataSource = new MatTableDataSource();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;

  public FormItemstatus: FormGroup;
  public dataitemstatus;

  public displayfrm = false;
  public displayfrmdel = false;

  public btnadd = false;
  public btnsave = false;
  public btncancel = false;
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<DialogData>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public apiweb: ApiwebService, public uploadService: UploadService) {}
  ngOnInit() {
    this.btnadd = true;
    this.CallItemStatus();
    this.FormItemstatus = this.formBuilder.group({
      Id: '',
      StatusName: '',
      Action: ''
    });
  }
  ClickAdd(){
    this.displayfrm = true;
    this.displayfrmdel = false;
    this.btnadd = false;
    this.btnsave = true;
    this.btncancel = true;
    this.apiweb.CreateItemSatus().subscribe( ( dataresponse: any ) => {
      // console.log(dataresponse);
      const data = dataresponse[0];
      this.FormItemstatus.patchValue({
        Id: data.Id,
        StatusName: data.StatusName,
        Action: 'Edit'
      });
    });
  }

  ClickSave(){
    this.SaveItemStatus();
    this.clearForm();
  }

  ClickCancel(){
    this.clearForm();
  }

  clearForm() {
    this.FormItemstatus.reset();
    this.displayfrm = false;
    this.displayfrmdel = false;
    this.btnadd = true;
    this.btnsave = false;
    this.btncancel = false;
  }

  onNoClick(): void {
    this.clearForm();
    this.dialogRef.close();
  }
  onOkClick(): void {
    console.log(this.FormItemstatus.value);
    this.apiweb.CommandManageEvents(this.FormItemstatus.value).subscribe( data => {
      console.log(data);
    });
    this.dialogRef.close();
  }

  CallItemStatus(){
    this.apiweb.CallAllStatusItem().subscribe((data: any) => {
      console.log(data);
      this.dataitemstatus = data;
      this.dataSource.data = data;
    });
  }

  SaveItemStatus(){
    this.apiweb.CmdItemStatus(this.FormItemstatus.value).subscribe( (dataresponse: any) => {
      // console.log(dataresponse);
      this.CallItemStatus();
    });
  }


  ClickMangeData( Action, Id, StatusName) {

    if ( Action === 'Edit'){
      this.btnadd = false;
      this.btnsave = true;
      this.btncancel = true;
      this.displayfrm = true;
      this.displayfrmdel = false;

      this.FormItemstatus.patchValue({
        Id,
        StatusName,
        Action
      });
      // this.clearForm();
    }
    if ( Action === 'Del'){
      this.btnadd = false;
      this.btnsave = true;
      this.btncancel = true;
      this.displayfrm = false;
      this.displayfrmdel = true;
      this.FormItemstatus.patchValue({
        Id,
        StatusName,
        Action
      });

    }
  }






}




// เพิ่ม ลบ แก้ไข
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-itemtype-dialog',
  templateUrl: 'dialog-itemtype-dialog.html',
  styleUrls: ['./frmcirculationmange.component.scss']
})
// tslint:disable-next-line:component-class-suffix
export class DialogItemtypeDialog implements OnInit  {
  displayedColumns: string[] = ['Id', 'Title', 'editdelete'];
  public dataSource = new MatTableDataSource();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;

  public FormItemstatus: FormGroup;
  public dataitemstatus;

  public displayfrm = false;
  public displayfrmdel = false;

  public btnadd = false;
  public btnsave = false;
  public btncancel = false;
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<DialogData>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public apiweb: ApiwebService, public uploadService: UploadService) {}
  ngOnInit() {
    this.btnadd = true;
    this.CallItemType();
    this.FormItemstatus = this.formBuilder.group({
      Id: '',
      TypeName: '',
      Action: ''
    });
  }
  ClickAdd(){
    this.displayfrm = true;
    this.displayfrmdel = false;
    this.btnadd = false;
    this.btnsave = true;
    this.btncancel = true;
    this.apiweb.CreateItemType().subscribe( ( dataresponse: any ) => {
      // console.log(dataresponse);
      const data = dataresponse[0];
      this.FormItemstatus.patchValue({
        Id: data.Id,
        TypeName: data.TypeName,
        Action: 'Edit'
      });
    });
  }

  ClickSave(){
    this.SaveItemStatus();
    this.clearForm();
  }

  ClickCancel(){
    this.clearForm();
  }

  clearForm() {
    this.FormItemstatus.reset();
    this.displayfrm = false;
    this.displayfrmdel = false;
    this.btnadd = true;
    this.btnsave = false;
    this.btncancel = false;
  }

  onNoClick(): void {
    this.clearForm();
    this.dialogRef.close();
  }
  onOkClick(): void {
    console.log(this.FormItemstatus.value);
    this.apiweb.CommandManageEvents(this.FormItemstatus.value).subscribe( data => {
      console.log(data);
    });
    this.dialogRef.close();
  }

  CallItemType(){
    this.apiweb.CallAllTypeItem().subscribe((data: any) => {
      console.log(data);
      this.dataitemstatus = data;
      this.dataSource.data = data;
    });
  }

  SaveItemStatus(){
    this.apiweb.CmdItemType(this.FormItemstatus.value).subscribe( (dataresponse: any) => {
      // console.log(dataresponse);
      this.CallItemType();
    });
  }


  ClickMangeData( Action, Id, TypeName) {

    if ( Action === 'Edit'){
      this.btnadd = false;
      this.btnsave = true;
      this.btncancel = true;
      this.displayfrm = true;
      this.displayfrmdel = false;

      this.FormItemstatus.patchValue({
        Id,
        TypeName,
        Action
      });
      // this.clearForm();
    }
    if ( Action === 'Del'){
      this.btnadd = false;
      this.btnsave = true;
      this.btncancel = true;
      this.displayfrm = false;
      this.displayfrmdel = true;
      this.FormItemstatus.patchValue({
        Id,
        TypeName,
        Action
      });

    }
  }

}


