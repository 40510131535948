<div class="row">
  <ng-container *ngFor="let data of DataAll">
    <div class="col-2 p-1">
      <div class="card">
        <ng-container *ngIf="data.Links === '' || data.Links === null ;else noexternal">
          <a href="journalsdetail/{{data.Id}}" [title]="data.TitleTh"> <img [src]="addressimage + data.ImageCover" alt="{{data.TitleTh}}" style="width: 100%; max-height: 262px;overflow-y: hidden;"></a>
        </ng-container>
        <ng-template #noexternal>
          <a href="{{data.Links}}" [title]="data.TitleTh" target="_blank"> <img [src]="addressimage + data.ImageCover" alt="{{data.TitleTh}}" style="width: 100%; max-height: 262px;overflow-y: hidden;"></a>
            </ng-template>

        <!-- <div class="card-block">
          <h4 class="card-title">Clixmatic</h4>
          <div class="meta">
            <a href="#">Friends</a>
          </div>
          <div class="card-text"><ul>1</ul><ul>2</ul>
          </div>
        </div>
        -->
        <div class="card-footer text-right">
          <!-- <span class="mr-1">{{data.BookPublishDate}}</span>-->
          <!-- <span><i class=""></i><i class="fas fa-eye">75 views</i> </span>-->
        </div>
      </div>
    </div>
  </ng-container>
</div>
<div class="row">
  <div class="col-12 text-right">
    <a href="journals" class="action-button shadow animate red">More..</a>
  </div>
</div>
