import {Component, Input, OnInit} from '@angular/core';
import {ApiwebService} from '../../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';

@Component({
  selector: 'app-blocktrendyarts',
  templateUrl: './blocktrendyarts.component.html',
  styleUrls: ['./blocktrendyarts.component.scss']
})
export class BlocktrendyartsComponent implements OnInit {

  // tslint:disable-next-line:no-input-rename
  @Input('Limits') limits;
  public QRcode;
  public AddressQRcode = 'http://library.cmu.ac.th/mediastore/97/view.html?id=';
  public elementType = 'url';
  public contenttype = 'trendyarts';
  public DataAll;
  constructor(public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute ) { }

  ngOnInit(): void {
    this.CallDataLimit(this.limits);
  }
  CallDataLimit(limits): any{
    this.apiweb.CallData(this.contenttype, 'get_limits?Limits=' + limits).subscribe( (dataresponse: any) => {
      if (dataresponse.status === true){
        this.DataAll = dataresponse.data;
      }else {
        this.DataAll = [];
      }
    });
  }
}
