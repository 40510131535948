

<h1 mat-dialog-title>ยืนยันการลบข้อมูล</h1>


<div mat-dialog-content>
  <div class="row">
    <div class="col-12">
      ต้องการลบข้อมูลใช่หรือไม่ กรุณาลบข้อมูลด้วยความระมัดระวัง
    </div>
  </div>
</div>
<div mat-dialog-actions>

  <span class="btn btn-success" (click)="SaveDelete()">ยืนยัน ลบข้อมูล</span>
  <span class="btn btn-warning" (click)="onNoClick()"> Cancel </span>
</div>

