import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {ApiwebService} from '../../../apiweb.service';

@Component({
  selector: 'app-dashboardconfigs',
  templateUrl: './dashboardconfigs.component.html',
  styleUrls: ['./dashboardconfigs.component.scss']
})
export class DashboardconfigsComponent implements OnInit {
  public FormConfigs: FormGroup;
  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute ) { }
  ngOnInit(): void {
    this.FormConfigs = this.formBuilder.group({
      Id: '',
      Faculty: '',
      SiteAddress: '',
      Telephone: '',
      Fax: '',
      Logo: '',
      Timeservices: '',
      Action: 'Edit'
    });
    this.CallDataConfigs();
  }

  CallDataConfigs(){
    this.apiweb.CallWebsiteConfig().subscribe( (data: any[]) => {
      if (data.length === 1){
        this.FormConfigs.patchValue({
          Id: data[0].Id,
          Faculty:  data[0].Faculty,
          SiteAddress:  data[0].SiteAddress,
          Telephone:  data[0].Telephone,
          Fax:  data[0].Fax,
          Logo:  data[0].Logo,
          Timeservices:  data[0].Timeservices
        });
      }
    });
  }

  OnClickSave(){
    this.apiweb.CommandConfig(this.FormConfigs.value).subscribe( (data: any[]) => {
        // console.log(data);
    });
  }

}
