import { Component, OnInit } from '@angular/core';

import {Router} from '@angular/router';
import {AuthService} from '../../auth.service';
@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.css']
})
export class LogoutComponent implements OnInit {

    private  txtusername: string = localStorage.getItem('access_token');

  constructor(private auth: AuthService, private router: Router) { }

  ngOnInit() {
      this.auth.logout();
      this.router.navigate(['login']);

  }

}
