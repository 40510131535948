import { Component, OnInit } from '@angular/core';
import {ApiwebService} from '../../../apiweb.service';
import {DomSanitizer} from '@angular/platform-browser';

@Component({
  selector: 'app-footerhome',
  templateUrl: './footerhome.component.html',
  styleUrls: ['./footerhome.component.scss']
})
export class FooterhomeComponent implements OnInit {
  public ContentTh = true;
  public BoxFooterTh1;
  public BoxFooterTh2;
  public BoxFooterTh3;
  public BoxFooterTh4;
  public BoxFooterEn1;
  public BoxFooterEn2;
  public BoxFooterEn3;
  public BoxFooterEn4;
  public SiteVideotrailer: any;
  public BackgroundFootters: any;
  public StyleFooter: { 'background-color': any };
  public BackgroundFooterColors: any;
  constructor(public apiweb: ApiwebService, public sanitizer: DomSanitizer) {
  }

  ngOnInit(): void {
    this.CallContent();
    if (localStorage.getItem('lang') === 'En' ) {
      this.ContentTh = false;
    }else{
      this.ContentTh = true;
    }

  }
  public CallContent(): any{
    this.apiweb.FrontendGetDataId('configs', 'get_configs', 'Faculty', this.apiweb.SiteFaculty).subscribe( (dataresponse: any) => {
      const data = dataresponse.data[0];
      this.BoxFooterTh1 = data.FooterContentTh1;
      this.BoxFooterTh2 = data.FooterContentTh2;
      this.BoxFooterTh3 = data.FooterContentTh3;
      this.BoxFooterTh4 = data.FooterContentTh4;
      this.BoxFooterEn1 = data.FooterContentEn1;
      this.BoxFooterEn2 = data.FooterContentEn2;
      this.BoxFooterEn3 = data.FooterContentEn3;
      this.BoxFooterEn4 = data.FooterContentEn4;
      this.SiteVideotrailer = data.VideoTrailer;
      this.BackgroundFootters = data.BackgroundFootters;
      this.BackgroundFooterColors = data.BackgroundFooterColors;
      if (this.BackgroundFootters === '' || this.BackgroundFootters === null){
        this.StyleFooter = {
          'background-color': this.BackgroundFooterColors
        };
      }else {
        this.StyleFooter = {
          // @ts-ignore
          'background-image': 'url("' + this.apiweb.ApiAddress + 'configs/' + this.BackgroundFootters + '"'
        };
      }
    });
  }
}

