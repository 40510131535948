import {Component, ElementRef, Inject, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ApiwebService} from '../../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {FormBuilder, FormGroup} from '@angular/forms';
import {UploadService} from '../../../upload.service';

export interface DialogData {
  Id: string;
  Action: string;
}
@Component({
  selector: 'app-dashboardtrendyarts',
  templateUrl: './dashboardtrendyarts.component.html',
  styleUrls: ['./dashboardtrendyarts.component.scss']
})
export class DashboardtrendyartsComponent implements OnInit {

  public contenttype = 'trendyarts';
  displayedColumns: string[] = ['Id', 'Title', 'StatusPublish', 'edit', 'delete'];
  public dataSource = new MatTableDataSource();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild('TABLE', { static: false }) TABLE: ElementRef;


  constructor(public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute ) { }
  ngOnInit(): void {
    this.CallAllData();
  }
  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  CallAllData(){
    this.apiweb.CallData(this.contenttype, 'get_all').subscribe( (dataresponse: any) => {
      if (dataresponse.status === true){
        this.dataSource.data = dataresponse.data;
      }else {
        this.dataSource.data = [];
      }
      this.dataSource.paginator = this.paginator;
    });
  }

  ClickManageData(Action, Id){
    this.router.navigate(['/dashboard/website/managetrendyarts/', { Action, Id }]);
  }

  CheckSwitchTrueFalse(Dataval){
    if (Dataval === '1'){
      return true;
    }
    if (Dataval === '0' || Dataval === ''  || Dataval === 'null' ){
      return false;
    }
  }

  checkCheckBoxvalue(Id, event){
    let valchange ;
    if (event.target.checked === true){
      valchange = 1;
    }else{
      valchange = 0;
    }
    let data ;
    data = { Id, StatusPublish: valchange, Action: 'StatusPublish'} ;
    this.apiweb.CommandData(this.contenttype, 'set_statuspublish', data).subscribe();
  }
// Dialog Del
  openDialogDel(Action, Id): void {
    const dialogRef = this.dialog.open(DialogDeltrendyartsDialog, {
      data: { Action, Id}
    });
    dialogRef.afterClosed().subscribe(result => {
      this.CallAllData();
    });
  }
}

// เพิ่ม ลบ แก้ไข
@Component({
  // tslint:disable-next-line:component-selector
  selector: 'dialog-deltrendyarts-dialog',
  templateUrl: 'dialog-deltrendyarts-dialog.html',
})
// tslint:disable-next-line:component-class-suffix
export class DialogDeltrendyartsDialog implements OnInit  {
  public FormDel: FormGroup;
  public contenttype = 'trendyarts';
  constructor(private formBuilder: FormBuilder, public dialogRef: MatDialogRef<DialogData>, @Inject(MAT_DIALOG_DATA) public data: DialogData, public apiweb: ApiwebService, public uploadService: UploadService) {}
  ngOnInit() {
    this.FormDel = this.formBuilder.group({
      Id: this.data.Id,
      Action: this.data.Action
    });
  }
  clearFormDel() {
    this.FormDel.reset();
  }
  onNoClick(): void {
    this.clearFormDel();
    this.dialogRef.close();
  }
  onOkClick(): void {
    console.log(this.FormDel.value);
    this.apiweb.CommandData(this.contenttype, 'set_status', this.FormDel.value).subscribe();
    this.dialogRef.close();
  }
}
