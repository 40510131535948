import {Component, OnInit, ViewChild} from '@angular/core';
import {MatTableDataSource} from '@angular/material/table';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {ApiwebService} from '../../apiweb.service';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-termpaper',
  templateUrl: './termpaper.component.html',
  styleUrls: ['./termpaper.component.scss']
})
export class TermpaperComponent implements OnInit {
  public ContentType = 'termpaper';
  public displaylang = localStorage.getItem('lang');
  public pathimage = this.apiweb.WebsiteAddressAssets + this.ContentType + '/';
  public pathfile = this.apiweb.WebsiteAddressAssets + this.ContentType + '/';
  public ShowStyle = true;
  public displayedColumns: string[] = ['no', 'TitleTh'];
  public dataSource = new MatTableDataSource();
  @ViewChild(MatSort, {static: false}) sort: MatSort;
  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;

  public datafiltering = [];
  public startslices;
  public endslices;
  public checktypes;

  constructor(public apiweb: ApiwebService , public dialog: MatDialog, private datePipe: DatePipe, private router: Router, private route: ActivatedRoute ) { }

  ngOnInit(): void {

    // console.log(this.displaylang);
    /*
    this.apiweb.CallEbook(this.apiweb.SiteFaculty, '0' ).subscribe( (dataresponse: any) => {
      const data = dataresponse.data;
      // console.log(data);
      this.dataSource.data = data;
      this.dataSource.paginator = this.paginator;
      this.datafiltering = this.dataSource.data.slice(0 , 9);
    });
    // เปลี่ยนภาษา
    if (localStorage.getItem('lang') === 'En'){
      this.ContentTh = false;
    } else {
      localStorage.setItem('lang', 'Th');
      this.ContentTh = true;
    }
  */
    this.route.params.subscribe(params => {
     // console.log(params);
      this.CallSearch(params.Keysearch);
    });

  }
  applyFilter(event: Event): any {
    const filterValue = (event.target as HTMLInputElement).value;
    // console.log(this.dataSource);
    this.dataSource.filter = filterValue.trim().toLowerCase();
    // console.log(this.dataSource);
    this.datafiltering = this.dataSource.filteredData.slice(this.startslices , this.endslices);

  }
  CallSearch(Keywordsearch): any {
    const keyworddatasearch = { keyword : Keywordsearch };
    const datasearch = 'get_termpaper';
    this.apiweb.CallData('frontend', datasearch).subscribe( (dataresponse: any) => {
      if (dataresponse.status === true){
        const data = dataresponse.data;
        this.dataSource.data = data;
        this.dataSource.paginator = this.paginator;
        this.datafiltering = this.dataSource.data.slice(0 , 9);
      }
    });
  }
  ClickLink(Id): any{
    this.router.navigate([ this.ContentType + 'detail', { Id }]);
  }
  OnclickShowStylelist(): any{
    this.ShowStyle = true;
  }
  OnclickShowStyleBox(): any{
    this.ShowStyle = false;
  }
  onPaginateChange(event): any{
    this.startslices = (event.pageIndex * event.pageSize);
    if (this.startslices === 0) {
      this.endslices = event.pageSize;
    } else {
      this.endslices = (event.pageIndex * event.pageSize) + event.pageSize;
    }
    this.datafiltering = this.dataSource.data.slice(this.startslices , this.endslices);
  }
}
