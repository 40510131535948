import { Component, OnInit } from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {ApiwebService} from '../../../apiweb.service';

@Component({
  selector: 'app-iconlogin',
  templateUrl: './iconlogin.component.html',
  styleUrls: ['./iconlogin.component.scss']
})
export class IconloginComponent implements OnInit {
  public loginForm: FormGroup;
  public loading = false;
  public submitted = false;
  public returnUrl: string;
  public error = '';

  public scope = this.apiweb.CMUOauthClientScope;
  public clientSecret = this.apiweb.CMUOauthClientSecret;
  public clientID = this.apiweb.CMUOauthClientID;
  public callback = this.apiweb.CMUOauthClientCallback;
  public homepage = this.apiweb.CMUOauthClientHomepage;

  public LinksLogin;
  constructor(
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private apiweb: ApiwebService
  ) {
    // redirect to home if already logged in    private alertService: AlertService
    // if (this.authenticationService.currentUserValue) {

  }

  ngOnInit() {
    this.LinksLogin = 'https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id=' + this.clientID + '&redirect_uri=' + this.callback + '&scope=' + this.scope + '&state=xyz';

  }
  ClickLogin(){
    this.LinksLogin = 'https://oauth.cmu.ac.th/v1/Authorize.aspx?response_type=code&client_id=' + this.clientID + '&redirect_uri=' + this.callback + '&scope=' + this.scope + '&state=xyz';
    this.router.navigate([this.LinksLogin]);
  }
}
