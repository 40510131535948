import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-blockbannerslideonly',
  templateUrl: './blockbannerslideonly.component.html',
  styleUrls: ['./blockbannerslideonly.component.scss']
})
export class BlockbannerslideonlyComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
