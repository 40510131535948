import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-blockvideosingle',
  templateUrl: './blockvideosingle.component.html',
  styleUrls: ['./blockvideosingle.component.scss']
})
export class BlockvideosingleComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('AddressVideo') AddressVideo;
  constructor() { }

  ngOnInit(): void {
    // console.log(this.AddressVideo);

  }

}
