import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-circulationreportservices',
  templateUrl: './circulationreportservices.component.html',
  styleUrls: ['./circulationreportservices.component.scss']
})
export class CirculationreportservicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
