import {Component, ElementRef, EventEmitter, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';


import { HttpEventType, HttpErrorResponse } from '@angular/common/http';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import {HttpClient} from '@angular/common/http';
import {Router} from '@angular/router';
import {ApiwebService} from '../../../apiweb.service';
import {UploadService} from '../../../upload.service';

@Component({
  selector: 'app-frmuploadfile',
  templateUrl: './frmuploadfile.component.html',
  styleUrls: ['./frmuploadfile.component.scss']
})

export class FrmuploadfileComponent implements OnInit {
  @ViewChild('fileUpload') fileUpload: ElementRef; files  = [];
  public dataUser = JSON.parse(localStorage.getItem('datauser'));
  public email = this.dataUser.EmailCMU;
  public department = this.dataUser.Department;

  public  UserAdd ;
  public  FacultyTh ;
  public  FacultyEn ;

  // ส่วนข้อมูลพื้นฐาน
  // tslint:disable-next-line:no-input-rename
  @Input('PathLocation') PathLocation;
  // tslint:disable-next-line:no-input-rename
  @Input('Action') action;
  // tslint:disable-next-line:no-input-rename
  @Input('Lang') lang;
  // tslint:disable-next-line:no-input-rename
  @Input('ContentId') contentid;
  // tslint:disable-next-line:no-input-rename
  @Input('Id') id;
  // tslint:disable-next-line:no-input-rename
  @Input('TypeContent') TypeContent;
  // tslint:disable-next-line:no-input-rename
  @Input('TypeInput') TypeInput;
  // tslint:disable-next-line:no-input-rename
  @Input('Frmname') frmname;

  // ส่วนเพิ่มข้อมูล
  // tslint:disable-next-line:no-input-rename
  @Input('UrlAdd') UrlAdd;

  // ส่วนเรียกข้อมูลที่เคยอัพโหลดแล้ว
  // tslint:disable-next-line:no-input-rename
  @Input('UrlEdit') UrlEdit;

  // กำหนดข้อมูลการบันทึกข้อมูลลงฐานข้อมูล

  public FormUpload: FormGroup;
  public ImageLocation;
  public ImageNameOld;
  public ImageNameNew;
  public ImageSizeOld;
  public ImageSizeNew;
  public ImageMasterfileLocation;
  public ImageSizeXLLocation;
  public ImageSizeLLocation;
  public ImageSizeMLocation;
  public ImageSizeSSLocation;
  public TypeImage;


  public Status;

  // upload file location Api
  public ServerURL ;
  public yourFile: File;
  public Action = 'Add';
  public FileListUpload: any;
  constructor(private formBuilder: FormBuilder, private apiweb: ApiwebService, private router: Router , public http: HttpClient, public uploadService: UploadService) {}

  ngOnInit() {
    // api upload config
    // console.log(this.DataLogin);

    this.UserAdd = this.email;
    this.ServerURL = this.UrlAdd + '&typecontent=' + this.TypeContent + '&facultyth=' + this.department + '&facultyen=' + this.department  + '&Lang=' + this.lang + '&ContentId=' + this.contentid + '&UserAdd=' + this.UserAdd + '&Action=' + this.Action ;

    this.FormUpload = this.formBuilder.group({

      ImageLocation: [''],
      ImageNameOld: [''],
      ImageNameNew: [''],
      ImageSizeOld: [''],
      ImageSizeNew: [''],
      ImageMasterfileLocation: [''],
      ImageSizeXLLocation: [''],
      ImageSizeLLocation: [''],
      ImageSizeMLocation: [''],
      ImageSizeSSLocation: [''],
      TypeImage: [''],
      FacultyTh: this.department,
      FacultyEn: this.department,



      Action: 'Add',
      FileId: this.id,
      ContentId: this.contentid,
      ContentType: this.TypeContent,
      Faculty: this.department,
      Lang: [''],
      FileName: [''],
      FileType: [''],
      LocationFile: [''],
      UserAdd: this.email,
      DateAdd: [''],
      DateTimeAdd: [''],
      TimeAdd: [''],
      Status: [''],

    });
    // console.log(this.FormUpload.value);

  }

  uploadFile(file) {
    const formData = new FormData();
    formData.append('file', file.data);
    // console.log(file.data);

    file.inProgress = true;
    this.uploadService.upload(formData, this.ServerURL).pipe(
      map(event => {
        switch (event.type) {
          case HttpEventType.UploadProgress:
            file.progress = Math.round(event.loaded * 100 / event.total);
            break;
          case HttpEventType.Response:
            return event;
        }
      }),
      catchError((error: HttpErrorResponse) => {
        file.inProgress = false;
        return of(`${file.data.name} upload failed.`);
      })).subscribe((event: any) => {
        if (typeof (event) === 'object') {
          console.log(event.body);
        }
        this.FormUpload.patchValue({
          ImageNameOld: file.data.name,
          TypeImage: file.data.type
        });
        this.apiweb.CommandUpload(this.FormUpload.value).subscribe( (data: any[]) => {
          console.log(data);
        });
        this.CallListFileUploadDatabase();
    });

    // console.log(this.FormUpload.value);
  }

  private uploadFiles() {
    this.fileUpload.nativeElement.value = '';
    this.files.forEach(file => {
      this.uploadFile(file);
    });
    // /console.log(this.files);
  }


  onClick() {
    const fileUpload = this.fileUpload.nativeElement; fileUpload.onchange = () => {
      this.files  = [];
      // tslint:disable-next-line:prefer-for-of
      for (let index = 0; index < fileUpload.files.length; index++) {
        const file = fileUpload.files[index];
        this.files.push({ data: file, inProgress: false, progress: 0});
      }
      this.uploadFiles();
    };
    fileUpload.click();
  }

  getFile(fileInput) {
    this.yourFile = fileInput.target.files[0];
    // console.log(this.yourFile);
  }

  // เรียกข้อมูลไฟล์จากฐานข้อมูล
  CallListFileUploadDatabase(){
    this.uploadService.CallFilelist(this.apiweb.ApiAddress , this.email, this.department, 'CallFile', this.contentid).subscribe( (data: any[]) => {
      console.log(data);
      this.FileListUpload = data;
    });
  }
  // ลบไฟล์ออกจากลิสรายการอัพโหลด
  OnClickDeleteFile(fileselect, fileid){
    console.log('delete files select');
    console.log(this.FormUpload.value);
    this.files.splice(fileid, 1 );
    this.http.post(this.UrlAdd, { ImageNameOld: fileselect , Action: 'Del', ContentId : this.contentid}).subscribe(data => {
      // console.log(data);
      this.CallListFileUploadDatabase();
    });

  }
  // ส่งข้อมูลลบไฟล์ออกจากฐานข้อมูล

}
