import {Component, Input, OnInit} from '@angular/core';
import {FormBuilder} from '@angular/forms';
import {ApiwebService} from '../../../../apiweb.service';
import {ActivatedRoute, Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import {DatePipe} from '@angular/common';
import {CdkDragDrop, moveItemInArray} from '@angular/cdk/drag-drop';

@Component({
  selector: 'app-dashboardsubmenu',
  templateUrl: './dashboardsubmenu.component.html',
  styleUrls: ['./dashboardsubmenu.component.scss']
})
export class DashboardsubmenuComponent implements OnInit {

  // ส่วนข้อมูลพื้นฐาน
  // tslint:disable-next-line:no-input-rename
  @Input('MainMenuId') mainMenuId;
  public DataSubMenu;
  constructor(private formBuilder: FormBuilder, public apiweb: ApiwebService, private router: Router , public dialog: MatDialog, private datePipe: DatePipe, private route: ActivatedRoute ) { }

  ngOnInit(): void {
    console.log(this.mainMenuId);
  }

  drop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.DataSubMenu, event.previousIndex, event.currentIndex);
    console.log(this.DataSubMenu);
  }
  CAllDataMainMenu(){
    this.apiweb.CallAllMainmenuFaculty().subscribe( (data: any[]) => {
      this.DataSubMenu = data;
      console.log(this.DataSubMenu);
    });
  }
}
