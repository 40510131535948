<div class="row pl-3 pr-3 pb-3">
  <div class="col-12 bg-white">
    <h3 style="color: #f55e5e;">What's On</h3>
  </div>
  <div class="col-12">
    <ng-container *ngFor="let data of DataAll">
      <div class="row">
        <div class="col-5 bg-white">
          <ngx-qrcode
            [elementType]="elementType"
            [value]="this.AddressQRcode+data.Code"
            cssClass="aclass"
            errorCorrectionLevel="L">
          </ngx-qrcode>
        </div>
        <div class="col-7 bg-white p-3">
          <strong>{{data.TitleTh}}</strong>
        </div>
      </div>
    </ng-container>
  </div>
  <div class="col-12 text-right" style="background-color: #f55e5e;">
    <a href="trendyarts"><strong>More..</strong></a>
  </div>
</div>


